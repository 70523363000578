import { NavItem } from '@electro/fleets/src/components/SideBarNavigation/SideBarNavigation.types'
import { useUiStore } from '@electro/fleets/src/hooks/stores'
import { Tooltip, Typography, IconButton } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import clsx from 'clsx'
import Link from 'next/link'
import { RestrictToCountries } from '@electro/fleets/src/components/'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { usePopper } from 'react-popper'
import { useClickAway } from 'react-use'

interface Props {
  navItem: NavItem
}

const styles = {
  root: 'lg:py-3 py-1',
  disabled: 'hover:cursor-not-allowed opacity-50',
  link: {
    root: 'font-normal no-underline focus:text-primary focus:ring-opacity-0 whitespace-nowrap group/menuItem flex items-center justify-between',
    disabled: 'pointer-events-none opacity-40',
  },
  expanded: {
    root: 'px-10',
    menuItemContainer: 'flex flex-row items-center group-hover/menuItem:text-primary w-96 max-w-72',
    text: 'text-[1rem] pl-2 whitespace-normal text-center leading-normal  group-hover/menuItem:text-primary flex items-center',
  },
  contracted: {
    menuItemContainer:
      'flex flex-col items-center group-hover/menuItem:text-primary w-[calc(theme(spacing.24)-theme(spacing.2))]',
    text: 'text-[10px] whitespace-normal text-center leading-normal group-hover/menuItem:text-primary flex flex-col items-center',
  },
  line: {
    root: 'before:absolute before:w-[1px] before:h-full before:top-0 before:left-[14px]',
    inactive: 'before:bg-white before:bg-opacity-45',
    active: 'before:bg-primary before:bg-opacity-100',
  },
  text: {
    inactive: 'text-white hover:text-primary',
    active: 'text-primary',
  },
  subNavigation: {
    root: 'mb-3 z-10',
    item: 'relative lg:py-3 py-1',
    link: 'h-8',
    label: 'pl-[36px]',
    expanded: '!relative !transform-none',
    contracted: 'py-8 pr-8 pl-[18px] rounded-2xl bg-base border-2 border-secondary',
  },
}

export const SideBarNavigationItem = ({ navItem }: Props) => {
  const open = useUiStore((state) => state.openSubNavigationItems[navItem.label])
  const setOpen = useUiStore((state) => state.syncOpenSubNavigationItems)
  const expanded = useUiStore((state) => state.navigationExpanded)
  const toggleOpenMobileNavigation = useUiStore((state) => state.toggleOpenMobileNavigation)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles: popperStyles, attributes: popperAttr } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [72, 10],
          },
        },
      ],
    },
  )

  const hasSubNavigation = !!navItem.children

  useClickAway({ current: popperElement }, () => {
    if (!expanded) handleNavigationClick()
  })

  const handleNavigationClick = () => {
    setOpen({ [navItem.label]: !open })

    if (!hasSubNavigation) toggleOpenMobileNavigation()
  }

  const handleSubNavigationClick = () => toggleOpenMobileNavigation()

  return (
    <RestrictToCountries countries={navItem.showInCountries} key={navItem.url}>
      <Tooltip disabled={!navItem.toolTipSection || hasSubNavigation} key={navItem.url}>
        <Tooltip.Trigger ariaLabel={navItem.toolTipSection?.ariaLabel} delay={1000}>
          <li
            className={clsx({
              [styles.root]: true,
              [styles.disabled]: navItem.disabled,
            })}
          >
            <Link
              ref={setReferenceElement}
              onClick={handleNavigationClick}
              href={navItem.url}
              className={tw({
                [styles.link.root]: true,
                [styles.link.disabled]: navItem.disabled,
                [styles.text.inactive]: !navItem.active,
                [styles.text.active]: navItem.active,
              })}
            >
              <div
                className={tw({
                  [styles.expanded.menuItemContainer]: expanded,
                  [styles.contracted.menuItemContainer]: !expanded,
                })}
              >
                {navItem.icon}

                <Typography
                  className={tw({
                    [styles.expanded.text]: expanded,
                    [styles.contracted.text]: !expanded,
                    [styles.text.inactive]: !navItem.active,
                    [styles.text.active]: navItem.active,
                  })}
                >
                  {navItem.label}
                </Typography>
              </div>

              {hasSubNavigation && expanded && (
                <IconButton>
                  {open ? (
                    <ChevronUpIcon
                      className={tw({
                        [styles.text.active]: navItem.active,
                      })}
                    />
                  ) : (
                    <ChevronDownIcon
                      className={tw({
                        [styles.text.active]: navItem.active,
                      })}
                    />
                  )}
                </IconButton>
              )}
            </Link>
          </li>
        </Tooltip.Trigger>
        <Tooltip.Body width={200}>{navItem.toolTipSection?.text}</Tooltip.Body>
      </Tooltip>

      {open && hasSubNavigation && (
        <ul
          className={tw({
            [styles.subNavigation.root]: true,
            [styles.subNavigation.expanded]: expanded,
            [styles.subNavigation.contracted]: !expanded,
          })}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...popperAttr.popper}
        >
          {navItem.children?.map((subNav) => (
            <Tooltip disabled={!subNav.toolTipSection} key={subNav.url}>
              <Tooltip.Trigger ariaLabel={subNav.toolTipSection?.ariaLabel} delay={1000}>
                <li
                  className={clsx({
                    [styles.subNavigation.item]: true,
                    [styles.disabled]: subNav.disabled,
                    [styles.line.root]: true,
                    [styles.line.active]: subNav.active,
                    [styles.line.inactive]: !subNav.active,
                  })}
                >
                  <Link
                    href={subNav.url}
                    onClick={handleSubNavigationClick}
                    className={tw({
                      [styles.subNavigation.link]: true,
                      [styles.link.root]: true,
                      [styles.link.disabled]: subNav.disabled,
                      [styles.text.inactive]: !subNav.active,
                      [styles.text.active]: subNav.active,
                    })}
                  >
                    <div>
                      <Typography
                        className={tw({
                          [styles.subNavigation.label]: true,
                          [styles.text.inactive]: !subNav.active,
                          [styles.text.active]: subNav.active,
                        })}
                      >
                        {subNav.label}
                      </Typography>
                    </div>
                  </Link>
                </li>
              </Tooltip.Trigger>
              <Tooltip.Body width={200}>{subNav.toolTipSection?.text}</Tooltip.Body>
            </Tooltip>
          ))}
        </ul>
      )}
    </RestrictToCountries>
  )
}
