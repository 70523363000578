import { Card, Tooltip } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { RadioGroup } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React, { ReactNode } from 'react'

interface RadioCardProps {
  icon?: ReactNode
  value: string
  tooltip?: ReactNode
  label: ReactNode
  error?: boolean
}

const styles = {
  radioGroupOption: {
    active: 'ring-2 ring-tertiary rounded-lg outline-none',
  },

  radioCard: {
    root: 'flex hover:border-tertiary text-white border rounded-lg mb-2 px-2 py-4 justify-between items-center cursor-pointer transition-colors duration-200 ease-in-out',
    checked: 'bg-secondary/40',
    unchecked: 'bg-transparent',
    error: 'border-action-danger',
  },
  selectionIndicator: {
    root: 'flex items-center justify-center w-8 h-8 rounded-full border border-secondary transition-colors duration-200 ease-in-out',
    checked: 'bg-secondary',
    unchecked: 'bg-transparent',
    error: 'border-action-danger',
  },
}

const SelectionIndicator = ({ checked, error }: { checked: boolean; error?: boolean }) => (
  <div
    className={tw({
      [styles.selectionIndicator.root]: true,
      [styles.selectionIndicator.checked]: checked,
      [styles.selectionIndicator.unchecked]: !checked,
      [styles.selectionIndicator.error]: error,
    })}
  />
)

export const RadioCard = ({ label, icon, value, tooltip, error }: RadioCardProps) => (
  <RadioGroup.Option
    className={({ active }) => (active ? styles.radioGroupOption.active : '')}
    value={value}
  >
    {({ checked }) => (
      <Card
        density="none"
        className={tw({
          [styles.radioCard.root]: true,
          [styles.radioCard.checked]: checked,
          [styles.radioCard.unchecked]: !checked,
          [styles.radioCard.error]: error,
        })}
      >
        <div className="flex items-center gap-2">
          {icon}
          {label}
          <Tooltip>
            <Tooltip.Trigger ariaLabel="tooltip">
              <InformationCircleIcon className="text-tertiary w-5 h-5" />
            </Tooltip.Trigger>
            <Tooltip.Body>{tooltip}</Tooltip.Body>
          </Tooltip>
        </div>
        <SelectionIndicator checked={checked} error={error} />
      </Card>
    )}
  </RadioGroup.Option>
)
