import { OperationVariables, QueryHookOptions, LazyQueryResult, useLazyQuery } from '@apollo/client'
import FLEETS_DRIVER_RECEIPTS_QUERY from '@electro/fleets/graphql/fleetsDriverReceipts.graphql'

import { Query, QueryFleetsDriversReceiptsArgs } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsDriversReceipts = Pick<Query, 'fleetsDriversReceipts'>

type UseFetchFleetsDriversReceipts = [
  ApolloLazyQueryFunction<FetchFleetsDriversReceipts>,
  LazyQueryResult<FetchFleetsDriversReceipts, OperationVariables>,
]

type FetchFleetsDriversReceiptsArgs = QueryHookOptions<
  FetchFleetsDriversReceipts,
  QueryFleetsDriversReceiptsArgs
>

export function useFetchFleetsDriversReceipts(
  options?: FetchFleetsDriversReceiptsArgs,
): UseFetchFleetsDriversReceipts {
  const [lazyFetchFleetsDriversReceipts, FleetsDriversReceiptsResult] =
    useLazyQuery<FetchFleetsDriversReceipts>(FLEETS_DRIVER_RECEIPTS_QUERY, options)

  return [lazyFetchFleetsDriversReceipts, FleetsDriversReceiptsResult]
}
