import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import GET_PAYMENT_INSTRUCTION_SECRET from '@electro/fleets/graphql/fleetsGetPaymentInstructionSecretMutation.graphql'
import {
  Mutation,
  MutationFleetsGetPaymentInstructionSecretArgs,
} from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type GetPaymentInstructionSecretMutation = Pick<Mutation, 'fleetsGetPaymentInstructionSecret'>
type GetPaymentInstructionSecretReturnType = [
  ApolloMutationFunction<GetPaymentInstructionSecretMutation>,
  MutationResult<GetPaymentInstructionSecretMutation>,
]
type Options = MutationHookOptions<
  GetPaymentInstructionSecretMutation,
  MutationFleetsGetPaymentInstructionSecretArgs
>

export const useFleetsGetPaymentSecretMutation = (
  options?: Options,
): GetPaymentInstructionSecretReturnType => {
  const [getPaymentInstructionSecretMutation, getPaymentInstructionSecretResult] = useMutation(
    GET_PAYMENT_INSTRUCTION_SECRET,
    options,
  )

  return [getPaymentInstructionSecretMutation, getPaymentInstructionSecretResult]
}
