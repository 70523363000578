/**
 * TODO
 * - call drivers query.
 * - list all drivers with no card assigned.
 * - render drivers in a list of buttons like vehicle select (consider reuse)
 * - validate submit
 * - display correct responses from the API on Error
 * - Add label to select menu
 * - add error message to select menu
 */
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  LoadingDoubleRowPlaceholder,
  Modal,
  SelectMenu,
} from '@electro/shared-ui-components'
import { useDriverMenuActions } from '@electro/fleets/src/components/DriverActionsDropDownMenu/hooks'
import { FetchFleetsDrivers, useFleetsFetchDrivers } from '@electro/fleets/src/services'
import { useEffect, useMemo } from 'react'
import { EjnEjnTokenStatusChoices } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

interface ReassignCardModalScreenProps {
  onClose: () => void
}

interface DriverOption {
  name: string
  pk: number
}

const validationSchema = Yup.object().shape({
  driver: Yup.number().required('drivers.reassign_electrocard.validation.select_driver').nullable(),
})

const formatDriverEdgesForSelectMenu = (drivers: FetchFleetsDrivers): DriverOption[] =>
  drivers?.fleetsDrivers?.edges
    .map((driverEdge) => {
      if (
        !driverEdge.node.ejnCard ||
        driverEdge.node.ejnCard.status !== EjnEjnTokenStatusChoices.Active
      ) {
        return {
          name: `${driverEdge.node.user.firstName} ${driverEdge.node.user.lastName}`,
          pk: driverEdge.node.pk,
        }
      }
      return null
    })
    .filter((e) => e)
    .sort((a, b) => a.name.localeCompare(b.name))

/**
 * @deprecated Use `ReassignCardModalScreen` in DriversListV2/components/DriverWidgets/components instead
 */
export const ReassignCardModalScreen = ({ onClose }: ReassignCardModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading: submitting, errorMessage }, { reassignCard, resetErrorMessage }] =
    useDriverMenuActions()

  const formik = useFormik({
    initialValues: {
      driver: '',
    },
    validationSchema,
    onSubmit: (fieldValues) =>
      reassignCard({
        newDriver: parseInt(fieldValues.driver, 10),
        onSuccess: () => {
          onClose()
        },
      }),
  })

  const [fetchDriversQuery, { loading: driversLoading, data: drivers }] = useFleetsFetchDrivers({
    variables: {
      first: 500,
      offset: 0,
    },
  })

  useEffect(() => {
    fetchDriversQuery()
  }, [fetchDriversQuery])

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  const driversWithNoElectroCard = useMemo(() => formatDriverEdgesForSelectMenu(drivers), [drivers])

  return (
    <>
      <Modal.Header>{t('drivers.reassign_electrocard.heading.reassign_electrocard')}</Modal.Header>
      <Modal.Body data-testid="reassign-card-modal">
        {!driversLoading &&
          (driversWithNoElectroCard?.length > 0 ? (
            <>
              <p> {t('drivers.reassign_electrocard.body.choose_driver')}</p>
              <p className="font-medium">
                {t('drivers.reassign_electrocard.body.drivers_list_not_appear')}{' '}
              </p>
            </>
          ) : (
            <>
              <p className="text-action-danger font-medium">
                {t('drivers.reassign_card.body.all_drivers_assigned')}
              </p>
              <p className="text-action-danger ">
                {t('drivers.reassign_electrocard.body.driver_has_active_card')}
              </p>
            </>
          ))}
        <form id="reassign-card-form" onSubmit={formik.handleSubmit}>
          {driversLoading && !drivers ? <LoadingDoubleRowPlaceholder rows={2} /> : null}
          {!driversLoading && driversWithNoElectroCard?.length > 0 ? (
            <>
              <SelectMenu
                name="select-driver"
                placeholder={t('drivers.reassign_electrocard.autocomplete.placeholder')}
                items={driversWithNoElectroCard}
                onChange={({ pk }) => formik.setFieldValue('driver', pk)}
                staticPosition
              />
              {errorMessage ? <p className="text-action-danger mt-2">{errorMessage}</p> : null}
            </>
          ) : null}
        </form>
        {formik.errors.driver ? (
          <div className="text-action-danger pt-2">{formik.errors.driver}</div>
        ) : null}
      </Modal.Body>
      <Modal.Actions stacked>
        <Button disabled={submitting} form="reassign-card-form" type="submit" fullWidth>
          {submitting
            ? t('drivers.widgets.driver_details.driver_actions.loading.reassigning')
            : t('drivers.widgets.driver_details.driver_actions.reassign_card')}
        </Button>
        <Button disabled={submitting} variant="outline" onClick={onClose} fullWidth>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
