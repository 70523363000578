import { useMount } from '@electro/shared/hooks'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { create } from 'zustand'

interface AsPathStoreType {
  prevAsPath: string | undefined
  currentAsPath: string | undefined
  asPathHistory: string[]
}

const asPathStore = create<AsPathStoreType>((set) => ({
  /**
   * This will store the path and query params for the previous page
   * Useful for when the .back() router method
   * is not enough to go back to the previous page.
   */
  prevAsPath: undefined,
  currentAsPath: undefined,
  /**
   * This is a history of asPath the first element is the currentAsPath
   * the second element is the previous asPath and so on...
   * [currentAsPath, prevAsPath, ...]
   * This will preserve the path and query params for when the .back() router method
   * is not enough to go back to the previous page.
   */
  asPathHistory: [],
}))

/** use as a hook to get prevAsPath, currentAsPath, and asPathHistory */
export const useAsPath = () => asPathStore((state) => state)

/** Only use this in _app.tsx or root it's like a Provider */
export const useAsPathInitializer = () => {
  const router = useRouter()
  const { currentAsPath } = useAsPath()

  const prevActionRef = useRef(router.asPath)

  useEffect(() => {
    if (router.asPath !== prevActionRef.current) {
      asPathStore.setState((state) => ({
        ...state,
        currentAsPath: router.asPath,
        prevAsPath: currentAsPath,
        asPathHistory: [router.asPath, ...state.asPathHistory],
      }))
    }

    prevActionRef.current = router.asPath
  }, [router, currentAsPath])

  useMount(() => {
    asPathStore.setState((state) => ({
      ...state,
      currentAsPath: router.asPath,
      prevAsPath: undefined,
      asPathHistory: [router.asPath],
    }))
  })
}
