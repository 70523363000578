import { useErrorMessage } from '@electro/fleets/src/hooks'
import { ApolloError } from '@apollo/client'
import { Button, Input } from '@electro/shared-ui-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import useTranslation from 'next-translate/useTranslation'

interface Fields {
  code: string
}

interface Props {
  onSubmit: (fields: Fields) => void
  loading?: boolean
  error?: ApolloError | null
}

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('credit_and_referrals.redeem_code_modal.credit_code_required')
    .nullable(),
})

export const RedeemCreditCodeForm = ({ onSubmit, loading = false, error }: Props) => {
  const errorMessage = useErrorMessage(error)
  const { t } = useTranslation('common')

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ handleBlur, values, errors, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Input
            fullWidth
            placeholder={t('credit_and_referrals.redeem_code_modal.placeholder')}
            name="code"
            value={values.code || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={errors.code || errorMessage}
            disabled={loading}
            required
          />

          <Button fullWidth type="submit" loading={loading}>
            {t('credit_and_referrals.redeem_code_modal.button.redeem')}
          </Button>
        </form>
      )}
    </Formik>
  )
}
