import { Button, Modal, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { useCallback, useEffect, useState } from 'react'
import { useDriverMenuActions } from '../../hooks'

interface ResendSignupEmailModalScreenProps {
  onClose: () => void
}

export const ResendSignupEmailModalScreen = ({ onClose }: ResendSignupEmailModalScreenProps) => {
  const { t } = useTranslation('common')
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [{ loading, errorMessage, driver }, { sendDriverSignupEmail, resetErrorMessage }] =
    useDriverMenuActions()

  const handleResend = useCallback(
    () => sendDriverSignupEmail({ onSuccess: () => setEmailSent(true) }),
    [sendDriverSignupEmail],
  )

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return (
    <>
      <Modal.Header>
        {t('drivers.widgets.driver_details.driver_actions.resend_signup_email')}
      </Modal.Header>
      {emailSent ? (
        <>
          <Modal.Body data-testid="email-sent">
            <Image
              src="/images/mr-zap-checking-his-email.png"
              alt="illustration of mr zap and constantine checking their emails "
              width={218}
              height={278}
              className="m-auto mt-4 mb-8"
            />
            <p>
              <Trans
                i18nKey="common:drivers.widgets.driver_details.driver_actions.sign_up_email_sent"
                data-testid="driver-email"
                components={{ driverEmail: <strong>{driver.user.email}</strong> }}
              />
            </p>
          </Modal.Body>
          <Modal.Actions stacked>
            <Button variant="outline" onClick={onClose} fullWidth>
              {t('common.button.close')}
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Body data-testid="resend-email-confirmation">
            <Trans
              i18nKey="common:drivers.widgets.driver_details.driver_actions.this_will_send_email_to"
              components={{ driverEmail: <strong>{driver.user.email}</strong> }}
            />
            <br />
            <br />
            <Typography>
              {t('drivers.widgets.driver_details.driver_actions.email_will_send_link')}
            </Typography>
            {errorMessage ? <p className="text-action-danger mt-2">{errorMessage}</p> : null}
          </Modal.Body>
          <Modal.Actions>
            <Button
              fullWidth
              onClick={handleResend}
              disabled={loading}
              form="resend-signup-email-action"
            >
              {loading ? t('common.button.loading.sending') : t('common.button.send')}
            </Button>
            <Button fullWidth disabled={loading} variant="outline" onClick={onClose}>
              {t('common.button.cancel')}
            </Button>
          </Modal.Actions>
        </>
      )}
    </>
  )
}
