import { Alert, Card } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

interface CreditBalanceErrorProps {
  show: boolean
}

export const CreditBalanceError = ({ show }: CreditBalanceErrorProps) => {
  const { t } = useTranslation('common')

  return (
    show && (
      <Card density="none">
        <Alert variant="error" className="m-4">
          {t('credit_and_referrals.error.body')}
        </Alert>
      </Card>
    )
  )
}
