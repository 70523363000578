import { LoadingOverlay, Typography } from '@electro/shared-ui-components'

import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { FleetsDriverCreatePasswordSuccess } from '@electro/fleets/src/components/FleetsDriverCreatePassword'
import { usefleetsDriverSetPassword } from '@electro/fleets/src/services'
import { CreatePasswordForm } from '@electro/fleets/src/components/CreatePasswordForm'
import useTranslation from 'next-translate/useTranslation'

interface FleetsDriverCreatePasswordProps {
  singleTimeToken: string
}

export const FleetsDriverCreatePassword = ({
  singleTimeToken,
}: FleetsDriverCreatePasswordProps) => {
  const { t } = useTranslation('common')
  const [fleetsDriverSetPasswordMutation, { error, loading, data }] = usefleetsDriverSetPassword()

  const { errorNotification } = useErrorNotificationEffect({ error, message: error?.message })

  async function handlePasswordSubmit({ password }) {
    try {
      await fleetsDriverSetPasswordMutation({
        variables: {
          singleTimeToken,
          newPassword: password,
        },
      })
    } catch (err) {
      errorNotification()
    }
  }

  const successfullySubmitted = data?.fleetsDriverSetPassword.success

  return (
    <>
      {loading && <LoadingOverlay fixed />}
      {successfullySubmitted && <FleetsDriverCreatePasswordSuccess />}
      {!successfullySubmitted && (
        <div className="max-w-xl py-16 px-16">
          <div>
            <Typography variant="h1" className="text-center">
              {t('create_password.heading.your_company_signed_you_up')}
            </Typography>
            <Typography variant="h2" className="my-4 font-light">
              {t('create_password.body.enter_password')}
            </Typography>
            <CreatePasswordForm
              submitButtonText="common.button.submit"
              disabled={loading}
              onSubmit={handlePasswordSubmit}
            >
              <CreatePasswordForm.Fields />
              <div className="pt-6">
                <CreatePasswordForm.SubmitButton />
              </div>
            </CreatePasswordForm>
          </div>
        </div>
      )}
    </>
  )
}
