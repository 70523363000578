import {
  MonthPicker,
  OnboardingActions,
  RecentCharges,
} from '@electro/fleets/src/components/DashboardSummary/components'
import {
  DashboardSummaryProvider,
  useDashboardSummary,
} from '@electro/fleets/src/components/DashboardSummary/hooks'

const DashboardSummary = () => {
  const { startDate, handleSelectedDate } = useDashboardSummary()

  return (
    <div className="lg:px-10 lg:pb-10 px-4 pb-4">
      <MonthPicker value={startDate} onChange={handleSelectedDate} />

      <div className="flex -ml-8 flex-wrap-reverse ">
        <RecentCharges />

        <OnboardingActions />
      </div>
    </div>
  )
}

const DashboardSummaryWithProvider = () => (
  <DashboardSummaryProvider>
    <DashboardSummary />
  </DashboardSummaryProvider>
)

export { DashboardSummaryWithProvider as DashboardSummary }
