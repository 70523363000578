import React, { useMemo, PropsWithChildren } from 'react'
import { OrderingDirectionEnum } from '@electro/fleets/generated/graphql'
import { BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/24/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

export interface TableSortHeaderButtonProps<T> {
  fieldName: T
  orderBy: T
  orderDirection: OrderingDirectionEnum
  onClick: () => void
  label: string
}

const styles = {
  root: 'flex items-center font-medium underline',
  active: 'text-tertiary',
}

export const TableSortHeaderButton = <T extends unknown>({
  orderBy,
  orderDirection,
  onClick,
  label,
  fieldName,
}: PropsWithChildren<TableSortHeaderButtonProps<T>>) => {
  const active = useMemo(() => orderBy === fieldName, [fieldName, orderBy])
  const isAscendingOrder = useMemo(
    () => orderDirection === OrderingDirectionEnum.Asc,
    [orderDirection],
  )
  return (
    <button
      className={tw({
        [styles.root]: true,
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <span>{label}</span>
      {active && isAscendingOrder && (
        <BarsArrowUpIcon data-testid="ascending-icon" className="w-5 h-5 ml-2 flex-shrink-0" />
      )}
      {active && !isAscendingOrder && (
        <BarsArrowDownIcon data-testid="descending-icon" className="w-5 h-5 ml-2 flex-shrink-0" />
      )}
    </button>
  )
}
