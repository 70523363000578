import { Card, Tag, TagColours, Typography } from '@electro/shared-ui-components'
import { DriverType } from '@electro/fleets/generated/graphql'

interface Props {
  driver: DriverType
}

export const TagsWidget = ({ driver }: Props) => (
  <Card className="col-span-4 lg:p-8 p-3">
    <Typography variant="h3" className="flex-1">
      Tags
    </Typography>

    <div className="flex flex-wrap mt-4 lg:justify-start justify-center">
      {driver.tags.length
        ? driver.tags.map((tag) => (
            <Tag key={tag.pk} colour={tag.colour as TagColours} label={tag.name} />
          ))
        : '-'}
    </div>
  </Card>
)
