import { Skeleton } from '@electro/shared-ui-components'

export const AccountSelectSkeleton = () => (
  <div className="space-y-4">
    <div className="px-8 space-y-4 mb-8">
      <Skeleton height={75} />
      <Skeleton height={75} />
    </div>

    <Skeleton height={40} variant="circular" />
  </div>
)
