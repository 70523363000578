import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import {
  ModalScreenNames,
  ModalScreensEnum,
} from '@electro/fleets/src/components/DriverActionsDropDownMenu/components'
import {
  useFleetsAssignCardToDriver,
  useFleetsEditDriver,
  useFleetsDeactivateDriversCard,
  useFleetsReassignCardToDriver,
  useFleetsSendDriverSignupEmail,
  useFleetsSendResetPasswordEmail,
} from '@electro/fleets/src/services'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import { useRouter } from 'next/router'
import { DriverType, EjnEjnTokenStatusChoices } from '@electro/fleets/generated/graphql'
import { DocumentNode } from 'graphql'
import { clearFleetsDriversFromCache } from '@electro/fleets/src/utils/apolloUtils'
import { useDriversQueryParams } from '@electro/fleets/src/hooks'

interface DriverActionsProps {
  children: any
  driver?: DriverType
}

interface ActionArgs {
  onSuccess?: () => void
  onError?: () => void
  refetch?: Array<{ query: DocumentNode; variables: Record<string, any> }>
}

type DeactivateDriverArgs = ActionArgs
type ReactivateDriverArgs = ActionArgs
type DeactivateCardArgs = ActionArgs
type SendDriverSignUpEmailArgs = ActionArgs
type SendResetDriverPasswordEmailArgs = ActionArgs

interface AssignCardToDriverArgs extends ActionArgs {
  hexCode: string
}

interface ReassignCardArgs extends ActionArgs {
  newDriver: number
}

interface State {
  loading: boolean
  errorMessage: string
  driver: DriverType
  activeDriverMenu: number
  activeModalScreen: ModalScreenNames
  hasElectroCardAssigned: boolean
}

interface Handlers {
  editDriverDetails: (pk: number) => () => void
  viewDriverReceipts: () => void
  deactivateDriver: (args: DeactivateDriverArgs) => void
  reactivateDriver: (args: ReactivateDriverArgs) => void
  assignCardToDriver: (args: AssignCardToDriverArgs) => void
  deactivateCard: (args: DeactivateCardArgs) => void
  reassignCard: (args: ReassignCardArgs) => void
  resetErrorMessage: () => void
  sendResetDriverPasswordEmail: (args: SendResetDriverPasswordEmailArgs) => void
  sendDriverSignupEmail: (args: SendDriverSignUpEmailArgs) => void
  showActionConfirmationModal: (modalScreenEnum: ModalScreensEnum) => void
  closeDriverActionModal: () => void
}

type UseDriverMenuActionsContext = [State, Handlers]

const useDriverMenuActionsProvider = ({
  driver,
}: {
  driver: DriverType
}): UseDriverMenuActionsContext => {
  const { pk: driverPk } = driver
  const router = useRouter()

  const [fleetsEditDriverMutation, fleetsEditDriver] = useFleetsEditDriver()
  const [fleetsAssignCardMutation, fleetsAssignCard] = useFleetsAssignCardToDriver()
  const [fleetsDeactivateCardMutation, fleetsDeactivateCard] = useFleetsDeactivateDriversCard()
  const [fleetsReassignCardMutation, fleetsReassignCard] = useFleetsReassignCardToDriver()
  const [fleetsSendDriverSignupEmailMutation, fleetsSendDriverSignupEmail] =
    useFleetsSendDriverSignupEmail()
  const [fleetsSendResetPasswordEmailMutation, fleetsSendResetPasswordEmail] =
    useFleetsSendResetPasswordEmail()

  const [activeDriverMenu, setActiveDriverMenu] = useState<number>(null)
  const [activeModalScreen, setActiveModalScreen] = useState<ModalScreenNames>(null)

  const { queryParams } = useDriversQueryParams()

  const refetchVariables = useMemo(() => {
    const isActive = queryParams.additionalParams.isActive === 'true' ? true : null

    return {
      first: queryParams.first,
      offset: queryParams.offset,
      ordering: queryParams.ordering,
      search: queryParams.search,
      isActive,
    }
  }, [queryParams])

  // TODO: Map error codes to human readable error messages with defaults.
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const resetErrorMessage = useCallback(() => setErrorMessage(null), [])

  const deactivateDriver = useCallback(
    async ({ onSuccess, onError }: DeactivateDriverArgs = {}) => {
      try {
        await fleetsEditDriverMutation({
          variables: {
            pk: driverPk,
            isActive: false,
          },
          refetchQueries: [
            {
              query: FLEETS_DRIVERS_QUERY,
              variables: refetchVariables,
            },
          ],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            if (data.fleetsEditDriver.success) {
              clearFleetsDriversFromCache(cache)
            }
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [fleetsEditDriverMutation, driverPk, refetchVariables, resetErrorMessage],
  )

  const reactivateDriver = useCallback(
    async ({ onSuccess, onError }: ReactivateDriverArgs = {}) => {
      try {
        await fleetsEditDriverMutation({
          variables: {
            pk: driverPk,
            isActive: true,
          },
          refetchQueries: [
            {
              query: FLEETS_DRIVERS_QUERY,
              variables: refetchVariables,
            },
          ],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            if (data.fleetsEditDriver.success) {
              clearFleetsDriversFromCache(cache)
            }
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [fleetsEditDriverMutation, driverPk, refetchVariables, resetErrorMessage],
  )

  const assignCardToDriver = useCallback(
    async ({ onSuccess, onError, hexCode }: AssignCardToDriverArgs) => {
      try {
        await fleetsAssignCardMutation({
          variables: {
            hexCode,
            driver: driverPk,
          },
          refetchQueries: [{ query: FLEETS_DRIVERS_QUERY, variables: refetchVariables }],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            if (data.fleetsAssignCardToDriver.success) {
              clearFleetsDriversFromCache(cache)
            }
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [driverPk, refetchVariables, fleetsAssignCardMutation, resetErrorMessage],
  )

  const reassignCard = useCallback(
    async ({ onSuccess, onError, newDriver }: ReassignCardArgs) => {
      try {
        await fleetsReassignCardMutation({
          variables: {
            card: driver?.ejnCard?.pk,
            newDriver,
            oldDriver: driverPk,
          },
          refetchQueries: [{ query: FLEETS_DRIVERS_QUERY, variables: refetchVariables }],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            if (data.fleetsReassignCardToDriver.success) {
              clearFleetsDriversFromCache(cache)
            }
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [
      driver?.ejnCard?.pk,
      driverPk,
      refetchVariables,
      fleetsReassignCardMutation,
      resetErrorMessage,
    ],
  )

  const deactivateCard = useCallback(
    async ({ onSuccess, onError }: DeactivateCardArgs = {}) => {
      try {
        await fleetsDeactivateCardMutation({
          variables: {
            card: driver?.ejnCard?.pk,
            driver: driverPk,
          },
          refetchQueries: [{ query: FLEETS_DRIVERS_QUERY, variables: refetchVariables }],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            if (data.fleetsFreezeDriversCard.success) {
              clearFleetsDriversFromCache(cache)
            }
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [
      driver?.ejnCard?.pk,
      driverPk,
      refetchVariables,
      fleetsDeactivateCardMutation,
      resetErrorMessage,
    ],
  )

  const sendResetDriverPasswordEmail = useCallback(
    async ({ onSuccess, onError }: SendResetDriverPasswordEmailArgs) => {
      try {
        await fleetsSendResetPasswordEmailMutation({
          variables: {
            pk: driverPk,
          },
        })
        onSuccess()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [driverPk, fleetsSendResetPasswordEmailMutation],
  )

  const sendDriverSignupEmail = useCallback(
    async ({ onSuccess, onError }: SendResetDriverPasswordEmailArgs) => {
      try {
        await fleetsSendDriverSignupEmailMutation({
          variables: {
            pk: driver.pk,
          },
        })
        onSuccess?.()
        resetErrorMessage()
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [driver.pk, fleetsSendDriverSignupEmailMutation, resetErrorMessage],
  )

  const editDriverDetails = useCallback(
    () => () => router.push(`/dashboard/drivers/${driverPk}/edit`),
    [driverPk, router],
  )

  const viewDriverReceipts = useCallback(
    () => router.push(`/dashboard/reporting/driver/${driverPk}`),
    [driverPk, router],
  )

  const showActionConfirmationModal = useCallback(
    (modalScreenName: ModalScreenNames) => () => {
      setActiveDriverMenu(driverPk)
      setActiveModalScreen(modalScreenName)
    },
    [driverPk],
  )

  const closeDriverActionModal = useCallback(() => setActiveDriverMenu(null), [])

  const hasElectroCardAssigned = useMemo(
    () => driver?.ejnCard?.status === EjnEjnTokenStatusChoices.Active,
    [driver?.ejnCard],
  )

  const loading = useMemo(
    () =>
      fleetsEditDriver.loading ||
      fleetsAssignCard.loading ||
      fleetsDeactivateCard.loading ||
      fleetsReassignCard.loading ||
      fleetsSendResetPasswordEmail.loading ||
      fleetsSendDriverSignupEmail.loading,
    [
      fleetsAssignCard.loading,
      fleetsEditDriver.loading,
      fleetsDeactivateCard.loading,
      fleetsReassignCard.loading,
      fleetsSendDriverSignupEmail.loading,
      fleetsSendResetPasswordEmail.loading,
    ],
  )

  const state = {
    loading,
    errorMessage,
    driver,
    activeDriverMenu,
    activeModalScreen,
    hasElectroCardAssigned,
  }

  const handlers = {
    editDriverDetails,
    viewDriverReceipts,
    deactivateDriver,
    reactivateDriver,
    assignCardToDriver,
    reassignCard,
    deactivateCard,
    resetErrorMessage,
    sendResetDriverPasswordEmail,
    sendDriverSignupEmail,
    showActionConfirmationModal,
    closeDriverActionModal,
  }

  return [state, handlers]
}

const DriverActionsContext = createContext<UseDriverMenuActionsContext>(null)

const useDriverMenuActions = () => {
  const context = useContext(DriverActionsContext)
  if (!context) {
    throw new Error(
      `useDriverMenuActions() cannot be used outside the context of <DriverActions/> `,
    )
  }
  return context
}

const DriverActionsProvider = ({ children, driver }: DriverActionsProps) => {
  const context = useDriverMenuActionsProvider({ driver })
  return <DriverActionsContext.Provider value={context}>{children}</DriverActionsContext.Provider>
}

export { DriverActionsProvider, useDriverMenuActions }
