import { useFleetsFetchDrivers } from '@electro/fleets/src/services'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@electro/shared-ui-components'
import { format } from 'date-fns'
import { useMount } from 'react-use'

export const CurrentPricingPlan = () => {
  const [getDriversQuery, { data, loading, error }] = useFleetsFetchDrivers()
  const month = format(new Date(), 'MMMM')

  useMount(() =>
    getDriversQuery({
      variables: {
        first: 0,
        offset: 0,
      },
    }),
  )

  return (
    <Table loading={loading} error={!!error}>
      <TableHead>
        <TableRow>
          <TableCell>Month</TableCell>
          <TableCell>Price per driver</TableCell>
          <TableCell>Drivers</TableCell>
          <TableCell>Monthly running total</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {!loading && !error && (
          <TableRow data-testid="current-pricing-row">
            <TableCell>{month}</TableCell>
            <TableCell>Free-beta</TableCell>
            <TableCell>{data?.fleetsDrivers?.totalCount}</TableCell>
            <TableCell>Free-beta</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
