import { Input, Button, Typography, IconButton, Alert } from '@electro/shared-ui-components'
import { HavingProblems } from '@electro/fleets/src/components/BusinessOnboardingForm/components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { simplePasswordValidator } from '@electro/shared/utils/validators'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import {
  BusinessType,
  FormUserTypeEnum,
  SignUpFormFieldsEnum,
  SignUpStep,
  useSignUp,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import { useState } from 'react'

const { FIRST_NAME, LAST_NAME, PASSWORD, BUSINESS_CLASSIFICATION } = SignUpFormFieldsEnum

const formFieldsConfigByUserType = {
  [FormUserTypeEnum.NEW_USER]: {
    fields: {
      [FIRST_NAME]: true,
      [LAST_NAME]: true,
      [PASSWORD]: true,
    },
    validationSchema: Yup.object().shape({
      [FIRST_NAME]: Yup.string().required('Please tell us your first name'),
      [LAST_NAME]: Yup.string().required('Please tell us your last name'),
      [PASSWORD]: Yup.string()
        .required('We need a password to log you in')
        .min(8, 'Password should be at least 8 characters.')
        .test(PASSWORD, 'Password is too common', simplePasswordValidator),
    }),
  },
  [FormUserTypeEnum.EXISTING_USER]: {
    fields: {
      [FIRST_NAME]: true,
      [LAST_NAME]: true,
    },
    validationSchema: Yup.object().shape({
      [FIRST_NAME]: Yup.string().required('Please tell us your first name'),
      [LAST_NAME]: Yup.string().required('Please tell us your last name'),
    }),
  },
}

const extraInfoByBusinessType = (businessType: string) => {
  switch (businessType) {
    case BusinessType.PARTNERSHIP:
      return 'Please only input the name of the Partnership Director'
    case BusinessType.SOLE_TRADER:
      return 'Please only input the name of the Sole Trader'
    default:
      return null
  }
}

export const UserDetailsForm = () => {
  const [validateOnChange, setValidateOnChange] = useState(false)
  const [{ formData, userType }, { handleScreenChange, handleSignUpDataChange }] = useSignUp()

  const handleFormSubmit = (values, actions) => {
    handleScreenChange(SignUpStep.COMPLETE)()
    handleSignUpDataChange(values)
    actions.setSubmitting(false)
  }

  const initialFormValuesDerivedFromState = {
    [FIRST_NAME]: formData?.[FIRST_NAME] || '',
    [LAST_NAME]: formData?.[LAST_NAME] || '',
    [PASSWORD]: formData?.[PASSWORD] || '',
  }

  const showFieldByUserType = (fieldName: SignUpFormFieldsEnum) =>
    formFieldsConfigByUserType[userType].fields[fieldName]

  const extraInformation = extraInfoByBusinessType(formData[BUSINESS_CLASSIFICATION])

  return (
    <Formik
      initialValues={initialFormValuesDerivedFromState}
      onSubmit={handleFormSubmit}
      validationSchema={formFieldsConfigByUserType[userType].validationSchema}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
    >
      {({ handleChange, handleSubmit, errors, values, submitCount }) => (
        <>
          <IconButton
            className="absolute top-2 left-2 flex items-center"
            onClick={() => {
              handleScreenChange(SignUpStep.BUSINESS_PROFILE_DATA_FORM)()
              handleSignUpDataChange(values)
            }}
            aria-label="back to business details form"
          >
            <ArrowLeftIcon className="w-5 h-5 text-secondary" />
          </IconButton>

          {extraInformation ? (
            <Alert variant="info" className="mb-4">
              {extraInformation}
            </Alert>
          ) : null}

          <form onSubmit={handleSubmit}>
            {showFieldByUserType(FIRST_NAME) ? (
              <Input
                required
                onChange={handleChange}
                errorMessage={errors?.[FIRST_NAME] as string}
                name={FIRST_NAME}
                fullWidth
                placeholder="e.g. Jane"
                label="First Name"
                value={values[FIRST_NAME]}
              />
            ) : null}
            {showFieldByUserType(LAST_NAME) ? (
              <Input
                required
                onChange={handleChange}
                errorMessage={errors?.[LAST_NAME] as string}
                name={LAST_NAME}
                fullWidth
                label="Last Name"
                placeholder="e.g. Smith"
                value={values[LAST_NAME]}
              />
            ) : null}
            {showFieldByUserType(PASSWORD) ? (
              <Input
                required
                onChange={handleChange}
                password
                errorMessage={errors[PASSWORD] as string}
                name={PASSWORD}
                fullWidth
                infoText={
                  <ol className={errors[PASSWORD] ? '[&_li]:text-action-danger' : ''}>
                    <li>Must be more than 8 characters</li>
                    <li>
                      Not a common password like &ldquo;password&rdquo; and &ldquo;football&rdquo;
                    </li>
                    <li>Must not based on your name or email address</li>
                  </ol>
                }
                label="Password"
                placeholder="*********"
                value={values[PASSWORD]}
              />
            ) : null}

            <div className="mb-12">
              <Typography variant="small" as="p">
                By clicking sign up, you agree to Electroverse for Business&apos; &nbsp;
                <a
                  href="https://electroverse.octopus.energy/legal/business/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms &amp; Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://electroverse.octopus.energy/legal/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Typography>
            </div>
            <Button onClick={() => setValidateOnChange(true)} type="submit" fullWidth>
              Sign up
            </Button>
            <HavingProblems />
          </form>
        </>
      )}
    </Formik>
  )
}
