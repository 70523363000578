import { LinkIcon } from '@heroicons/react/16/solid'
import { Typography } from '@electro/server-components'
import { Button } from '@electro/shared-ui-components'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import useTranslation from 'next-translate/useTranslation'

export const DesktopShareButton = ({ show, handleCopyURL, referralURL }) => {
  const { t } = useTranslation('common')
  return show ? (
    <>
      <button onClick={handleCopyURL} className="flex items-center" data-testid="referralUrl">
        <LinkIcon className="text-secondary w-10" />
        <Typography variant="p" className="hover:underline">
          {referralURL}
        </Typography>
      </button>
      <Button onClick={handleCopyURL}>
        <Typography variant="p">{t('common.button.copy')}</Typography>
        <DocumentDuplicateIcon className="text-white max-w-10 w-11 max-h-6 ml-1" />
      </Button>
    </>
  ) : null
}
