import { Alert, Button, Card, Typography } from '@electro/shared-ui-components'
import { useFleetsFetchBusinessEntity } from '@electro/fleets/src/services'
import { WidgetSkeleton } from '@electro/fleets/src/skeletons'
import { useMount } from 'react-use'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { InformationItem } from '@electro/fleets/src/components/BusinessOverview/components/InformationItem'

export const DeliveryAddress = () => {
  const { t } = useTranslation('common')
  const [fetchBusinessEntity, { data, loading, error }] = useFleetsFetchBusinessEntity()
  const router = useRouter()

  useMount(() => fetchBusinessEntity())

  if (loading) return <WidgetSkeleton rows={5} />

  return (
    <Card data-testid="delivery-address-widget">
      {!!error && (
        <>
          <Typography variant="h3">{t('admin.delivery_address.heading')}</Typography>

          <Alert variant="error" className="mt-4">
            <div className="mb-2 text-lg ">{t('common.error.something_went_wrong')}</div>
            {error?.message}
          </Alert>
        </>
      )}

      {!!data && !error && (
        <>
          <div className="flex items-center space-x-2">
            <Typography variant="h3">{t('admin.delivery_address.heading')}</Typography>

            <Button
              data-testid="edit-delivery-address-button"
              size="xs"
              variant="naked"
              onClick={() => router.push('/dashboard/admin/delivery-address')}
            >
              <PencilIcon className="w-5 h-5" />
            </Button>
          </div>

          <div className="flex flex-col mt-4 gap-5">
            <InformationItem
              labelKey="admin.delivery_address.address_1"
              value={data?.fleetsBusinessEntity?.deliveryAddressLine1}
            />
            <InformationItem
              labelKey="admin.delivery_address.address_2"
              value={data?.fleetsBusinessEntity?.deliveryAddressLine2}
            />
            <InformationItem
              labelKey="common.city"
              value={data?.fleetsBusinessEntity?.deliveryCity}
            />
            <InformationItem
              labelKey="admin.delivery_address.address_4"
              value={data?.fleetsBusinessEntity?.deliveryAddressLine4}
            />
            <InformationItem
              labelKey="common.postcode"
              value={data?.fleetsBusinessEntity?.deliveryPostcode}
            />
          </div>
        </>
      )}
    </Card>
  )
}
