import { useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { SelectMenu } from '@electro/shared-ui-components'
import {
  CountryType,
  QueryCountriesArgs,
  useCountriesQuery,
} from '@electro/fleets/generated/graphql'

interface CountrySelectProps {
  /**
   * Preselect a country by passing an alpha2 string, then will filter query and set selected country
   */
  defaultCountryAlpha2?: string
  disabled?: boolean
  onSelect: (country: CountryType) => void
  errorMessage?: string
  /**
   * @filters - filters function
   */
  filter?: (countries: CountryType[]) => CountryType[]
  /**
   * @queryVariables - variables to pass directly to the countries query
   */
  queryVariables?: QueryCountriesArgs
  name?: string
  label?: string
  className?: string
}

export const CountrySelect = ({
  defaultCountryAlpha2,
  disabled = false,
  onSelect,
  errorMessage,
  filter,
  queryVariables,
  name = 'countryCode',
  label = 'common.label.country',
  className,
}: CountrySelectProps) => {
  const [selectedCountry, setSelectedCountry] = useState(null)
  const { t } = useTranslation('common')
  const { data, loading, error: queryError } = useCountriesQuery({ variables: queryVariables })

  const filteredItems = useMemo(() => {
    const items = data?.countries?.edges?.map((edge) => edge?.node) || []
    return filter ? filter(items) : items
  }, [data?.countries?.edges, filter])

  const error = useMemo(
    () => errorMessage || queryError?.message,
    [queryError?.message, errorMessage],
  )

  useEffect(() => {
    if (filteredItems.length > 0) {
      const country = filteredItems.find(
        (filteredCountry) => filteredCountry.alpha2 === defaultCountryAlpha2,
      )

      if (country) setSelectedCountry(country)
    }

    // eslint-disable-next-line
  }, [filteredItems])

  const handleSelect = (country: CountryType) => {
    onSelect(country)
    setSelectedCountry(country)
  }

  return (
    <SelectMenu
      value={selectedCountry}
      label={t(label) || t('common.label.country')}
      errorMessage={error}
      items={filteredItems}
      name={name}
      placeholder={t(loading ? 'common.loading.loading_ellipsis' : 'common.select')}
      disabled={loading || disabled}
      onChange={handleSelect}
      className={className}
    />
  )
}
