import { gql } from '@apollo/client'
/**
 * NOTE:
 * Mutations here are kept out of codegen because in the future they
 * will only exist on the sandbox endpoint and not in the staging schema.
 *
 * Keeping them separate here ensures that our current codegen setup
 * doesn't break.
 *
 * All other queries can be added to apps/fleets/graphql/*.graphql files
 * so they can be codegen'd with the rest of the schema.
 *
 * PLease prefix the mutation with SANDBOX_ to make it clear that
 * these are sandbox mutations
 */

export const SANDBOX_SPIN_UP_DEMO_MUTATION = gql`
  mutation fleetsSpinUpFleetDemo($demoRequestToken: String!) {
    fleetsSpinUpFleetDemo(demoRequestToken: $demoRequestToken) {
      payload
      refreshExpiresIn
      success
      token
      refreshToken
    }
  }
`
