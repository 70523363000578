import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input, Button } from '@electro/shared-ui-components'

import {
  AdminBusinessFormFields,
  AdminBusinessFormFieldNames,
} from '@electro/fleets/src/types/AdminForm'
import { postcodeValidator } from '@electro/shared/utils/validators'
import useTranslation from 'next-translate/useTranslation'

export interface AdminBusinessFormProps {
  sending?: boolean
  formFields?: AdminBusinessFormFields
  onSubmit?: (values: AdminBusinessFormFields) => void
  submitText?: string
  submittingText?: string
}

const { ADDRESS_1, ADDRESS_2, CITY, ADDRESS_4, POSTCODE } = AdminBusinessFormFieldNames
const adminUserDetailsValidationSchema = Yup.object().shape({
  [ADDRESS_1]: Yup.string().required('admin.edit_delivery_address.validation.address_1'),
  [ADDRESS_2]: Yup.string(),
  [CITY]: Yup.string().required('admin.edit_delivery_address.validation.city'),
  [ADDRESS_4]: Yup.string(),
  [POSTCODE]: Yup.string()
    .required('admin.edit_delivery_address.validation.postcode')
    .test('postcode', 'admin.edit_delivery_address.validation.test', postcodeValidator),
})

export const AdminBusinessForm = ({
  sending,
  onSubmit,
  submitText = 'admin.edit_delivery_address.button.save_details',
  submittingText = 'admin.edit_delivery_address.button.loading.saving',
  formFields,
}: AdminBusinessFormProps) => {
  const { t } = useTranslation('common')
  const formik = useFormik({
    initialValues: {
      [ADDRESS_1]: formFields?.[ADDRESS_1],
      [ADDRESS_2]: formFields?.[ADDRESS_2],
      [CITY]: formFields?.[CITY],
      [ADDRESS_4]: formFields?.[ADDRESS_4],
      [POSTCODE]: formFields?.[POSTCODE],
    },
    validationSchema: adminUserDetailsValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  const handlePostcodeChange = (event) => {
    formik.setFieldValue(POSTCODE, event.target.value?.toUpperCase())
  }

  return (
    <div aria-label="admin-business-details-form">
      <form onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <h3>{t('admin.delivery_address.heading')}</h3>
        <p>{t('admin.edit_delivery_address.form.body')}</p>
        <Input
          fullWidth
          name={ADDRESS_1}
          label={t('admin.delivery_address.address_1')}
          placeholder={t('placeholder_text.address_1')}
          value={formik.values[ADDRESS_1]}
          errorMessage={t(formik.errors[ADDRESS_1])}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={ADDRESS_2}
          label={t('admin.delivery_address.address_2')}
          placeholder={t('placeholder_text.address_2')}
          value={formik.values[ADDRESS_2]}
          errorMessage={t(formik.errors[ADDRESS_2])}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={CITY}
          label={t('common.city')}
          placeholder={t('placeholder_text.address_3')}
          value={formik.values[CITY]}
          errorMessage={t(formik.errors[CITY])}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={ADDRESS_4}
          label={t('admin.delivery_address.address_4')}
          placeholder={t('placeholder_text.address_4')}
          value={formik.values[ADDRESS_4]}
          errorMessage={t(formik.errors[ADDRESS_4])}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={POSTCODE}
          label={t('common.postcode')}
          placeholder={t('placeholder_text.postcode')}
          value={formik.values[POSTCODE]}
          errorMessage={t(formik.errors[POSTCODE])}
          onChange={handlePostcodeChange}
        />

        <div className="pt-8">
          <Button disabled={sending} data-testid="Submit" fullWidth type="submit">
            {sending ? t(submittingText) : t(submitText)}
          </Button>
        </div>
      </form>
    </div>
  )
}
