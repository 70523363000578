import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import FLEETS_ADD_TAG from '@electro/fleets/graphql/fleetsAddTagMutation.graphql'
import { Mutation, MutationFleetsAddTagArgs } from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsAddTagMutation = Pick<Mutation, 'fleetsAddTag'>

type FleetsAddTagReturnType = [
  ApolloMutationFunction<FleetsAddTagMutation>,
  MutationResult<FleetsAddTagMutation>,
]
type FleetsAddTagsArgs = MutationHookOptions<FleetsAddTagMutation, MutationFleetsAddTagArgs>

export const useFleetsAddTag = (options?: FleetsAddTagsArgs): FleetsAddTagReturnType => {
  const [addTagMutation, addTagMutationResults] = useMutation<FleetsAddTagMutation>(
    FLEETS_ADD_TAG,
    options,
  )

  return [addTagMutation, addTagMutationResults]
}
