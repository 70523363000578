import {
  Button,
  IconButton,
  Input,
  SelectMenu,
  Slider,
  Typography,
} from '@electro/shared-ui-components'
import { useFormik } from 'formik'
import {
  CompanyProfileDataFormFieldsEnum,
  CompanyProfileDataFormFields,
  SignUpStep,
  useSignUp,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { HavingProblems } from '../HavingProblems'

const businessSectorList = [
  { name: 'Transport' },
  { name: 'Logistics' },
  { name: 'Construction' },
  { name: 'Agriculture' },
  { name: 'Manufacturing' },
  { name: 'Retail' },
  { name: 'Wholesale' },
  { name: 'Healthcare' },
  { name: 'Education' },
  { name: 'Hospitality' },
  { name: 'Finance' },
  { name: 'Real Estate' },
  { name: 'IT' },
  { name: 'Other' },
]

const howDidYouHearAboutUs = [
  { name: 'Trade Show' },
  { name: 'Online Advertising' },
  { name: 'Referral from a Friend' },
  { name: 'Social Media' },
  { name: 'Email Marketing' },
  { name: 'Search Engine (Google)' },
  { name: 'Direct Mail' },
  { name: 'Word of Mouth' },
  { name: 'News Article' },
  { name: 'Local Event' },
  { name: 'Television Ad' },
  { name: 'Radio Ad' },
  { name: 'Podcast Recommendation' },
  { name: 'Other' },
]

const {
  SECTOR,
  HOW_HEARD,
  DRIVER_ESTIMATE,
  HOME_CHARING_ESTIMATE,
  WORKPLACE_CHARING_ESTIMATE,
  PUBLIC_CHARING_ESTIMATE,
} = CompanyProfileDataFormFieldsEnum

interface SelectItem {
  name: string
  value: string | number
}

export const BusinessProfileDataForm = () => {
  const [{ formData }, { handleScreenChange, handleSignUpDataChange }] = useSignUp()

  const handleFormSubmit = (values, actions) => {
    handleScreenChange(SignUpStep.USER_DETAILS_FORM)()
    handleSignUpDataChange(values)
    actions.setSubmitting(false)
  }

  const handleFormSkip = () => {
    handleScreenChange(SignUpStep.USER_DETAILS_FORM)()
  }

  const convertDemicalToPercentage = (number: number): number => {
    if (number) return Number((number * 100).toFixed(0))

    return 0
  }

  const { values, handleSubmit, setFieldValue, errors, handleChange } =
    useFormik<CompanyProfileDataFormFields>({
      initialValues: {
        [SECTOR]: formData?.[SECTOR] || '',
        [HOW_HEARD]: formData?.[HOW_HEARD] || '',
        [DRIVER_ESTIMATE]: formData?.[DRIVER_ESTIMATE] || 0,
        [HOME_CHARING_ESTIMATE]: convertDemicalToPercentage(formData?.[HOME_CHARING_ESTIMATE]),
        [WORKPLACE_CHARING_ESTIMATE]: convertDemicalToPercentage(
          formData?.[WORKPLACE_CHARING_ESTIMATE],
        ),
        [PUBLIC_CHARING_ESTIMATE]: convertDemicalToPercentage(formData?.[PUBLIC_CHARING_ESTIMATE]),
      },
      onSubmit: handleFormSubmit,
    })

  const handleBusinessSectorChange = (item: SelectItem) => {
    setFieldValue(SECTOR, item.name)
  }

  const handleHowDidYouHearAboutUsChange = (item: SelectItem) => {
    setFieldValue(HOW_HEARD, item.name)
  }

  const handleChargingEstimates = (fieldName, sliderValues: number[]) => {
    // We need to convert percentage to demical because backend accept values only from 0.00 to 1
    const valueDemical = sliderValues[0] / 100
    setFieldValue(fieldName, valueDemical)
  }

  return (
    <>
      <IconButton
        className="absolute top-2 left-2 flex items-center"
        onClick={() => {
          handleScreenChange(SignUpStep.BUSINESS_DETAILS_FORM)()
          handleSignUpDataChange(values)
        }}
        aria-label="back to business details form"
      >
        <ArrowLeftIcon className="w-5 h-5 text-secondary" />
      </IconButton>
      <form onSubmit={handleSubmit}>
        <SelectMenu
          label="Business Sector"
          items={businessSectorList}
          placeholder="e.g. Transport"
          name={SECTOR}
          onChange={handleBusinessSectorChange}
          errorMessage={errors[SECTOR] as string}
          initialSelectedItem={businessSectorList.find((item) => item.name === values[SECTOR])}
        />
        <SelectMenu
          items={howDidYouHearAboutUs}
          label="How did you hear about us?"
          placeholder="e.g. Trade Show"
          name={HOW_HEARD}
          onChange={handleHowDidYouHearAboutUsChange}
          errorMessage={errors[HOW_HEARD] as string}
          initialSelectedItem={howDidYouHearAboutUs.find((item) => item.name === values[HOW_HEARD])}
        />

        <Input
          fullWidth
          value={values[DRIVER_ESTIMATE]}
          label="How many Electric Vehicles do you have?"
          type="number"
          name={DRIVER_ESTIMATE}
          onChange={handleChange}
          placeholder="e.g. 5"
          errorMessage={errors?.[DRIVER_ESTIMATE]}
        />

        <Typography variant="small" className="mt-10 pl-2">
          Roughly, where do your EVs complete charging?
        </Typography>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) =>
            handleChargingEstimates(PUBLIC_CHARING_ESTIMATE, sliderValues)
          }
          initialValues={[values[PUBLIC_CHARING_ESTIMATE]]}
          name={PUBLIC_CHARING_ESTIMATE}
        >
          {({ values: sliderValues }) => (
            <div className="mt-6">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Public charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) =>
            handleChargingEstimates(WORKPLACE_CHARING_ESTIMATE, sliderValues)
          }
          initialValues={[values[WORKPLACE_CHARING_ESTIMATE]]}
          name={WORKPLACE_CHARING_ESTIMATE}
        >
          {({ values: sliderValues }) => (
            <div className="mt-10">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Workplace charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>

        <Slider
          min={0}
          max={100}
          onChange={(sliderValues) => handleChargingEstimates(HOME_CHARING_ESTIMATE, sliderValues)}
          initialValues={[values[HOME_CHARING_ESTIMATE]]}
          name={HOME_CHARING_ESTIMATE}
        >
          {({ values: sliderValues }) => (
            <div className="mt-10">
              <Slider.Label>
                <div className="flex justify-between">
                  <Typography variant="small">Home charging</Typography>
                  <Typography variant="small">{sliderValues[0]}%</Typography>
                </div>
              </Slider.Label>
              <Slider.Rail />
            </div>
          )}
        </Slider>

        <div className="flex justify-between space-x-4 mt-8">
          <Button variant="outline" fullWidth onClick={handleFormSkip}>
            Skip
          </Button>

          <Button type="submit" fullWidth>
            Continue
          </Button>
        </div>

        <HavingProblems />
      </form>
    </>
  )
}
