import { Card, Typography } from '@electro/shared-ui-components'
import { Loader } from '@electro/fleets/src/components/DashboardSummary/components'
import { MinusIcon } from '@heroicons/react/24/solid'

interface Props {
  title?: string
  metric?: number | string
  loading?: boolean
  icon?: React.ReactNode
  noDataText: string
}

export const MetricWidget = ({ loading, metric, title, icon, noDataText }: Props) => (
  <Card frosted className="h-56 flex-1 flex flex-col p-6 justify-between">
    <div className="flex justify-between">
      <Typography variant="h6">{title}</Typography>
      {icon}
    </div>

    {loading && (
      <div className="h-full">
        <Loader />
      </div>
    )}

    {!loading && !metric && (
      <Typography variant="p" className="text-tertiary">
        {noDataText || (
          <MinusIcon className="w-6 h-6 absolute bottom-6 left-6" data-testid="minus-icon" />
        )}
      </Typography>
    )}

    {!loading && metric && (
      <Typography variant="h1" className="text-tertiary font-normal">
        {metric}
      </Typography>
    )}
  </Card>
)
