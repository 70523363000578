import { DataGridRowDef } from '../DataGrid'

/**
 * This function remove the uuid key is assigned to row object. The uuid key is
 * needed to be used when render list of rows and keep track on data grid changes
 */
export const cleanRow = (row: DataGridRowDef) => {
  const copyRow = { ...row }
  delete copyRow.key

  return copyRow
}
