import { create } from 'zustand'
import { subDays } from 'date-fns'
import { DateRangeType } from '@electro/shared-ui-components'

const today = new Date()
const thirtyDaysAgo = subDays(today, 30)

const INITIAL_DATE_RANGE = {
  startDate: thirtyDaysAgo,
  endDate: today,
}

const INITIAL_DATE_LIMITS = {
  startDate: null,
  endDate: null,
}

interface DateRange {
  startDate: Date
  endDate: Date
}

interface State {
  dateRange: DateRange
  dateLimits: DateRangeType
  setDateRange: (dateRange: DateRange) => void
  setDateLimits: (dateLimits: DateRangeType) => void
  resetDateRange: () => void
}

export const useDateRangeStore = create<State>((set) => ({
  dateRange: INITIAL_DATE_RANGE,
  dateLimits: INITIAL_DATE_LIMITS,
  setDateRange: (dateRange: DateRangeType) =>
    set(() => ({
      dateRange: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    })),
  resetDateRange: () =>
    set({
      dateRange: INITIAL_DATE_RANGE,
    }),
  setDateLimits: (dateLimits: DateRangeType) =>
    set(() => ({
      dateLimits: {
        startDate: dateLimits.startDate,
        endDate: dateLimits.endDate,
      },
    })),
}))
