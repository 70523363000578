import { Button, Typography } from '@electro/shared-ui-components'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import useTranslation from 'next-translate/useTranslation'

export const MobileShareButton = ({ show, handleCopyURL }) => {
  const { t } = useTranslation('common')
  return show ? (
    <div className="flex w-2/3 m-auto mt-4">
      <Button fullWidth onClick={handleCopyURL}>
        <Typography className="mr-2">{t('common.button.share')}</Typography>
        <ArrowUpOnSquareIcon className="text-white w-5" />
      </Button>
    </div>
  ) : null
}
