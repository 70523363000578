export interface SignUpFormExistingUserProps {
  onSuccess: () => void
}

export enum SignUpFormExistingUserFieldNamesEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_NAME = 'companyName',
  COMPANIES_HOUSE_NUMBER = 'companyHouseNumber',
  COMPANIES_HOUSE_IS_VALID = 'companyHouseIsValid',
}

const { FIRST_NAME, LAST_NAME, COMPANY_NAME, COMPANIES_HOUSE_NUMBER, COMPANIES_HOUSE_IS_VALID } =
  SignUpFormExistingUserFieldNamesEnum

export interface SignUpFormExistingUserFieldValues {
  [FIRST_NAME]: string
  [LAST_NAME]: string
  [COMPANY_NAME]: string
  [COMPANIES_HOUSE_NUMBER]: string
  [COMPANIES_HOUSE_IS_VALID]: boolean
}
