import FLEETS_ADMIN_SET_PASSWORD_MUTATION from '@electro/fleets/graphql/fleetsAdminSetPasswordMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsAdminSetPasswordArgs } from '@electro/fleets/generated/graphql'

type FleetsAdminSetPasswordMutation = Pick<Mutation, 'fleetsAdminSetPassword'>

type UseFleetsAdminSetPassword = [
  ApolloMutationFunction<FleetsAdminSetPasswordMutation>,
  MutationResult<FleetsAdminSetPasswordMutation>,
]

type UseFleetsAdminSetPasswordArgs = MutationHookOptions<
  FleetsAdminSetPasswordMutation,
  MutationFleetsAdminSetPasswordArgs
>

export function useFleetsAdminSetPassword(
  options?: UseFleetsAdminSetPasswordArgs,
): UseFleetsAdminSetPassword {
  const [mutation, result] = useMutation<FleetsAdminSetPasswordMutation>(
    FLEETS_ADMIN_SET_PASSWORD_MUTATION,
    options,
  )

  return [mutation, result]
}
