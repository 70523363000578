import Image from 'next/image'
import { Typography } from '@electro/shared-ui-components'

export const FleetsErrorMessage = () => {
  const styles = {
    root: 'mx-auto h-screen w-screen flex flex-col justify-center items-center content-center text-center bg-gradient-mesh p-10',
    logo: 'flex justify-center pb-5 mb-10',
    title: 'text-3xl text-white',
    copy: 'px-12 text-lg text-white',
  }
  return (
    <div className={styles.root}>
      <div>
        <div className={styles.logo}>
          <Image
            src="/images/404-van.svg"
            alt="mr zap is exhausted next to a 404 error van"
            width={600}
            height={600}
            priority
          />
        </div>
        <Typography variant="h1" className={styles.title}>
          Something went wrong!
        </Typography>
        <Typography variant="p" className={styles.copy}>
          Sorry about that - we&#39;re looking into it. Please check back soon!
        </Typography>
      </div>
    </div>
  )
}
