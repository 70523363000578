import { Button, Modal } from '@electro/shared-ui-components'
import { useDriverMenuActions } from '@electro/fleets/src/components/DriverActionsDropDownMenu/hooks'
import { useCallback, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

interface ReactivateDriverModalScreenProps {
  onClose: () => void
}

export const ReactivateDriverModalScreen = ({ onClose }: ReactivateDriverModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage }, { reactivateDriver, resetErrorMessage }] =
    useDriverMenuActions()
  const handleConfirmation = useCallback(() => {
    reactivateDriver({ onSuccess: onClose })
  }, [reactivateDriver, onClose])

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return (
    <>
      <Modal.Header>
        {t('drivers.widgets.driver_details.driver_actions.reactivate_driver')}
      </Modal.Header>
      <Modal.Body data-testid="Reactivate-driver-modal">
        {!!errorMessage && <div className="text-action-danger mt-2">{errorMessage}</div>}{' '}
      </Modal.Body>
      <Modal.Actions stacked>
        <Button onClick={handleConfirmation} disabled={loading} fullWidth>
          {loading
            ? t('admin.reactivate_admin_user_modal.button.loading.reactivating')
            : t('admin.reactivate_admin_user_modal.button.reactivate')}
        </Button>
        <Button disabled={loading} variant="outline" onClick={onClose} fullWidth>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
