import { Widget } from '@typeform/embed-react'
import { Alert, Card } from '@electro/shared-ui-components'

export const WorkplaceCharging = () => (
  <div className="flex-1 flex justify-center">
    <div className="flex-1 flex flex-col max-w-184">
      <Alert variant="info" className="mb-8">
        Get up to a £350 grant per socket through the Government&apos;s Workplace Charging Scheme!
        Find out more &nbsp;
        <a
          href="https://www.find-government-grants.service.gov.uk/grants/workplace-charging-scheme-2"
          rel="noreferrer"
          target="_blank"
          className="text-secondary font-medium"
        >
          here
        </a>
      </Alert>

      <div className="flex-1 flex" data-testid="workplace-typeform">
        <Card density="none" className="flex-1">
          <Widget id="ihqvsshU" className="h-full w-full [&>div>iframe]:rounded-2xl" />
        </Card>
      </div>
    </div>
  </div>
)
