import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'
import { Mutation, MutationFleetsBulkCardOrderArgs } from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import FLEETS_BULK_CARD_ORDER_MUTATION from '@electro/fleets/graphql/fleetsBulkCardOrderMutation.graphql'

type BulkCardOrderMutation = Pick<Mutation, 'fleetsBulkCardOrder'>

type BulkCardOrderReturnType = [
  ApolloMutationFunction<BulkCardOrderMutation>,
  MutationResult<BulkCardOrderMutation>,
]

type Options = MutationHookOptions<BulkCardOrderMutation, MutationFleetsBulkCardOrderArgs>

export const useFleetsBulkCardOrder = (options?: Options): BulkCardOrderReturnType => {
  const [bulkCardOrderMutation, bulkCardOrder] = useMutation(
    FLEETS_BULK_CARD_ORDER_MUTATION,
    options,
  )

  return [bulkCardOrderMutation, bulkCardOrder]
}
