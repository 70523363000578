import { createContext, useContext, ReactNode } from 'react'
import { BusinessEntity, useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'

interface State {
  onboardingFlags: Partial<BusinessEntity['onboardingFlags']>
  incomplete: boolean
  complete: boolean
}

interface OnboardingStatusProps {
  children: ReactNode | ReactNode[] | ((state: State) => any)
}

type UseOnboardingStatus = [state: State]

const OnboardingStatusContext = createContext<UseOnboardingStatus>(null)

function useOnboardingStatusProvider(): UseOnboardingStatus {
  /**
   * The <OnboardingStatus/> provider is not strictly necessary here as we are mapping global state.
   * For clean component semantics we are forcing the pattern below over adding each
   * component part as an individual element:
   *
   * <OnboardingStatus>
   *   <OnboardingStatus.Incomplete/>
   *   <OnboardingStatus.AddressRequired/>
   *   <OnboardingStatus.PaymentRequired/>
   *   <OnboardingStatus.Complete/>
   * </OnboardingStatus>
   */
  const onboardingFlags = useBusinessEntityStore((state) => state.businessEntity.onboardingFlags)

  const incomplete = onboardingFlags
    ? Object.values(onboardingFlags).some((flag) => flag !== true)
    : null

  const complete = onboardingFlags ? Object.values(onboardingFlags).every((flag) => flag) : null

  return [{ onboardingFlags, incomplete, complete }]
}

const useOnboardingStatus = (): UseOnboardingStatus => {
  const context = useContext(OnboardingStatusContext)
  if (!context)
    throw new Error('Compound component cannot be used outside of <OnboardingStatus/> wrapper')
  return context
}

const OnboardingStatus = ({ children }: OnboardingStatusProps) => {
  const ctx = useOnboardingStatusProvider()
  return (
    <OnboardingStatusContext.Provider value={ctx}>
      <OnboardingStatusRenderPropProvider>{children}</OnboardingStatusRenderPropProvider>
    </OnboardingStatusContext.Provider>
  )
}

/**
 * For a little extra flexibility return the flags for direct consumption
 * <OnboardingStatus>
 *  {(flags) => <YourComponent/>}
 * </OnboardingStatus>
 */
const OnboardingStatusRenderPropProvider = ({ children }) => {
  const [state] = useOnboardingStatus()
  if (typeof children === 'object') return children
  return children(state as State)
}

const Incomplete = ({ children }) => {
  const [{ incomplete }] = useOnboardingStatus()
  return incomplete ? children : null
}

const AddressRequired = ({ children }) => {
  const [{ onboardingFlags }] = useOnboardingStatus()
  return !onboardingFlags?.hasDeliveryAddress && !onboardingFlags?.hasPaymentMethod
    ? children
    : null
}

const PaymentRequired = ({ children }) => {
  const [{ onboardingFlags }] = useOnboardingStatus()
  return onboardingFlags?.hasDeliveryAddress && !onboardingFlags?.hasPaymentMethod ? children : null
}

const Complete = ({ children }) => {
  const [{ complete }] = useOnboardingStatus()
  return complete ? children : null
}

OnboardingStatus.Incomplete = Incomplete
OnboardingStatus.AddressRequired = AddressRequired
OnboardingStatus.PaymentRequired = PaymentRequired
OnboardingStatus.Complete = Complete

export { OnboardingStatus }
