import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Input, Button } from '@electro/shared-ui-components'
import { simplePasswordValidator } from '@electro/shared/utils/validators'
import useTranslation from 'next-translate/useTranslation'

export interface NewPasswordFormProps {
  loading?: boolean
  error?: Partial<ApolloError>
  onSubmit?: (values: { password: string }) => void
  submitText?: string
  submittingText?: string
}

const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('create_password.validation.password_required')
    .min(8, 'create_password.validation.password_min_characters')
    .test('password', 'create_password.validation.too_common', simplePasswordValidator),
})

const styles = {
  errorMessage: 'text-action-danger pt-2',
}

export const NewPasswordForm = ({
  loading,
  error,
  onSubmit,
  submitText = 'new_password.button.save_password',
  submittingText = 'common.loading.button.save',
}: NewPasswordFormProps) => {
  const { t } = useTranslation('common')
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: newPasswordValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  return (
    <div aria-label="new-password-form">
      <h3>{t('create_password.heading.change_password')}</h3>
      <form onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <Input
          fullWidth
          name="password"
          password
          label={t('reset_password.heading.new_password')}
          placeholder="********"
          value={formik.values.password}
          errorMessage={t(formik.errors.password)}
          onChange={formik.handleChange}
        />

        <div className="pt-8">
          <Button data-testid="Submit" fullWidth type="submit">
            {loading ? t(submittingText) : t(submitText)}
          </Button>
        </div>
        {!!error && (
          <div className={styles.errorMessage}>
            {error.message ? error.message : t('errors.something_went_wrong')}
          </div>
        )}
      </form>
    </div>
  )
}
