import { Skeleton } from '@electro/shared-ui-components'
import { v1 as uuid } from 'uuid'

interface Props {
  subtitle?: boolean
  rows?: number
}

export const FormSkeleton = ({ subtitle, rows = 2 }: Props) => (
  <div data-testid="form-skeleton" className="flex-1 flex flex-col">
    <div className="mb-8 space-y-4">
      <Skeleton data-testid="form-skeleton-title" width={200} />
      {subtitle && <Skeleton data-testid="form-skeleton-title" width={350} />}
    </div>

    {Array.from(Array(rows)).map(() => (
      <div data-testid="form-skeleton-input" key={uuid()} className="mb-4">
        <Skeleton width={150} className="mb-2" />
        <Skeleton height={44} />
      </div>
    ))}

    <Skeleton data-testid="form-skeleton-button" variant="circular" height={40} className="mt-4" />
  </div>
)
