import { Alert, Button, Card, Typography } from '@electro/shared-ui-components'
import { AccountSelectButton } from '@electro/fleets/src/components/AccountSelect/components'
import { AccountType, useFleetsCurrentAdminAccountsQuery } from '@electro/fleets/generated/graphql'

import { FLEETS_ACCOUNT_ID } from '@electro/fleets/src/constants/localStorage'
import { useLocalStorage } from 'react-use'
import { client } from '@electro/fleets/client'
import { useRouter } from 'next/router'
import { AccountSelectSkeleton } from '@electro/fleets/src/components/AccountSelect/AccountSelectSkeleton'
import { useErrorMessage, useFleetsAuth } from '@electro/fleets/src/hooks'

const styles = {
  root: 'text-center',
  header: 'px-8 mb-4',
  content: 'px-8 mb-8',
  title: 'mb-4',
  subtitle: 'mb-4',
  description: 'text-left',
  accounts: ' space-y-4',
}

export const AccountSelect = () => {
  const { push } = useRouter()
  const { handleAccountSelect } = useFleetsAuth()
  const { data, loading, error } = useFleetsCurrentAdminAccountsQuery()
  const [activeAccount, setActiveAccount] = useLocalStorage(FLEETS_ACCOUNT_ID, null)
  const errorMessage = useErrorMessage(error)

  const accountSelect = (account: AccountType) => {
    setActiveAccount(account.id)
    client.resetStore()
    handleAccountSelect(account)
  }

  const submitAccountSelect = () => push('/dashboard')

  return (
    <Card frosted className="max-w-md w-full">
      <div className={styles.root}>
        <div className={styles.header}>
          <Typography className={styles.subtitle}>
            Which business account would you like to view?
          </Typography>

          <Typography variant="h5" className={styles.description}>
            Select account:
          </Typography>
        </div>

        {!!error && !loading && <Alert variant="error">{errorMessage}</Alert>}

        {loading && !error && <AccountSelectSkeleton />}

        {!!data && !loading && !error && (
          <>
            <div className={styles.content}>
              <div className={styles.accounts}>
                {data?.fleetsCurrentAdminAccounts?.map((account) => (
                  <AccountSelectButton
                    key={account.id}
                    isSelected={account.id === activeAccount}
                    onClick={() => accountSelect(account)}
                  >
                    {account?.entityName}
                  </AccountSelectButton>
                ))}
              </div>
            </div>

            <Button fullWidth disabled={!activeAccount} onClick={submitAccountSelect}>
              Let&apos;s go!
            </Button>
          </>
        )}
      </div>
    </Card>
  )
}
