import { PlusIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { Button, Modal } from '@electro/shared-ui-components'
import {
  FileUploadModalScreen,
  InitialModalScreen,
  FileUploadCompleteModalScreen,
} from '@electro/fleets/src/components/BulkDriverUploadButton/components'
import useTranslation from 'next-translate/useTranslation'
import {
  BulkDriverUploadProvider,
  useBulkDriverUpload,
  BulkUploadModalScreensEnum,
} from './hooks/useBulkDriverUpload'

const BulkDriverUploadButton = () => {
  const { t } = useTranslation('common')
  const [{ modalOpen, activeModalScreen }, { toggleModal, setActiveModalScreen }] =
    useBulkDriverUpload()

  const handleClose = () => {
    toggleModal()
  }

  const handleReset = () => {
    setActiveModalScreen(BulkUploadModalScreensEnum.INITIAL)
  }

  return (
    <>
      <Button onClick={toggleModal} fullWidth>
        <UserGroupIcon className="w-6 h-6" />{' '}
        <PlusIcon className="relative w-2 h-2 mr-1 -top-2 -left-1" />
        {t('drivers.button.bulk_upload_drivers')}
      </Button>

      <Modal open={modalOpen} onClose={handleClose} size="xl" onAfterLeave={handleReset}>
        <Modal.CloseButton />
        {activeModalScreen === BulkUploadModalScreensEnum.INITIAL ? <InitialModalScreen /> : null}
        {activeModalScreen === BulkUploadModalScreensEnum.UPLOAD ? <FileUploadModalScreen /> : null}
        {activeModalScreen === BulkUploadModalScreensEnum.UPLOAD_COMPLETE ? (
          <FileUploadCompleteModalScreen />
        ) : null}
      </Modal>
    </>
  )
}

const BulkDriverUploadButtonWithProvider = () => (
  <BulkDriverUploadProvider>
    <BulkDriverUploadButton />
  </BulkDriverUploadProvider>
)

export { BulkDriverUploadButtonWithProvider as BulkDriverUploadButton }
