import { format } from 'date-fns'
import { Typography } from '@electro/server-components'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'

type creditProps = {
  amount: number
  currency: string
  createdAt: string
}

interface CreditHistoryItemProps {
  credit: creditProps
  locale: string
}

export const CreditHistoryItem = ({ credit, locale }: CreditHistoryItemProps) => {
  const formatDate = (createdAt: string) => format(new Date(new Date(createdAt)), 'dd MMMM yyyy')

  return (
    <div key={credit.createdAt} className="flex justify-between pl-4 pr-16 py-3 ">
      {formatDate(credit.createdAt)}
      <Typography className="!text-action-success" data-testid="creditHistoryItem">
        + &nbsp;
        {formatPriceToLocalisedValue({
          price: credit?.amount,
          currency: credit?.currency,
          locale,
          isFloat: true,
        })}
      </Typography>
    </div>
  )
}
