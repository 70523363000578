import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react'
import { downloadFile } from '@electro/shared/utils/downloadFile'

interface CommonArgs {
  /**
   * required - Your fetch function, this must return a promise that resolves to a blob (file binary)
   */
  fetcher: () => Promise<Blob>
  /**
   * required - the filename for the file to download
   */
  filename: string
  /*
   * optional - called when the file is successfully downloaded
   */
  onSuccess?: () => void
  /*
   * optional - called when the file fails to download
   */
  onError?: (err: unknown) => void
}

interface UseDownloadFileProps extends CommonArgs {
  children: ({
    downloading,
    error,
    success,
    fetchFileThenDownload,
  }: StateWithHandlersIntersection) => Element | ReactNode | ReactNode[]
}

type UseDownloadFileArgs = CommonArgs

interface State {
  downloading: boolean
  error: boolean
  success: boolean
}

interface Handlers {
  fetchFileThenDownload: () => Promise<void>
}

type StateWithHandlersIntersection = State & Handlers

type UseDownloadFile = [state: State, handlers: Handlers]

const UseDownloadFileContext = createContext<UseDownloadFile>(null)

/**
 * useDownloadFile() is a hook that allows you to download a file from a fetcher function
 * you'll need to provide a fetcher function that returns a promise that resolves to a blob
 *
 * @returns a tuple containing the state and handlers
 */
export const useDownloadFile = ({
  fetcher,
  filename,
  onSuccess,
  onError,
}: UseDownloadFileArgs): UseDownloadFile => {
  const [downloading, setDownLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const fetchFileThenDownload = useCallback(async () => {
    try {
      setDownLoading(true)
      const blob = await fetcher()
      downloadFile(blob, filename)
      setSuccess(true)
      onSuccess?.()
    } catch (err) {
      onError?.(err)
      setError(true)
    } finally {
      setDownLoading(false)
    }
  }, [fetcher, filename, onError, onSuccess])

  const state = useMemo(
    () => ({
      downloading,
      error,
      success,
    }),
    [downloading, error, success],
  )

  const handlers = useMemo(
    () => ({
      fetchFileThenDownload,
    }),
    [fetchFileThenDownload],
  )

  return [state, handlers]
}

function useDownloadFileProvider({
  fetcher,
  filename,
  onError,
  onSuccess,
}: UseDownloadFileArgs): UseDownloadFile {
  const [{ downloading, error, success }, { fetchFileThenDownload }] = useDownloadFile({
    fetcher,
    filename,
    onError,
    onSuccess,
  })

  const state = useMemo(
    () => ({
      downloading,
      error,
      success,
    }),
    [downloading, error, success],
  )

  const handlers = useMemo(
    () => ({
      fetchFileThenDownload,
    }),
    [fetchFileThenDownload],
  )

  return [state, handlers]
}

/**
 * This component is for a little extra flexibility return the flags and
 * handlers for direct consumption e.g.
 *
 * <DownloadFile>
 *   {(flags, handlers) => <YourComponent/>}
 * </DownloadFile>
 */
const RenderPropsProvider = ({ children }) => {
  const [{ downloading, error, success }, { fetchFileThenDownload }] = useDownloadFileContext()
  if (typeof children === 'object') return children
  return children({
    downloading,
    error,
    success,
    fetchFileThenDownload,
  })
}

export const DownloadFileProvider = ({
  children,
  fetcher,
  filename,
  onSuccess,
  onError,
}: UseDownloadFileProps) => {
  const ctx = useDownloadFileProvider({ fetcher, filename, onSuccess, onError })
  return <UseDownloadFileContext.Provider value={ctx}>{children}</UseDownloadFileContext.Provider>
}

/**
 * DownLoad file is a component that allows you to download a file from a fetcher function
 * you'll need to provide a fetcher function that returns a promise that resolves to a blob.
 * Alternatively you can use the useDownloadFile() hook
 */
const DownloadFile = ({
  children,
  fetcher,
  filename,
  onSuccess,
  onError,
}: UseDownloadFileProps) => (
  <DownloadFileProvider
    fetcher={fetcher}
    filename={filename}
    onSuccess={onSuccess}
    onError={onError}
  >
    <RenderPropsProvider>{children}</RenderPropsProvider>
  </DownloadFileProvider>
)

const useDownloadFileContext = (): UseDownloadFile => {
  const context = useContext(UseDownloadFileContext)
  if (!context)
    throw new Error('useDownloadFile() cannot be used outside of <DownloadFileProvider/>')
  return context
}

const Failed = ({ children }) => {
  const [{ error }] = useDownloadFileContext()
  return error ? children : null
}

const Idle = ({ children }) => {
  const [{ downloading }] = useDownloadFileContext()
  return !downloading ? children : null
}

const Downloading = ({ children }) => {
  const [{ downloading }] = useDownloadFileContext()
  return downloading ? children : null
}

DownloadFile.Failed = Failed
DownloadFile.Idle = Idle
DownloadFile.Downloading = Downloading

export { DownloadFile }
