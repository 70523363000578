import { Button, Modal } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect } from 'react'
import { useDriverMenuActions } from '../../hooks'

interface DeactivateCardModalScreenProps {
  onClose: () => void
}

/**
 * @deprecated Use `DeactivateCardModalScreen` in DriversListV2/components/DriverWidgets/components instead
 */
export const DeactivateCardModalScreen = ({ onClose }: DeactivateCardModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage }, { deactivateCard, resetErrorMessage }] = useDriverMenuActions()

  const handleDeactivate = useCallback(
    () => deactivateCard({ onSuccess: onClose }),
    [deactivateCard, onClose],
  )

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return (
    <>
      <Modal.Header>{t('drivers.widgets.driver_details.deactivate_card.heading')}</Modal.Header>
      <Modal.Body data-testid="Deactivate-card-modal">
        <p>{t('drivers.widgets.driver_details.deactivate_card.body.remove_active_card')}</p>
        <p>{t('drivers.widgets.driver_details.deactivate_card.body.cancel_card')}</p>
        {errorMessage ? <p className="text-action-danger mt-2">{errorMessage}</p> : null}
      </Modal.Body>
      <Modal.Actions stacked>
        <Button onClick={handleDeactivate} disabled={loading} form="assign-card-form">
          {loading
            ? t('admin.deactivate_admin_user_modal.button.loading.deactivating')
            : t('drivers.widgets.driver_details.driver_actions.deactivate_card')}
        </Button>
        <Button disabled={loading} variant="outline" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
