import { QUERY_DATE_FORMAT, TIME_ONLY_FORMAT } from '@electro/shared/constants'
import { format } from 'date-fns'

export function formatDateForQuery(date: Date) {
  if (!date) return null
  return format(date, QUERY_DATE_FORMAT)
}

export function formatTimeFromDateTime(date: Date) {
  if (!date) return null
  return format(date, TIME_ONLY_FORMAT)
}
