/**
 * Spaghetti code alert
 *
 * This component is not the best approach and probably need refactoring!
 * We might need here to build a proper DatePicker component but as we don't
 * have to much time now i am going to implement this component for now.
 */

import { Button, Menu } from '@electro/shared-ui-components'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { useFleetsBusinessEntityQuery } from '@electro/fleets/generated/graphql'
import { useLocalisedDateFormat, FormattingOptionsEnum } from '@electro/shared/hooks/'
import { useRouter } from 'next/router'
import { mapNextLocaleToDateFnsLocale } from '@electro/fleets/src/utils/mapNextLocaleToDateFnsLocale'
import { format } from 'date-fns'

interface Props {
  value: Date
  onChange: (date: Date) => void
}

export const MonthPicker = ({ value, onChange }: Props) => {
  const { locale } = useRouter()
  const formattedInitialDate = useLocalisedDateFormat({
    date: value,
    formattingOption: FormattingOptionsEnum.LLLL_yyyy,
  })

  const [selectedDate, setSelectedDate] = useState<string | string[]>(formattedInitialDate)
  const { data } = useFleetsBusinessEntityQuery()

  const dateBusinessRegistered = useMemo(() => {
    if (!data || !data.fleetsBusinessEntity) {
      return null
    }

    function normalizeDate() {
      const formattedDate = new Date(data.fleetsBusinessEntity.registeredAt)
      formattedDate.setDate(1)
      return formattedDate
    }

    return normalizeDate()
  }, [data])

  const monthListDates = useMemo(() => {
    if (!dateBusinessRegistered) {
      return []
    }

    const monthsSinceBusinessRegistered = []
    while (dateBusinessRegistered <= new Date()) {
      monthsSinceBusinessRegistered.push(new Date(dateBusinessRegistered))
      dateBusinessRegistered.setMonth(dateBusinessRegistered.getMonth() + 1)
    }

    monthsSinceBusinessRegistered.sort((a: Date, b: Date) => a.getTime() - b.getTime())

    return monthsSinceBusinessRegistered
  }, [dateBusinessRegistered])

  const handleDateSelect = (date: Date) => {
    const monthYearFormattedDate = format(date, 'MMMM yyyy', {
      locale: mapNextLocaleToDateFnsLocale(locale),
    })

    setSelectedDate(monthYearFormattedDate)
    onChange(date)
  }

  return (
    <Menu>
      <Menu.Button chevron={false} as="div" noPadding>
        <Button>
          {selectedDate} <ChevronDownIcon className="h-5 w-5 ml-2" />
        </Button>
      </Menu.Button>

      <Menu.MenuItemList className="overflow-y-auto max-h-96">
        {Array.isArray(monthListDates) &&
          monthListDates.map((date: Date) => (
            <Menu.MenuItem key={date.getTime()} onSelect={() => handleDateSelect(date)}>
              {format(date, 'MMMM yyyy', {
                locale: mapNextLocaleToDateFnsLocale(locale),
              })}
            </Menu.MenuItem>
          ))}
      </Menu.MenuItemList>
    </Menu>
  )
}
