import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'
import FLEETS_DELETE_TAG from '@electro/fleets/graphql/fleetsDeleteTagMutation.graphql'
import { Mutation, MutationFleetsDeleteTagArgs } from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsDeleteTagMutation = Pick<Mutation, 'fleetsDeleteTag'>

type FleetsDeleteTagReturnType = [
  ApolloMutationFunction<FleetsDeleteTagMutation>,
  MutationResult<FleetsDeleteTagMutation>,
]

type Options = MutationHookOptions<FleetsDeleteTagMutation, MutationFleetsDeleteTagArgs>

export const useFleetsDeleteTag = (options?: Options): FleetsDeleteTagReturnType => {
  const [deleteTagMutation, deleteTagResults] = useMutation(FLEETS_DELETE_TAG, options)

  return [deleteTagMutation, deleteTagResults]
}
