export interface UserAdminDetailsFormFields {
  firstName: string
  lastName: string
  email: string
}

export type keysOfUserAdminDetailsFormFields = keyof UserAdminDetailsFormFields

export enum UserAdminDetailsFormFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export interface AdminBusinessFormFields {
  address1: string
  address2: string
  city: string
  address4: string
  postcode: string
}

export type keysOfAdminBusinessFormFields = keyof AdminBusinessFormFields

export enum AdminBusinessFormFieldNames {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  ADDRESS_4 = 'address4',
  POSTCODE = 'postcode',
}
