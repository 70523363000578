import * as dateFnsLocales from 'date-fns/locale'

type LocaleKey = keyof typeof dateFnsLocales

export const mapNextLocaleToDateFnsLocale = (nextLocale: string): Locale => {
  if (!nextLocale) {
    console.error('Warning: locale is undefined, falling back on enGB.')
    return dateFnsLocales.enGB
  }

  const localeKey: LocaleKey =
    nextLocale.slice(0, 2).toLowerCase() === nextLocale.slice(3, 5).toLowerCase()
      ? (nextLocale.slice(0, 2) as LocaleKey)
      : (nextLocale.replace('-', '') as LocaleKey)

  const locale = dateFnsLocales[localeKey]

  if (!locale) {
    console.error(
      `Warning: locale ${nextLocale} is not a valid date-fns locale, falling back on enGB. Check date-fns docs (https://github.com/date-fns/date-fns/tree/main/src/locale) for more information.`,
    )
    return dateFnsLocales.enGB
  }

  return locale
}
