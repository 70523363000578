import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tag,
  Tooltip,
} from '@electro/shared-ui-components'
import { DriverType } from '@electro/fleets/generated/graphql'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DevicePhoneMobileIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useState } from 'react'
import { DriverWidgets } from '@electro/fleets/src/components/DriversListV2/components'

const styles = {
  desktop: {
    rowOpen: 'bg-secondary-dark lg:relative',
    rowClosed: 'hover:cursor-pointer',
  },
  mobile: {
    root: 'h-8 w-8',
    active: 'text-green-500',
    inactive: ' opacity-50',
    widget: 'fixed top-0 right-0 h-full w-screen overflow-y-auto z-40',
    closeButton: tw(
      'h-6 w-6 absolute lg:hidden top-4 right-4 focus:outline-none focus:ring-2',
      'focus:ring-opacity-50 hover:opacity-50 rounded-lg ',
      'text-white hover:text-tertiary hover:opacity-100 focus:ring-opacity-100',
      'focus:ring-tertiary',
    ),
  },
}

interface Props {
  driver: DriverType
}

export const DriverTableRow = ({ driver }: Props) => {
  const [open, setOpen] = useState(false)
  const driverHasElectrocard = driver.user.hasElectricJuiceCard
  const driverIsActive = driver.user.isActive
  const driverHasSignUp = driver.hasSignedUp
  const borderBottomWidth = open ? 0 : 2.5

  const handleCollapse = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <TableRow
        data-testid="driver-table-row"
        className={tw({ [styles.desktop.rowOpen]: open }, { [styles.desktop.rowClosed]: !open })}
        onClick={handleCollapse}
      >
        <TableCell style={{ borderBottomWidth }}>
          <span>
            {driver?.user?.firstName} {driver?.user?.lastName}
          </span>
        </TableCell>

        <TableCell style={{ borderBottomWidth }}>
          {driverIsActive ? (
            <Tag label="Active" colour="green" className="w-18" />
          ) : (
            <Tag label="Inactive" className="w-18" />
          )}
        </TableCell>

        <TableCell style={{ borderBottomWidth }}>
          <Tooltip>
            <Tooltip.Trigger ariaLabel="driver-has-electrocard">
              <Tag
                variant="outlined"
                label="RFID"
                color={driverHasElectrocard ? 'green' : 'lightGrey'}
              />
            </Tooltip.Trigger>
            <Tooltip.Body>
              {driverHasElectrocard
                ? 'This driver has an Electrocard assigned to them'
                : "This driver doesn't have an Electrocard assigned to them"}
            </Tooltip.Body>
          </Tooltip>
        </TableCell>

        <TableCell style={{ borderBottomWidth }}>
          <Tooltip>
            <Tooltip.Trigger ariaLabel="driver-is-logged-in">
              <DevicePhoneMobileIcon
                data-testid="device-mobile-icon"
                className={tw({
                  [styles.mobile.root]: true,
                  [styles.mobile.active]: driverHasSignUp,
                  [styles.mobile.inactive]: !driverHasSignUp,
                })}
              />
            </Tooltip.Trigger>
            <Tooltip.Body>
              {driverHasSignUp
                ? 'This driver has logged into the Electroverse app'
                : 'This driver has not logged into the Electroverse app yet'}
            </Tooltip.Body>
          </Tooltip>
        </TableCell>

        <TableCell className="lg:flex lg:justify-end hidden" style={{ borderBottomWidth }}>
          <Button aria-label="Expand table" variant="naked" size="xs">
            {open ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
          </Button>
        </TableCell>
      </TableRow>
      {open ? (
        <TableRow
          data-testid="driver-table-row-collapsed"
          className={tw(styles.desktop.rowOpen, styles.mobile.widget)}
        >
          <TableCell className="w-screen" colSpan={5}>
            <DriverWidgets driver={driver} />

            <IconButton
              aria-label="Close dialog"
              onClick={handleCollapse}
              className={styles.mobile.closeButton}
              type="button"
            >
              <XMarkIcon className="w-6 h-6" />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}
