import React from 'react'
import { DateRange, DateRangeType } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'
import { mapNextLocaleToDateFnsLocale } from '@electro/fleets/src/utils/mapNextLocaleToDateFnsLocale'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  handleDateRangeChange: ({ startDate, endDate }: DateRangeType) => void
  dateRange: DateRangeType
  dateLimits?: DateRangeType
}

export const DriverConsumptionReportControls = ({
  handleDateRangeChange,
  dateRange,
  dateLimits,
}: Props) => {
  const { t } = useTranslation('common')
  const { locale } = useRouter()

  return (
    <DateRange
      label={t('date_range.label.selected_date_range')}
      onChange={handleDateRangeChange}
      startDate={dateRange?.startDate}
      endDate={dateRange?.endDate}
      fromDate={dateLimits?.startDate}
      toDate={dateLimits?.endDate}
      dateFnsLocale={mapNextLocaleToDateFnsLocale(locale)}
      placeholder={t('reporting.individual_driver_report.date_range.placeholder')}
      buttonTextReset={t('common.button.reset')}
      buttonTextApply={t('common.button.apply')}
    />
  )
}
