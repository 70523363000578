import { useFleetsAuth, UseFleetsAuth } from '@electro/fleets/src/hooks'

import { createContext, useContext, useEffect, useMemo } from 'react'

interface AuthorisedProps {
  children: any
  onAuthorised?: () => void
}

interface UseAuthorisedContext {
  authorised: boolean
  unauthorised: boolean
  loading: boolean
  error: unknown
}

export const useAuthorisedProvider = ({ onAuthorised }) => {
  const {
    isAuthenticated,
    isAuthenticatedLoading,
    hasAuthenticationCheckBeenMade,
    isAuthenticatedError,
  } = useFleetsAuth()

  const authorised = useMemo(
    () => !isAuthenticatedLoading && isAuthenticated && hasAuthenticationCheckBeenMade,
    [isAuthenticated, isAuthenticatedLoading, hasAuthenticationCheckBeenMade],
  )
  const unauthorised = useMemo(
    () => !isAuthenticatedLoading && !isAuthenticated && hasAuthenticationCheckBeenMade,
    [isAuthenticated, isAuthenticatedLoading, hasAuthenticationCheckBeenMade],
  )

  /**
   * Watch the auth lifecycle, and give the option
   * to pass a callback as a custom handler if the
   * user succeeds auth.
   */
  useEffect(() => {
    if (isAuthenticated && onAuthorised && !isAuthenticatedError) onAuthorised()
    // eslint-disable-next-line
  }, [isAuthenticated, isAuthenticatedError])

  return {
    error: isAuthenticatedError,
    loading: isAuthenticatedLoading,
    authorised,
    unauthorised,
  }
}

const AuthorisedContext = createContext<UseAuthorisedContext>(null)

const useAuthorised = () => {
  const context = useContext(AuthorisedContext)
  if (!context) {
    throw Error('Authorised components cannot be used outside the context of <Authorised/>' as any)
  }
  return context
}

/**
 * For a little extra flexibility return the contents of
 * the auth hook to the component render e.g:
 *
 * <Authorised>
 *  {(auth) => <YourComponent/>}
 * </Authorised>
 */
const AuthorisedRenderPropProvider = ({ children }) => {
  const auth: UseFleetsAuth = useFleetsAuth()
  if (typeof children === 'object') return children
  return children(auth as UseFleetsAuth)
}

const LoggedIn = ({ children }) => {
  const { authorised } = useAuthorised()
  return authorised ? children : null
}

const LoggedOut = ({ children }) => {
  const { unauthorised } = useAuthorised()
  return unauthorised ? children : null
}

const Error = ({ children }) => {
  const { error } = useAuthorised()
  return error ? children : null
}

/**
 * Will show when the useFleetsAuth hook is calling the
 * Back End to verify the user
 */
const Loading = ({ children }) => {
  const { loading } = useAuthorised()
  return loading ? children : null
}

const Authorised = ({ children, onAuthorised }: AuthorisedProps) => {
  const context = useAuthorisedProvider({ onAuthorised })
  return (
    <AuthorisedContext.Provider value={context}>
      <AuthorisedRenderPropProvider>{children}</AuthorisedRenderPropProvider>
    </AuthorisedContext.Provider>
  )
}

Authorised.LoggedOut = LoggedOut
Authorised.LoggedIn = LoggedIn
Authorised.Loading = Loading
Authorised.Error = Error

export { Authorised }
