import * as Yup from 'yup'
import { Input, Modal, Button } from '@electro/shared-ui-components'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import {
  BulkUploadModalScreensEnum,
  useBulkDriverUpload,
} from '@electro/fleets/src/components/BulkDriverUploadButton/hooks'
import { SpinnerIcon } from '@electro/fleets/src/icons'
import { Formik } from 'formik'
import useTranslation from 'next-translate/useTranslation'

const validationSchema = (t) =>
  Yup.object().shape({
    file: Yup.mixed()
      .required(t('drivers.bulk_upload_drivers.validation.required'))
      .test(
        'fileType',
        t('drivers.bulk_upload_drivers.validation.file_type'),
        (value) => value?.type === 'text/csv',
      )
      .test(
        'fileSize',
        t('drivers.bulk_upload_drivers.validation.file_size'),
        (value) => value?.size < 1000000,
      )
      .nullable(),
  })

export const FileUploadModalScreen = () => {
  const { t } = useTranslation('common')
  const [{ loading }, { setActiveModalScreen, handleUploadDrivers }] = useBulkDriverUpload()

  function handleBackClick() {
    setActiveModalScreen(BulkUploadModalScreensEnum.INITIAL)
  }

  const handleUploadFormSubmit = async (values, formikActions) => {
    try {
      formikActions.setErrors({})
      await handleUploadDrivers(values.file)
    } catch (err) {
      /**
       * We only expect one error to be returned from the server
       * so we can safely destructure the first error from the array
       * and set it as the error for the file input
       *
       * Top save time we are not mapping errorCodes to custom error messages,
       * or providing a great deal of depth to the errors returned from the server.
       */
      const [firstError] = err.graphQLErrors
      formikActions.setErrors({ file: firstError?.message })
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ file: null }}
      validationSchema={validationSchema(t)}
      onSubmit={handleUploadFormSubmit}
    >
      {({ errors, setFieldValue, handleSubmit, isValid, dirty }) => (
        <>
          <Modal.Header>{t('drivers.bulk_upload_drivers.header')}</Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit} id="driver-upload-form">
              <Input
                success={isValid && dirty}
                disabled={loading}
                fullWidth
                type="file"
                label={t('drivers.bulk_upload_drivers.input_label')}
                name="file"
                onChange={(e) => {
                  setFieldValue('file', e.currentTarget.files[0])
                }}
                errorMessage={errors.file as string}
              />
            </form>
          </Modal.Body>
          <Modal.Actions center>
            <Button variant="outline" disabled={loading} onClick={handleBackClick}>
              {t('common.button.back')}
            </Button>
            <Button type="submit" form="driver-upload-form" disabled={loading}>
              {!loading ? (
                <>
                  <ArrowUpTrayIcon className="h-6 w-6 mr-1.5" />
                  {t('common.button.upload')}
                </>
              ) : (
                <>
                  <SpinnerIcon className="h-6 w-6 mr-1.5 animate-spin" />
                  {t('common.button.loading.upload')}
                </>
              )}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Formik>
  )
}
