import { Table, TableBody, TableCell, TableHead, TableRow } from '@electro/shared-ui-components'
import { ChargeRecordTableRow } from '@electro/fleets/src/components/PaymentAndInvoices/components/BusinessChargeRecords/components'
import { useFleetsChargeRecordsQuery } from '@electro/fleets/generated/graphql'

export const BusinessChargeRecords = () => {
  const { data, loading, error } = useFleetsChargeRecordsQuery({ fetchPolicy: 'cache-first' })

  return (
    <div>
      <Table
        data-testid="charge-records-table"
        loading={loading}
        loadingMessage="Loading your charge records..."
        emptyTable={data && data.fleetsChargeRecords.edges.length === 0}
        emptyTableMessage="You don't have any charge records yet"
        error={!!error}
        errorMessage={error?.message}
      >
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Year</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.fleetsChargeRecords.edges.map((edge) => (
              <ChargeRecordTableRow key={edge.node.pk} chargeRecordEdge={edge} />
            ))}
        </TableBody>
      </Table>
    </div>
  )
}
