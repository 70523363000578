import { Modal } from '@electro/shared-ui-components'
import {
  AssignElectroCardModalScreen,
  DeactivateDriverModalScreen,
  DeactivateCardModalScreen,
  ResendSignupEmailModalScreen,
  ResetDriverPasswordModalScreen,
  ReassignCardModalScreen,
  ReactivateDriverModalScreen,
} from '@electro/fleets/src/components/DriverActionsDropDownMenu/components'

export enum ModalScreensEnum {
  ASSIGN_DRIVER_MODAL_SCREEN = 'ASSIGN_DRIVER_MODAL_SCREEN',
  REASSIGN_DRIVER_MODAL_SCREEN = 'REASSIGN_DRIVER_MODAL_SCREEN',
  DEACTIVATE_CARD_MODAL_SCREEN = 'DEACTIVATE_CARD_MODAL_SCREEN',
  DEACTIVATE_DRIVER_MODAL_SCREEN = 'DEACTIVATE_DRIVER_MODAL_SCREEN',
  RESET_PASSWORD_MODAL_SCREEN = 'RESET_PASSWORD_MODAL_SCREEN',
  RESEND_SIGNUP_EMAIL_MODAL_SCREEN = 'RESEND_SIGNUP_EMAIL_MODAL_SCREEN',
  REACTIVATE_DRIVER_MODAL_SCREEN = 'REACTIVATE_DRIVER_MODAL_SCREEN',
}

export type ModalScreenNames = `${ModalScreensEnum}`

interface DriverActionsModalProps {
  open: boolean
  onClose: () => void
  activeModalScreen: ModalScreenNames
}

export const DriverActionsModal = ({
  open,
  onClose,
  activeModalScreen,
}: DriverActionsModalProps) => {
  const mapActiveModalScreen = (screen: ModalScreenNames) => {
    const props = { onClose }
    const map = {
      [ModalScreensEnum.ASSIGN_DRIVER_MODAL_SCREEN]: <AssignElectroCardModalScreen {...props} />,
      [ModalScreensEnum.RESET_PASSWORD_MODAL_SCREEN]: <ResetDriverPasswordModalScreen {...props} />,
      [ModalScreensEnum.REASSIGN_DRIVER_MODAL_SCREEN]: <ReassignCardModalScreen {...props} />,
      [ModalScreensEnum.DEACTIVATE_CARD_MODAL_SCREEN]: <DeactivateCardModalScreen {...props} />,
      [ModalScreensEnum.DEACTIVATE_DRIVER_MODAL_SCREEN]: <DeactivateDriverModalScreen {...props} />,
      [ModalScreensEnum.RESEND_SIGNUP_EMAIL_MODAL_SCREEN]: (
        <ResendSignupEmailModalScreen {...props} />
      ),
      [ModalScreensEnum.REACTIVATE_DRIVER_MODAL_SCREEN]: <ReactivateDriverModalScreen {...props} />,
    }
    return map[screen]
  }

  return (
    <Modal size="md" open={open} onClose={onClose}>
      <Modal.CloseButton />
      {mapActiveModalScreen(activeModalScreen)}
    </Modal>
  )
}
