import { VehicleType } from '@electro/fleets/generated/graphql'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

export enum AddEvFormStage {
  REGISTRATION_LOOK_UP = 'REGISTRATION_LOOK_UP',
  MANUAL_LOOK_UP = 'MANUAL_LOOK_UP',
}

interface AddEvFormReturnType {
  formState: AddEvFormStage
  setFormState: Dispatch<SetStateAction<AddEvFormStage>>
  handleSubmit: (vehicle: VehicleType, reg?: string) => void
  loading: boolean
  success: boolean
}

const AddEvFormContext = createContext<AddEvFormReturnType>(null)

interface Props {
  children?: ReactNode
  onSubmit: (vehicle: VehicleType, reg?: string) => void
  loading: boolean
  success: boolean
  locale: string
}

const useAddEvFormProvider = ({
  onSubmit,
  loading,
  success,
  locale = 'en-GB',
}: Props): AddEvFormReturnType => {
  const [formState, setFormState] = useState(
    locale === 'en-GB' ? AddEvFormStage.REGISTRATION_LOOK_UP : AddEvFormStage.MANUAL_LOOK_UP,
  )

  return {
    formState,
    setFormState,
    handleSubmit: onSubmit,
    loading,
    success,
  }
}

export const AddEvFormProvider = ({ children, onSubmit, loading, success, locale }: Props) => {
  const value = useAddEvFormProvider({ onSubmit, loading, success, locale })

  return <AddEvFormContext.Provider value={value}>{children}</AddEvFormContext.Provider>
}

export const useAddEvForm = () => {
  const context = useContext(AddEvFormContext)

  if (!context) throw new Error('useAddEvForm() cannot be used outside of <AddEvFormProvider/>')
  return context
}
