import { ApolloError } from '@apollo/client'
import { Alert, Skeleton, Typography } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useErrorMessage } from '@electro/fleets/src/hooks'

export type BorderColours =
  | 'lightGrey'
  | 'grey'
  | 'brown'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'red'
  | 'tertiary'
  | 'secondary'
  | 'primary'

const styles = {
  borderRoot: 'border-2 p-4 rounded-2xl min-w-36',
  border: {
    lightGrey: 'border-slate-300',
    grey: 'border-gray-400',
    brown: 'border-amber-700',
    orange: 'border-orange-400',
    yellow: 'border-yellow-400',
    green: 'border-action-success',
    blue: 'border-blue-400',
    red: 'border-action-danger',
    primary: 'border-primary',
    secondary: 'border-secondary',
    tertiary: 'border-tertiary',
  },
  statHeadingRoot: 'mb-1',
  statTextRoot: 'text-xl lg:text-3xl break-all',
  statText: {
    lightGrey: 'text-slate-300',
    grey: 'text-gray-400',
    brown: 'text-amber-700',
    orange: 'text-orange-400',
    yellow: 'text-yellow-400',
    green: 'text-action-success',
    blue: 'text-blue-400',
    red: 'text-action-danger',
    primary: 'text-primary',
    secondary: 'text-secondary',
    tertiary: 'text-tertiary',
  },
}

export interface StatisticWidgetProps {
  statData: number | string
  statHeading?: string
  loading: boolean
  error: ApolloError
  borderColour: BorderColours
  className?: string
}

export const StatisticWidget = ({
  statData,
  statHeading,
  loading,
  error,
  borderColour = 'primary',
  className,
}: StatisticWidgetProps) => {
  const errorMessage = useErrorMessage(error)
  if (error) {
    return (
      <Alert variant="error">
        <Typography variant="h4">{errorMessage}</Typography>
      </Alert>
    )
  }

  const baseClasses = tw({
    [styles.borderRoot]: true,
    [styles.border[borderColour]]: true,
    [className]: !!className,
  })

  return (
    <div data-testid="widget-wrapper" className={baseClasses}>
      {loading ? (
        <Skeleton variant="text" data-testid="widget-loading" className="min-h-16 min-w-36" />
      ) : (
        <>
          <Typography
            variant="h3"
            className={tw({
              [styles.statHeadingRoot]: true,
              [styles.statText[borderColour]]: true,
              [className]: !!className,
            })}
          >
            {statHeading}
          </Typography>
          {statData && (
            <div
              data-testid="widget-stat"
              className={tw({
                [styles.statTextRoot]: true,
                [styles.statText[borderColour]]: true,
                [className]: !!className,
              })}
            >
              {statData}
            </div>
          )}
        </>
      )}
    </div>
  )
}
