import { Button, Modal, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

interface YourElectrocardsAreOnTheWayProps {
  show: boolean
}

export const YourElectrocardsAreOnTheWayModal = ({ show }: YourElectrocardsAreOnTheWayProps) => {
  const { t } = useTranslation('common')
  const { closeModalScreen } = useDriverWidgets()
  const { push } = useRouter()

  const handleViewElectrocards = () => push('/dashboard/electrocards')

  if (!show) return null

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col items-center">
          <Image
            alt="confused mr-zap"
            src="/images/card-ordered-mail-plane.svg"
            width="180"
            height="180"
          />

          <Typography variant="h3" className="text-center mt-5">
            {t('drivers.widgets.assign_electrocard.electrocards_on_their_way.header')}
          </Typography>

          <Typography className="text-center mt-5">
            {t('drivers.widgets.assign_electrocard.electrocards_on_their_way.subheader')}
          </Typography>
        </div>
      </Modal.Body>

      <Modal.Actions stacked>
        <Button variant="outline" fullWidth onClick={closeModalScreen}>
          {t('common.button.close')}
        </Button>
        <Button fullWidth onClick={handleViewElectrocards}>
          {t('drivers.widgets.assign_electrocard.electrocards_on_their_way.view_electrocards')}
        </Button>
      </Modal.Actions>
    </>
  )
}
