import React, { useState, useMemo, useCallback } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Typography } from '@electro/shared-ui-components'
import { FleetVehicleTypeEdge } from '@electro/fleets/generated/graphql'

interface VehicleOptionsSelectProps {
  options: FleetVehicleTypeEdge[]
  onSelect: (vehicle: FleetVehicleTypeEdge) => void
}

const styles = {
  root: tw(
    'rounded-xl w-full text-left px-4 py-3 mb-1.5 border max-h-20 min-h-8 flex justify-between border-secondary',
    'hover:border-tertiary',
  ),
  selected: 'ring-1 border-secondary bg-secondary ring-secondary',
}

export const AssignVehicleOptionsSelect = ({ options, onSelect }: VehicleOptionsSelectProps) => {
  const [selectedVehicleEdgePk, setSelectedVehicleEdgePk] = useState<number | null>(null)
  const sortedOptions: FleetVehicleTypeEdge[] = useMemo(() => {
    if (options?.length === 0) return []

    return [...options].sort((a, b) => a.node.name.localeCompare(b.node.name))
  }, [options])

  const handleSelect = useCallback(
    (vehicleEdge: FleetVehicleTypeEdge) => () => {
      setSelectedVehicleEdgePk(vehicleEdge.node.pk)
      onSelect(vehicleEdge)
    },
    [onSelect],
  )

  if (!options) return null

  return (
    <div className="pt-2 pb-5 px-0.5" data-testid="vehicle-options-select">
      {sortedOptions.map((vehicle) => (
        <button
          className={tw({
            [styles.root]: true,
            [styles.selected]: selectedVehicleEdgePk === vehicle?.node.pk,
          })}
          data-testid="select-box-option"
          key={vehicle.node.pk}
          onClick={handleSelect(vehicle)}
        >
          <Typography className="w-2/3 truncate">
            {`${vehicle.node.vehicle.vehicleMake} ${vehicle.node.vehicle.vehicleModel}`}
          </Typography>
          {vehicle.node.regNumber !== 'undefined' ? (
            <Typography>{vehicle.node.regNumber}</Typography>
          ) : null}
        </button>
      ))}
    </div>
  )
}
