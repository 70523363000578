import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'
import FLEETS_STORE_PAYMENT_INSTRUCTION from '@electro/fleets/graphql/fleetsStorePaymentInstructionMutation.graphql'
import {
  Mutation,
  MutationFleetsStorePaymentInstructionArgs,
} from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type StoreInstructionMutation = Pick<Mutation, 'fleetsStorePaymentInstruction'>
type StoreInstructionReturnType = [
  ApolloMutationFunction<StoreInstructionMutation>,
  MutationResult<StoreInstructionMutation>,
]
type Options = MutationHookOptions<
  StoreInstructionMutation,
  MutationFleetsStorePaymentInstructionArgs
>

export const useFleetsStorePaymentInstruction = (options?: Options): StoreInstructionReturnType => {
  const [storePaymentInstructionMutation, storePaymentInstructionResult] = useMutation(
    FLEETS_STORE_PAYMENT_INSTRUCTION,
    options,
  )

  return [storePaymentInstructionMutation, storePaymentInstructionResult]
}
