import { useEffect, useMemo } from 'react'
import { AdminUserTypes, useAdminUserStore } from '@electro/fleets/src/hooks/stores'

interface OnlyAllowForProps {
  children: any
  userTypes?: AdminUserTypes[]
  /**
   * Callback, runs when a user fails granular permissions check
   */
  onRestricted?: () => void
}

/**
 * Will only render children for the conditions supplied as props. To start with this is
 * User types but it can be extended in the future to params like locales.
 */
export const OnlyAllowFor = ({ userTypes, children, onRestricted }: OnlyAllowForProps) => {
  const { userType } = useAdminUserStore((state) => state.currentAdminUser)

  const userTypeMatch = useMemo(
    () => userTypes.some((user) => user === userType),
    [userType, userTypes],
  )

  const canRenderChildren = useMemo(() => userTypeMatch, [userTypeMatch])

  /**
   * Provide a call back if the user does not meet the required
   * granular conditions.
   */
  useEffect(() => {
    if (onRestricted && !canRenderChildren) {
      onRestricted?.()
    }
  }, [canRenderChildren, onRestricted])

  return canRenderChildren ? children : null
}
