import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'

export const FleetsDriverCreatePasswordSuccess = () => {
  const { t } = useTranslation('common')
  return (
    <div className="flex flex-grow items-center justify-center bg-base">
      <div className="max-w-lg p-4">
        <h1 className="mb-8 text-center">{t('reset_password.heading.password_saved')}</h1>
        <div className="flex justify-around flex-col sm:flex-row items-center">
          <a
            target="blank"
            href="https://apps.apple.com/gb/app/octopus-electric-universe/id1535444463"
            className="mb-6 mt-2"
          >
            <Image
              src="/images/download-app-store.png"
              alt="Download on the app store"
              width="203"
              height="60"
            />
          </a>
          <a
            target="blank"
            href="https://play.google.com/store/apps/details?id=energy.octopus.electricjuice.android"
            className="mb-6 mt-2"
          >
            <Image
              src="/images/download-google-play.png"
              alt="Download on the google play store"
              width="203"
              height="60"
            />
          </a>
        </div>
        <h2>{t('create_password.success.heading.what_next')}</h2>
        <p>
          <Trans
            i18nKey="common:create_password.success.body.download_app_text"
            components={{
              googlePlayStoreLink: (
                <a
                  target="blank"
                  href="https://play.google.com/store/apps/details?id=energy.octopus.electricjuice.android"
                >
                  {t('common.play_store')}
                </a>
              ),
              appleAppStoreLink: (
                <a
                  target="blank"
                  href="https://apps.apple.com/gb/app/octopus-electric-universe/id1535444463"
                >
                  {t('common.app_store')}
                </a>
              ),
            }}
          />
        </p>
        <p>{t('create_password.success.body.once_card_arrives')}</p>
      </div>
    </div>
  )
}
