import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

interface DriverConsumptionReportHeadlineProps {
  totalCount: number
  loading: boolean
}

export const DriverConsumptionReportHeadline = ({
  totalCount = 0,
  loading,
}: DriverConsumptionReportHeadlineProps) => {
  const { t } = useTranslation('common')

  if (loading) return <div className="cp-thickLine" />
  return (
    <Typography variant="h2" data-testid="results-count">
      <span className="font-medium">{totalCount}</span>
      &nbsp; {t('common.results')}
    </Typography>
  )
}
