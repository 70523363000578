import { Button, Modal, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import useTranslation from 'next-translate/useTranslation'

export const AllElectroCardsAssignedModal = ({ show }) => {
  const { t } = useTranslation('common')
  const { closeModalScreen } = useDriverWidgets()

  return show ? (
    <Modal.Body>
      <div className="flex flex-col items-center">
        <Image alt="confused mr-zap" src="/images/mr-zap-confused.svg" width="150" height="150" />
        <Typography className="text-center mt-5">
          {t('drivers.widgets.assign_electrocard.all_electrocards_are_assigned')}
        </Typography>
        <Button className="mt-5" fullWidth onClick={closeModalScreen}>
          Close
        </Button>
      </div>
    </Modal.Body>
  ) : null
}
