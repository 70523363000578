import { Typography } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'

interface InformationItemProps {
  labelKey: string
  value: string
  show?: boolean
  columnNumber?: number
}

const styles = {
  root: 'text-small md:text-base',
  row: {
    column2: 'lg:col-span-2 align-center py-2',
    column3: 'align-center py-2',
  },
}

export const InformationItem = ({
  labelKey,
  value,
  show = true,
  columnNumber = 1,
}: InformationItemProps) => {
  const { t } = useTranslation('common')
  return show ? (
    <div
      className={tw({
        [styles.row.column2]: columnNumber === 2,
        [styles.row.column3]: columnNumber === 3,
      })}
    >
      <Typography variant="small">{t(labelKey)}</Typography>
      <Typography variant="h6">{value || '-'}</Typography>
    </div>
  ) : null
}
