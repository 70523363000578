import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils'
import Router from 'next/router'
import FLEETS_BUSINESS_ENTITY_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityQuery.graphql'
import { FLEETS_LOGOUT } from '@electro/fleets/src/services/useFleetsLogoutMutation'

import { FLEETS_AUTH_TOKEN_KEY, FLEETS_REFRESH_TOKEN_KEY } from '@electro/shared/constants'
import { FLEETS_ACCOUNT_ID } from '@electro/fleets/src/constants/localStorage'

import {
  useAdminUserStore,
  useBusinessEntityStore,
  useAuthStore,
} from '@electro/fleets/src/hooks/stores'
import { ErrorCode } from '@electro/fleets/generated/graphql'

const createAuthLink = () =>
  setContext((_, { headers }) => {
    const token = localStorage.getItem(FLEETS_AUTH_TOKEN_KEY)

    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      },
    }
  })

const createAccountLink = () =>
  setContext((_, { headers }) => {
    let nextHeaders

    if (
      window !== undefined &&
      window?.localStorage &&
      window?.localStorage?.getItem(FLEETS_ACCOUNT_ID)
    ) {
      const accountId = window?.localStorage?.getItem(FLEETS_ACCOUNT_ID)
      nextHeaders = { ...headers, 'X-Account-Id': accountId }
    }
    return {
      headers: {
        ...nextHeaders,
      },
    }
  })

const httpLink = createUploadLink({
  uri: '/api/graphql',
  headers: {
    'Api-key': process.env.NEXT_PUBLIC_FLEETS_API_KEY,
    'Accept-Encoding': 'gzip, deflate, br',
    source: 'web',
  },
})

const checkBusinessEntityValidation = async () => {
  const {
    setIsAuthenticated,
    setHasAuthenticationCheckBeenMade,
    setIsAuthenticatedLoading,
    resetAuth,
  } = useAuthStore.getState()
  const { resetCurrentAdminUser } = useAdminUserStore.getState()
  const { resetBusinessEntity } = useBusinessEntityStore.getState()

  try {
    setIsAuthenticatedLoading(true)

    await client.query({
      query: FLEETS_BUSINESS_ENTITY_QUERY,
      fetchPolicy: 'network-only',
    })
    setIsAuthenticatedLoading(false)
  } catch (error) {
    // clear authentication tokens from localStorage
    window?.localStorage?.removeItem(FLEETS_AUTH_TOKEN_KEY)
    window?.localStorage?.removeItem(FLEETS_REFRESH_TOKEN_KEY)
    window?.localStorage?.removeItem(FLEETS_ACCOUNT_ID)

    // reset zustand stores
    resetCurrentAdminUser()
    resetBusinessEntity()
    resetAuth()

    // reset apollo client store
    client.clearStore()

    setIsAuthenticated(false)
    setHasAuthenticationCheckBeenMade(true)
    setIsAuthenticatedLoading(false)
    client.mutate({ mutation: FLEETS_LOGOUT })

    // redirect user to login
    if (Router) {
      Router.push('/dashboard/login')
    }
  }
}

const errorLink = onError((error: ErrorResponse) => {
  const { operation, networkError, graphQLErrors } = error
  const { JwtTokenExpired, Unauthorized, InvalidJwtToken } = ErrorCode
  const { resetAuth, isAuthenticated, hasAuthenticationCheckBeenMade } = useAuthStore.getState()

  /**
   * Handle unauthorized requests and 403
   *
   * When a request is unauthorized or with error code 403 that means token is
   * expired. We want to reset auth store which will trigger authentication flow
   * by verify the current token and if is expired it will refresh the token
   */
  if (
    isAuthenticated &&
    hasAuthenticationCheckBeenMade &&
    ((networkError as ServerError)?.statusCode === 403 || graphQLErrors)
  ) {
    const [firstError] = graphQLErrors || []
    const firstGraphqlErrorExists = graphQLErrors && firstError && 'errorCode' in firstError
    const tokenErrorCodes = [JwtTokenExpired, Unauthorized, InvalidJwtToken]

    if (
      (firstGraphqlErrorExists && tokenErrorCodes.includes(firstError.errorCode as ErrorCode)) ||
      (networkError as ServerError)?.statusCode === 403
    ) {
      resetAuth()
    }
  }

  if (
    isAuthenticated &&
    hasAuthenticationCheckBeenMade &&
    operation?.operationName === 'fleetsBusinessEntity'
  ) {
    checkBusinessEntityValidation()
  }
})

const authLink = createAuthLink()
const accountLink = createAccountLink()
const link = from([accountLink, authLink, errorLink, httpLink])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
