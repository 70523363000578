import { useCallback } from 'react'
import { format, parseISO } from 'date-fns'
import { LazyQueryResult, OperationVariables } from '@apollo/client'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from '@electro/shared-ui-components'
import { HandleOrderChange, TableSortHeaderButton } from '@electro/fleets/src/components'
import { UK_SHORT_DATE_FORMAT } from '@electro/shared/constants'
import { FetchFleetsDriversReceipts } from '@electro/fleets/src/services'
import { DriverReceiptLocation } from '@electro/fleets/src/components/DriverIndividualReceipts/components'
import {
  DriverReceiptsConnectionTypeEdge,
  DriverReceiptsConnectionTypeOrderingFields as DriverReceiptsOrderByEnum,
  DriversReceiptStatusOptions,
  DriverTokenTypeOrderingFields,
  OrderingDirectionEnum,
} from '@electro/fleets/generated/graphql'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useRouter } from 'next/router'
import { formatTimeFromDateTime } from '@electro/fleets/src/utils/formatters'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

type OnHeaderClick = HandleOrderChange<DriverReceiptsOrderByEnum>

interface DriverIndividualReceiptsTableProps {
  fleetsDriversReceipts: LazyQueryResult<FetchFleetsDriversReceipts, OperationVariables>
  onHeaderClick: ({ nextOrderBy }: OnHeaderClick) => void
  orderDirection: OrderingDirectionEnum
  orderBy: DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields
}

const styles = {
  root: 'overflow-x-auto',
  operatorLogo:
    'lg:w-16 lg:h-16 max-w-12 max-h-12 border rounded-lg flex justify-center items-center p-1 bg-white ml-2',
  unitText: {
    container: 'lg:text-left text-center',
    text: 'font-light text-sm',
  },
  invoiceStatus: {
    invoiced: 'text-action-success',
    pending: 'text-[#FFC532]',
    processed: 'text-info-light',
  },
}
export const DriverIndividualReceiptsTable = ({
  fleetsDriversReceipts,
  onHeaderClick,
  orderDirection,
  orderBy,
}: DriverIndividualReceiptsTableProps) => {
  const { t } = useTranslation('common')

  function headerClickHandler(fieldName: DriverReceiptsOrderByEnum) {
    return () =>
      onHeaderClick({
        nextOrderBy: fieldName,
      })
  }

  const { data, loading, error } = fleetsDriversReceipts

  const chargeDuration = useCallback((seconds: number) => durationFromSeconds(seconds), [])
  const router = useRouter()

  const toolTipGenerator = (status: string, invoiceNumber?: string) => {
    const tooltipMessages = {
      [DriversReceiptStatusOptions.Invoiced]: (
        <Trans
          i18nKey="common:reporting.individual_driver_report.tooltip.invoiced"
          components={{ invoiceNumber: <span>{invoiceNumber}</span> }}
        />
      ),
      [DriversReceiptStatusOptions.Processed]: t(
        'reporting.individual_driver_report.tooltip.processed',
      ),
      [DriversReceiptStatusOptions.Pending]: t(
        'reporting.individual_driver_report.tooltip.pending',
      ),
    }

    return (
      <Tooltip>
        <Tooltip.Trigger ariaLabel={tooltipMessages[status]}>
          {invoiceNumber || status}
        </Tooltip.Trigger>
        <Tooltip.Body>{tooltipMessages[status]}</Tooltip.Body>
      </Tooltip>
    )
  }

  return (
    <div className={styles.root}>
      <Table
        className="w-full"
        data-testid="driver-receipts-Table"
        loading={loading}
        loadingMessage={t('reporting.individual_driver_report.table.loading_message')}
        error={!!error}
        errorMessage={error?.message}
        emptyTable={data && data.fleetsDriversReceipts.totalCount === 0}
        emptyTableMessage={t('reporting.individual_driver_report.table.empty_table')}
      >
        <TableHead>
          <TableRow>
            <TableHeader>{t('common.operator')}</TableHeader>
            <TableHeader>{t('common.location')}</TableHeader>
            <TableHeader>
              {t('reporting.individual_driver_report.table.header.start_time')}
            </TableHeader>
            <TableHeader>
              {t('reporting.individual_driver_report.table.header.duration')}
            </TableHeader>
            <TableHeader>
              <TableSortHeaderButton<DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields>
                orderBy={orderBy}
                orderDirection={orderDirection}
                fieldName={DriverReceiptsOrderByEnum.PeriodEnd}
                label={t('common.date')}
                onClick={headerClickHandler(DriverReceiptsOrderByEnum.PeriodEnd)}
              />
            </TableHeader>
            <TableHeader>
              <TableSortHeaderButton<DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields>
                orderBy={orderBy}
                orderDirection={orderDirection}
                fieldName={DriverReceiptsOrderByEnum.GrossAmount}
                label={t('reporting.individual_driver_report.table.header.gross_amount')}
                onClick={headerClickHandler(DriverReceiptsOrderByEnum.GrossAmount)}
              />
            </TableHeader>
            <TableHeader>
              <TableSortHeaderButton<DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields>
                orderBy={orderBy}
                orderDirection={orderDirection}
                fieldName={DriverReceiptsOrderByEnum.NetAmount}
                label={t('reporting.individual_driver_report.table.header.net_amount')}
                onClick={headerClickHandler(DriverReceiptsOrderByEnum.NetAmount)}
              />
            </TableHeader>
            <TableHeader>
              {t('reporting.individual_driver_report.table.header.additional_fees')}
            </TableHeader>
            <TableHeader>
              <TableSortHeaderButton<DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields>
                orderBy={orderBy}
                orderDirection={orderDirection}
                fieldName={DriverReceiptsOrderByEnum.KwhUsed}
                label={t('reporting.individual_driver_report.table.header.kwh_used')}
                onClick={headerClickHandler(DriverReceiptsOrderByEnum.KwhUsed)}
              />
            </TableHeader>
            <TableHeader>{t('payment_and_invoices.business_invoices.invoice')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.fleetsDriversReceipts.edges.map(
              (receiptEdge: DriverReceiptsConnectionTypeEdge) => {
                const {
                  node: {
                    pk,
                    periodStart,
                    grossAmount,
                    netAmount,
                    kwhUsed,
                    additionalFees,
                    currency,
                    operator,
                    location,
                    duration,
                    chargingLocationId,
                    status,
                    invoiceNumber,
                  },
                } = receiptEdge
                return (
                  <TableRow data-testid="driver-receipt-row" key={pk} data-id={pk}>
                    <TableCell>
                      {operator ? (
                        <div className={styles.operatorLogo}>
                          <img
                            src={operator.logoDark}
                            alt={`${operator.name} Logo`}
                            title={`${operator.name}`}
                          />
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <DriverReceiptLocation
                        location={location}
                        chargingLocationId={chargingLocationId}
                      />
                    </TableCell>

                    <TableCell className={styles.unitText.container}>
                      {formatTimeFromDateTime(new Date(periodStart))
                        ? `${formatTimeFromDateTime(new Date(periodStart))}`
                        : ''}
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      {chargeDuration(duration)?.hours
                        ? `${chargeDuration(duration)?.hours}hr `
                        : ''}
                      {chargeDuration(duration)?.minutes
                        ? `${chargeDuration(duration)?.minutes}mins`
                        : ''}
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      {format(parseISO(periodStart), UK_SHORT_DATE_FORMAT)}
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      <span className={styles.unitText.text}>
                        {formatPriceToLocalisedValue({
                          price: grossAmount,
                          currency,
                          locale: router.locale,
                          isFloat: true,
                        })}
                      </span>
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      <span className={styles.unitText.text}>
                        {formatPriceToLocalisedValue({
                          price: netAmount,
                          currency,
                          locale: router.locale,
                          isFloat: true,
                        })}
                      </span>
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      <span className={styles.unitText.text}>
                        {formatPriceToLocalisedValue({
                          price: additionalFees,
                          currency,
                          locale: router.locale,
                          isFloat: true,
                        })}
                      </span>
                    </TableCell>
                    <TableCell className={styles.unitText.container}>
                      {kwhUsed}
                      <span className={styles.unitText.text}>kwh</span>
                    </TableCell>
                    <TableCell
                      className={tw({
                        [styles.unitText.text]: true,
                        [styles.invoiceStatus.invoiced]:
                          status === DriversReceiptStatusOptions.Invoiced,
                        [styles.invoiceStatus.pending]:
                          status === DriversReceiptStatusOptions.Pending,
                        [styles.invoiceStatus.processed]:
                          status === DriversReceiptStatusOptions.Processed,
                      })}
                    >
                      {toolTipGenerator(status, invoiceNumber)}
                    </TableCell>
                  </TableRow>
                )
              },
            )}
        </TableBody>
      </Table>
    </div>
  )
}
