const colors = [
  'rgb(240, 80, 248)',
  'rgb(255, 216, 79)',
  'rgb(96, 24, 200)',
  'rgb(255, 162, 107)',
  'rgb(148, 159, 255)',
  'rgb(113, 227, 255)',
  'rgb(24, 0, 72)',
]

type Key = string | number
type Domain = (string | number)[]
type Range = string[]

/**
 * This function create a color scale which
 * we can call and map the given domain
 */
export const scaleOrdinal = (domain: Domain, range: Range = colors) => {
  const index = new Map()
  const keys = []

  domain.forEach((d) => {
    if (index.has(d)) return
    const currentLength = keys.push(d) - 1
    index.set(d, currentLength)
  })

  const getRange = (key: Key) => {
    const i = index.get(key)
    return range[i % range.length]
  }

  const scale = (key: Key) => getRange(key)

  /**
   * This function generate and return the complete
   * color array which normally we would map though it
   */
  scale.all = () => domain.map((d) => getRange(d))

  return scale
}
