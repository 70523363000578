import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { Input, Button } from '@electro/shared-ui-components'
import * as Yup from 'yup'
import { simplePasswordValidator } from '@electro/shared/utils/validators'
import useTranslation from 'next-translate/useTranslation'

type PasswordType = string

export interface CreatePasswordFormFields {
  password: PasswordType
}

interface CreatePasswordFormProps {
  children: ReactNode | ReactNode[]
  onSubmit: (values: CreatePasswordFormFields) => void
  password?: PasswordType
  submitButtonText?: string
  disabled?: boolean
  subHeading?: string
  errorMessage?: string
}

const signupValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('create_password.validation.password_required')
    .min(8, 'create_password.validation.password_min_characters')
    .test('password', 'create_password.validation.too_common', simplePasswordValidator),
})

const CreatePasswordFormContext = createContext(null)

const CreatePasswordForm = ({
  children,
  onSubmit,
  password = '',
  submitButtonText = 'common.button.continue',
  disabled,
  subHeading,
  errorMessage,
}: CreatePasswordFormProps) => {
  const formik = useFormik({
    initialValues: {
      password,
    },
    validationSchema: signupValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  useEffect(() => {
    if (errorMessage) formik.setErrors({ password: errorMessage })
  }, [errorMessage, formik])

  const context = useMemo(
    () => ({
      formik,
      submitButtonText,
      disabled,
      subHeading,
    }),
    [disabled, formik, subHeading, submitButtonText],
  )

  return (
    <CreatePasswordFormContext.Provider value={context}>
      {children}
    </CreatePasswordFormContext.Provider>
  )
}

const Fields = () => {
  const { t } = useTranslation('common')
  const { formik, disabled } = useContext(CreatePasswordFormContext)

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null
  const success = formik.submitCount > 0 && formik.isValid

  return (
    <form aria-label="create-password-form" onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
      <h3>{t('reset_password.new_password.heading_of_list')}</h3>
      <ol className="pb-4 text-sm text-white">
        <li>{t('reset_password.new_password.list.less_than_8')}</li>
        <li>{t('reset_password.new_password.list.common_password')}</li>
        <li>{t('reset_password.new_password.list.password_name_email')}</li>
      </ol>
      <div className="relative">
        <Input
          fullWidth
          name="password"
          label={t('common.form.password')}
          password
          placeholder="*********"
          value={formik.values.password}
          errorMessage={t(formik.errors.password)}
          onChange={formik.handleChange}
          className="pr-16"
          success={success}
          disabled={disabled}
        />
      </div>
    </form>
  )
}

const SubmitButton = () => {
  const { t } = useTranslation('common')
  const { submitButtonText, disabled, formik } = useContext(CreatePasswordFormContext)
  return (
    <Button
      disabled={disabled}
      data-testid="Submit"
      fullWidth
      type="button"
      onClick={formik.submitForm}
    >
      {t(submitButtonText)}
    </Button>
  )
}

CreatePasswordForm.Fields = Fields
CreatePasswordForm.SubmitButton = SubmitButton

export { CreatePasswordForm }
