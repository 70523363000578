/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode } from 'react'
import { CountryCodeType } from '@electro/fleets/src/constants/countryCodes'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'

interface RestrictToCountriesProps {
  countries?: CountryCodeType[]
  children: ReactNode | ReactNode[] | string
}
/**
 * Looks at the user's country code and checks if it is in the list of countries passed in.
 * NOTE: this will NOT restrict access based on the users current locale(language),
 * only the country code.
 */
export const RestrictToCountries = ({ countries, children }: RestrictToCountriesProps) => {
  const countryCode = useBusinessEntityStore((state) => state.businessEntity.countryCode)

  if (!countries) {
    return <>{children}</>
  }

  const isVisibleToUsersCountry = countries?.includes(countryCode)

  return isVisibleToUsersCountry ? <>{children}</> : null
}
