import { AddEvForm } from '@electro/fleets/src/components/VehicleList/components'
import { Button, Modal, Typography } from '@electro/shared-ui-components'
import { useVehicleList } from '@electro/fleets/src/components/VehicleList/hooks'
import Image from 'next/image'
import { VehicleType, useFleetsAddVehicleMutation } from '@electro/fleets/generated/graphql'
import { useState } from 'react'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import * as Sentry from '@sentry/nextjs'
import FLEETS_VEHICLE_QUERY from '@electro/fleets/graphql/fleetsVehicles.graphql'
import { useRouter } from 'next/router'
import { OFFSET_PARAM, FIRST_PARAM } from '@electro/fleets/src/constants/urlParams'
import useTranslation from 'next-translate/useTranslation'

export const AddEvModal = () => {
  const { query, locale } = useRouter()
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation('common')

  const { showAddVehicleModal, setShowAddVehicleModal } = useVehicleList()
  const [addVehicle, { loading, error }] = useFleetsAddVehicleMutation({
    onCompleted: () => setSuccess(true),
    onError(err) {
      Sentry.captureException(err)
    },
  })

  const handleCloseModal = () => {
    setShowAddVehicleModal(false)
    setSuccess(false)
  }

  const handleAddVehicle = (vehicle: VehicleType, reg = '') => {
    const vehicleName = `${vehicle.vehicleMake} ${vehicle.vehicleModel} ${
      vehicle.vehicleModelVersion ? vehicle.vehicleModelVersion : ''
    }`

    addVehicle({
      refetchQueries: [
        {
          query: FLEETS_VEHICLE_QUERY,
          variables: {
            first: Number(query[FIRST_PARAM]) || 20,
            offset: Number(query[OFFSET_PARAM]),
          },
        },
      ],
      variables: {
        vehicle: vehicle.pk,
        reg,
        name: `${vehicleName} (${vehicle.batteryCapacityFull}kWh)`,
      },
    })
  }

  useErrorNotificationEffect({
    error,
    message: error?.message,
  })

  return (
    <Modal size="md" open={showAddVehicleModal} onClose={handleCloseModal}>
      <Modal.CloseButton />

      <Modal.Body>
        <AddEvForm loading={loading} success={success} onSubmit={handleAddVehicle} locale={locale}>
          <AddEvForm.Fields />
          <AddEvForm.Success>
            <Typography variant="h1" className="mb-4">
              {t('common.success')}
            </Typography>
            <Typography className="mb-6">
              {t('vehicles.add_vehicle_modal.success.header')}
            </Typography>
            <Typography>{t('vehicles.add_vehicle_modal.success.sub_header')}</Typography>

            <Image
              className="m-auto my-16"
              src="/images/zap-star-car.svg"
              alt="Electroverse Logo"
              width="250"
              height="200"
            />

            <Button fullWidth onClick={handleCloseModal}>
              {t('common.button.done')}
            </Button>
          </AddEvForm.Success>
        </AddEvForm>
      </Modal.Body>
    </Modal>
  )
}
