import { useRouter } from 'next/router'
import { parse } from 'date-fns'

import { DriverConsumptionPageSearchQueryParams } from 'apps/fleets/pages/dashboard/reporting/driver/[driverPk]'

import { Card, DateRangeType, Pagination } from '@electro/shared-ui-components'
import {
  HandleOrderChange,
  DriverIndividualReceiptsSummary,
  DriverIndividualReceiptsTable,
  DriverConsumptionReportControls,
  DownloadReportButton,
} from '@electro/fleets/src/components'
import { useFetchFleetsDriversReceipts } from '@electro/fleets/src/services'
import { useUrlPagination } from '@electro/fleets/src/hooks'
import { useMount } from '@electro/shared/hooks'
import { useDateRangeStore, useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import { QUERY_DATE_FORMAT } from '@electro/shared/constants'
import { formatDateForQuery } from '@electro/fleets/src/utils/formatters'

import {
  DriverReceiptsConnectionTypeOrderingFields as DriverReceiptsOrderByEnum,
  DriverTokenTypeOrderingFields,
  OrderingDirectionEnum,
} from '@electro/fleets/generated/graphql'
import { useMemo, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

type DriverIndividualReceiptsProps = DriverConsumptionPageSearchQueryParams

interface UpdateDriverReceiptsReportArgs {
  dateRange: DateRangeType
  ordering?: {
    orderBy: DriverReceiptsOrderByEnum | DriverTokenTypeOrderingFields
    orderDirection: OrderingDirectionEnum
  }
}

const RESULTS_PER_PAGE = 20
const DEFAULT_ORDERING = {
  orderBy: DriverReceiptsOrderByEnum.PeriodEnd,
  orderDirection: OrderingDirectionEnum.Desc,
}

export const DriverIndividualReceipts = ({
  driverPk,
  startDate,
  endDate,
  orderBy,
  orderDirection,
  showAllReceipts,
}: DriverIndividualReceiptsProps) => {
  const { t } = useTranslation('common')
  /* the network-only fetch is required because the startDate changes depending on whether the URL has a `showAllReceipts` param, which is not reflected in the cache */
  const [fleetsDriversReceiptsQuery, fleetsDriversReceipts] = useFetchFleetsDriversReceipts({
    fetchPolicy: 'network-only',
  })

  const router = useRouter()
  const driver = fleetsDriversReceipts.data?.fleetsDriversReceipts.edges[0]?.node?.driver

  const dateRange = useDateRangeStore((state) => state.dateRange)
  const dateLimits = useDateRangeStore((state) => state.dateLimits)

  const setDateRange = useDateRangeStore((state) => state.setDateRange)
  const setDateLimits = useDateRangeStore((state) => state.setDateLimits)

  const dateBusinessRegistered = useMemo(
    () => new Date(useBusinessEntityStore.getState().businessEntity.registeredAt),
    [],
  )

  useEffect(() => {
    setDateLimits({
      startDate: dateBusinessRegistered,
      endDate: new Date(),
    })
  }, [dateBusinessRegistered, setDateLimits])

  const handleStartAndEndDate = useMemo(() => {
    if (showAllReceipts) {
      return {
        startDate: formatDateForQuery(dateBusinessRegistered),
        endDate: formatDateForQuery(dateRange.endDate),
      }
    }
    return {
      startDate: formatDateForQuery(dateRange.startDate),
      endDate: formatDateForQuery(dateRange.endDate),
    }
  }, [showAllReceipts, dateRange.startDate, dateRange.endDate, dateBusinessRegistered])

  const {
    currentPageOffset,
    hasNextPage,
    hasPreviousPage,
    nextHandler,
    prevHandler,
    showPagination,
  } = useUrlPagination({
    resultsPerPage: RESULTS_PER_PAGE,
    totalCount: fleetsDriversReceipts.data?.fleetsDriversReceipts.totalCount,
    onPaginationChange: ({ offset, query }) => {
      fleetsDriversReceiptsQuery({
        variables: {
          drivers: [parseInt(driverPk, 10)],
          startDate: handleStartAndEndDate.startDate,
          endDate: handleStartAndEndDate.endDate,
          first: RESULTS_PER_PAGE,
          offset,
          ordering: [
            {
              orderBy: query.orderBy,
              direction: query.orderDirection,
            },
          ],
        },
      })
    },
  })

  const updateIndividualReceiptsReport = async ({
    dateRange: nextDateRange,
    ordering,
  }: UpdateDriverReceiptsReportArgs) => {
    const nextFormattedDateRange = {
      startDate: showAllReceipts
        ? formatDateForQuery(dateBusinessRegistered)
        : formatDateForQuery(nextDateRange.startDate),
      endDate: formatDateForQuery(nextDateRange.endDate),
    }

    const nextOrdering = {
      orderBy: ordering?.orderBy || DEFAULT_ORDERING.orderBy,
      orderDirection: ordering?.orderDirection || DEFAULT_ORDERING.orderDirection,
    }

    await fleetsDriversReceiptsQuery({
      variables: {
        drivers: [parseInt(driverPk, 10)],
        startDate: nextFormattedDateRange.startDate,
        endDate: nextFormattedDateRange.endDate,
        first: RESULTS_PER_PAGE,
        offset: currentPageOffset,
        ordering: [
          {
            orderBy: nextOrdering.orderBy,
            direction: nextOrdering.orderDirection,
          },
        ],
      },
    })

    setDateRange({ startDate: nextDateRange.startDate, endDate: nextDateRange.endDate })

    router.replace({
      query: {
        ...router.query,
        ...nextOrdering,
        ...nextFormattedDateRange,
      },
    })
  }

  useMount(() => {
    const orderingOnMount = {
      orderBy: orderBy || DEFAULT_ORDERING.orderBy,
      orderDirection: orderDirection || DEFAULT_ORDERING.orderDirection,
    }

    const dateRangeOnMount = {
      startDate: startDate ? parse(startDate, QUERY_DATE_FORMAT, new Date()) : dateRange.startDate,
      endDate: endDate ? parse(endDate, QUERY_DATE_FORMAT, new Date()) : dateRange.endDate,
    }

    updateIndividualReceiptsReport({ dateRange: dateRangeOnMount, ordering: orderingOnMount })
  })

  const receiptCount = fleetsDriversReceipts.data?.fleetsDriversReceipts.totalCount

  const handleDateRangeChange = (dateRangeEvent: DateRangeType) => {
    updateIndividualReceiptsReport({ dateRange: dateRangeEvent })
  }

  function handleOrderChange({ nextOrderBy }: HandleOrderChange<DriverReceiptsOrderByEnum>) {
    const [nextOrderByKey] = Object.keys(DriverReceiptsOrderByEnum).filter(
      (enumKey) => DriverReceiptsOrderByEnum[enumKey] === nextOrderBy,
    )
    const nextOrderDirection =
      orderBy === DriverReceiptsOrderByEnum[nextOrderByKey] &&
      orderDirection === OrderingDirectionEnum.Asc
        ? OrderingDirectionEnum.Desc
        : OrderingDirectionEnum.Asc

    updateIndividualReceiptsReport({
      dateRange,
      ordering: {
        orderBy: nextOrderBy,
        orderDirection: nextOrderDirection,
      },
    })
  }

  const showReportActions = useMemo(
    () =>
      fleetsDriversReceipts?.data?.fleetsDriversReceipts?.edges?.length > 0 &&
      !fleetsDriversReceipts.loading,
    [fleetsDriversReceipts?.data, fleetsDriversReceipts.loading],
  )

  return (
    <Card>
      <div className="flex flex-col justify-center gap-4 lg:grid lg:grid-cols-1 lg:justify-start">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-6">
          <DriverIndividualReceiptsSummary {...{ receiptCount }} />
          <div className="mb-4 md:col-span-4">
            <DriverConsumptionReportControls
              {...{
                handleDateRangeChange,
                dateRange,
                dateLimits,
              }}
            />
          </div>
        </div>
        <div className="flex justify-center h-10 lg:justify-end">
          {showReportActions ? (
            <DownloadReportButton
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              driver={driver}
              business={null}
              reportType="driver"
            />
          ) : null}
        </div>

        <DriverIndividualReceiptsTable
          onHeaderClick={handleOrderChange}
          orderBy={orderBy}
          orderDirection={orderDirection}
          fleetsDriversReceipts={fleetsDriversReceipts}
        />
        {showPagination && (
          <Pagination
            nextDisabled={!hasNextPage}
            previousDisabled={!hasPreviousPage}
            onClickNext={nextHandler}
            onClickPrevious={prevHandler}
            currentStartRange={currentPageOffset + 1}
            currentEndRange={hasNextPage ? currentPageOffset + RESULTS_PER_PAGE : receiptCount}
            totalResults={receiptCount}
            previousButtonText={t('common.button.previous.label')}
            nextButtonText={t('common.button.next.label')}
            previousAriaLabel={t('common.button.previous.aria_label')}
            nextAriaLabel={t('common.button.next.aria_label')}
            ofText={t('common.of')}
            toText={t('common.to')}
          />
        )}
      </div>
    </Card>
  )
}
