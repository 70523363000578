import { Card, Skeleton } from '@electro/shared-ui-components'
import { Fragment } from 'react'
import { v1 as uuid } from 'uuid'

interface Props {
  rows?: number
}

export const WidgetSkeleton = ({ rows = 2 }: Props) => (
  <Card data-testid="widget-skeleton">
    <Skeleton width={180} />

    <div className="grid grid-cols-2 gap-8 mt-8">
      {Array.from(Array(rows)).map(() => (
        <Fragment key={uuid()}>
          <Skeleton width={150} />
          <Skeleton width={150} />
        </Fragment>
      ))}
    </div>
  </Card>
)
