import {
  DriversReceiptsAggregateConnectionTypeOrderingFields,
  OrderingDirectionEnum,
} from '@electro/fleets/generated/graphql'
import { QueryKeyEnum } from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { useQueryParams } from '@electro/fleets/src/hooks/useQueryParams'

export const useDriversConsumptionQueryParams = () =>
  useQueryParams<DriversReceiptsAggregateConnectionTypeOrderingFields>(QueryKeyEnum.ReportingPage, {
    initialState: {
      first: 50,
      ordering: [
        {
          orderBy: DriversReceiptsAggregateConnectionTypeOrderingFields.Name,
          direction: OrderingDirectionEnum.Asc,
        },
      ],
    },
  })
