import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

interface VehicleListReturnType {
  showAddVehicleModal: boolean
  setShowAddVehicleModal: Dispatch<SetStateAction<boolean>>
}

const VehicleListContext = createContext<VehicleListReturnType>(null)

const useVehicleListProvider = (): VehicleListReturnType => {
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false)

  return {
    showAddVehicleModal,
    setShowAddVehicleModal,
  }
}

export const VehicleListProvider = ({ children }) => {
  const value = useVehicleListProvider()

  return <VehicleListContext.Provider value={value}>{children}</VehicleListContext.Provider>
}

export const useVehicleList = () => {
  const context = useContext(VehicleListContext)

  if (!context) throw new Error('useVehicleList() cannot be used outside of <VehicleListProvider/>')
  return context
}
