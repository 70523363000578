import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'
import { Mutation, MutationFleetsTagDriversArgs } from '@electro/fleets/generated/graphql'
import FLEETS_TAG_DRIVERS from '@electro/fleets/graphql/fleetsTagDriversMutation.graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsTagDriverMutation = Pick<Mutation, 'fleetsTagDrivers'>

type FleetsTagDriversReturnType = [
  ApolloMutationFunction<FleetsTagDriverMutation>,
  MutationResult<FleetsTagDriverMutation>,
]

type Options = MutationHookOptions<FleetsTagDriverMutation, MutationFleetsTagDriversArgs>

export const useFleetsTagDrivers = (options?: Options): FleetsTagDriversReturnType => {
  const [fleetsTagDriversMutation, fleetsTagDriversResult] = useMutation(
    FLEETS_TAG_DRIVERS,
    options,
  )

  return [fleetsTagDriversMutation, fleetsTagDriversResult]
}
