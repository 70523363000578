import { Input, LoadingSpinner } from '@electro/shared-ui-components'
import { useDebounce } from 'react-use'
import * as Sentry from '@sentry/nextjs'
import {
  CharityInformationType,
  useFleetsRetrieveCharityInfoMutation,
} from '@electro/fleets/generated/graphql'
import { SIGN_UP_TOKEN } from '@electro/fleets/src/constants/urlParams'
import { useRouter } from 'next/router'
import { formatNumbersOnly } from '@electro/shared/utils/formatters'
import { useState } from 'react'
import getMessageFromError from '@electro/fleets/src/utils/getMessageFromError'
import { charityNumberValidator } from '@electro/shared/utils/validators'

interface CharityNumberLookupProps {
  value: string
  error?: string
  onChange?: (e) => void
  onValid?: (data: CharityInformationType) => void
  onError?: (error: any) => void
  name: string
  required?: boolean
  label?: string
  placeholder?: string
}

export const CharityNumberLookup = ({
  label = 'Charities Commission Number',
  placeholder = 'e.g. 0123456',
  value,
  error,
  onValid,
  onError,
  name,
  required,
  onChange,
}: CharityNumberLookupProps) => {
  const [getCharityInfo, { data: charityData, loading: charityInfoLoading }] =
    useFleetsRetrieveCharityInfoMutation()

  const [fieldValue, setFieldValue] = useState(value)

  const router = useRouter()
  const signupToken = router.query[SIGN_UP_TOKEN] as string

  const handleCharityNumberChange = (e) => {
    const formattedCharityNumber = formatNumbersOnly(e.target.value)
    onChange?.(e)
    setFieldValue(formattedCharityNumber)
  }

  useDebounce(
    async () => {
      const charityNumber = fieldValue
      const charityNumberIsValid = charityNumberValidator(charityNumber)

      if (charityNumberIsValid) {
        try {
          const { data } = await getCharityInfo({
            variables: {
              charityNumber,
              signupToken,
            },
          })

          if (data?.fleetsRetrieveCharityInfo.success) {
            onValid?.(data?.fleetsRetrieveCharityInfo?.charityInfo)
          }
        } catch (err) {
          const errorMessage = await getMessageFromError(err, router.locale)
          onError?.(errorMessage)
          Sentry.captureException(err, (scope) => scope.setExtras({ charityNumber }))
        }
      }
    },
    400,
    [fieldValue, signupToken],
  )

  const charityFound = Boolean(charityData?.fleetsRetrieveCharityInfo?.charityInfo)

  return (
    <div className="relative">
      {charityInfoLoading ? <LoadingSpinner className="absolute right-2 top-8 w-5 h-5" /> : null}
      <Input
        placeholder={placeholder}
        fullWidth
        label={label}
        type="text"
        onChange={handleCharityNumberChange}
        maxLength={8}
        value={fieldValue}
        errorMessage={error}
        name={name}
        required={required}
        success={charityFound}
        successMessage={
          charityFound && !error
            ? `Charity found: ${charityData?.fleetsRetrieveCharityInfo.charityInfo.charityName}`
            : null
        }
      />
    </div>
  )
}
