import FLEETS_DEACTIVATE_DRIVERS_CARD_MUTATION from '@electro/fleets/graphql/fleetsDeactivateDriversCardMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsFreezeDriversCardArgs } from '@electro/fleets/generated/graphql'

type DeactivateDriversCardsMutation = Pick<Mutation, 'fleetsFreezeDriversCard'>

type UseFleetsDeactivateDriversCard = [
  ApolloMutationFunction<DeactivateDriversCardsMutation>,
  MutationResult<DeactivateDriversCardsMutation>,
]

type UseFleetsDeactivateDriversCardArgs = MutationHookOptions<
  DeactivateDriversCardsMutation,
  MutationFleetsFreezeDriversCardArgs
>

export function useFleetsDeactivateDriversCard(
  options?: UseFleetsDeactivateDriversCardArgs,
): UseFleetsDeactivateDriversCard {
  const [mutation, result] = useMutation<DeactivateDriversCardsMutation>(
    FLEETS_DEACTIVATE_DRIVERS_CARD_MUTATION,
    options,
  )

  return [mutation, result]
}
