import { Typography } from '@electro/shared-ui-components'
import {
  BusinessChargeRecords,
  BusinessInvoices,
  CurrentSubscriptions,
  PaymentMethodV2,
} from '@electro/fleets/src/components/PaymentAndInvoices/components'
import { useFleetsChargeRecordsQuery } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { DemoModeWrapper } from '@electro/fleets/src/components'

const styles = {
  root: 'flex-1 flex flex-col space-y-8',
  wrapper: 'overflow-auto pb-10',
}

export const PaymentAndInvoices = () => {
  const { t } = useTranslation('common')
  const { data: chargeRecordData, error: chargeRecordError } = useFleetsChargeRecordsQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Typography variant="h3" className="mb-3">
          {t('payment_and_invoices.heading.current_subscriptions')}
        </Typography>

        <CurrentSubscriptions />
      </div>

      <DemoModeWrapper>
        <div className={styles.wrapper}>
          <Typography variant="h3" className="mb-3">
            {t('payment_and_invoices.heading.direct_debit_details')}
          </Typography>

          <PaymentMethodV2 />
        </div>
      </DemoModeWrapper>

      <div className={styles.wrapper}>
        <Typography variant="h3" className="mb-3 inline-flex items-center gap-2">
          {t('payment_and_invoices.heading.invoices')}
        </Typography>
        <BusinessInvoices />
      </div>

      {chargeRecordData?.fleetsChargeRecords?.edges.length > 0 || chargeRecordError ? (
        <div className={styles.wrapper}>
          <Typography variant="h3" className="mb-3">
            {t('payment_and_invoices.heading.charge_records')}
          </Typography>
          <BusinessChargeRecords />
        </div>
      ) : null}
    </div>
  )
}
