import { useCallback, useEffect, useMemo, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { Button, LoadingBolt, Typography } from '@electro/shared-ui-components'
import getYearFromPartialDateString from '@electro/shared/utils/getYearFromPartialDateString'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import {
  useAddEvForm,
  AddEvFormStage,
} from '@electro/fleets/src/components/VehicleList/components/AddEvForm/hooks'

import {
  Query,
  QueryVehiclesArgs,
  VehicleType,
  useVehicleMakesLazyQuery,
  useVehicleModelVersionsLazyQuery,
  useVehicleModelsLazyQuery,
  useVehiclesByMakeModelVersionLazyQuery,
} from '@electro/fleets/generated/graphql'
import { GTM } from '@electro/fleets/src/utils/event-triggers'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import useTranslation from 'next-translate/useTranslation'
import { RestrictToCountries } from '@electro/fleets/src/components'
import { COUNTRY_CODES } from '@electro/fleets/src/constants/countryCodes'

export type VehiclesLookupFields = QueryVehiclesArgs

enum vehicleSelectStagesEnum {
  VEHICLE_MAKE_STAGE = 'vehicleMake',
  VEHICLE_MODEL_STAGE = 'vehicleModel',
  VEHICLE_MODEL_VERSION_STAGE = 'vehicleModelVersion',
  VEHICLE_DETAILS_STAGE = 'vehicleFull',
  COMPLETED_STAGE = 'completed',
}

const {
  VEHICLE_MAKE_STAGE,
  VEHICLE_MODEL_STAGE,
  VEHICLE_MODEL_VERSION_STAGE,
  VEHICLE_DETAILS_STAGE,
  COMPLETED_STAGE,
} = vehicleSelectStagesEnum

type Stages = `${vehicleSelectStagesEnum}` | null

type VehicleDict = {
  [stage in Stages]?: {
    value: string
  }
}

interface MenuItem {
  value: string

  [key: string]: any
}

type ListBoxChangeHandler = (MenuItem) => void

const styles = {
  menuOption: tw(
    'cursor-default select-none relative py-2 pl-4 pr-4',
    'hover:bg-white hover:bg-opacity-25',
    'hover:text-tertiary',
  ),
}

export const ManualVehicleLookup = () => {
  const { t } = useTranslation('common')
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [stage, setStage] = useState<Stages>(VEHICLE_MAKE_STAGE)
  const [vehicleParts, setVehicleParts] = useState<VehicleDict>({})
  const [octoEvDbId, setOctoEvDbId] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleType>(null)

  const companyName = useBusinessEntityStore((state) => state.businessEntity.companyName)

  const { setFormState, handleSubmit, loading: submitLoading } = useAddEvForm()
  const [fetchVehicleMakes, { loading: vehicleMakesLoading, error: vehicleMakesError }] =
    useVehicleMakesLazyQuery()
  const [fetchVehicleModels, { loading: vehicleModelsLoading, error: vehicleModelsError }] =
    useVehicleModelsLazyQuery()
  const [
    fetchVehicleModelVersions,
    { loading: vehicleModelVersionsLoading, error: vehicleModelVersionsError },
  ] = useVehicleModelVersionsLazyQuery()
  const [
    fetchVehicleMakeModelVersion,
    { loading: vehicleMakeModelVersionLoading, error: vehicleMakeModelVersionError },
  ] = useVehiclesByMakeModelVersionLazyQuery()

  const loading = useMemo(
    () =>
      vehicleMakesLoading ||
      vehicleModelsLoading ||
      vehicleModelVersionsLoading ||
      vehicleMakeModelVersionLoading,
    [
      vehicleMakesLoading,
      vehicleModelsLoading,
      vehicleModelVersionsLoading,
      vehicleMakeModelVersionLoading,
    ],
  )

  const error = useMemo(() => {
    const queryErrors = [
      vehicleMakesError,
      vehicleModelsError,
      vehicleModelVersionsError,
      vehicleMakeModelVersionError,
    ]

    return queryErrors.find((err) => err)
  }, [
    vehicleMakesError,
    vehicleModelsError,
    vehicleModelVersionsError,
    vehicleMakeModelVersionError,
  ])

  const stageOperations = useMemo(
    () => ({
      [VEHICLE_MAKE_STAGE]: {
        query: fetchVehicleMakes,
        handleData: (data) => {
          const nextMenuItems = data.vehicleMakes.map((item) => ({
            value: item,
          }))
          return setMenuItems(nextMenuItems)
        },
      },
      [VEHICLE_MODEL_STAGE]: {
        query: () =>
          fetchVehicleModels({
            variables: { vehicleMake: vehicleParts?.[VEHICLE_MAKE_STAGE]?.value },
          }),
        handleData: (data) => {
          const nextMenuItems = data.vehicleModels.map((item) => ({
            value: item,
          }))
          return setMenuItems(nextMenuItems)
        },
      },
      [VEHICLE_MODEL_VERSION_STAGE]: {
        query: () =>
          fetchVehicleModelVersions({
            variables: {
              vehicleMake: vehicleParts?.[VEHICLE_MAKE_STAGE]?.value,
              vehicleModel: vehicleParts?.[VEHICLE_MODEL_STAGE]?.value,
            },
          }),
        /**
         * If the API returns null or an empty string at this phase we do not want to exit the lookup!
         * a null or '' value in vehicleModelVersions actually represents a 'Standard' variant of this
         * vehicle. In this we can send an empty string back to the api and we will get back a list of
         * vehicles. To make this clearer to the users we are showing more details on the vehicle on
         * the last choice.
         */
        handleData: (data) => {
          const { vehicleModelVersions } = data
          const noVehicleModelVersions = vehicleModelVersions.length === 0

          if (noVehicleModelVersions) {
            return setStage(COMPLETED_STAGE)
          }
          const nextMenuItems = data.vehicleModelVersions.map((item) => ({
            value: item,
          }))
          return setMenuItems(nextMenuItems)
        },
      },
      [VEHICLE_DETAILS_STAGE]: {
        query: () =>
          fetchVehicleMakeModelVersion({
            variables: {
              vehicleMake: vehicleParts?.[VEHICLE_MAKE_STAGE]?.value,
              vehicleModel: vehicleParts?.[VEHICLE_MODEL_STAGE]?.value || '',
              vehicleModelVersion: vehicleParts?.[VEHICLE_MODEL_VERSION_STAGE]?.value || '',
            },
          }),
        handleData: (data: Pick<Query, 'vehicles'>) => {
          const [firstVehicle] = data.vehicles.edges
          /**
           * In some cases we see empty fields for availabilityDateFrom && availabilityDateFrom
           * in these cases we want to send the user to the complete stage so they can add
           * a vehicle
           */
          const singleVehicleWithNoAvailabilityData =
            data.vehicles.edges.length === 1 &&
            !firstVehicle.node.availabilityDateFrom &&
            !firstVehicle.node.availabilityDateTo

          if (singleVehicleWithNoAvailabilityData) {
            // octoEvDbId is needed on completed stage to get the ev vehicle
            // from returned data, otherwise the submitted vehicle will be undefined
            setOctoEvDbId(firstVehicle.node.octoEvDbId)

            return setStage(COMPLETED_STAGE)
          }

          const getFormattedYearFromVehicle = (vehicle: VehicleType) =>
            `${getYearFromPartialDateString(vehicle.availabilityDateFrom)}${
              vehicle.availabilityDateTo
                ? `-${getYearFromPartialDateString(vehicle.availabilityDateTo)}`
                : ''
            }`

          const getMakeModelFromVehicle = (vehicle: VehicleType) =>
            `${vehicle.vehicleMake} ${vehicle.vehicleModel || ''} ${
              vehicle.vehicleModelVersion || ''
            }`

          const getFullBatteryCapacityFromVehicle = (vehicle: VehicleType) =>
            `(${vehicle.batteryCapacityFull}kWh)`

          const formattedVehicleDetails = data.vehicles.edges.map((edge) => ({
            octoEvDbId: edge.node.octoEvDbId,
            value: `${getMakeModelFromVehicle(edge.node)} ${getFullBatteryCapacityFromVehicle(
              edge.node,
            )} ${getFormattedYearFromVehicle(edge.node)}`,
          }))
          return setMenuItems(formattedVehicleDetails)
        },
      },
      [COMPLETED_STAGE]: {
        query: () => null,
        handleData: () => null,
      },
    }),
    [
      fetchVehicleMakes,
      fetchVehicleModelVersions,
      fetchVehicleModels,
      fetchVehicleMakeModelVersion,
      vehicleParts,
    ],
  )

  const stageKeys = useMemo(
    () => Object.keys(stageOperations).map((stageKey: Stages) => stageKey),
    [stageOperations],
  )

  const isCompletedStage = useMemo(() => stage === COMPLETED_STAGE, [stage])

  const nextStage: Stages = useMemo(() => {
    const currentStageIndex = stageKeys.indexOf(stage)
    return stageKeys[currentStageIndex + 1] || null
  }, [stage, stageKeys])

  const handleSelect: ListBoxChangeHandler = useCallback(
    (item: MenuItem) => {
      const nextVehicleParts = {
        ...vehicleParts,
        [stage]: {
          value: item.value,
        },
      }
      setOctoEvDbId(item.octoEvDbId)
      setVehicleParts(nextVehicleParts)
      setStage(nextStage)
    },
    [nextStage, stage, vehicleParts],
  )

  const handleButtonClick = useCallback(
    (stageKey: Stages) => () => {
      setStage(stageKey)
      setSelectedVehicle(null)

      // We also want to clear the next stages when a button is clicked on.
      // We are using the 'vehicleParts' object as our source of truth.
      const stageIndex = Object.keys(vehicleParts)
        .map((s) => s)
        .indexOf(stageKey)

      const nextVehicleParts = Object.keys(vehicleParts)
        .map((s) => ({
          [s]: { value: vehicleParts[s].value },
        }))
        .filter((_, index) => index < stageIndex)

      const reducedParts = nextVehicleParts.reduce((result, item) => {
        const nextResult = result
        const key = Object.keys(item)[0]
        nextResult[key] = item[key]
        return nextResult
      }, {})

      setVehicleParts(reducedParts)
    },
    [vehicleParts],
  )

  // every time the stage changes we want to programmatically
  // load the next set of data from a new source and
  // apply a different handler.
  useEffect(() => {
    const getVehicles = async () => {
      const { data } = await stageOperations[stage].query()
      stageOperations[stage].handleData(data)
    }
    if (!isCompletedStage) {
      getVehicles()
    } else {
      setMenuItems(null)
    }
  }, [stage, isCompletedStage, stageOperations])

  useErrorNotificationEffect({
    error,
    message: 'Could not fetch vehicle details! Try again later.',
  })

  // On the last stage we need to fetch a vehicle with any details we have available.
  useEffect(() => {
    if (isCompletedStage) {
      const fetchSelectedVehicle = async () => {
        const { data } = await fetchVehicleMakeModelVersion({
          variables: {
            vehicleMake: vehicleParts?.[VEHICLE_MAKE_STAGE]?.value,
            vehicleModel: vehicleParts?.[VEHICLE_MODEL_STAGE]?.value,
            vehicleModelVersion: vehicleParts?.[VEHICLE_MODEL_VERSION_STAGE]?.value,
          },
        })
        const selectedEv = data.vehicles?.edges.find(
          (edge) => edge.node.octoEvDbId === octoEvDbId,
        )?.node

        setSelectedVehicle({
          ...selectedEv,
          chargeStandardPower: 0,
          efficiencyReal: 100,
          isHybrid: false,
        })
      }

      fetchSelectedVehicle()
    }
  }, [fetchVehicleMakeModelVersion, isCompletedStage, octoEvDbId, vehicleParts])

  return (
    <>
      <Typography variant="h1" className="mb-4">
        {t('vehicles.add_vehicle_modal.button.add_manually')}
      </Typography>

      <div className="overflow-auto mt-7 max-h-[calc(100vh-300px)] flex flex-col">
        <div data-testid="vehicle-selector-stage-menu" className="flex flex-col gap-2 pb-4">
          {Object.keys(vehicleParts)?.map((stageKey: Stages) => (
            <Button
              disabled={loading}
              fullWidth
              variant="outline"
              key={stageKey}
              data-testid={`${stageKey}-button`}
              onClick={handleButtonClick(stageKey)}
              className="last:mb-2 flex justify-between px-3"
            >
              <span>
                {vehicleParts[stageKey].value ||
                  t('vehicles.add_vehicle_modal_manual.button.standard')}
              </span>
              <PencilIcon className="w-4 h-4" />
            </Button>
          ))}
        </div>
        {loading && (
          <LoadingBolt size="sm" subtitle={t('common.button.loading_searching')} className="my-4" />
        )}

        <div className="overflow-auto">
          {menuItems?.length > 0 && !loading && (
            <Listbox
              data-testid="vehicle-make-model-menu"
              value={{ value: vehicleParts[stage]?.value }}
              onChange={handleSelect}
            >
              <Listbox.Options className="mb-4" static>
                {menuItems?.map((item) => (
                  <Listbox.Option
                    disabled={loading}
                    data-testid="vehicle-select-option"
                    key={item.octoEvDbId || item.value}
                    className={styles.menuOption}
                    value={item}
                  >
                    <span
                      className={tw({
                        'block truncate': true,
                      })}
                    >
                      {item?.value || t('vehicles.add_vehicle_modal_manual.button.standard')}
                    </span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          )}
        </div>
      </div>

      <div className="mt-4 px-12 space-y-4">
        <Button
          fullWidth
          disabled={!selectedVehicle || submitLoading}
          onClick={() => {
            handleSubmit(selectedVehicle)
            GTM.submitVehicle({ companyName, vehicle: selectedVehicle, vehicleLookup: 'manual' })
          }}
        >
          {submitLoading
            ? t('vehicles.add_vehicle_modal.button.adding_vehicle')
            : t('vehicles.add_vehicle_modal.button.add_vehicle')}
        </Button>
        <RestrictToCountries countries={[COUNTRY_CODES.GBR]}>
          <Button
            fullWidth
            variant="outline"
            onClick={() => setFormState(AddEvFormStage.REGISTRATION_LOOK_UP)}
          >
            {t('common.button.back')}
          </Button>
        </RestrictToCountries>
      </div>
    </>
  )
}
