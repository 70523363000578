export type SetState<T> = (updater: (args: State<T>) => State<T>) => void

export interface State<T> {
  isInitialized: boolean
  queryParams: Partial<QueryParamsState<T>>
}

export enum OrderingDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface Ordering<T> {
  orderBy: T
  direction: OrderingDirectionEnum
}

export interface QueryParamsState<T> {
  first: number
  offset: number
  search: string
  ordering: Ordering<T>[]
  additionalParams: Record<string, string>
}

export interface Query<T> {
  queryKey: string
  queryHash: string
  subscribers: Observer<T>[]
  state: State<T>
  subscribe: (subscriber: Observer<T>) => () => void
  setState: SetState<T>
  initialize: () => void
}

export type Render = () => void

export interface ObserverArgs {}

export interface Observer<T> {
  notify: () => void
  subscribe: (rerender: Render) => () => void
  getQueryParamState: () => State<T>
}

export interface Options<T> {
  initialState?: Partial<QueryParamsState<T>>
}

export enum QueryKeyEnum {
  DriverPage = 'driversPage',
  ReportingPage = 'reportingPage',
  ElectrocardsListPage = 'electrocardsListPage',
  VehicleListPage = 'vehicleListPage',
  ExpensesPage = 'expensesPage',
}
