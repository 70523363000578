import { useMemo } from 'react'

import {
  useFleetsDriversTokensQuery,
  useFleetsCardOrdersQuery,
  EjnEjnCardOrderStatusChoices,
} from '@electro/fleets/generated/graphql'

import {
  AllElectroCardsAssignedModal,
  AssignElectrocardModal,
  OrderMoreElectroCardsModal,
  YourElectrocardsAreOnTheWayModal,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components/AssignElectroCardModalScreen/components'
import { Button, LoadingOverlay, Modal, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { useErrorMessage } from '@electro/fleets/src/hooks/'

export const AssignElectroCardModalScreen = () => {
  const { t } = useTranslation('common')
  const { closeModalScreen } = useDriverWidgets()
  const { data, loading, error } = useFleetsDriversTokensQuery({
    variables: {
      first: 50,
      offset: 0,
    },
  })
  const {
    data: cardOrderData,
    loading: cardOrderLoading,
    error: cardOrderError,
  } = useFleetsCardOrdersQuery()
  const electrocardsError = useErrorMessage(error)
  const electrocardsOrderError = useErrorMessage(cardOrderError)
  const hasElectrocards = useMemo(() => data?.fleetsDriversTokens.edgeCount > 0, [data])
  const hasUnassignedCards = useMemo(() => data?.unassignedTokens?.totalCount > 0, [data])
  const hasOrderedElectroCards = useMemo(
    () =>
      cardOrderData?.fleetsCardOrders?.edges?.filter(
        (cardOrder) =>
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.New ||
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.Pending,
      )?.length > 0,
    [cardOrderData],
  )
  const hasNoUnAssignedElectrocards = useMemo(
    () => !hasUnassignedCards && !hasOrderedElectroCards && hasElectrocards,
    [hasUnassignedCards, hasOrderedElectroCards, hasElectrocards],
  )
  const hasPlacedAnOrder = useMemo(
    () => !hasUnassignedCards && hasOrderedElectroCards,
    [hasUnassignedCards, hasOrderedElectroCards],
  )

  if (electrocardsError || electrocardsOrderError) {
    return (
      <>
        <Modal.Body>
          <div className="flex flex-col items-center">
            <Image alt="confused mr-zap" src="/images/mr-zap-oh-no.png" width="334" height="243" />

            <Typography variant="h3" className="text-center mt-5">
              {t('errors.common.oops')}!
            </Typography>

            <Typography className="text-center mt-5">
              {electrocardsError || electrocardsOrderError}
            </Typography>
          </div>
        </Modal.Body>

        <Modal.Actions stacked>
          <Button variant="outline" fullWidth onClick={closeModalScreen}>
            {t('common.button.close')}
          </Button>
        </Modal.Actions>
      </>
    )
  }

  if (loading || cardOrderLoading) {
    return (
      <div className="h-112">
        <LoadingOverlay fixed />
      </div>
    )
  }

  return (
    <>
      <AssignElectrocardModal show={hasUnassignedCards} />
      <OrderMoreElectroCardsModal show={!hasElectrocards && !hasOrderedElectroCards} />
      <AllElectroCardsAssignedModal show={hasNoUnAssignedElectrocards} />
      <YourElectrocardsAreOnTheWayModal show={hasPlacedAnOrder} />
    </>
  )
}
