import { createContext, PropsWithChildren } from 'react'
import Router from 'next/router'
import { QueryParams } from '@electro/fleets/src/hooks/useQueryParams/QueryParams'

export const QueryParamsContext = createContext(null)

export const queryParams = new QueryParams({ router: Router })

export const QueryParamsProvider = ({ children }: PropsWithChildren) => (
  <QueryParamsContext.Provider value={queryParams}>{children}</QueryParamsContext.Provider>
)
