import { useUrlPagination } from '@electro/fleets/src/hooks'
import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Menu,
  useToastNotification,
  Tooltip,
} from '@electro/shared-ui-components'
import {
  DriverType,
  useFleetsRemoveDriverVehicleMutation,
  useFleetsVehiclesQuery,
} from '@electro/fleets/generated/graphql'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { ModalScreensEnum } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/DriverWidgets.types'
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/24/solid'
import * as Sentry from '@sentry/nextjs'
import FLEETS_VEHICLE_QUERY from '@electro/fleets/graphql/fleetsVehicles.graphql'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'

const VEHICLES_RESULTS_PER_PAGE = 10

interface Props {
  driver: DriverType
}

const styles = {
  cellText: 'lg:text-sm lg:text-white text-sm whitespace-normal',
}

export const VehiclesWidget = ({ driver }: Props) => {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const { setActiveModalScreen } = useDriverWidgets()
  const [removeDriverVehicle, { loading: removeDriverVehicleLoading }] =
    useFleetsRemoveDriverVehicleMutation({
      refetchQueries: [
        {
          query: FLEETS_VEHICLE_QUERY,
          variables: { driver: driver.pk, first: VEHICLES_RESULTS_PER_PAGE, offset: 0 },
        },
      ],
      onError: (err) => {
        Sentry.captureException(err)
        showToastNotification({
          variant: 'error',
          heading: 'Oops',
          body: err?.message,
        })
      },
      onCompleted: () =>
        showToastNotification({
          variant: 'success',
          heading: t('common.success'),
          body: t('drivers.widgets.assign_vehicle.toast.vehicle_unassigned'),
          timeout: 3000,
        }),
    })
  const { data, loading, error, refetch } = useFleetsVehiclesQuery({
    variables: {
      driver: driver.pk,
      first: VEHICLES_RESULTS_PER_PAGE,
      offset: 0,
    },
  })

  const handleOpenAssignVehicleModal = () => {
    setActiveModalScreen(ModalScreensEnum.ASSIGN_VEHICLE_MODAL_SCREEN)
  }

  const handleRemoveDriverVehicle = (fleetVehiclePk) =>
    removeDriverVehicle({
      variables: {
        driver: driver.pk,
        fleetVehicle: fleetVehiclePk,
      },
    })

  const {
    currentPageOffset,
    hasNextPage,
    hasPreviousPage,
    nextHandler,
    prevHandler,
    showPagination,
  } = useUrlPagination({
    resultsPerPage: VEHICLES_RESULTS_PER_PAGE,
    totalCount: data?.fleetsVehicles?.totalCount,
    onPaginationChange: ({ offset }) =>
      refetch({
        first: VEHICLES_RESULTS_PER_PAGE,
        offset,
      }),
  })

  return (
    <div className="col-span-2 border-[2.5px] border-secondary rounded-2xl border-separate border-spacing-0 bg-base max-h-56">
      <div>
        <div className="flex flex-row border-b-[2.5px] border-b-secondary h-16 justify-between p-4 align-middle">
          <h3>{t('drivers.widgets.driver_vehicles.heading')}</h3>
          <Tooltip>
            <Tooltip.Trigger ariaLabel="assign an ev to the selected driver" delay={500}>
              <Button size="xs" onClick={handleOpenAssignVehicleModal} fullWidth>
                <PlusIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
                {t('drivers.widgets.assign_vehicle.button.assign_vehicle')}
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Body>{t('drivers.widgets.assign_vehicle.tooltip.body')}</Tooltip.Body>
          </Tooltip>
        </div>
        <div className="max-h-36 overflow-y-auto">
          <Table
            data-testid="vehicle-list"
            emptyTable={!loading && data?.fleetsVehicles?.totalCount === 0}
            emptyTableMessage={t('drivers.widgets.assign_vehicle.empty_table')}
            loading={loading}
            loadingMessage={t('drivers.widgets.assign_vehicle.table_loading')}
            error={!!error}
            errorMessage={error?.message}
            className="border-none"
          >
            <TableBody>
              {data?.fleetsVehicles.edges.map((fleetVehicleEdge) => (
                <TableRow key={fleetVehicleEdge.node.id} className="px-3 mx-1">
                  <TableCell className={tw(styles.cellText)}>
                    {fleetVehicleEdge.node.vehicle.vehicleMake}
                  </TableCell>
                  <TableCell
                    className={tw(styles.cellText, 'text-overflow-ellipsis lg:p-2 max-h-4')}
                  >
                    {fleetVehicleEdge.node.name}
                  </TableCell>
                  <TableCell
                    className={tw(styles.cellText, 'text-overflow-ellipsis lg:px-2 max-h-4')}
                  >
                    {fleetVehicleEdge.node.regNumber || '-'}
                  </TableCell>
                  <TableCell>
                    <Menu>
                      <Menu.Button
                        noPadding
                        chevron={false}
                        data-testid="remove-vehicle-menu-button"
                      >
                        <EllipsisHorizontalIcon className="w-6 h-6" />
                      </Menu.Button>
                      <Menu.MenuItemList variant="dark">
                        <Menu.MenuItem
                          disabled={removeDriverVehicleLoading}
                          onSelect={() => handleRemoveDriverVehicle(fleetVehicleEdge.node.pk)}
                        >
                          {removeDriverVehicleLoading
                            ? t('drivers.widgets.assign_vehicle.button.loading.remove_vehicle')
                            : t('drivers.widgets.assign_vehicle.button.remove_vehicle')}
                        </Menu.MenuItem>
                      </Menu.MenuItemList>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      {showPagination && (
        <div className="-mt-4">
          <Pagination
            nextDisabled={!hasNextPage}
            previousDisabled={!hasPreviousPage}
            onClickNext={nextHandler}
            onClickPrevious={prevHandler}
            currentStartRange={currentPageOffset + 1}
            currentEndRange={
              hasNextPage
                ? currentPageOffset + VEHICLES_RESULTS_PER_PAGE
                : data?.fleetsVehicles?.totalCount
            }
            totalResults={data?.fleetsVehicles?.totalCount}
            previousButtonText={t('common.button.previous.label')}
            nextButtonText={t('common.button.next.label')}
            previousAriaLabel={t('common.button.previous.aria_label')}
            nextAriaLabel={t('common.button.next.aria_label')}
            ofText={t('common.of')}
            toText={t('common.to')}
          />
        </div>
      )}
    </div>
  )
}
