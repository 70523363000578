import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import getMessageFromError from '@electro/fleets/src/utils/getMessageFromError'

export const useErrorMessage = (error: ApolloError) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { locale } = useRouter()

  useEffect(() => {
    const updateErrorMessage = async () => {
      const currentErrorMessage = await getMessageFromError(error, locale)

      setErrorMessage(currentErrorMessage)
    }

    updateErrorMessage()
  }, [error, locale])

  return errorMessage
}
