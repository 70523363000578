/**
 * @param driversCount
 * We want to allow businesses to ordering a card for each driver in their fleet
 * The maximum number is determined by the number of drivers who can order cards
 * and the number of drivers who are already assigned cards.
 */
interface GetMaxNumberOfCardsArgs {
  driversCount: number
  pendingCards: number
  activeCards: number
  unassignedCards: number
}
export const getMaxNumberOfCards = ({
  driversCount,
  pendingCards,
  activeCards,
  unassignedCards,
}: GetMaxNumberOfCardsArgs) => {
  const totalCards = pendingCards + activeCards + unassignedCards
  const driversWhoCanOrderCards = driversCount - totalCards

  const extraAllowedCards = getExtraAllowedCards(driversWhoCanOrderCards)

  const maxNumberOfCards = extraAllowedCards + driversWhoCanOrderCards

  return maxNumberOfCards < 2 ? 2 : maxNumberOfCards
}

const getExtraAllowedCards = (driversWhoCanOrderCards: number) => {
  if (driversWhoCanOrderCards <= 15) {
    return 2
  }
  if (driversWhoCanOrderCards <= 30) {
    return 4
  }
  if (driversWhoCanOrderCards <= 50) {
    return 6
  }
  if (driversWhoCanOrderCards <= 100) {
    return 10
  }
  if (driversWhoCanOrderCards <= 150) {
    return 15
  }
  if (driversWhoCanOrderCards > 150) {
    return 20
  }

  return 0
}
