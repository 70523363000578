import { FleetChargeRecordTypeEdge } from '@electro/fleets/generated/graphql'
import { Button, TableCell, TableRow, useToastNotification } from '@electro/shared-ui-components'
import { format } from 'date-fns'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { fetchChargeRecord } from '@electro/fleets/src/services/rest'
import { DownloadFile } from 'libs/shared-ui-components/src/lib/DownloadFile'
import { DemoModeWrapper } from '@electro/fleets/src/components/DemoModeWrapper'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  chargeRecordEdge: FleetChargeRecordTypeEdge
}

export const ChargeRecordTableRow = ({ chargeRecordEdge }: Props) => {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const date = new Date(chargeRecordEdge.node.startDate)
  const year = format(date, 'yyyy')
  const month = format(date, 'MMMM')

  return (
    <TableRow>
      <TableCell>{month}</TableCell>
      <TableCell>{year}</TableCell>
      <TableCell className="w-1/5">
        <DemoModeWrapper show tooltip={t('demo.tooltip.no_csv_download')}>
          {({ isDemoMode }) => (
            <DownloadFile
              fetcher={() =>
                fetchChargeRecord({ downloadLink: chargeRecordEdge.node.downloadLink })
              }
              filename={`receipt-${month}-${year}`}
              onError={() => {
                showToastNotification({
                  variant: 'error',
                  heading: 'Oops',
                  body: 'Something went wrong, unable to download charge record!',
                })
              }}
              onSuccess={() => {
                showToastNotification({
                  variant: 'success',
                  heading: 'Charge record downloaded',
                  body: 'Charge record downloaded successfully!',
                  timeout: 5000,
                })
              }}
            >
              {({ fetchFileThenDownload }) => (
                <>
                  <DownloadFile.Idle>
                    <Button
                      disabled={isDemoMode}
                      size="xs"
                      variant="naked"
                      onClick={fetchFileThenDownload}
                    >
                      <ArrowDownTrayIcon className="w-6 h-6" />
                    </Button>
                  </DownloadFile.Idle>
                  <DownloadFile.Downloading>
                    <div className="flex items-center relative px-2" data-testid="spinner-loading">
                      <div className="w-6 h-6 rounded-full absolute border-2 border-solid border-primary border-opacity-30" />
                      <div className="w-6 h-6 rounded-full animate-spin absolute border-2 border-solid  border-primary border-t-transparent" />
                    </div>
                  </DownloadFile.Downloading>
                </>
              )}
            </DownloadFile>
          )}
        </DemoModeWrapper>
      </TableCell>
    </TableRow>
  )
}
