import { DriverDetails, DriverIndividualReceipts } from '@electro/fleets/src/components'
import { DriverConsumptionPageSearchQueryParams } from 'apps/fleets/pages/dashboard/reporting/driver/[driverPk]'

type DriverReportProps = DriverConsumptionPageSearchQueryParams

export const DriverReport = ({
  startDate,
  endDate,
  driverPk,
  orderBy,
  orderDirection,
  showAllReceipts,
}: DriverReportProps) => (
  <div className="lg:p-10 p-3 grid grid-cols-2 gap-10" data-testid="driver-receipts-report">
    <div className="col-span-2">
      <DriverDetails driverPk={driverPk} />
    </div>
    <div className="col-span-2">
      <DriverIndividualReceipts
        {...{
          driverPk,
          startDate,
          endDate,
          orderBy,
          orderDirection,
          showAllReceipts,
        }}
      />
    </div>
  </div>
)
