import { useRouter } from 'next/router'

import { useCallback, useMemo } from 'react'

const PAGINATION_PAGE_NUMBER_PARAM = 'page'

export interface UseUrlPaginationArgs {
  resultsPerPage?: number
  onPaginationChange: ({ query, offset }) => void
  totalCount?: number
}

/**
 * Custom hook that assumes use of URL paramaters for pagination.
 *
 * Provides the callback onPaginationChange() that can be used to run queries with the pagination
 * params that have been set to the URL.
 */

/**
 * The function below has been exported independently because it's used in DriverListV2.
 * In that component we're using the router directly to gather the required data.
 * The arguments this function requires means we need access to it outside the hook.
 */
export const getOffsetFromPageNumberParam = ({
  page = '1',
  resultsPerPageCount,
}: {
  page: string
  resultsPerPageCount: number
}) => {
  const pageNumber = parseInt(page, 10)
  const offset = pageNumber * resultsPerPageCount - resultsPerPageCount
  return offset
}

export const useUrlPagination = ({
  resultsPerPage = 20,
  onPaginationChange,
  totalCount,
}: UseUrlPaginationArgs) => {
  const router = useRouter()

  const searchQuery = router.query

  const currentPageOffset = useMemo(
    () =>
      getOffsetFromPageNumberParam({
        resultsPerPageCount: resultsPerPage,
        page: searchQuery?.[PAGINATION_PAGE_NUMBER_PARAM] as string,
      }),
    [resultsPerPage, searchQuery],
  )

  const currentPageNumber = useMemo(
    () => parseInt(searchQuery?.[PAGINATION_PAGE_NUMBER_PARAM] as string, 10) || 1,
    [searchQuery],
  )

  const hasNextPage = useMemo(() => {
    const lastResultIndex = currentPageOffset + resultsPerPage
    return lastResultIndex <= totalCount
  }, [currentPageOffset, resultsPerPage, totalCount])

  const hasPreviousPage = useMemo(() => currentPageNumber > 1, [currentPageNumber])

  const showPagination = useMemo(() => totalCount > resultsPerPage, [resultsPerPage, totalCount])

  const getNextOffset = useCallback(
    (pageNumber: number) =>
      getOffsetFromPageNumberParam({
        resultsPerPageCount: resultsPerPage,
        [PAGINATION_PAGE_NUMBER_PARAM]: `${pageNumber}`,
      }),
    [resultsPerPage],
  )

  function nextHandler() {
    const pageIncrement = currentPageNumber + 1
    router.push({
      query: {
        ...searchQuery,
        [PAGINATION_PAGE_NUMBER_PARAM]: pageIncrement,
      },
    })
    const nextOffset = getNextOffset(pageIncrement)
    onPaginationChange({ query: searchQuery, offset: nextOffset })
  }

  function prevHandler() {
    const pageDecrement = currentPageNumber - 1
    router.push({
      query: {
        ...searchQuery,
        [PAGINATION_PAGE_NUMBER_PARAM]: pageDecrement,
      },
    })
    const nextOffset = getNextOffset(pageDecrement)
    onPaginationChange({ query: searchQuery, offset: nextOffset })
  }

  return {
    showPagination,
    currentPageOffset,
    currentPageNumber,
    hasNextPage,
    hasPreviousPage,
    nextHandler,
    prevHandler,
  }
}
