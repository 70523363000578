import { Toast } from '@electro/shared-ui-components'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

export const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['electro-fleets-cookie-consent'])
  const [show, setShow] = useState(false)
  const setCookieWithExpiryInOneYear = () => {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)
    setCookie('electro-fleets-cookie-consent', true, {
      expires,
    })
  }

  useEffect(() => setShow(!cookies['electro-fleets-cookie-consent']), [cookies])

  return show ? (
    <Toast
      variant="default"
      position="bottomLeft"
      className="[touch-action:none]" // Prevents gesture zooming on touch screens
      body={
        <div className="text-sm">
          We use cookies to provide a great experience. If you&apos;re happy with this, continue
          browsing.{' '}
          <a target="blank" href="https://www.allaboutcookies.org">
            Manage my cookies
          </a>{' '}
          <a target="blank" href="https://electroverse.octopus.energy/legal/privacy">
            Privacy policy
          </a>
        </div>
      }
      display
      onClose={setCookieWithExpiryInOneYear}
    />
  ) : null
}
