import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react'
import { useToastNotification } from '@electro/shared-ui-components'
import { useFleetsBulkCreateDrivers } from '@electro/fleets/src/services/useFleetsBulkCreateDrivers'
import { fetchDriversBulkUploadCsvTemplate } from '@electro/fleets/src/services/rest'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import { useRouter } from 'next/router'
import { useDownloadFile } from 'libs/shared-ui-components/src/lib/DownloadFile'
import useTranslation from 'next-translate/useTranslation'

interface UseBulkDriverUploadProps {
  children: ReactNode | ReactNode[]
}

export enum BulkUploadModalScreensEnum {
  INITIAL = 'INITIAL',
  UPLOAD = 'UPLOAD',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
}

type BulkUploadModalScreens = `${BulkUploadModalScreensEnum}`

interface State {
  modalOpen: boolean
  loading: boolean
  downloadTemplateError: boolean
  activeModalScreen: BulkUploadModalScreens
}

interface Handlers {
  handleDownloadCsvTemplate: () => void
  handleUploadDrivers: (file: File) => void
  toggleModal: () => void
  setActiveModalScreen: (screen: BulkUploadModalScreens) => void
}

type UseBulkDriverUpload = [state: State, handlers: Handlers]

const UseBulkDriverUploadContext = createContext<UseBulkDriverUpload>(null)

function useBulkDriverUploadProvider(): UseBulkDriverUpload {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [modalOpen, setModalOpen] = useState(false)
  const [activeModalScreen, setActiveModalScreen] = useState<BulkUploadModalScreens>(
    BulkUploadModalScreensEnum.INITIAL,
  )

  const { showToastNotification } = useToastNotification()

  const toggleModal = useCallback(() => {
    setModalOpen((isOpen) => !isOpen)
  }, [])

  const [
    { downloading: downloadingTemplate, error: downloadError },
    { fetchFileThenDownload: downloadCsvTemplate },
  ] = useDownloadFile({
    filename: 'bulk-upload-drivers-template',
    fetcher: fetchDriversBulkUploadCsvTemplate,
    onSuccess: () => {
      toggleModal()
      showToastNotification({
        heading: t('common.success'),
        body: t('drivers.bulk_upload_modal.toast.success_body'),
        variant: 'success',
        timeout: 5000,
      })
    },
  })

  const [uploadDriversCsv, { loading: uploadingDrivers }] = useFleetsBulkCreateDrivers()
  const handleDownloadCsvTemplate = useCallback(() => downloadCsvTemplate(), [downloadCsvTemplate])

  const handleUploadDrivers = useCallback(
    async (file: File) => {
      const response = await uploadDriversCsv({
        variables: { file },
        refetchQueries: [
          {
            query: FLEETS_DRIVERS_QUERY,
            variables: { first: 50, offset: 0 },
          },
        ],
        awaitRefetchQueries: true,
      })
      /**
       * We really only care about the success flag here, if it's true we can redirect to the drivers page.
       * We are also removing the pagination from the drivers page so we don't need to worry about the offset.
       */
      if (response.data.fleetsBulkCreateDrivers.success) {
        router.replace('/dashboard/drivers')
        setActiveModalScreen(BulkUploadModalScreensEnum.UPLOAD_COMPLETE)
      }
    },
    [router, uploadDriversCsv],
  )

  const loading = useMemo(
    () => downloadingTemplate || uploadingDrivers,
    [downloadingTemplate, uploadingDrivers],
  )

  const state = useMemo(
    () => ({
      modalOpen,
      loading,
      downloadTemplateError: downloadError,
      activeModalScreen,
    }),
    [activeModalScreen, downloadError, loading, modalOpen],
  )

  const handlers = useMemo(
    () => ({
      toggleModal,
      handleDownloadCsvTemplate,
      setActiveModalScreen,
      handleUploadDrivers,
    }),
    [handleDownloadCsvTemplate, handleUploadDrivers, toggleModal],
  )

  return [state, handlers]
}

export const BulkDriverUploadProvider = ({ children }: UseBulkDriverUploadProps) => {
  const ctx = useBulkDriverUploadProvider()
  return (
    <UseBulkDriverUploadContext.Provider value={ctx}>
      {children}
    </UseBulkDriverUploadContext.Provider>
  )
}

export const useBulkDriverUpload = (): UseBulkDriverUpload => {
  const context = useContext(UseBulkDriverUploadContext)
  if (!context)
    throw new Error(
      'useBulkDriverUpload() cannot be used outside of <UseBulkDriverUploadProvider/>',
    )
  return context
}
