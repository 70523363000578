import {
  EstimatedCostType,
  FleetSubscriptionTypeEdge,
  SubscriptionCostType,
} from '@electro/fleets/generated/graphql'
import { TableCell, TableRow } from '@electro/shared-ui-components'
import { v1 as uuid } from 'uuid'
import { UserIcon } from '@heroicons/react/24/outline'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import useTranslation from 'next-translate/useTranslation'

interface CurrentSubscriptionsProps {
  edge: FleetSubscriptionTypeEdge
}

export const CurrentSubscriptionsRow = ({ edge }: CurrentSubscriptionsProps) => {
  const { t } = useTranslation('common')

  const getPlanTitle = (estimatedCost: EstimatedCostType) => {
    if (estimatedCost?.type === SubscriptionCostType.FullPrice) {
      return t('payment_and_invoices.current_subscriptions.row.paid_drivers')
    }
    if (estimatedCost?.type === SubscriptionCostType.TrialPrice) {
      return t('payment_and_invoices.current_subscriptions.row.trial_drivers')
    }

    return t('payment_and_invoices.current_subscriptions.row.free_drivers')
  }

  return edge.node.estimatedCost.map((estimatedCost) => {
    const planTitle = getPlanTitle(estimatedCost)

    return (
      <TableRow data-testid={`subscription-row-${estimatedCost.type}`} key={uuid()}>
        <TableCell>
          <div className="flex items-center">
            <UserIcon className="h-6 w-6 text-secondary mr-4" /> {planTitle}
          </div>
        </TableCell>
        <TableCell>{estimatedCost.count}</TableCell>
        <TableCell>
          {formatPriceToLocalisedValue({
            price: Number(estimatedCost.price),
            isFloat: true,
          })}
        </TableCell>
      </TableRow>
    )
  })
}
