import { RadioGroup } from '@headlessui/react'
import { Button, Alert } from '@electro/shared-ui-components'
import * as Yup from 'yup'
import { RadioCard } from '@electro/fleets/src/components/RadioCard'
import {
  BusinessType,
  SignUpFormFieldsEnum,
  SignUpStep,
  useSignUp,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import { HavingProblems } from '@electro/fleets/src/components/BusinessOnboardingForm/components'
import { BriefcaseIcon, UserIcon, HeartIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Formik } from 'formik'

const styles = {
  icon: 'w-8 h-8 text-white',
}

const RADIOS = [
  {
    label: 'Limited Company',
    icon: <BriefcaseIcon className={styles.icon} />,
    value: BusinessType.LIMITED_COMPANY,
    tooltip: 'Registered with Companies House',
  },
  {
    label: 'Sole Trader',
    icon: <UserIcon className={styles.icon} />,
    value: BusinessType.SOLE_TRADER,
    tooltip: 'You run your own business as an individual or work for yourself',
  },
  {
    label: 'Charity',
    icon: <HeartIcon className={styles.icon} />,
    value: BusinessType.CHARITY,
    tooltip: 'Registered charitable organisation',
  },
  {
    label: 'Partnership',
    icon: <UsersIcon className={styles.icon} />,
    value: BusinessType.PARTNERSHIP,
    tooltip: 'Registered with Companies House',
  },
]

const { BUSINESS_CLASSIFICATION } = SignUpFormFieldsEnum

const validationSchema = Yup.object().shape({
  [BUSINESS_CLASSIFICATION]: Yup.string().required('Please select your business type'),
})

export const SelectBusinessClassification = () => {
  const [{ formData }, { handleScreenChange, handleSignUpDataChange }] = useSignUp()

  const handleFormSubmit = (values, actions) => {
    handleScreenChange(SignUpStep.BUSINESS_DETAILS_FORM)()
    handleSignUpDataChange(values)
    actions.setSubmitting(false)
  }

  const initialFormValuesDerivedFromState = {
    [BUSINESS_CLASSIFICATION]: formData?.[BUSINESS_CLASSIFICATION] || '',
  }

  return (
    <Formik
      initialValues={initialFormValuesDerivedFromState}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange
    >
      {({ values, handleSubmit, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit}>
          <RadioGroup
            name={BUSINESS_CLASSIFICATION}
            value={values[BUSINESS_CLASSIFICATION]}
            onChange={(value) => setFieldValue(BUSINESS_CLASSIFICATION, value)}
          >
            <RadioGroup.Label className="sr-only">Select your business type</RadioGroup.Label>

            {RADIOS.map(({ value, icon, label, tooltip }) => (
              <RadioCard
                key={value}
                label={label}
                icon={icon}
                value={value}
                tooltip={tooltip}
                error={!!errors?.[BUSINESS_CLASSIFICATION]}
              />
            ))}
          </RadioGroup>
          {errors?.[BUSINESS_CLASSIFICATION] ? (
            <Alert variant="error">{errors[BUSINESS_CLASSIFICATION] as string}</Alert>
          ) : null}

          <Button fullWidth className="text-center mt-10" type="submit">
            Continue
          </Button>
          <HavingProblems />
        </form>
      )}
    </Formik>
  )
}
