import FLEETS_REFRESH_TOKEN_MUTATION from '@electro/fleets/graphql/refreshToken.graphql'

import { Mutation, MutationFleetsAdminLogInArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

export type FleetsRefreshTokenMutation = Pick<Mutation, 'refreshToken'>

type UseFleetsAdminLogIn = [
  ApolloMutationFunction<FleetsRefreshTokenMutation>,
  MutationResult<FleetsRefreshTokenMutation>,
]

export function useFleetsFetchRefreshToken(): UseFleetsAdminLogIn {
  const [FleetsRefreshTokenMutation, FleetsAdminLogInResult] = useMutation<
    FleetsRefreshTokenMutation,
    MutationFleetsAdminLogInArgs
  >(FLEETS_REFRESH_TOKEN_MUTATION)

  return [FleetsRefreshTokenMutation, FleetsAdminLogInResult]
}
