import { ChangeEvent, useMemo, useState, useCallback } from 'react'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'

import {
  DriverTokenTypeOrderingFields,
  EjnEjnTokenStatusChoices,
  FleetsDriversTokensQuery,
  useFleetsDriversTokensLazyQuery,
} from '@electro/fleets/generated/graphql'
import {
  Modal,
  Autocomplete,
  Button,
  OrderingDirectionEnum,
  useToastNotification,
} from '@electro/shared-ui-components'
import { useFormik } from 'formik'
import Image from 'next/image'
import { useMount, useDebounce } from 'react-use'
import * as Yup from 'yup'
import * as Sentry from '@sentry/nextjs'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { useDriversQueryParams, useErrorMessage } from '@electro/fleets/src/hooks/'

interface FormFields {
  hexCode: string
}

interface electrocardOptions {
  label: string
  pk: number
}

const styles = {
  header: 'mb-4',
  image: 'm-auto my-4 mt-10',
}

const DEFAULT_ORDERING = [
  {
    orderBy: DriverTokenTypeOrderingFields.Number,
    direction: OrderingDirectionEnum.Asc,
  },
]

const validationSchema = Yup.object().shape({
  hexCode: Yup.string()
    .required('drivers.widgets.assign_electrocard.validation.electrocard')
    .nullable(),
})

interface Props {
  show: boolean
}

export const AssignElectrocardModal = ({ show }: Props) => {
  const { t } = useTranslation('common')
  const [getElectrocards, { data, loading, error }] = useFleetsDriversTokensLazyQuery()
  const { driver, closeModalScreen, assignCard, assignCardLoading, assignCardError } =
    useDriverWidgets()
  const [query, setQuery] = useState('')
  const { queryParams } = useDriversQueryParams()
  const { showToastNotification } = useToastNotification()
  const errorAssignCardMessage = useErrorMessage(assignCardError)
  const errorGetElectroCardsMessage = useErrorMessage(error)
  const errorMessage = errorAssignCardMessage || errorGetElectroCardsMessage

  const returnFormattedAndSortedElectroCards = useCallback(
    (electroCards: FleetsDriversTokensQuery): electrocardOptions[] =>
      (electroCards?.fleetsDriversTokens?.edges || []).map((edge) => ({
        label: edge.node.number || t('drivers.widgets.assign_electrocard.list.number_not_assigned'),
        pk: edge.node.pk,
      })),
    [t],
  )

  const formik = useFormik({
    initialValues: {
      hexCode: '',
    },
    validationSchema,
    onSubmit: ({ hexCode }: FormFields) =>
      assignCard({
        variables: {
          hexCode,
          driver: driver.pk,
        },
        refetchQueries: [
          {
            query: FLEETS_DRIVERS_QUERY,
            variables: queryParams,
          },
        ],
        onError: (err) => {
          Sentry.captureException(err)
        },
        onCompleted: () =>
          showToastNotification({
            variant: 'success',
            heading: t('common.success'),
            body: t('drivers.widgets.assign_electrocard.toast.body'),
            timeout: 3000,
          }),
      }),
  })

  useMount(() =>
    getElectrocards({
      variables: {
        first: 50,
        offset: 0,
        status: EjnEjnTokenStatusChoices.Unassigned,
        ordering: DEFAULT_ORDERING,
      },
    }),
  )

  const sortedAndFormattedElectrocardsArray = useMemo(
    () => returnFormattedAndSortedElectroCards(data),
    [data, returnFormattedAndSortedElectroCards],
  )

  const handleChange = (cardNumber: Record<string, string>) => {
    formik.setFieldValue('hexCode', cardNumber.label)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)

  useDebounce(
    () => {
      getElectrocards({
        variables: {
          first: 50,
          offset: 0,
          status: EjnEjnTokenStatusChoices.Unassigned,
          ordering: DEFAULT_ORDERING,
          search: query,
        },
      })
    },
    200,
    [query],
  )

  const handleClose = () => setQuery('')

  return show ? (
    <>
      <Modal.Header>
        {t('drivers.widgets.assign_electrocard.heading.assign_an_electrocard')}
      </Modal.Header>
      <Modal.Body data-testid="assign-card-modal" className="overflow-visible">
        <h4 className={styles.header}>
          {t('drivers.widgets.assign_electrocard.body.instructions')}
        </h4>
        <form id="assign-card-form" onSubmit={formik.handleSubmit}>
          <Autocomplete
            fullWidth
            placeholder={t('drivers.widgets.assign_electrocard.placeholder')}
            loading={loading}
            errorMessage={t(formik.errors.hexCode) || errorMessage}
            options={sortedAndFormattedElectrocardsArray}
            noOptionsText={
              query ? (
                <Trans
                  i18nKey="common:drivers.widgets.assign_electrocard.autocomplete.no_card_found"
                  components={{ searchQuery: <span>{query}</span> }}
                />
              ) : (
                t('drivers.widgets.assign_electrocard.autocomplete.no_suggestions_found')
              )
            }
            onChange={handleChange}
            onInputChange={handleInputChange}
            onClose={handleClose}
          />
        </form>
        <div>
          <Image
            className={styles.image}
            src="/images/card-number-location-no-arrow-animated.svg"
            alt="Illustration of an Electrocards number location"
            width={235}
            height={286}
          />
        </div>
      </Modal.Body>
      <Modal.Actions stacked>
        <Button fullWidth disabled={assignCardLoading} form="assign-card-form" type="submit">
          {assignCardLoading
            ? t('drivers.widgets.button.loading.assigning')
            : t('drivers.widgets.driver_details.driver_actions.assign_card')}
        </Button>
        <Button fullWidth disabled={assignCardLoading} variant="outline" onClick={closeModalScreen}>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  ) : null
}
