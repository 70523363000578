import { FleetsTagType } from '@electro/fleets/generated/graphql'

export type keysOfDriverFormFields = keyof DriverFormFields
export type keysOfEditDriverFormFields = keyof EditDriverFormFields

export enum DriverFormFieldNames {
  PREFERRED_NAME_ON_CARD = 'preferredNameOnCard',
  GIVEN_NAME = 'givenName',
  FAMILY_NAME = 'familyName',
  EMAIL = 'email',
  MOBILE = 'mobile',
  ADDRESS_LINE1 = 'addressLine1',
  ADDRESS_LINE2 = 'addressLine2',
  ADDRESS_LINE3 = 'addressLine3',
  ADDRESS_LINE4 = 'addressLine4',
  POSTCODE = 'postcode',
  COUNTRY = 'country',
  TAGS = 'tags',
}

export interface DriverFormFields {
  [DriverFormFieldNames.GIVEN_NAME]: string
  [DriverFormFieldNames.FAMILY_NAME]: string
  [DriverFormFieldNames.EMAIL]: string
  [DriverFormFieldNames.MOBILE]: string
  [DriverFormFieldNames.TAGS]?: FleetsTagType[]
}

export interface EditDriverFormFields {
  [DriverFormFieldNames.GIVEN_NAME]: string
  [DriverFormFieldNames.FAMILY_NAME]: string
  [DriverFormFieldNames.EMAIL]: string
  [DriverFormFieldNames.MOBILE]: string
  [DriverFormFieldNames.TAGS]?: FleetsTagType[]
}

export type CreateNewDriverFormFields = DriverFormFields
