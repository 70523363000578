import { Typography } from '@electro/server-components'
import { SwitchToggle } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { useDriversQueryParams } from '@electro/fleets/src/hooks'

export const FilterToolbar = ({ getDrivers }) => {
  const { t } = useTranslation('common')
  const { queryParams, syncQueryParams } = useDriversQueryParams()

  const handleToggleActiveDrivers = () => {
    const { first, offset, ordering, search } = queryParams
    const nextQueryParamIsActive =
      queryParams.additionalParams.isActive === 'true' ? 'null' : 'true'
    syncQueryParams({ additionalParams: { isActive: nextQueryParamIsActive } })

    const nextIsActive = queryParams.additionalParams.isActive === 'true' ? null : true
    getDrivers({
      variables: {
        first,
        offset,
        ordering,
        search,
        isActive: nextIsActive,
      },
    })
  }

  return (
    <>
      <Typography variant="small">{t('drivers.filter_toolbar.active_only')}</Typography>
      <SwitchToggle
        checked={queryParams.additionalParams.isActive === 'true'}
        onChange={handleToggleActiveDrivers}
      />
    </>
  )
}
