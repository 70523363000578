import { Card, Typography } from '@electro/server-components'
import { CurrencyType } from '@electro/fleets/generated/graphql'
import { Locales } from '@electro/types/locales'

interface ReferralStatWidgetProps {
  widgetHeading: string
  referralSummaryData: string | number
  currencyData?: CurrencyType
  show: boolean
  isCurrency?: boolean
}

/* As this component is dealing with currency and non-currency data, 
it is currently rounding everything. This shouldn't be an issue as we're expecting 
non-currency data to be a whole number, 
whereas currency data WILL be a decimal.  */
export const ReferralStatWidget = ({
  widgetHeading,
  referralSummaryData,
  show,
  currencyData,
  isCurrency = false,
}: ReferralStatWidgetProps) => {
  const data =
    typeof referralSummaryData === 'string' ? parseFloat(referralSummaryData) : referralSummaryData

  return show ? (
    <Card density="high" className="min-h-22 lg:min-h-28">
      <Typography variant="h3">{widgetHeading}</Typography>
      {data > 0 ? (
        <Typography variant="h4" className="text-4xl" data-testid="widgetData">
          {isCurrency
            ? new Intl.NumberFormat(Locales.EN, {
                style: 'currency',
                currency: currencyData.isoCode,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                currencyDisplay: 'narrowSymbol',
              }).format(Number(referralSummaryData))
            : referralSummaryData}
        </Typography>
      ) : null}
    </Card>
  ) : null
}
