import { Button, Modal } from '@electro/shared-ui-components'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'
import { useDriversQueryParams } from '@electro/fleets/src/hooks'
import useTranslation from 'next-translate/useTranslation'

export const DeactivateCardModalScreen = () => {
  const { t } = useTranslation('common')
  const { queryParams } = useDriversQueryParams()
  const { driver, closeModalScreen, deactivateCard, deactivateCardLoading, deactivateCardError } =
    useDriverWidgets()

  const handleDeactivate = () => {
    if (!driver) return

    deactivateCard({
      variables: {
        card: driver.ejnCard?.pk,
        driver: driver.pk,
      },
      refetchQueries: [
        {
          query: FLEETS_DRIVERS_QUERY,
          variables: queryParams,
        },
      ],
    })
  }

  return (
    <>
      <Modal.Header>{t('drivers.widgets.driver_details.deactivate_card.heading')}</Modal.Header>
      <Modal.Body data-testid="Deactivate-card-modal">
        <p className="text-left">
          {t('drivers.widgets.driver_details.deactivate_card.body.cancel_card')}
        </p>
        {!!deactivateCardError && (
          <div className="text-action-danger mt-2">{deactivateCardError.message}</div>
        )}
      </Modal.Body>
      <Modal.Actions stacked>
        <Button
          fullWidth
          onClick={handleDeactivate}
          disabled={deactivateCardLoading}
          form="assign-card-form"
          variant="warning"
        >
          {deactivateCardLoading
            ? t('admin.deactivate_admin_user_modal.button.loading.deactivating')
            : t('drivers.widgets.driver_details.driver_actions.deactivate_card')}
        </Button>
        <Button
          fullWidth
          disabled={deactivateCardLoading}
          variant="outline"
          onClick={closeModalScreen}
        >
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
