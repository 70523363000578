import FLEETS_ADMIN_LOG_IN_MUTATION from '@electro/fleets/graphql/fleetsAdminLogInMutation.graphql'

import { Mutation, MutationFleetsAdminLogInArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

export type FleetsAdminLogInMutation = Pick<Mutation, 'fleetsAdminLogIn'>

type UseFleetsAdminLogIn = [
  ApolloMutationFunction<FleetsAdminLogInMutation>,
  MutationResult<FleetsAdminLogInMutation>,
]

export function useFetchFleetsAdminLoginToken(): UseFleetsAdminLogIn {
  const [FleetsAdminLogInMutation, FleetsAdminLogInResult] = useMutation<
    FleetsAdminLogInMutation,
    MutationFleetsAdminLogInArgs
  >(FLEETS_ADMIN_LOG_IN_MUTATION)

  return [FleetsAdminLogInMutation, FleetsAdminLogInResult]
}
