import { Card, Typography } from '@electro/server-components'
import { FleetsCurrentBalanceType } from '@electro/fleets/generated/graphql'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'

interface CreditBalanceNoDataProps {
  currentBalanceData: FleetsCurrentBalanceType
  show: boolean
}

export const CreditBalanceNoData = ({ currentBalanceData, show }: CreditBalanceNoDataProps) =>
  show ? (
    <Card className="h-12 flex items-center">
      <div className="w-full flex justify-between">
        <Typography variant="h6">Credit balance</Typography>
        <Typography>
          {formatPriceToLocalisedValue({
            price: currentBalanceData?.amount,
            currency: currentBalanceData?.currency,
            isFloat: true,
          })}
        </Typography>
      </div>
    </Card>
  ) : null
