export interface AdminBusinessBillingFormFields {
  address1: string
  address2: string
  city: string
  country: string
  email: string
  name: string
  postcode: string
}

export enum AdminBusinessBillingFormFieldsEnum {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  COUNTRY = 'country',
  EMAIL = 'email',
  NAME = 'name',
  POSTCODE = 'postcode',
}
