import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client'
import FLEETS_ADMINS_QUERY from '@electro/fleets/graphql/fleetsAdminUsersQuery.graphql'

import { Query, QueryFleetsAdminsArgs } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsAdminUsers = Pick<Query, 'fleetsAdmins'> & Pick<Query, 'fleetsCurrentAdmin'>

type UseFleetsFetchAdmins = [
  ApolloLazyQueryFunction<FetchFleetsAdminUsers>,
  LazyQueryResult<FetchFleetsAdminUsers, OperationVariables>,
]

type FetchFleetsAdminUsersArgs = LazyQueryHookOptions<FetchFleetsAdminUsers, QueryFleetsAdminsArgs>

export function useFleetsFetchAdminUsers(
  options?: FetchFleetsAdminUsersArgs,
): UseFleetsFetchAdmins {
  const [mutation, result] = useLazyQuery<FetchFleetsAdminUsers>(FLEETS_ADMINS_QUERY, options)

  return [mutation, result]
}
