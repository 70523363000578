import { NavSection } from '@electro/fleets/src/components/SideBarNavigation/SideBarNavigation.types'
import { SideBarNavigationItem } from '@electro/fleets/src/components/SideBarNavigation/components'
import { useUiStore } from '@electro/fleets/src/hooks/stores'
import { Typography } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Transition } from '@headlessui/react'

interface Props {
  navSection: NavSection
}

const styles = {
  section: {
    root: 'mb-6 !block',
    title: 'uppercase text-white py-3',
    item: {
      root: 'lg:py-3 py-1',
      disabled: 'hover:cursor-not-allowed opacity-50',
      link: {
        root: 'font-normal no-underline focus:text-primary focus:ring-opacity-0 whitespace-nowrap group/menuItem flex items-center justify-between',
        inactive: 'text-white hover:text-primary',
        active: 'text-primary',
        disabled: 'pointer-events-none opacity-40',
      },
      expanded: {
        root: 'px-10',
        menuItemContainer:
          'flex flex-row items-center group-hover/menuItem:text-primary w-96 max-w-72',
        text: 'text-[1rem] pl-2 whitespace-normal text-center leading-normal  group-hover/menuItem:text-primary flex items-center',
      },
      contracted: {
        root: 'px-0',
        menuItemContainer:
          'flex flex-col items-center group-hover/menuItem:text-primary w-[calc(theme(spacing.24)-theme(spacing.2))]',
        text: 'text-[10px] whitespace-normal text-center leading-normal group-hover/menuItem:text-primary flex flex-col items-center',
      },
    },
  },
  hide: 'opacity-0',
}

export const SideBarNavigationSection = ({ navSection }: Props) => {
  const expanded = useUiStore((state) => state.navigationExpanded)

  return (
    <Transition
      className={tw({
        [styles.section.root]: true,
        [styles.section.item.expanded.root]: expanded,
        [styles.section.item.contracted.root]: !expanded,
      })}
      unmount={false}
      as="li"
      enter="transition-all duration-300"
      enterFrom="px-0"
      enterTo="opacity-100 px-10"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 px-10"
      leaveTo="px-0"
      show={expanded}
    >
      <Typography
        variant="h3"
        className={tw({
          [styles.section.title]: true,
          [styles.hide]: !expanded,
        })}
      >
        {navSection.title}
      </Typography>

      <ul>
        {navSection.navItems.map((navItem) => (
          <SideBarNavigationItem key={navItem.label} navItem={navItem} />
        ))}
      </ul>
    </Transition>
  )
}
