import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import FLEETS_EDIT_TAG from '@electro/fleets/graphql/fleetsEditTagMutation.graphql'
import { Mutation, MutationFleetsEditTagArgs } from '@electro/fleets/generated/graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsEditTagMutation = Pick<Mutation, 'fleetsEditTag'>
type FleetsEditTagReturnType = [
  ApolloMutationFunction<FleetsEditTagMutation>,
  MutationResult<FleetsEditTagMutation>,
]
type Options = MutationHookOptions<FleetsEditTagMutation, MutationFleetsEditTagArgs>

export const useFleetsEditTag = (options?: Options): FleetsEditTagReturnType => {
  const [editTagMutation, editTagResults] = useMutation(FLEETS_EDIT_TAG, options)

  return [editTagMutation, editTagResults]
}
