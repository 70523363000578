import {
  CreateNewDriverFormFields,
  EditDriverFormFields,
} from '@electro/fleets/src/types/DriverForm'
import {
  MutationFleetsCreateDriverArgs,
  MutationFleetsEditDriverArgs,
} from '@electro/fleets/generated/graphql'

export const convertDriverFormToMutationArgs = (
  formFields: Partial<EditDriverFormFields | CreateNewDriverFormFields>,
): Partial<MutationFleetsCreateDriverArgs | MutationFleetsEditDriverArgs> => {
  if ('tags' in formFields) {
    const tagsPks = formFields.tags.map((tag) => tag.pk)
    const newFormFields = { ...formFields, tags: tagsPks }

    return newFormFields
  }
  return formFields as Omit<EditDriverFormFields | CreateNewDriverFormFields, 'tags'>
}
