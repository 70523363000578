import { GraphQLError } from 'graphql'
import { create } from 'zustand'

interface State {
  /** Shows if the user is authenticated or not */
  isAuthenticated: boolean
  /** Shows if user authentication is loading */
  isAuthenticatedLoading: boolean
  /** Shows if is user authentication is verified with request on the backend */
  hasAuthenticationCheckBeenMade: boolean
  /** Shows the error if user authentication had failed */
  isAuthenticatedError: GraphQLError
}

interface Action {
  setIsAuthenticated: (isAuthenticated: boolean) => void
  setIsAuthenticatedLoading: (isAuthenticatedLoading: boolean) => void
  setHasAuthenticationCheckBeenMade: (hasAuthenticationCheckBeenMade: boolean) => void
  setIsAuthenticatedError: (isAuthenticatedError: GraphQLError) => void

  resetAuth: () => void
}

const initialState: State = {
  isAuthenticated: false,
  isAuthenticatedLoading: false,
  hasAuthenticationCheckBeenMade: false,
  isAuthenticatedError: null,
}

export const useAuthStore = create<State & Action>((set) => ({
  ...initialState,
  setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
  setIsAuthenticatedLoading: (isAuthenticatedLoading: boolean) => set({ isAuthenticatedLoading }),
  setHasAuthenticationCheckBeenMade: (hasAuthenticationCheckBeenMade: boolean) =>
    set({ hasAuthenticationCheckBeenMade }),
  setIsAuthenticatedError: (isAuthenticatedError: GraphQLError) => set({ isAuthenticatedError }),
  resetAuth: () => set(initialState),
}))
