import { ReactNode } from 'react'

import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import { Alert, Button, Skeleton } from '@electro/shared-ui-components'
import { PaymentMethod } from '@stripe/stripe-js'
import { StripeProvider } from '@electro/fleets/src/hooks'
import {
  PaymentMethodProvider,
  usePaymentMethod,
} from '@electro/fleets/src/components/PaymentMethodForm/hooks'

const stripeStylingOptions = {
  style: {
    base: {
      color: '#fff',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#67809F',
      },
    },
    invalid: {
      color: '#FF0000',
    },
  },
}

const styles = {
  errorMessage: 'text-action-danger pt-2',
}

export interface Props {
  onConfirmPaymentSuccess?: (paymentMethod: string | PaymentMethod) => void
  children: ReactNode | ReactNode[]
}

const PaymentMethodForm = ({ onConfirmPaymentSuccess, children }: Props) => (
  <StripeProvider>
    <PaymentMethodProvider onConfirmPaymentSuccess={onConfirmPaymentSuccess}>
      {children}
    </PaymentMethodProvider>
  </StripeProvider>
)

const Fields = () => {
  const {
    paymentSecretLoading,
    paymentSecretError,
    confirmPaymentError,
    formErrors,
    handleFormError,
    handleSubmit,
  } = usePaymentMethod()

  if (paymentSecretLoading) {
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className=" col-span-2">
          <Skeleton variant="rounded" height={32} />
        </div>
        <div>
          <Skeleton variant="rounded" height={32} />
        </div>
        <div>
          <Skeleton variant="rounded" height={32} />
        </div>
      </div>
    )
  }

  if (paymentSecretError) {
    return (
      <Alert variant="error" className="mt-4">
        {paymentSecretError}
      </Alert>
    )
  }

  return (
    <form aria-label="payment-method-form" id="payment-method-form" onSubmit={handleSubmit}>
      <div>
        <div className="block font-normal text-sm pl-2 text-white">Card number</div>
        <CardNumberElement
          data-testid="stripeCardElement"
          options={stripeStylingOptions}
          onChange={handleFormError}
        />
        {!!formErrors.cardNumber && (
          <div className={styles.errorMessage}>{formErrors.cardNumber}</div>
        )}

        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="block font-normal text-sm pl-2 mt-7 text-white">Expiration date</div>
            <CardExpiryElement options={stripeStylingOptions} onChange={handleFormError} />

            {!!formErrors.cardExpiry && (
              <div className={styles.errorMessage}>{formErrors.cardExpiry}</div>
            )}
          </div>

          <div>
            <div className="block font-normal text-sm pl-2 mt-7 text-white">CVC Number</div>
            <CardCvcElement options={stripeStylingOptions} onChange={handleFormError} />
            {!!formErrors.cardCvc && (
              <div className={styles.errorMessage}>{formErrors.cardCvc}</div>
            )}
          </div>
        </div>
      </div>

      {confirmPaymentError && (
        <Alert variant="error" className="mt-4">
          {confirmPaymentError}
        </Alert>
      )}
    </form>
  )
}

const SubmitButton = () => {
  const { paymentSecretLoading, paymentSecretError, confirmPaymentLoading } = usePaymentMethod()

  if (paymentSecretLoading) {
    return <Skeleton className="mt-14" variant="circular" height={40} />
  }

  return (
    <Button
      className="mt-14"
      form="payment-method-form"
      data-testid="Submit"
      disabled={confirmPaymentLoading || paymentSecretLoading || !!paymentSecretError}
      type="submit"
      fullWidth
    >
      {confirmPaymentLoading ? 'Submitting...' : 'Add card'}
    </Button>
  )
}

PaymentMethodForm.Fields = Fields
PaymentMethodForm.SubmitButton = SubmitButton

export { PaymentMethodForm }
