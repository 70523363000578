import FLEETS_EDIT_ADMIN_ACTIVE_STATUS from '@electro/fleets/graphql/fleetsEditAdminActiveStatusMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import {
  Mutation,
  MutationFleetsEditAdminActiveStatusArgs,
} from '@electro/fleets/generated/graphql'

type EditAdminStatusMutation = Pick<Mutation, 'fleetsEditAdminActiveStatus'>

type UseFleetsEditAdminStatus = [
  ApolloMutationFunction<EditAdminStatusMutation>,
  MutationResult<EditAdminStatusMutation>,
]

type UseFleetsEditAdminStatusArgs = MutationHookOptions<
  EditAdminStatusMutation,
  MutationFleetsEditAdminActiveStatusArgs
>

export function useFleetsEditAdminStatus(
  options?: UseFleetsEditAdminStatusArgs,
): UseFleetsEditAdminStatus {
  const [mutation, result] = useMutation<EditAdminStatusMutation>(
    FLEETS_EDIT_ADMIN_ACTIVE_STATUS,
    options,
  )

  return [mutation, result]
}
