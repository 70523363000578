import {
  RegistrationVehicleLookup,
  ManualVehicleLookup,
} from '@electro/fleets/src/components/VehicleList/components/AddEvForm/components'
import {
  AddEvFormProvider,
  useAddEvForm,
  AddEvFormStage,
} from '@electro/fleets/src/components/VehicleList/components/AddEvForm/hooks'
import { AddEvFormProps } from '@electro/fleets/src/components/VehicleList/components/AddEvForm/AddEvForm.types'

const AddEvForm = ({ children, onSubmit, loading, success, locale }: AddEvFormProps) => (
  <AddEvFormProvider onSubmit={onSubmit} loading={loading} success={success} locale={locale}>
    {children}
  </AddEvFormProvider>
)

const Fields = () => {
  const { formState, success } = useAddEvForm()

  const components = {
    [AddEvFormStage.REGISTRATION_LOOK_UP]: <RegistrationVehicleLookup />,
    [AddEvFormStage.MANUAL_LOOK_UP]: <ManualVehicleLookup />,
  }

  if (success) return null

  return components[formState]
}

const Success = ({ children }) => {
  const { success } = useAddEvForm()

  return success ? children : null
}

AddEvForm.Fields = Fields
AddEvForm.Success = Success

export { AddEvForm }
