import { useMemo } from 'react'
import { useFleetsOnboarding } from '@electro/fleets/src/hooks'
import { Button, Card, IconButton, Stepper, Typography } from '@electro/shared-ui-components'
import Link from 'next/link'
import { GTM } from '@electro/fleets/src/utils/event-triggers'
import ReactJoyride, { ACTIONS, CallBackProps, Step, Styles } from 'react-joyride'
import { useLocalStorage } from 'react-use'
import {
  FLEETS_USER_TOUR,
  FLEETS_ONBOARDING_WIDGET_VISIBLE,
} from '@electro/fleets/src/constants/localStorage'
import { UserTour, OnboardingWidget } from '@electro/fleets/src/types/localStorage'
import { Loader } from '@electro/fleets/src/components/DashboardSummary/components'
import { XMarkIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'
import { DemoModeWrapper } from '@electro/fleets/src/components'

const tourStyles: Partial<Styles> = {
  buttonNext: {
    display: 'none',
  },
  options: {
    zIndex: 30,
  },
}

export const OnboardingActions = () => {
  const { t } = useTranslation('common')
  const tourSteps: Step[] = [
    {
      target: '#onboarding',
      title: t('onboarding_steps.welcome.title'),
      placement: 'top',
      content: t('onboarding_steps.welcome.content'),
      disableBeacon: true,
      disableScrolling: true,
    },
  ]
  const {
    businessEntityStatus,
    driversStatus,
    electrocardsStatus,
    adminUsersStatus,
    loading,
    success,
  } = useFleetsOnboarding()
  const [userTour, setUserTour] = useLocalStorage<UserTour | undefined>(FLEETS_USER_TOUR, {})
  const [onboardingWidget, setIsOnboardingWidgetVisible] = useLocalStorage<
    OnboardingWidget | undefined
  >(FLEETS_ONBOARDING_WIDGET_VISIBLE, { visible: true })

  const steps = useMemo(
    () => [
      !businessEntityStatus.hasDeliveryAddress,
      !businessEntityStatus.hasPaymentMethod,
      !driversStatus.hasUploadDrivers,
      !electrocardsStatus.hasOrderElectrocards,
      !adminUsersStatus.hasAddedAdminUsers,
    ],
    [
      businessEntityStatus,
      adminUsersStatus.hasAddedAdminUsers,
      driversStatus.hasUploadDrivers,
      electrocardsStatus.hasOrderElectrocards,
    ],
  )

  const activeStep = useMemo(() => {
    const stepIndex = steps.findIndex(Boolean)

    // findIndex will return -1 if all steps are completed
    // and we will won't find any step status to be true
    if (stepIndex === -1) {
      return steps.length
    }

    return stepIndex
  }, [steps])

  const runJoyride = useMemo((): boolean => {
    const stepIndex = steps.findIndex(Boolean)
    const stepsCompleted = stepIndex === -1

    // Don't show user tour if business entity is still
    // loading or if all onboarding steps are completed
    if (!success || loading || stepsCompleted) {
      return false
    }
    return !userTour?.onboarding
  }, [steps, userTour, success, loading])

  const handleJoyRideCallback = ({ action }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      const existingUserTour = { ...userTour, onboarding: true }
      setUserTour(existingUserTour)
    }
  }

  return (
    onboardingWidget.visible && (
      <DemoModeWrapper>
        <div className="flex-2 mt-8 ml-8 flex flex-col lg:min-w-[430px]">
          <ReactJoyride
            callback={handleJoyRideCallback}
            run={runJoyride}
            styles={tourStyles}
            steps={tourSteps}
          />
          <Card className="flex-1" id="onboarding">
            {loading && (
              <div className="min-h-[320px] flex justify-center items-center">
                <Loader />
              </div>
            )}

            {!loading && success && (
              <div className="flex justify-between">
                <Stepper orientation="vertical" activeStep={activeStep}>
                  <Stepper.Step>
                    <Typography variant="h6">
                      {t('onboarding_steps.stepper.complete_business_info.heading')}
                    </Typography>

                    <Stepper.StepContent>
                      <Typography>
                        {t('onboarding_steps.stepper.complete_business_info.content')}
                      </Typography>

                      <Link href="/dashboard/admin/delivery-address" className="no-underline">
                        <Button
                          className="mt-4"
                          onClick={() =>
                            GTM.onboardingStepClicked({ onboardingStep: 'complete-business-info' })
                          }
                        >
                          {t(
                            'onboarding_steps.stepper.complete_business_info.button.add_business_info',
                          )}
                        </Button>
                      </Link>
                    </Stepper.StepContent>
                  </Stepper.Step>

                  <Stepper.Step>
                    <Typography variant="h6">
                      {t('onboarding_steps.stepper.add_payment_details.heading')}
                    </Typography>

                    <Stepper.StepContent>
                      <Typography>
                        {t(
                          'onboarding_steps.stepper.add_payment_details.button.complete_direct_debit',
                        )}
                      </Typography>

                      <Link href="/dashboard/payment-and-invoices" className="no-underline">
                        <Button
                          className="mt-4"
                          onClick={() =>
                            GTM.onboardingStepClicked({ onboardingStep: 'add-payment-details' })
                          }
                        >
                          {t(
                            'onboarding_steps.stepper.add_payment_details.button.setup_direct_debit',
                          )}
                        </Button>
                      </Link>
                    </Stepper.StepContent>
                  </Stepper.Step>

                  <Stepper.Step>
                    <Typography variant="h6">
                      {t('onboarding_steps.stepper.upload_drivers.heading')}
                    </Typography>

                    <Stepper.StepContent>
                      <Typography>
                        {t('onboarding_steps.stepper.upload_drivers.content')}
                      </Typography>

                      <Link href="/dashboard/drivers" className="no-underline">
                        <Button
                          className="mt-4"
                          onClick={() =>
                            GTM.onboardingStepClicked({ onboardingStep: 'upload-drivers' })
                          }
                        >
                          {t('onboarding_steps.stepper.upload_drivers.button.upload_drivers')}
                        </Button>
                      </Link>
                    </Stepper.StepContent>
                  </Stepper.Step>

                  <Stepper.Step>
                    <Typography variant="h6">
                      {t('onboarding_steps.stepper.order_electrocards.heading')}
                    </Typography>

                    <Stepper.StepContent>
                      <Typography>
                        {t('onboarding_steps.stepper.order_electrocards.content')}
                      </Typography>

                      <Link href="/dashboard/electrocards" className="no-underline">
                        <Button
                          className="mt-4"
                          onClick={() =>
                            GTM.onboardingStepClicked({ onboardingStep: 'order-electrocards' })
                          }
                        >
                          {t('onboarding_steps.stepper.order_electrocards.heading')}
                        </Button>
                      </Link>
                    </Stepper.StepContent>
                  </Stepper.Step>

                  <Stepper.Step>
                    <Typography variant="h6">
                      {t('onboarding_steps.stepper.share_platform_access.heading')}
                    </Typography>

                    <Stepper.StepContent>
                      <Typography>
                        {t('onboarding_steps.stepper.share_platform_access.content')}
                      </Typography>

                      <Link href="/dashboard/admin/users" className="no-underline">
                        <Button
                          className="mt-4"
                          onClick={() => GTM.onboardingStepClicked({ onboardingStep: 'add-admin' })}
                        >
                          {t('onboarding_steps.stepper.share_platform_access.button.add_users')}
                        </Button>
                      </Link>
                    </Stepper.StepContent>
                  </Stepper.Step>
                </Stepper>
                {activeStep === -1 ||
                  (activeStep >= 4 && (
                    <IconButton
                      aria-label="Close dialog"
                      onClick={() => setIsOnboardingWidgetVisible({ visible: false })}
                    >
                      <XMarkIcon className="w-6 h-6" />
                    </IconButton>
                  ))}
              </div>
            )}
          </Card>
        </div>
      </DemoModeWrapper>
    )
  )
}
