import { Alert, Button, Modal } from '@electro/shared-ui-components'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { DownloadCsvTemplateButton } from '@electro/fleets/src/components/BulkDriverUploadButton/components/'
import {
  BulkUploadModalScreensEnum,
  useBulkDriverUpload,
} from '@electro/fleets/src/components/BulkDriverUploadButton/hooks/'
import useTranslation from 'next-translate/useTranslation'

export const InitialModalScreen = () => {
  const { t } = useTranslation('common')
  const [{ downloadTemplateError, loading }, { setActiveModalScreen }] = useBulkDriverUpload()

  function handleClickBulkUpload() {
    setActiveModalScreen(BulkUploadModalScreensEnum.UPLOAD)
  }

  return (
    <>
      <Modal.Header>{t('drivers.bulk_upload_modal.heading.add_all_drivers')}</Modal.Header>
      <Modal.Body>
        <>
          <p>{t('drivers.bulk_upload_modal.body.need_csv_of_drivers')}</p>
          <p>{t('drivers.bulk_upload_modal.body.csv_headings_check')}</p>
          <ul className="pl-5 pb-4">
            <li className="list-item list-disc">{t('common.first_name')}</li>
            <li className="list-item list-disc">{t('common.last_name')}</li>
            <li className="list-item list-disc">{t('common.email')}</li>
            <li className="list-item list-disc">{t('common.form_label.mobile_optional')}</li>
          </ul>
          <p>{t('drivers.bulk_upload_modal.body.mobile_number_instruction')}</p>
          <p>{t('drivers.bulk_upload_modal.body.download_template')}</p>
          {downloadTemplateError && (
            <Alert variant="error" className="mt-6 mb-4">
              {t('drivers.bulk_upload_modal.error.body')}
            </Alert>
          )}
        </>
      </Modal.Body>
      <Modal.Actions center>
        <Button disabled={loading} onClick={handleClickBulkUpload}>
          <ArrowUpTrayIcon className="w-6 h-6 mr-1" />
          {t('drivers.bulk_upload_modal.button.upload_csv')}
        </Button>
        <DownloadCsvTemplateButton />
      </Modal.Actions>
    </>
  )
}
