import { create } from 'zustand'

export enum AdminUserTypesEnum {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
}

export type AdminUserTypes = `${AdminUserTypesEnum}`

interface CurrentAdminUser {
  email: string
  userType: AdminUserTypes
  hasSignedUp: boolean
}

interface State {
  currentAdminUser: CurrentAdminUser
  setCurrentAdminUser: (user: CurrentAdminUser) => void
  resetCurrentAdminUser: () => void
}

const INITIAL_STATE = {
  email: null,
  userType: null,
  hasSignedUp: null,
}

export const useAdminUserStore = create<State>((set) => ({
  currentAdminUser: INITIAL_STATE,
  setCurrentAdminUser: (user: CurrentAdminUser) => set(() => ({ currentAdminUser: user })),
  resetCurrentAdminUser: () => set(() => ({ currentAdminUser: INITIAL_STATE })),
}))
