import { useEffect } from 'react'
import {
  LoadingDoubleRowPlaceholder,
  useToastNotification,
  Card,
  Typography,
} from '@electro/shared-ui-components'
import { useFleetsFetchDriver } from '@electro/fleets/src/services'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  infoTextLabel: 'lg:text-base lg:text-white text-sm',
  infoTextData: 'lg:text-base lg:text-white text-sm pl-2',
}

export const DriverDetails = ({ driverPk }: { driverPk: string }) => {
  const { t } = useTranslation('common')
  const { loading, data, error } = useFleetsFetchDriver({
    fetchPolicy: 'network-only',
    variables: { pk: parseInt(driverPk, 10) },
  })

  const { showToastNotification } = useToastNotification()

  useEffect(() => {
    if (error)
      showToastNotification({
        heading: 'Error',
        variant: 'error',
        body: 'There was a problem fetching driver data',
      })
  }, [error, showToastNotification])

  return (
    <Card>
      <div data-testid="driver-detail">
        {loading && <LoadingDoubleRowPlaceholder rows={5} />}
        {!!data && !error && (
          <div className="w-full">
            <h1 className="mb-2 text-3xl">{`${data.fleetsDriver.user.firstName} ${data.fleetsDriver.user.lastName}`}</h1>
            <h3 className="text-sm font-normal break-words lg:font-medium lg:text-base lg:text-white">
              {data.fleetsDriver.user.email}
            </h3>
            <div>
              <Typography as="span" className={styles.infoTextLabel}>
                {t('common.mobile')}:
              </Typography>
              <Typography as="span" className={styles.infoTextData}>
                {data.fleetsDriver.mobileNumber}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}
