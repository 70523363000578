/**
 * Flag to check if the app is running in demo mode.
 * We have a separate build for demo mode on the domain fleets-demo.electroverse.octopus.energy
 * The demo build points to a different BE endpoint which seeds the DB with demo data.
 *
 * NOTE: This flag should only be used where the <CheckEnabledForDemo/> component is not appropriate.
 */
export const isDemoMode = process.env.NEXT_PUBLIC_DEMO_BUILD === 'true'

/**
 * Executable for when the env variable changes during runtime (e.g. in a test suite)
 *
 * NOTE: This flag should only be used where the <CheckEnabledForDemo/> component is not appropriate.
 */
export const isDemoModeExec = () => process.env.NEXT_PUBLIC_DEMO_BUILD === 'true'
