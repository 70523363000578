import { EstimatedCostType, useFleetsSubscriptionsQuery } from '@electro/fleets/generated/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@electro/shared-ui-components'
import { CurrentSubscriptionsRow } from '@electro/fleets/src/components/PaymentAndInvoices/components/CurrentSubscriptions/components'
import { useMemo } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import useTranslation from 'next-translate/useTranslation'

const estimatedCostDefault: EstimatedCostType = {
  count: 0,
  price: 0,
  currencyCode: '',
  amount: 0,
}

export const sumEstimatesCostReduce = (accumulator, currentValue) => {
  const newValue = {
    ...accumulator,
    amount: accumulator.amount + Number(currentValue.amount),
    count: accumulator.count + Number(currentValue.count),
  }

  return newValue
}

export const CurrentSubscriptions = () => {
  const { t } = useTranslation('common')
  const { data, loading, error } = useFleetsSubscriptionsQuery()
  const totalCost: EstimatedCostType = useMemo(() => {
    let totalEstimatedCost = { ...estimatedCostDefault }

    const sumEstimatesCosts = data?.fleetsSubscriptions?.edges?.map((edge) =>
      edge.node.estimatedCost.reduce(sumEstimatesCostReduce, estimatedCostDefault),
    )

    if (sumEstimatesCosts) {
      totalEstimatedCost = sumEstimatesCosts.reduce(sumEstimatesCostReduce, estimatedCostDefault)
    }

    return totalEstimatedCost
  }, [data])

  return (
    <Table
      loading={loading}
      loadingMessage={t('payment_and_invoices.current_subscriptions.loading_table')}
      error={!!error}
    >
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>{t('common.drivers')}</TableCell>
          <TableCell>{t('common.price')}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {!loading &&
          !error &&
          data?.fleetsSubscriptions.edges.map((edge) => (
            <CurrentSubscriptionsRow key={edge.node.pk} edge={edge} />
          ))}

        <TableRow data-testid="total-subscription-row">
          <TableCell>
            <div className="flex items-center">
              <Tooltip>
                <Tooltip.Trigger
                  ariaLabel={t('payment_and_invoices.current_subscriptions.tooltip_trigger')}
                >
                  <InformationCircleIcon className="h-6 w-6 text-secondary mr-4" />
                </Tooltip.Trigger>
                <Tooltip.Body>
                  {t('payment_and_invoices.current_subscriptions.tooltip.body')}
                </Tooltip.Body>
              </Tooltip>
              {t('payment_and_invoices.current_subscriptions.row.net_total')}
            </div>
          </TableCell>

          <TableCell>{totalCost.count}</TableCell>
          <TableCell>
            {formatPriceToLocalisedValue({
              price: Number(totalCost.amount),
              isFloat: true,
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
