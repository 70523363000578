import { Button, Typography } from '@electro/shared-ui-components'
import { CheckIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import Image from 'next/image'
import { MouseEventHandler, ReactNode } from 'react'

interface Props {
  children: ReactNode
  isSelected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const styles = {
  root: 'rounded-lg justify-between p-2 border-opacity-30',
  content: 'flex items-center',
  font: 'font-normal',
  isSelected: 'border-opacity-100',
  icons: {
    truck: 'mr-4',
    check: 'h-8 w-8 text-secondary',
  },
}

export const AccountSelectButton = ({ children, isSelected, onClick }: Props) => (
  <Button
    fullWidth
    variant="outline"
    className={clsx({
      [styles.root]: true,
      [styles.isSelected]: isSelected,
    })}
    onClick={onClick}
  >
    <div className={styles.content}>
      <Image
        src="/images/fleet-trucks.svg"
        alt="Fleets trucks"
        width={58}
        height={58}
        className={styles.icons.truck}
        data-testid="fleets-trucks-icon"
      />

      <Typography variant="h4" className={styles.font}>
        {children}
      </Typography>
    </div>

    {isSelected && <CheckIcon data-testid="selected-account-icon" className={styles.icons.check} />}
  </Button>
)
