import { Table, TableBody, TableCell, TableHead, TableRow } from '@electro/shared-ui-components'

import useTranslation from 'next-translate/useTranslation'
import {
  FleetInvoiceTypeOrderingFields,
  OrderingDirectionEnum,
  useFleetsInvoicesQuery,
} from '@electro/fleets/generated/graphql'
import { BusinessInvoicesTableRow } from './components'

export const BusinessInvoices = () => {
  const { t } = useTranslation('common')
  const { data, loading, error } = useFleetsInvoicesQuery({
    variables: {
      ordering: [
        {
          orderBy: FleetInvoiceTypeOrderingFields.CreatedAt,
          direction: OrderingDirectionEnum.Desc,
        },
      ],
    },
  })

  return (
    <div>
      <Table
        data-testid="invoices-table"
        loading={loading}
        loadingMessage={t('payment_and_invoices.business_invoices.loading_message')}
        emptyTable={data && data.fleetsInvoices.edges.length === 0}
        emptyTableMessage={t('payment_and_invoices.business_invoices.empty_table')}
        error={!!error}
        errorMessage={error?.message}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('payment_and_invoices.business_invoices.created_date')}</TableCell>
            <TableCell>{t('payment_and_invoices.business_invoices.number')}</TableCell>
            <TableCell>{t('payment_and_invoices.business_invoices.status')}</TableCell>
            <TableCell>{t('payment_and_invoices.business_invoices.invoice')}</TableCell>
            <TableCell>
              {t('payment_and_invoices.business_invoices.charge_breakdown.heading')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.fleetsInvoices.edges.map((edge) => (
              <BusinessInvoicesTableRow key={edge.node.pk} invoiceEdge={edge} />
            ))}
        </TableBody>
      </Table>
    </div>
  )
}
