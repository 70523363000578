import { useStripePaymentSetup } from '@electro/fleets/src/hooks'
import { useElements, useStripe, CardNumberElement } from '@stripe/react-stripe-js'
import { Stripe, StripeElementChangeEvent, StripeElements } from '@stripe/stripe-js'
import { FormEvent, createContext, useContext, useState } from 'react'
import { useMount } from 'react-use'

interface FormErrors {
  cardNumber?: string
  cardExpiry?: string
  cardCvc?: string
}

interface PaymentMethodReturnType {
  stripe: Stripe
  elements: StripeElements
  paymentSecret: string
  paymentSecretLoading: boolean
  paymentSecretError: string
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  handleFormError: (event: StripeElementChangeEvent) => void
  formErrors: FormErrors
  confirmPaymentError: string
  confirmPaymentLoading: boolean
}

const PaymentMethodFormContext = createContext<PaymentMethodReturnType>(null)

const usePaymentMethodProvider = ({ onConfirmPaymentSuccess }): PaymentMethodReturnType => {
  const stripe = useStripe()
  const elements = useElements()
  const [paymentSecret, setPaymentSecret] = useState('')
  const [paymentSecretLoading, setPaymentSecretLoading] = useState(false)
  const [paymentSecretError, setPaymentSecretError] = useState('')
  const { getPaymentSecret, confirmPaymentMethod } = useStripePaymentSetup()
  const [confirmPaymentError, setConfirmPaymentError] = useState('')
  const [confirmPaymentLoading, setConfirmPaymentLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const handleGetPaymentSecret = async () => {
    try {
      setPaymentSecretLoading(true)

      const result = await getPaymentSecret()
      setPaymentSecret(result.data.fleetsGetPaymentInstructionSecret.secretKey)
    } catch (error) {
      setPaymentSecretError('Something went wrong, try again later!')
    } finally {
      setPaymentSecretLoading(false)
    }
  }

  useMount(handleGetPaymentSecret)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    try {
      setConfirmPaymentLoading(true)

      const result = await stripe.confirmCardSetup(paymentSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      })

      if (!result.error) {
        await confirmPaymentMethod(result.setupIntent.payment_method)
        onConfirmPaymentSuccess()
      }
    } catch (error) {
      setConfirmPaymentError('Something went wrong, unable to add payment method!')
    } finally {
      setConfirmPaymentLoading(false)
    }
  }

  const handleFormError = (event: StripeElementChangeEvent) => {
    setFormErrors({ ...formErrors, [event.elementType]: event.error?.message })
  }

  return {
    stripe,
    elements,
    paymentSecret,
    paymentSecretLoading,
    paymentSecretError,
    handleSubmit,
    handleFormError,
    formErrors,
    confirmPaymentError,
    confirmPaymentLoading,
  }
}

export const PaymentMethodProvider = ({ onConfirmPaymentSuccess, children }) => {
  const value = usePaymentMethodProvider({ onConfirmPaymentSuccess })

  return (
    <PaymentMethodFormContext.Provider value={value}>{children}</PaymentMethodFormContext.Provider>
  )
}

export const usePaymentMethod = () => {
  const context = useContext(PaymentMethodFormContext)

  if (!context)
    throw new Error('usePaymentMethod() cannot be used outside of <PaymentMethodProvider/>')
  return context
}
