import CREATE_DRIVER_MUTATION from '@electro/fleets/graphql/fleetsCreateAdminMutation.graphql'

import { Mutation, MutationFleetsCreateAdminArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsCreateAdmin = Pick<Mutation, 'fleetsCreateAdmin'>

type UseFleetsCreateAdmin = [
  ApolloMutationFunction<FleetsCreateAdmin>,
  MutationResult<FleetsCreateAdmin>,
]

export function useFleetsCreateAdmin(options): UseFleetsCreateAdmin {
  const [createAdminMutation, createAdminMutationResult] = useMutation<
    FleetsCreateAdmin,
    MutationFleetsCreateAdminArgs
  >(CREATE_DRIVER_MUTATION, options)

  return [createAdminMutation, createAdminMutationResult]
}
