import Image from 'next/image'

import {
  BusinessOnboardingProvider,
  useSignUp,
  SignUpStep,
  STEPPER_FORM_STAGE_ORDER,
  FormUserType,
  SignUpFormFieldsType,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks/useSignUp'
import {
  BusinessDetailsForm,
  UserDetailsForm,
  SelectBusinessClassification,
  BusinessProfileDataForm,
  Complete,
} from '@electro/fleets/src/components/BusinessOnboardingForm/components'
import { Stepper } from '@electro/shared-ui-components'

const mapFormScreenToStep = (stepName: SignUpStep) => {
  const screenMap = {
    [SignUpStep.SELECT_COMPANY_TYPE]: <SelectBusinessClassification />,
    [SignUpStep.BUSINESS_DETAILS_FORM]: <BusinessDetailsForm />,
    [SignUpStep.BUSINESS_PROFILE_DATA_FORM]: <BusinessProfileDataForm />,
    [SignUpStep.USER_DETAILS_FORM]: <UserDetailsForm />,
    [SignUpStep.COMPLETE]: <Complete />,
  }

  return screenMap[stepName]
}

const BusinessOnboardingForm = () => {
  const [{ activeScreen, currentStageIndex }] = useSignUp()

  const clampedActiveStepIndex =
    currentStageIndex < 0 ? STEPPER_FORM_STAGE_ORDER.length : currentStageIndex

  return (
    <>
      <Image
        src="/images/electroverse-logo-dark-business.svg"
        alt="Electroverse For Business Logo"
        width="180"
        height="180"
        className="mx-auto"
      />
      <Stepper
        className="justify-center flex flex-row w-3/4 mx-auto mt-8 mb-9"
        activeStep={clampedActiveStepIndex}
      >
        {STEPPER_FORM_STAGE_ORDER.map((step) => (
          <Stepper.Step blank key={step} />
        ))}
      </Stepper>
      {mapFormScreenToStep(activeScreen)}
    </>
  )
}

const BusinessOnboardingFormsContainer = ({
  userType,
  onComplete,
}: {
  userType?: FormUserType
  onComplete?: (data: SignUpFormFieldsType) => void
}) => (
  <BusinessOnboardingProvider {...{ userType, onComplete }}>
    <BusinessOnboardingForm />
  </BusinessOnboardingProvider>
)

export { BusinessOnboardingFormsContainer as BusinessOnboardingForm }
