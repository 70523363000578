import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import FLEETS_DRIVER_QUERY from '@electro/fleets/graphql/fleetsDriverQuery.graphql'
import { Query, QueryFleetsDriverArgs } from '@electro/fleets/generated/graphql'

export type FetchDriver = Pick<Query, 'fleetsDriver'>
type UseFleetsFetchDriver = QueryResult<FetchDriver, QueryFleetsDriverArgs>

export function useFleetsFetchDriver(
  options: QueryHookOptions<FetchDriver, QueryFleetsDriverArgs>,
): UseFleetsFetchDriver {
  const fleetsDriverQuery = useQuery<FetchDriver, QueryFleetsDriverArgs>(
    FLEETS_DRIVER_QUERY,
    options,
  )

  return fleetsDriverQuery
}
