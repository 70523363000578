export const PASSWORD_RESET_TOKEN_PARAM = 'password-reset-token'
export const SIGN_UP_TOKEN = 't'
export const FIRST_PARAM = 'first'
export const OFFSET_PARAM = 'offset'
export const ORDER_BY_PARAM = 'orderBy'
export const ORDER_DIRECTION_PARAM = 'direction'
export const SEARCH = 'search'
export const IS_ACTIVE_PARAM = 'isActive'
export const REFERRAL_CODE = 'referralCode'
export const CAMPAIGN_CODE = 'campaignCode'
export const STRIPE_SESSION_ID_PARAM = 'session_id'
