import { ApolloError } from '@apollo/client'
import { useFleetsAddTag } from '@electro/fleets/src/services/useFleetsAddTag'
import { useFleetsFetchTags } from '@electro/fleets/src/services/useFleetsFetchTags'
import { FleetsTagTypeEdge, MutationFleetsAddTagArgs } from '@electro/fleets/generated/graphql'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import FLEETS_TAGS from '@electro/fleets/graphql/fleetsTagsQuery.graphql'
import { useToastNotification } from '@electro/shared-ui-components'

interface TagsManagerReturnType {
  initialTagEdges: FleetsTagTypeEdge[]
  tagEdges: FleetsTagTypeEdge[]
  searchTextValue: string
  setSearchTextValue: any
  loading: boolean
  success: boolean
  error: ApolloError
  createTag: (args: MutationFleetsAddTagArgs) => void
  createTagLoading: boolean
}

const TagsManagerContext = createContext<TagsManagerReturnType>(null)

const useTagsManagerProvider = (): TagsManagerReturnType => {
  const [initialTagEdges, setInitialTagEdges] = useState<FleetsTagTypeEdge[]>([])
  const [tagEdges, setTagEdges] = useState<FleetsTagTypeEdge[]>([])
  const [searchTextValue, setSearchTextValue] = useState('')
  const { data, loading, error } = useFleetsFetchTags()
  const [success, setSuccess] = useState(false)
  const [createTag, { loading: createTagLoading }] = useFleetsAddTag({
    refetchQueries: [{ query: FLEETS_TAGS }],
  })
  const { showToastNotification } = useToastNotification()

  useEffect(() => {
    if (data) {
      setInitialTagEdges(data.fleetsTags.edges)
    }

    if (data) {
      setSuccess(true)
    }
  }, [data])

  useEffect(() => {
    const filteredTags = initialTagEdges.filter((fleetTagEdge: FleetsTagTypeEdge) =>
      fleetTagEdge.node.name.toLowerCase().includes(searchTextValue.toLowerCase()),
    )

    setTagEdges(filteredTags)
  }, [searchTextValue, initialTagEdges])

  const createTagHandler = useCallback(
    async ({ colour, name }: MutationFleetsAddTagArgs) => {
      try {
        await createTag({ variables: { colour, name } })
        setSearchTextValue('')
      } catch (err) {
        showToastNotification({
          heading: 'Oops!',
          body: err.message,
          variant: 'error',
        })
      }
    },
    [createTag, showToastNotification],
  )

  return {
    initialTagEdges,
    tagEdges,
    loading,
    error,
    success,
    createTag: createTagHandler,
    createTagLoading,
    searchTextValue,
    setSearchTextValue,
  }
}

export const TagsManagerProvider = ({ children }) => {
  const value = useTagsManagerProvider()

  return <TagsManagerContext.Provider value={value}>{children}</TagsManagerContext.Provider>
}

export const useTagsManager = () => {
  const context = useContext(TagsManagerContext)

  if (!context) throw new Error('useTagsManager() cannot be used outside of <TagsManagerProvider/>')
  return context
}
