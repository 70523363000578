import { useFleetsFetchBusinessEntity } from '@electro/fleets/src/services'
import { Skeleton, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import { useMount } from 'react-use'
import { useUiStore } from '@electro/fleets/src/hooks/stores'
import { useRouter } from 'next/router'
import { Transition } from '@headlessui/react'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: {
    default: 'flex flex-col items-center lg:mt-8 mt-12 z-10 relative py-4',
    expanded: 'opacity-100 ',
    contracted: 'opacity-0 h-0',
  },
  logo: 'flex justify-center',
  business: {
    root: '!flex flex-col items-center mt-4',
    name: 'min-h-[24px]',
  },
  hide: 'opacity-0',
}

export const SideBarNavigationHeader = () => {
  const expanded = useUiStore((state) => state.navigationExpanded)
  const [fetchBusinessEntity, { data, loading }] = useFleetsFetchBusinessEntity()
  const router = useRouter()
  useMount(() => fetchBusinessEntity())

  return (
    <div id="navigation-header" className={styles.root.default}>
      <div className={styles.logo}>
        <button onClick={() => router.push('/dashboard')}>
          <Image
            src="/images/electroverse-logo-dark-isolated.svg"
            alt="Electroverse Logo"
            width="60"
            height="60"
          />
        </button>
      </div>

      <Transition
        enter="transition-all duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100 h-fit"
        leave="transition-all duration-500"
        leaveFrom="opacity-100 h-fit"
        leaveTo="opacity-0 h-0"
        show={expanded}
        unmount={false}
        as="div"
        className={tw({
          [styles.business.root]: true,
          [styles.root.contracted]: !expanded,
          [styles.root.expanded]: expanded,
        })}
      >
        <Typography variant="h2" className="whitespace-nowrap">
          Electroverse for Business
        </Typography>

        <div className={styles.business.name}>
          {loading ? (
            <Skeleton width={150} />
          ) : (
            <Typography className="text-center">{data?.fleetsBusinessEntity?.name}</Typography>
          )}
        </div>
      </Transition>
    </div>
  )
}
