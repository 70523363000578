import FLEETS_ASSIGN_CARD_TO_DRIVER_MUTATION from '@electro/fleets/graphql/fleetsReassignCardToDriverMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsReassignCardToDriverArgs } from '@electro/fleets/generated/graphql'

type ReassignCardToDriverMutation = Pick<Mutation, 'fleetsReassignCardToDriver'>

type UseFleetsReassignCardToDriver = [
  ApolloMutationFunction<ReassignCardToDriverMutation>,
  MutationResult<ReassignCardToDriverMutation>,
]

type UseFleetsReassignCardToDriverArgs = MutationHookOptions<
  ReassignCardToDriverMutation,
  MutationFleetsReassignCardToDriverArgs
>

export function useFleetsReassignCardToDriver(
  options?: UseFleetsReassignCardToDriverArgs,
): UseFleetsReassignCardToDriver {
  const [reassignCardMutation, reassignCardResult] = useMutation<ReassignCardToDriverMutation>(
    FLEETS_ASSIGN_CARD_TO_DRIVER_MUTATION,
    options,
  )

  return [reassignCardMutation, reassignCardResult]
}
