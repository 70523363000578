import FLEETS_EDIT_BUSINESS_ENTITY_MUTATION from '@electro/fleets/graphql/fleetsEditBusinessEntityMutation.graphql'

import { Mutation, MutationFleetsEditBusinessEntityArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsEditBusinessEntity = Pick<Mutation, 'fleetsEditBusinessEntity'>

type UseFleetsEditBusinessEntity = [
  ApolloMutationFunction<FleetsEditBusinessEntity>,
  MutationResult<FleetsEditBusinessEntity>,
]

export function useFleetsEditBusinessEntity(): UseFleetsEditBusinessEntity {
  const [fleetsEditBusinessEntityMutation, fleetsEditBusinessEntityResult] = useMutation<
    Pick<Mutation, 'fleetsEditBusinessEntity'>,
    MutationFleetsEditBusinessEntityArgs
  >(FLEETS_EDIT_BUSINESS_ENTITY_MUTATION)

  return [fleetsEditBusinessEntityMutation, fleetsEditBusinessEntityResult]
}
