import { FLEETS_ACCOUNT_ID } from '@electro/fleets/src/constants/localStorage'

interface Props {
  downloadLink: string
}

export async function fetchChargeRecord({ downloadLink }: Props): Promise<Blob> {
  // FYI downloadLink field includes the whole url no
  // need to add env endpoint in front of it. We need
  // to remove it in order to pass it on rest api route
  const path = downloadLink.replace(process.env.NEXT_PUBLIC_EJN_API_FLEETS_REST_ENDPOINT, '')
  const accountId = window?.localStorage?.getItem(FLEETS_ACCOUNT_ID)

  const response = await fetch(`/api/rest/${path}`, {
    method: 'GET',
    headers: {
      'X-Account-Id': accountId,
    },
  })

  if (response.status !== 200) throw new Error('Error fetching charge record')

  return response.blob()
}
