import CREATE_DRIVER_MUTATION from '@electro/fleets/graphql/fleetsCreateBusinessEntityMutation.graphql'

import { Mutation, MutationFleetsCreateBusinessEntityArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsCreateBusinessEntity = Pick<Mutation, 'fleetsCreateBusinessEntity'>

type UseFleetsCreateBusinessEntity = [
  ApolloMutationFunction<FleetsCreateBusinessEntity>,
  MutationResult<FleetsCreateBusinessEntity>,
]

export function useFleetsCreateBusinessEntity(): UseFleetsCreateBusinessEntity {
  const [createBusinessEntityMutation, createBusinessEntityMutationResult] = useMutation<
    FleetsCreateBusinessEntity,
    MutationFleetsCreateBusinessEntityArgs
  >(CREATE_DRIVER_MUTATION)

  return [createBusinessEntityMutation, createBusinessEntityMutationResult]
}
