import {
  useFleetsCreditHistoryQuery,
  useFleetsCurrentBalanceQuery,
  useFleetsReferralSummaryQuery,
} from '@electro/fleets/generated/graphql'
import {
  Alert,
  Card,
  Typography,
  useToastNotification,
  Tooltip,
} from '@electro/shared-ui-components'
import {
  CreditBalanceAndHistory,
  CreditBalanceNoData,
  CreditBalanceError,
  CreditBalanceSkeleton,
  DesktopShareButton,
  MobileShareButton,
  RedeemCodeModal,
  ReferralLinkSkeleton,
  ReferralStatsSkeleton,
  ReferralStatWidget,
} from '@electro/fleets/src/components/CreditAndReferrals/components/'
import { useMedia } from 'react-use'
import electroTheme from '@electro/shared/theme/electro'
import { FLEETS_REFERRAL_URL } from '@electro/fleets/src/constants/urlConstants'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useCallback, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { devOrPreviewEnv } from '@electro/shared/utils/isEnv'
import { useErrorMessage } from '@electro/fleets/src/hooks'
import { useRouter } from 'next/router'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { DemoModeWrapper } from '@electro/fleets/src/components'

const styles = {
  referralLinkCard: {
    root: 'mt-5 flex justify-between items-center',
  },
  loadingCard: {
    root: 'mt-5 h-20',
  },
  mobileReferralLinkNoCard: 'hidden',
}

export const CreditAndReferrals = () => {
  const { data, loading, error } = useFleetsCurrentBalanceQuery({
    onError: (err) => Sentry.captureException(err),
  })
  const {
    data: creditHistoryData,
    loading: creditHistoryLoading,
    error: creditHistoryError,
  } = useFleetsCreditHistoryQuery({
    onError(err) {
      Sentry.captureException(err)
    },
  })
  const mobileScreenMatch = useMedia(`(min-width: ${electroTheme.screens.md})`)
  const { showToastNotification } = useToastNotification()
  const { referralCode } = useBusinessEntityStore((state) => state.businessEntity)
  const {
    data: referralSummaryData,
    loading: referralSummaryLoading,
    error: referralSummaryError,
  } = useFleetsReferralSummaryQuery({ onError: (err) => Sentry.captureException(err) })
  const errorMessage = useErrorMessage(error)
  const router = useRouter()
  const { t } = useTranslation('common')

  const referralCreditAmount = useMemo(
    () =>
      // This is hard coded to GBP for now, as we are not getting currency from backend
      formatPriceToLocalisedValue({
        price: 15,
        currency: 'GBP',
        locale: router.locale,
        isFloat: true,
      }),
    [router.locale],
  )

  const referralURL = referralCode
    ? `${FLEETS_REFERRAL_URL}${referralCode}`
    : t('credit_and_referrals.toast.error.code_not_found')
  const shareData = useMemo(
    () => ({
      title: t('credit_and_referrals.share.title'),
      text: `${t('credit_and_referrals.share.join_get_credit')} ${referralCreditAmount}`,
      url: referralURL,
    }),
    [referralCreditAmount, referralURL, t],
  )
  const showErrorToast = useCallback(() => {
    showToastNotification({
      variant: 'error',
      heading: t('credit_and_referrals.toast.heading.oops_there_problem'),
      body: t('credit_and_referrals.toast.please_try_again'),
      timeout: 5000,
    })
  }, [showToastNotification, t])

  const copyReferralCode = useCallback(() => {
    navigator.clipboard
      .writeText(referralURL)
      .then(() => {
        showToastNotification({
          variant: 'success',
          heading: t('credit_and_referrals.toast.heading.code_copied'),
          body: t('credit_and_referrals.toast.body.paste_in_message'),
          timeout: 5000,
        })
      })
      .catch(() => {
        console.error('Error copying referral code to clipboard')
        showErrorToast()
      })
  }, [referralURL, showToastNotification, t, showErrorToast])

  const shareReferralCode = useCallback(() => {
    navigator
      .share(shareData)
      .then(() => {
        showToastNotification({
          variant: 'success',
          heading: t('credit_and_referrals.toast.heading.code_shared'),
          body: t('credit_and_referrals.toast.body.paste_in_message'),
          timeout: 5000,
        })
      })
      .catch(() => {
        console.error('Error sharing referral code')
        Sentry.captureException('Error sharing referral code')
      })
  }, [shareData, showToastNotification, t])

  const handleCopyURL = useCallback(() => {
    const canShare = navigator.share && navigator.canShare(shareData)
    const hasReferralCode = !!referralCode

    if (hasReferralCode) {
      if (canShare) shareReferralCode()
      else copyReferralCode()
    } else {
      showErrorToast()
    }
  }, [copyReferralCode, referralCode, shareData, shareReferralCode, showErrorToast])

  return (
    <div>
      <section data-testid="credit-balance-section">
        <RedeemCodeModal />
        <CreditBalanceSkeleton show={loading} />
        {!loading && error && (
          <Card>
            <Alert variant="error">{errorMessage}</Alert>
          </Card>
        )}

        <CreditBalanceNoData
          currentBalanceData={data?.fleetsCurrentBalance}
          show={!loading && creditHistoryData?.fleetsCreditHistory.length === 0}
        />
        <CreditBalanceError show={!!creditHistoryError} />

        <CreditBalanceAndHistory
          currentBalanceData={data?.fleetsCurrentBalance}
          creditHistoryData={creditHistoryData}
          creditHistoryLoading={creditHistoryLoading}
          creditHistoryError={creditHistoryError}
          locale={router.locale}
          show={
            !loading &&
            !creditHistoryLoading &&
            data &&
            creditHistoryData?.fleetsCreditHistory.length > 0
          }
        />
      </section>
      <DemoModeWrapper>
        <section data-testid="referral-link-section">
          <Typography variant="h2" className="mb-5 mt-8">
            <Trans
              i18nKey="common:credit_and_referrals.heading.give_and_get_referral_bonus"
              components={{ referralCredit: <span>{referralCreditAmount}</span> }}
            />
          </Typography>
          <Typography variant="p">
            <Trans
              i18nKey="common:credit_and_referrals.subheading.refer_business_info"
              components={{ referralCredit: <span>{referralCreditAmount}</span> }}
            />
          </Typography>
          <div className="flex flex-col">
            <div className="flex gap-2 mt-10">
              <Typography variant="h3">
                {t('credit_and_referrals.heading.share_referral_link')}
              </Typography>
              <Tooltip>
                <Tooltip.Trigger
                  ariaLabel={t('credit_and_referrals.heading.share_referral_link')}
                  className="flex align-middle"
                  delay={300}
                >
                  <Image
                    src="/images/information-circle-icon.svg"
                    alt="information circle icon"
                    width="25"
                    height="25"
                  />
                </Tooltip.Trigger>
                <Tooltip.Body>
                  <Typography variant="p">{t('credit_and_referrals.tooltip.body')}</Typography>
                </Tooltip.Body>
              </Tooltip>
            </div>
            <Typography variant="p" className="mt-3">
              {t('credit_and_referrals.body.all_they_need_is_link')}
            </Typography>
          </div>
          <Card
            density="high"
            className={tw({
              [styles.referralLinkCard.root]: true,
              [styles.mobileReferralLinkNoCard]: !mobileScreenMatch,
            })}
          >
            <DesktopShareButton
              show={mobileScreenMatch && !loading}
              handleCopyURL={handleCopyURL}
              referralURL={referralURL}
            />
            <ReferralLinkSkeleton show={loading} />
          </Card>
          <MobileShareButton show={!mobileScreenMatch && !loading} handleCopyURL={handleCopyURL} />
        </section>
        {devOrPreviewEnv ? (
          <section>
            <Typography variant="h2" className="mb-5 mt-8">
              {t('credit_and_referrals.referral_stats.heading')}
            </Typography>
            {referralSummaryError !== undefined && (
              <Card>
                <Alert variant="error">{t('credit_and_referrals.error.body')}</Alert>
              </Card>
            )}
            <div className="flex lg:flex-row flex-col lg:gap-10 gap-5 flex-wrap">
              <ReferralStatsSkeleton
                show={referralSummaryLoading && referralSummaryError === undefined}
              />
              <ReferralStatsSkeleton
                show={referralSummaryLoading && referralSummaryError === undefined}
              />
              <ReferralStatsSkeleton
                show={referralSummaryLoading && referralSummaryError === undefined}
              />
              <ReferralStatWidget
                widgetHeading={t('credit_and_referrals.referral_stats.referred_businesses')}
                referralSummaryData={referralSummaryData?.fleetsReferralSummary?.totalReferrals}
                show={!referralSummaryLoading && referralSummaryError === undefined}
              />
              <ReferralStatWidget
                widgetHeading={t('credit_and_referrals.referral_stats.completed_referrals')}
                referralSummaryData={
                  referralSummaryData?.fleetsReferralSummary?.totalCompletedReferrals
                }
                show={!referralSummaryLoading && referralSummaryError === undefined}
              />
              <ReferralStatWidget
                widgetHeading={t('credit_and_referrals.referral_stats.total_credit')}
                referralSummaryData={referralSummaryData?.fleetsReferralSummary?.totalRewarded}
                show={!referralSummaryLoading && referralSummaryError === undefined}
                currencyData={referralSummaryData?.fleetsReferralSummary?.currency}
                isCurrency
              />
            </div>
          </section>
        ) : null}
      </DemoModeWrapper>
    </div>
  )
}
