import {
  DriverDetailsWidget,
  ElectrocardWidget,
  TagsWidget,
  VehiclesWidget,
  DriverWidgetsModal,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components'
import {
  useDriverWidgets,
  DriverWidgetsProvider,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'
import { DriverWidgetsProps } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/DriverWidgets.types'

const styles = {
  root: 'lg:grid lg:grid-cols-4 gap-4 lg:-mt-4 flex flex-col',
}

const DriverWidgets = ({ driver }: DriverWidgetsProps) => {
  const { activeModalScreen, closeModalScreen } = useDriverWidgets()

  return (
    <div className={styles.root}>
      <DriverDetailsWidget driver={driver} />
      <ElectrocardWidget driver={driver} />
      <VehiclesWidget driver={driver} />
      <TagsWidget driver={driver} />

      <DriverWidgetsModal activeModalScreen={activeModalScreen} onClose={closeModalScreen} />
    </div>
  )
}

const DriverWidgetsContainer = ({ driver }: DriverWidgetsProps) => (
  <DriverWidgetsProvider driver={driver}>
    <DriverWidgets driver={driver} />
  </DriverWidgetsProvider>
)

export { DriverWidgetsContainer as DriverWidgets }
