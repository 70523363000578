import { Elements } from '@stripe/react-stripe-js'
import { PaymentMethod, loadStripe } from '@stripe/stripe-js'
import {
  useFleetsGetPaymentSecretMutation,
  useFleetsStorePaymentInstruction,
} from '@electro/fleets/src/services'
import { useGetStripePublicKeyMutation } from '@electro/fleets/generated/graphql'
import { useMount } from 'react-use'
import { Alert } from '@electro/shared-ui-components'
import { useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'

const Loader = () => (
  <div className="flex items-center relative px-2" data-testid="spinner-loading">
    <div
      className="w-6 h-6 rounded-full absolute
border-2 border-solid border-primary border-opacity-30"
    />
    <div
      className="w-6 h-6 rounded-full animate-spin absolute
border-2 border-solid  border-primary    border-t-transparent"
    />
  </div>
)

export const useStripePaymentSetup = () => {
  const [getPaymentSecret] = useFleetsGetPaymentSecretMutation()
  const [storePaymentInstruction] = useFleetsStorePaymentInstruction()

  const confirmPaymentMethodHandler = (paymentMethod: string | PaymentMethod) => {
    const validFromDate = new Date()
    validFromDate.setHours(0, 0, 0, 0)
    const validFrom = validFromDate.toISOString()

    const storePaymentInstructionMutationArgs = {
      vendorReference: paymentMethod,
      validFrom,
    }

    return storePaymentInstruction({
      variables: storePaymentInstructionMutationArgs,
    })
  }

  return { getPaymentSecret, confirmPaymentMethod: confirmPaymentMethodHandler }
}

export const StripeProvider = ({ children }) => {
  const [getStripeKey, { data, loading, error }] = useGetStripePublicKeyMutation({
    onError: (err) => Sentry.captureException(err),
  })
  const stripePromise = useMemo(
    () => (data?.getStripePublicKey?.key ? loadStripe(data?.getStripePublicKey?.key) : null),
    [data],
  )

  useMount(() => getStripeKey())

  if (loading) return <Loader />

  if (error) {
    return (
      <Alert variant="error">
        Oops, there was an error when we tried to load this payment form, please try again later!
      </Alert>
    )
  }

  return <Elements stripe={stripePromise}>{children}</Elements>
}
