import { ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline'

import { Button, Head, LoadingOverlay } from '@electro/shared-ui-components'
import { SideBarNavigation } from '@electro/fleets/src/components/SideBarNavigation'
import { Authorised } from '@electro/fleets/src/components/Authorised'
import { FirstTimeUserWelcome } from '@electro/fleets/src/components/DashboardTemplate/components'
import { useFleetsAuth } from '@electro/fleets/src/hooks'
import { useBusinessEntityStore, useUiStore } from '@electro/fleets/src/hooks/stores'
import useTranslation from 'next-translate/useTranslation'
import { isDemoMode } from '@electro/fleets/src/utils/envFlagCheck'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface DashboardTemplateProps {
  header: string
  subHeader?: string | ReactNode
  children?: ReactNode | ReactNode[]
  pageTitle: string
  pageDescription?: string
}

const styles = {
  root: 'h-screen w-screen flex flex-row',
  main: {
    root: 'flex-1 !overflow-auto flex flex-col bg-base-dark transition-all duration-300',
    expanded: 'lg:pl-96',
    contracted: 'lg:pl-24',
  },
  header: {
    root: 'p-5 mt-12 lg:mt-0 lg:p-10 pb-0',
    header: 'flex-grow',
    subHeader: 'font-normal',
    logout: 'lg:relative fixed top-2.5 right-0 z-40',
    logoutIcon: 'w-5 h-5 ml-2',
  },
}

export const DashboardTemplate = ({
  children,
  subHeader,
  header,
  pageTitle,
  pageDescription,
}: DashboardTemplateProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { isAuthenticated, hasAuthenticationCheckBeenMade } = useFleetsAuth()
  const onboardingFlags = useBusinessEntityStore((state) => state.businessEntity.onboardingFlags)
  if (!isAuthenticated && hasAuthenticationCheckBeenMade) router.push('/dashboard/login')
  const expanded = useUiStore((state) => state.navigationExpanded)

  /**
   * We don't want users with un-verified email using the platform.
   * To avoid this we are redirecting them to a verification reminder page
   * before they see any data in the dashboard.
   */

  useEffect(() => {
    if (
      isAuthenticated &&
      hasAuthenticationCheckBeenMade &&
      onboardingFlags &&
      onboardingFlags.hasFetched &&
      !onboardingFlags.hasVerifiedEmail &&
      !isDemoMode
    ) {
      router.push('/onboarding/email-verification-required')
    }
  }, [onboardingFlags, router, isAuthenticated, hasAuthenticationCheckBeenMade])

  return (
    <Authorised>
      {({ logout, logoutLoading }) => (
        <>
          <Authorised.LoggedIn>
            <Head app="fleets" title={pageTitle} description={pageDescription} />
            <div className={styles.root} data-testid="dashboard-page-template">
              <SideBarNavigation />

              <main
                className={tw({
                  [styles.main.root]: true,
                  [styles.main.expanded]: expanded,
                  [styles.main.contracted]: !expanded,
                })}
              >
                <header className={styles.header.root}>
                  <div className="flex">
                    <h1 data-testid="page-display-heading" className={styles.header.header}>
                      {header}
                    </h1>
                    <Button
                      className={styles.header.logout}
                      variant="naked"
                      onClick={logout}
                      loading={logoutLoading}
                    >
                      {t('menu.button.log_out')}
                      <ArrowRightStartOnRectangleIcon className={styles.header.logoutIcon} />
                    </Button>
                  </div>
                  {!!subHeader && <h2 className={styles.header.subHeader}>{subHeader}</h2>}
                </header>

                <section className="flex-1">{children}</section>
              </main>

              <FirstTimeUserWelcome />
            </div>
          </Authorised.LoggedIn>
          <Authorised.Loading>
            <LoadingOverlay fixed />
          </Authorised.Loading>
        </>
      )}
    </Authorised>
  )
}
