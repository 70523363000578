import {
  DriverExpensesConnectionTypeOrderingFields,
  OrderingDirectionEnum,
} from '@electro/fleets/generated/graphql'
import { useQueryParams } from '@electro/fleets/src/hooks/useQueryParams'
import { QueryKeyEnum } from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { useRouter } from 'next/router'

export const useDriversExpensesQueryParams = () => {
  const router = useRouter()
  const queryParamsFromUrl = router.query

  const defaultParams = {
    first: 20,
    ordering: [
      {
        orderBy: DriverExpensesConnectionTypeOrderingFields.DriverName,
        direction: OrderingDirectionEnum.Asc,
      },
    ],
  }

  // If query parameters exist in the URL, use them. If not, use the default values.
  const initialState = queryParamsFromUrl || defaultParams

  return useQueryParams<DriverExpensesConnectionTypeOrderingFields>(QueryKeyEnum.ExpensesPage, {
    initialState,
  })
}
