import { Details, Typography, Skeleton } from '@electro/shared-ui-components'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import { generateNumberArray } from '@electro/fleets/src/utils/generateNumberArray'
import {
  FleetsCurrentBalanceType,
  FleetsCreditHistoryQuery,
} from '@electro/fleets/generated/graphql'
import { ApolloError } from '@apollo/client'
import { CreditHistoryItem } from '@electro/fleets/src/components/CreditAndReferrals/components/CreditHistoryItem'
import useTranslation from 'next-translate/useTranslation'

interface CreditBalanceAndHistoryProps {
  currentBalanceData: FleetsCurrentBalanceType
  creditHistoryData: FleetsCreditHistoryQuery
  creditHistoryLoading: boolean
  creditHistoryError: ApolloError
  locale: string
  show: boolean
}

export const CreditBalanceAndHistory = ({
  currentBalanceData,
  creditHistoryData,
  creditHistoryLoading,
  creditHistoryError,
  locale,
  show,
}: CreditBalanceAndHistoryProps) => {
  const { t } = useTranslation('common')

  return show ? (
    <Details>
      <Details.Item className="rounded-2xl shadow-xl border-2  bg-base backdrop-blur border-secondary">
        <Details.Title>
          <div className="w-full flex justify-between">
            <Typography variant="h6">{t('credit_and_referrals.heading.credit_balance')}</Typography>

            <Typography>
              {formatPriceToLocalisedValue({
                price: currentBalanceData?.amount,
                currency: currentBalanceData?.currency,
                locale,
                isFloat: true,
              })}
            </Typography>
          </div>
        </Details.Title>

        <Details.Content className="p-0">
          <div className="-mt-4 divide-y divide-secondary">
            {creditHistoryLoading &&
              !creditHistoryError &&
              generateNumberArray(5).map((number) => (
                <div key={number} className="flex justify-between pl-4 pr-16 py-3 ">
                  <Skeleton width={150} />
                  <Skeleton width={50} />
                </div>
              ))}

            {!creditHistoryLoading &&
              creditHistoryData &&
              creditHistoryData?.fleetsCreditHistory?.map((credit) => (
                <CreditHistoryItem
                  key={`${credit.amount} ${credit.createdAt}`}
                  credit={credit}
                  locale={locale}
                />
              ))}
          </div>
        </Details.Content>
      </Details.Item>
    </Details>
  ) : null
}
