/**
 * This is a manifest of components that are available for server side rendering.
 * These components can be used in the app directory. They cannot use any react lifecycle methods.
 *
 * NOTE:
 * This list is very limited because if a component either uses react lifecycle methods or uses a hook, it will not work.
 * Also if the component imports another component from the client side components the whole client side library is parsed,
 * this will throw an error if any the component in the client side library use react lifecycle methods (they do).
 *
 * TODO: Add more components to this list.
 * - Button
 * - Input
 * - Select
 * - Radio
 * - Details
 */

import { Typography } from './lib/Typography'
import { Skeleton } from './lib/Skeleton'
import { TruncatedText } from './lib/TruncatedText'
import { Tab, TabsList } from './lib/Tabs'
import { IconButton } from './lib/IconButton'
import { Checkbox } from './lib/Checkbox'
import { Card } from './lib/Card'
import { Alert } from './lib/Alert'
import { LoadingSpinner } from './lib/LoadingSpinner'

export {
  Typography,
  Skeleton,
  TruncatedText,
  Tab,
  TabsList,
  IconButton,
  Checkbox,
  Card,
  Alert,
  LoadingSpinner,
}
