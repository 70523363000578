import { useContext, useState, useRef, useEffect } from 'react'

import { QueryParamsContext } from '@electro/fleets/src/hooks/useQueryParams/QueryParamsProvider'
import {
  Render,
  Options,
  QueryParamsState,
  QueryKeyEnum,
} from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { QueryParams } from '@electro/fleets/src/hooks/useQueryParams/QueryParams'

const createQueryParamsObserver = <T extends string>(
  queryContext: QueryParams<T>,
  queryKey: QueryKeyEnum,
  options: Options<T>,
) => {
  const query = queryContext.getQueryParam(queryKey, options)

  const observer = {
    notify: () => {},
    subscribe: (rerender: Render) => {
      const unsubscribe = query.subscribe(observer)
      observer.notify = rerender

      return unsubscribe
    },
    getQueryParamState: () => ({
      ...query.state,
      syncQueryParams: (newQueryParams: Partial<QueryParamsState<T>>) =>
        queryContext.syncQueryParams(queryKey, newQueryParams),
    }),
  }

  if (!query.state.isInitialized) {
    query.initialize()
  }

  return observer
}

export const useQueryParams = <T extends string>(
  queryKey: QueryKeyEnum,
  options: Options<T> = {},
) => {
  const queryContext = useContext(QueryParamsContext)

  if (!queryContext) {
    throw new Error(
      `useQueryParams() cannot be used outside the context of <QueryParamsProvider/> `,
    )
  }

  const observer = useRef(createQueryParamsObserver<T>(queryContext, queryKey, options))

  const [, setCount] = useState(0)
  const rerender = () => setCount((c) => c + 1)

  useEffect(() => observer.current.subscribe(rerender), [])

  return observer.current.getQueryParamState()
}
