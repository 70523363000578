import { Card } from '@electro/shared-ui-components'
import { useFleetsSummaryStatsQuery } from '@electro/fleets/generated/graphql'
import { useDashboardSummary } from '@electro/fleets/src/components/DashboardSummary/hooks'
import {
  MetricWidget,
  ChargingByOperatorWidget,
  ChargingByDriver,
  ChargersUsedBySpeed,
} from '@electro/fleets/src/components/DashboardSummary/components/RecentCharges/components'
import { format } from 'date-fns'
import { QUERY_DATE_FORMAT } from '@electro/shared/constants'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'
import { useCallback, useMemo } from 'react'
import Image from 'next/image'
import { GlobeAsiaAustraliaIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import useTranslation from 'next-translate/useTranslation'

export const RecentCharges = () => {
  const { t } = useTranslation('common')
  const { startDate, endDate } = useDashboardSummary()
  const { data, loading } = useFleetsSummaryStatsQuery({
    variables: {
      startDate: format(startDate, QUERY_DATE_FORMAT),
      endDate: format(endDate, QUERY_DATE_FORMAT),
    },
  })

  const averageChargeSessionDuration = useCallback(
    (seconds: number) => durationFromSeconds(seconds),
    [],
  )

  const totalCostMetric = useMemo(() => {
    const totalCost = data?.fleetsSummaryStats?.totalCost
    return totalCost ? `£${totalCost}` : ''
  }, [data])

  const averageSessionLengthMetric = useMemo(() => {
    const averageSession = data?.fleetsSummaryStats?.averageSessionLength
    const { days = 0, hours = 0, minutes = 0 } = averageChargeSessionDuration(averageSession) ?? {}

    let averageSessionOutput = ''
    averageSessionOutput += days
      ? `${days} ${
          days > 1
            ? t('dashboard.widgets.average_session.days')
            : t('dashboard.widgets.average_session.day')
        }`
      : ''
    averageSessionOutput += hours
      ? `${hours} ${
          hours > 1
            ? t('dashboard.widgets.average_session.hours')
            : t('dashboard.widgets.average_session.hour')
        }`
      : ''
    averageSessionOutput += minutes
      ? `${minutes} ${
          minutes > 1
            ? t('dashboard.widgets.average_session.mins')
            : t('dashboard.widgets.average_session.min')
        }`
      : ''

    return averageSessionOutput
  }, [averageChargeSessionDuration, data?.fleetsSummaryStats?.averageSessionLength, t])

  const carbonSavedMetric = useMemo(() => {
    const carbonSaved = data?.fleetsSummaryStats?.totalCo2Saved
    return carbonSaved ? `${carbonSaved}kg` : ''
  }, [data])

  const businessHasCharged = useBusinessEntityStore((state) => state.businessEntity?.hasCharged)

  return (
    <div className="flex-3 mt-8 ml-8 lg:min-w-[600px]">
      <Card className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] lg:gap-8 xs:gap-4">
        <MetricWidget
          title={t('dashboard.widgets.title.total_cost')}
          metric={totalCostMetric}
          loading={loading}
          icon={
            <Image
              src="/images/dashboardIcons/total_cost.svg"
              alt="Total Cost Icon"
              width="25"
              height="25"
            />
          }
          noDataText=""
        />

        <ChargingByOperatorWidget />

        <ChargingByDriver />

        <ChargersUsedBySpeed />

        <MetricWidget
          title={t('dashboard.widgets.title.average_session')}
          metric={averageSessionLengthMetric}
          loading={loading}
          icon={<ClockIcon className="h-6 w-6 text-[#60F0F8]" />}
          noDataText={
            businessHasCharged ? '' : t('dashboard.widgets.title.not_charged.average_session')
          }
        />

        <MetricWidget
          title={t('dashboard.widgets.title.carbon_saved')}
          metric={carbonSavedMetric}
          loading={loading}
          icon={<GlobeAsiaAustraliaIcon className="h-6 w-6 text-[#60F0F8]" />}
          noDataText={
            businessHasCharged ? '' : t('dashboard.widgets.title.not_charged.carbon_saved')
          }
        />
      </Card>
    </div>
  )
}
