import { LazyQueryHookOptions, LazyQueryResult, useLazyQuery } from '@apollo/client'
import FLEETS_BUSINESS_ENTITY_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityQuery.graphql'

import { Query } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsBusinessEntity = Pick<Query, 'fleetsBusinessEntity'>

type UseFleetsFetchBusinessEntity = [
  ApolloLazyQueryFunction<FetchFleetsBusinessEntity>,
  LazyQueryResult<FetchFleetsBusinessEntity, {}>,
]

type FetchFleetsBusinessEntityArgs = LazyQueryHookOptions<FetchFleetsBusinessEntity>

export function useFleetsFetchBusinessEntity(
  options?: FetchFleetsBusinessEntityArgs,
): UseFleetsFetchBusinessEntity {
  const [query, result] = useLazyQuery<FetchFleetsBusinessEntity>(
    FLEETS_BUSINESS_ENTITY_QUERY,
    options,
  )

  return [query, result]
}
