import React, { createContext, useContext, ReactNode, useCallback, useState, useMemo } from 'react'
import { FormikProps, useFormik } from 'formik'
import { EditTagFormFields } from '@electro/fleets/src/components/TagsManager/components/EditTagForm'
import * as Yup from 'yup'
import { TagColours, useToastNotification } from '@electro/shared-ui-components'
import { useFleetsDeleteTag, useFleetsEditTag } from '@electro/fleets/src/services'
import FLEETS_TAGS from '@electro/fleets/graphql/fleetsTagsQuery.graphql'

type EditTagReturnType = {
  formik: FormikProps<EditTagFormFields>
  validateOnBlur: (e: React.FocusEvent<any>) => void
  oldValues: string
  tagPk: number
  deleteTagMutation: (pk: number) => void
  deleteTagLoading: boolean
  editTagLoading: boolean
}

export const tagColors: TagColours[] = [
  'lightGrey',
  'grey',
  'brown',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'red',
]

export const tagColourNames: string[] = [
  'tags.colours.light_grey',
  'tags.colours.grey',
  'tags.colours.brown',
  'tags.colours.orange',
  'tags.colours.yellow',
  'tags.colours.green',
  'tags.colours.blue',
  'tags.colours.purple',
  'tags.colours.pink',
  'tags.colours.red',
]

const schema = Yup.object().shape({
  name: Yup.string().required('tags.edit_tag.validation.name'),
  colour: Yup.string().oneOf(tagColors).required('tags.edit_tag.validation.colour'),
})

const EditTagContext = createContext(null)

interface Props {
  tagPk: number
  formFields?: EditTagFormFields
}

const UseEditTagProvider = ({ tagPk, formFields }: Props): EditTagReturnType => {
  const [pk] = useState(tagPk)
  const [deleteTagMutation, { loading }] = useFleetsDeleteTag({
    refetchQueries: [{ query: FLEETS_TAGS }],
  })
  const [editTagMutation, { loading: editTagLoading }] = useFleetsEditTag({
    refetchQueries: [{ query: FLEETS_TAGS }],
  })
  const { showToastNotification } = useToastNotification()
  const oldValues = useMemo(() => JSON.stringify(formFields), [formFields])

  const onSubmitHandler = async (values: EditTagFormFields) => {
    const newValues = JSON.stringify(values)

    if (oldValues === newValues) return

    try {
      await editTagMutation({ variables: { pk: tagPk, ...values } })
    } catch (err) {
      showToastNotification({
        heading: 'Oops!',
        body: err.message,
        variant: 'error',
      })
    }
  }

  const deleteTagHandler = useCallback(
    async (deleteTagPk: number) => {
      try {
        await deleteTagMutation({ variables: { pk: deleteTagPk } })
      } catch (err) {
        showToastNotification({
          heading: 'Oops!',
          body: err.message,
          variant: 'error',
        })
      }
    },
    [showToastNotification, deleteTagMutation],
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      colour: 'blue',
      ...formFields,
    },
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: onSubmitHandler,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  return {
    formik,
    validateOnBlur,
    oldValues,
    tagPk: pk,
    deleteTagMutation: deleteTagHandler,
    deleteTagLoading: loading,
    editTagLoading,
  }
}

interface ProviderProps {
  tagPk: number
  formFields?: EditTagFormFields
  children: ReactNode | ReactNode[]
}

export const EditTagProvider = ({ tagPk, formFields, children }: ProviderProps) => {
  const value = UseEditTagProvider({ tagPk, formFields })

  return <EditTagContext.Provider value={value}>{children}</EditTagContext.Provider>
}

export const useEditTag = (): EditTagReturnType => {
  const context = useContext(EditTagContext)

  if (!context) throw new Error('useEditTag() cannot be used outside of <EditTagProvider/>')
  return context
}
