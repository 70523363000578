import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Formik } from 'formik'
import { Input, Button, IconButton } from '@electro/shared-ui-components'

import {
  SignUpStep,
  useSignUp,
  SignUpFormFieldsEnum,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import { useState } from 'react'
import {
  CompaniesHouseLookup,
  CharityNumberLookup,
  HavingProblems,
  VatNumberLookup,
} from '@electro/fleets/src/components/BusinessOnboardingForm/components'

import {
  CHARITY_LOOKUP_COMPLETED,
  COMPANIES_HOUSE_LOOKUP_COMPLETED,
  VAT_LOOKUP_COMPLETED,
  formFieldsConfigByBusinessType,
} from './validationSchema'

const {
  COMPANY_NAME,
  COMPANIES_HOUSE_NUMBER,
  VAT_NUMBER,
  WEBSITE,
  CHARITIES_COMMISSION_NUMBER,
  BUSINESS_CLASSIFICATION,
} = SignUpFormFieldsEnum

function removeAsyncLookupValidationFlags(values) {
  // We don't want to forward validation flags to the callback
  const {
    [COMPANIES_HOUSE_LOOKUP_COMPLETED]: _,
    [CHARITY_LOOKUP_COMPLETED]: __,
    [VAT_LOOKUP_COMPLETED]: ___,
    ...valuesWithoutValidationFlags
  } = values
  return valuesWithoutValidationFlags
}

export const BusinessDetailsForm = () => {
  const [validateOnChange, setValidateOnChange] = useState(false)
  const [{ formData }, { handleScreenChange, handleSignUpDataChange }] = useSignUp()

  const handleFormSubmit = async (values, actions) => {
    handleScreenChange(SignUpStep.BUSINESS_PROFILE_DATA_FORM)()
    const valuesWithoutValidationFlags = removeAsyncLookupValidationFlags(values)

    handleSignUpDataChange(valuesWithoutValidationFlags)
    actions.setSubmitting(false)
  }

  const showValidationOnChange = () => setValidateOnChange(true)

  const initialFormValuesDerivedFromState = {
    [COMPANY_NAME]: formData?.[COMPANY_NAME] || '',
    [COMPANIES_HOUSE_NUMBER]: formData?.[COMPANIES_HOUSE_NUMBER] || '',
    [VAT_NUMBER]: formData?.[VAT_NUMBER] || '',
    [WEBSITE]: formData?.[WEBSITE] || '',
    [CHARITIES_COMMISSION_NUMBER]: formData?.[CHARITIES_COMMISSION_NUMBER] || '',
    [COMPANIES_HOUSE_LOOKUP_COMPLETED]: false,
    [CHARITY_LOOKUP_COMPLETED]: false,
  }

  const showFieldByBusinessType = (fieldName: SignUpFormFieldsEnum) =>
    formFieldsConfigByBusinessType[formData[BUSINESS_CLASSIFICATION]].fields[fieldName]

  /**
   * Dynamically look at the validation schema to see if a given field is required
   */
  const getIsFieldRequired = (fieldName: SignUpFormFieldsEnum): boolean => {
    const fieldSchema =
      formFieldsConfigByBusinessType[formData[BUSINESS_CLASSIFICATION]].validationSchema.fields[
        fieldName
      ]

    if ('exclusiveTests' in fieldSchema) {
      return JSON.parse(JSON.stringify(fieldSchema)).exclusiveTests.required
    }

    return false
  }

  return (
    <>
      <Formik
        initialValues={initialFormValuesDerivedFromState}
        onSubmit={handleFormSubmit}
        validationSchema={
          formFieldsConfigByBusinessType[formData[BUSINESS_CLASSIFICATION]].validationSchema
        }
        validateOnBlur={false}
        validateOnChange={validateOnChange}
      >
        {({ handleChange, handleSubmit, errors, values, setFieldValue, setFieldError }) => (
          <>
            <IconButton
              className="absolute top-2 left-2 flex items-center"
              onClick={() => {
                handleScreenChange(SignUpStep.SELECT_COMPANY_TYPE)()
                handleSignUpDataChange(values)
              }}
              aria-label="back to select your business type"
            >
              <ArrowLeftIcon className="w-5 h-5 text-secondary" />
            </IconButton>
            <form onSubmit={handleSubmit}>
              {showFieldByBusinessType(COMPANY_NAME) ? (
                <Input
                  errorMessage={errors?.[COMPANY_NAME] as string}
                  name={COMPANY_NAME}
                  placeholder="e.g. Electroverse"
                  fullWidth
                  required={getIsFieldRequired(COMPANY_NAME)}
                  label="Company Name"
                  onChange={handleChange}
                  value={values[COMPANY_NAME]}
                />
              ) : null}
              {showFieldByBusinessType(CHARITIES_COMMISSION_NUMBER) ? (
                <CharityNumberLookup
                  onChange={handleChange}
                  value={values[CHARITIES_COMMISSION_NUMBER]}
                  error={
                    (errors?.[CHARITIES_COMMISSION_NUMBER] ||
                      errors?.[CHARITY_LOOKUP_COMPLETED]) as string
                  }
                  onValid={() => {
                    setFieldError(CHARITIES_COMMISSION_NUMBER, '')
                    setFieldError(CHARITY_LOOKUP_COMPLETED, '')
                    setFieldValue(CHARITY_LOOKUP_COMPLETED, true)
                  }}
                  required={getIsFieldRequired(CHARITIES_COMMISSION_NUMBER)}
                  onError={(err) => {
                    setFieldError(CHARITIES_COMMISSION_NUMBER, err)
                    setFieldError(CHARITY_LOOKUP_COMPLETED, err)
                  }}
                  name={CHARITIES_COMMISSION_NUMBER}
                />
              ) : null}
              {showFieldByBusinessType(COMPANIES_HOUSE_NUMBER) ? (
                <CompaniesHouseLookup
                  onChange={handleChange}
                  value={values[COMPANIES_HOUSE_NUMBER]}
                  error={
                    (errors?.[COMPANIES_HOUSE_NUMBER] ||
                      errors?.[COMPANIES_HOUSE_LOOKUP_COMPLETED]) as string
                  }
                  onValid={() => {
                    setFieldValue(COMPANIES_HOUSE_LOOKUP_COMPLETED, true)
                    setFieldError(COMPANIES_HOUSE_LOOKUP_COMPLETED, '')
                    setFieldError(COMPANIES_HOUSE_NUMBER, '')
                  }}
                  required={getIsFieldRequired(COMPANIES_HOUSE_NUMBER)}
                  onError={(err) => {
                    setFieldError(COMPANIES_HOUSE_NUMBER, err)
                    setFieldError(COMPANIES_HOUSE_LOOKUP_COMPLETED, err)
                  }}
                  name={COMPANIES_HOUSE_NUMBER}
                />
              ) : null}
              {showFieldByBusinessType(VAT_NUMBER) ? (
                <VatNumberLookup
                  onChange={handleChange}
                  value={values[VAT_NUMBER]}
                  error={(errors?.[VAT_NUMBER] || errors?.[VAT_LOOKUP_COMPLETED]) as string}
                  onValid={() => {
                    setFieldValue(VAT_LOOKUP_COMPLETED, true)
                    setFieldError(VAT_LOOKUP_COMPLETED, '')
                    setFieldError(VAT_NUMBER, '')
                  }}
                  required={getIsFieldRequired(VAT_NUMBER)}
                  onError={(err) => {
                    setFieldError(VAT_NUMBER, err)
                    setFieldError(VAT_LOOKUP_COMPLETED, err)
                  }}
                  name={VAT_NUMBER}
                />
              ) : null}
              {showFieldByBusinessType(WEBSITE) ? (
                <Input
                  errorMessage={errors?.[WEBSITE] as string}
                  name={WEBSITE}
                  required={getIsFieldRequired(WEBSITE)}
                  fullWidth
                  label="Website"
                  placeholder="e.g. octopus.energy"
                  onChange={handleChange}
                  value={values[WEBSITE]}
                />
              ) : null}
              <Button onClick={showValidationOnChange} className="mt-8" type="submit" fullWidth>
                Continue
              </Button>
            </form>
          </>
        )}
      </Formik>
      <HavingProblems />
    </>
  )
}
