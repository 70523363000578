import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'

import { Input, Button, Typography } from '@electro/shared-ui-components'
import { ukMobileNumberValidator } from '@electro/shared/utils/validators'
import { formatMobileNumber } from '@electro/shared/utils/formatters'

import {
  DriverFormFields,
  keysOfDriverFormFields,
  DriverFormFieldNames,
  CreateNewDriverFormFields,
} from '@electro/fleets/src/types/DriverForm'
import { FleetsTagType } from '@electro/fleets/generated/graphql'
import { TagsManager } from '@electro/fleets/src/components/TagsManager'
import useTranslation from 'next-translate/useTranslation'

export interface DriverFormProps {
  loading?: boolean
  error?: Partial<ApolloError>
  formFields?: Partial<DriverFormFields>
  onSubmit?: (values: CreateNewDriverFormFields) => void
  submitText?: string
  submittingText?: string
  disabledFields?: keysOfDriverFormFields[]
}

const { GIVEN_NAME, FAMILY_NAME, MOBILE, EMAIL, TAGS } = DriverFormFieldNames

// TODO Add validation for France on mobile number
const createDriverValidationSchema = () =>
  Yup.object().shape({
    [GIVEN_NAME]: Yup.string().required('drivers.create_driver.validation_warning.first_name'),
    [FAMILY_NAME]: Yup.string().required('drivers.create_driver.validation_warning.last_name'),
    [EMAIL]: Yup.string()
      .email('drivers.create_driver.validation_warning.email')
      .required('drivers.create_driver.validation_warning.email_required'),
    [MOBILE]: Yup.string()
      .test(MOBILE, 'Enter a valid UK mobile phone number starting 07.', (mobileNumber) => {
        if (mobileNumber?.length > 1) {
          return ukMobileNumberValidator(mobileNumber)
        }
        return true
      })
      .length(11, 'Enter a valid UK mobile phone number starting 07.')
      .nullable(),
    [TAGS]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          colour: Yup.string(),
        }),
      )
      .nullable(),
  })

const styles = {
  errorMessage: 'text-action-danger pt-2',
  counter: 'text-right text-sm -mt-4',
  postcodeCity: 'flex space-x-4',
  backButton: 'absolute top-4 left-4',
}

export const DriverForm = ({
  loading,
  error,
  onSubmit,
  submitText = 'common.button.submit',
  submittingText = 'common.button.loading.submitting',
  disabledFields,
  formFields,
}: DriverFormProps) => {
  const { t } = useTranslation('common')
  const formik = useFormik({
    initialValues: {
      [GIVEN_NAME]: formFields?.[GIVEN_NAME] || '',
      [FAMILY_NAME]: formFields?.[FAMILY_NAME] || '',
      [EMAIL]: formFields?.[EMAIL] || '',
      [MOBILE]: formFields?.[MOBILE] || '',
      [TAGS]: formFields?.[TAGS] || [],
    },
    validationSchema: createDriverValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const handlePhoneNumberChange = (e) => {
    const formattedMobileNumber = formatMobileNumber(e.target.value)
    formik.setFieldValue(MOBILE, formattedMobileNumber)
  }

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  const handleTagChange = (tags: FleetsTagType[]) => {
    formik.setFieldValue('tags', tags)
  }

  function isFieldDisabled(fieldName: keysOfDriverFormFields) {
    if (!disabledFields) return false
    return disabledFields.includes(fieldName) || loading
  }

  return (
    <div aria-label="driver-form">
      <form id="driver-form" onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <section>
          <Input
            fullWidth
            disabled={isFieldDisabled(GIVEN_NAME)}
            name={GIVEN_NAME}
            label={t('drivers.create_driver.form_label.first_name')}
            placeholder={t('form_placeholder.first_name')}
            value={formik.values[GIVEN_NAME]}
            errorMessage={t(formik.errors[GIVEN_NAME])}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(FAMILY_NAME)}
            name={FAMILY_NAME}
            label={t('drivers.create_driver.form_label.last_name')}
            placeholder={t('form_placeholder.family_name')}
            value={formik.values[FAMILY_NAME]}
            errorMessage={t(formik.errors[FAMILY_NAME])}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(EMAIL)}
            name={EMAIL}
            label={t('drivers.create_driver.form_label.email')}
            placeholder={t('form_placeholder.email')}
            helpText={t('drivers.create_driver.help_text.email')}
            value={formik.values[EMAIL]}
            errorMessage={t(formik.errors[EMAIL])}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(MOBILE)}
            name={MOBILE}
            label={t('drivers.create_driver.form_label.mobile_number')}
            type="tel"
            placeholder={t('form_placeholder.mobile')}
            value={formik.values[MOBILE]}
            errorMessage={formik.errors[MOBILE]}
            onChange={handlePhoneNumberChange}
            maxLength={11}
            infoText={t('common.form.info_text.optional')}
          />
        </section>
      </form>

      <div className="mb-10">
        <Typography variant="small" className="pl-1 pb-1">
          {t('common.tags.tags')}
        </Typography>

        <TagsManager variant="form" tags={formik.values[TAGS]} onTagsChange={handleTagChange} />
      </div>

      <div className="pt-8">
        <Button form="driver-form" disabled={loading} data-testid="Submit" fullWidth type="submit">
          {loading ? t(submittingText) : t(submitText)}
        </Button>
      </div>

      {!!error && (
        <div className={styles.errorMessage}>
          {error.message ? t(error.message) : t('common.error_message')}
        </div>
      )}
    </div>
  )
}
