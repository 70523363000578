import { useMount } from 'react-use'
import { Typography } from '@electro/shared-ui-components'
import { useSignUp } from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'

export const Complete = () => {
  const [, { handleFormCompleted }] = useSignUp()
  /**
   * Trigger sending form data to the top level onComplete callback
   * in the parent component <BusinessOnboardingForm />
   */
  useMount(() => {
    handleFormCompleted()
  })
  return (
    <div className="text-center">
      <Typography variant="h1" as="h1">
        Thank you for choosing Octopus Electroverse!
      </Typography>
      <Typography variant="p" as="p">
        Your application is being reviewed, we will be in touch in a maximum of 1-2 working days.
        Stay tuned for an update in your inbox soon
      </Typography>
    </div>
  )
}
