import { FleetInvoiceTypeEdge } from '@electro/fleets/generated/graphql'
import { Button, TableCell, TableRow, useToastNotification } from '@electro/shared-ui-components'
import { format } from 'date-fns'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { fetchChargeRecord } from '@electro/fleets/src/services/rest'
import { DownloadFile } from 'libs/shared-ui-components/src/lib/DownloadFile'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  invoiceEdge: FleetInvoiceTypeEdge
}

const styles = {
  status: {
    isPaid: 'text-action-success',
    isPending: 'text-[#FFC532]',
    isCancelled: 'text-info-danger',
  },
}

export const BusinessInvoicesTableRow = ({ invoiceEdge }: Props) => {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const formattedCreatedAt = format(new Date(invoiceEdge.node.createdAt), 'dd.MM.yyyy')
  const invoiceStatus = invoiceEdge.node.status

  return (
    <TableRow>
      <TableCell>{formattedCreatedAt}</TableCell>
      <TableCell>{invoiceEdge.node.invoiceNumber}</TableCell>
      <TableCell
        className={tw({
          [styles.status.isPaid]: invoiceStatus === 'PAID',
          [styles.status.isPending]: invoiceStatus === 'PENDING',
          [styles.status.isCancelled]: invoiceStatus === 'CANCELLED',
        })}
      >
        {invoiceEdge.node.status}
      </TableCell>
      <TableCell className=" w-1/5">
        <DownloadFile
          fetcher={() => fetchChargeRecord({ downloadLink: invoiceEdge.node.downloadLink })}
          filename={`invoice-${invoiceEdge.node.invoiceNumber}`}
          onError={() => {
            showToastNotification({
              variant: 'error',
              heading: t('errors.common.oops'),
              body: t('payment_and_invoices.business_invoices.invoice_download.toast.error.body'),
            })
          }}
          onSuccess={() => {
            showToastNotification({
              variant: 'success',
              heading: t(
                'payment_and_invoices.business_invoices.invoice_download.toast.success.heading',
              ),
              body: t('payment_and_invoices.business_invoices.invoice_download.toast.success.body'),
              timeout: 5000,
            })
          }}
        >
          {({ fetchFileThenDownload }) => (
            <>
              <DownloadFile.Idle>
                <Button
                  size="xs"
                  variant="naked"
                  onClick={fetchFileThenDownload}
                  data-testid="invoiceDownloadButton"
                >
                  <ArrowDownTrayIcon className="w-6 h-6" />
                </Button>
              </DownloadFile.Idle>
              <DownloadFile.Downloading>
                <div className="flex items-center relative px-2" data-testid="spinner-loading">
                  <div className="w-6 h-6 rounded-full absolute border-2 border-solid border-primary border-opacity-30" />
                  <div className="w-6 h-6 rounded-full animate-spin absolute border-2 border-solid  border-primary border-t-transparent" />
                </div>
              </DownloadFile.Downloading>
            </>
          )}
        </DownloadFile>
      </TableCell>
      <TableCell className=" w-1/5">
        <DownloadFile
          fetcher={() =>
            fetchChargeRecord({ downloadLink: invoiceEdge.node.chargeBreakdownDownloadLink })
          }
          filename={`charge_breakdown-invoice-${invoiceEdge.node.invoiceNumber}`}
          onError={() => {
            showToastNotification({
              variant: 'error',
              heading: t('errors.common.oops'),
              body: t('payment_and_invoices.business_invoices.charge_breakdown.toast.error.body'),
              timeout: 8000,
            })
          }}
          onSuccess={() => {
            showToastNotification({
              variant: 'success',
              heading: t(
                'payment_and_invoices.business_invoices.charge_breakdown.toast.success.heading',
              ),
              body: t('payment_and_invoices.business_invoices.charge_breakdown.toast.success.body'),
              timeout: 5000,
            })
          }}
        >
          {({ fetchFileThenDownload }) => (
            <>
              <DownloadFile.Idle>
                <Button
                  size="xs"
                  variant="naked"
                  onClick={fetchFileThenDownload}
                  data-testid="chargeBreakdownDownloadButton"
                >
                  <ArrowDownTrayIcon className="w-6 h-6" />
                </Button>
              </DownloadFile.Idle>
              <DownloadFile.Downloading>
                <div className="flex items-center relative px-2" data-testid="spinner-loading">
                  <div className="w-6 h-6 rounded-full absolute border-2 border-solid border-primary border-opacity-30" />
                  <div className="w-6 h-6 rounded-full animate-spin absolute border-2 border-solid  border-primary border-t-transparent" />
                </div>
              </DownloadFile.Downloading>
            </>
          )}
        </DownloadFile>
      </TableCell>
    </TableRow>
  )
}
