import {
  Alert,
  Button,
  Card,
  Skeleton,
  Typography,
  useToastNotification,
} from '@electro/shared-ui-components'
import {
  useFleetsBusinessEntityQuery,
  useFleetsStartDirectDebitCheckoutSessionMutation,
  useFleetsStorePaymentInstructionFromCheckoutMutation,
} from '@electro/fleets/generated/graphql'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { useEffect, useMemo } from 'react'
import { isObject } from '@electro/shared/utils/typed'
import FLEETS_BUSINESS_ENTITY_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityQuery.graphql'
import ReactJoyride, { ACTIONS, CallBackProps, Step } from 'react-joyride'
import { useLocalStorage } from 'react-use'
import { UserTour } from '@electro/fleets/src/types/localStorage'
import { FLEETS_USER_TOUR } from '@electro/fleets/src/constants/localStorage'
import useTranslation from 'next-translate/useTranslation'
import { STRIPE_SESSION_ID_PARAM } from '@electro/fleets/src/constants/urlParams'

const styles = {
  card: {
    root: 'flex-1 flex lg:flex-row flex-col items-center',
    info: 'flex-[2] flex justify-between items-center',
    action: 'lg:flex-[1.5] flex justify-end mt-3',
  },
  message: {
    lightGray: 'text-tertiary-shade',
    error: 'text-action-danger border-action-danger',
  },
}

export const PaymentMethodV2 = () => {
  const { t } = useTranslation('common')
  const tourSteps: Step[] = [
    {
      target: '#payment-method',
      placement: 'top',
      content: t('onboarding_steps.payment_method.content'),
      disableBeacon: true,
    },
  ]
  const router = useRouter()
  const sessionId = router.query[STRIPE_SESSION_ID_PARAM] as string
  const { showToastNotification } = useToastNotification()
  const {
    data: businessEntityData,
    loading: businessEntityLoading,
    error: businessEntityError,
  } = useFleetsBusinessEntityQuery()
  const [
    getDirectDebitSession,
    {
      data: directDebitSessionData,
      loading: directDebitSessionLoading,
      error: directDebitSessionError,
    },
  ] = useFleetsStartDirectDebitCheckoutSessionMutation({
    onError: (error) => Sentry.captureException(error),
  })
  const [
    storePaymentInstructionFromCheckout,
    { loading: paymentInstructionLoading, error: paymentInstructionError },
  ] = useFleetsStorePaymentInstructionFromCheckoutMutation({
    onError: (error) => Sentry.captureException(error),
    onCompleted: () =>
      showToastNotification({
        variant: 'success',
        heading: t('payment_and_invoices.payment_method.toast.heading'),
        timeout: 5000,
      }),
    refetchQueries: [{ query: FLEETS_BUSINESS_ENTITY_QUERY }],
  })
  const [userTour, setUserTour] = useLocalStorage<UserTour | undefined>(FLEETS_USER_TOUR, {})

  const paymentMethod = useMemo(
    () =>
      businessEntityData?.fleetsBusinessEntity?.billingAccount?.paymentMethod?.paymentMethodDetails,
    [businessEntityData?.fleetsBusinessEntity?.billingAccount?.paymentMethod?.paymentMethodDetails],
  )

  const sortCode = useMemo(() => {
    if (isObject(paymentMethod) && 'sortCode' in paymentMethod) {
      return paymentMethod.sortCode
    }

    return null
  }, [paymentMethod])

  // on success direct debit user will be redirected on this page
  // with a sessionId. If we have a sessionId we will send that
  // on the backend so they can do the final actions
  useEffect(() => {
    async function sendPaymentInstruction() {
      if (sessionId) {
        try {
          const validFrom = new Date()
          validFrom.setHours(0, 0, 0, 0)
          await storePaymentInstructionFromCheckout({
            variables: {
              sessionId,
              validFrom,
            },
          })
          /**
           * Stripping the stripe session url param here so the mutation is not called
           * again on page refresh or when a user navigates to another page
           * then back to this page.
           */
          router.replace(
            router.pathname,
            {
              query: Object.fromEntries(
                Object.entries(router.query).filter(([key]) => key !== STRIPE_SESSION_ID_PARAM),
              ),
            },
            { shallow: true },
          )
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
    sendPaymentInstruction()
  }, [router, sessionId, storePaymentInstructionFromCheckout])

  const handleDirectDebitSetup = () => getDirectDebitSession()

  // this useEffect watch direct debit session data if there
  // a sessions url we will redirect user to the checkout page
  useEffect(() => {
    if (directDebitSessionData?.fleetsStartDirectDebitCheckoutSession?.checkoutUrl) {
      window.location.href =
        directDebitSessionData?.fleetsStartDirectDebitCheckoutSession?.checkoutUrl
    }
  }, [directDebitSessionData])

  const runJoyride = useMemo(
    (): boolean =>
      !userTour.paymentMethod &&
      !businessEntityData?.fleetsBusinessEntity.billingAccount?.paymentMethod,
    [userTour, businessEntityData],
  )

  const handleJoyRideCallback = ({ action }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      const existingUserTour: UserTour = { ...userTour, paymentMethod: true }
      setUserTour(existingUserTour)
    }
  }

  const getAccountHolder = useMemo(() => {
    const paymentMethodInfo =
      businessEntityData?.fleetsBusinessEntity?.billingAccount?.paymentMethod

    if (
      paymentMethodInfo?.paymentMethodType === 'STRIPE_DIRECT_DEBIT' &&
      // eslint-disable-next-line no-underscore-dangle
      paymentMethodInfo?.paymentMethodDetails.__typename === 'DirectDebitType'
    ) {
      return paymentMethodInfo?.paymentMethodDetails?.accountHolder
    }
    return undefined
  }, [businessEntityData?.fleetsBusinessEntity?.billingAccount?.paymentMethod])

  return (
    <>
      <ReactJoyride
        callback={handleJoyRideCallback}
        run={runJoyride}
        styles={{
          buttonNext: {
            display: 'none',
          },
        }}
        steps={tourSteps}
      />

      <Card id="payment-method">
        <Alert variant="info" className="mb-8">
          <p className="mb-2 text-sm font-medium !text-secondary">
            {t('payment_and_invoices.payment_method.alert.heading')}
          </p>

          <Typography className="text-sm text-secondary lg:text-base">
            {t('payment_and_invoices.payment_method.alert.body')}
          </Typography>
        </Alert>

        {!!directDebitSessionError && (
          <Alert variant="error" className="mb-4">
            {directDebitSessionError?.message}
          </Alert>
        )}

        {businessEntityLoading && (
          <div className={styles.card.root}>
            <div className={styles.card.info}>
              <div>
                <Skeleton variant="text" width={100} />
              </div>

              <div>
                <Skeleton variant="text" width={150} />
              </div>

              <div>
                <Skeleton variant="text" width={50} />
              </div>
            </div>

            <div className={styles.card.action}>
              <Skeleton variant="circular" width={190} height={40} />
            </div>
          </div>
        )}

        {!businessEntityLoading && (
          <div className={styles.card.root}>
            <div className={styles.card.info}>
              {businessEntityError && (
                <div className={styles.message.error}>
                  {t('payment_and_invoices.payment_method.error.could_not_fetch_payment_method')}
                </div>
              )}

              {businessEntityData?.fleetsBusinessEntity?.billingAccount?.paymentMethod && (
                <div className="flex-1 flex justify-between">
                  <Typography>{getAccountHolder} </Typography>
                  <Typography>
                    &nbsp;****
                    {
                      businessEntityData.fleetsBusinessEntity.billingAccount.paymentMethod
                        .paymentMethodDetails?.identifier
                    }
                    &nbsp; / &nbsp;
                    {sortCode}
                  </Typography>
                </div>
              )}

              {!businessEntityData?.fleetsBusinessEntity.billingAccount?.paymentMethod && (
                <div className={styles.message.lightGray}>
                  {t('payment_and_invoices.payment_method.no_direct_debit')}
                </div>
              )}
            </div>

            <div className={styles.card.action}>
              <Button
                disabled={!!directDebitSessionError}
                loading={directDebitSessionLoading || paymentInstructionLoading}
                onClick={handleDirectDebitSetup}
              >
                {t('payment_and_invoices.payment_method.button.set_up')}
              </Button>
            </div>
          </div>
        )}

        {!!paymentInstructionError && (
          <Typography className=" text-action-danger mt-4" variant="small">
            {t('payment_and_invoices.payment_method.error.error_setting_up_direct_debit')}
          </Typography>
        )}
      </Card>
    </>
  )
}
