import { MutationHookOptions, QueryResult, useQuery } from '@apollo/client'

import FLEETS_TAGS from '@electro/fleets/graphql/fleetsTagsQuery.graphql'
import { Query, QueryFleetsTagsArgs } from '@electro/fleets/generated/graphql'

export type FleetsTags = Pick<Query, 'fleetsTags'>

export type FleetsTagsReturnType = QueryResult<FleetsTags>

type Options = MutationHookOptions<FleetsTags, QueryFleetsTagsArgs>

export const useFleetsFetchTags = (options?: Options): FleetsTagsReturnType => {
  const fleetsTagsResults = useQuery<FleetsTags>(FLEETS_TAGS, options)

  return fleetsTagsResults
}
