import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

export interface DriverIndividualReceiptsSummaryProps {
  receiptCount: number
}

const styles = {
  root: 'md:col-span-2',
}

export const DriverIndividualReceiptsSummary = ({
  receiptCount,
}: DriverIndividualReceiptsSummaryProps) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.root} data-testid="driver-receipt-summary">
      <Typography variant="h2">
        <span className="font-medium" data-testid="driver-receipt-count">
          {receiptCount}
        </span>
        &nbsp; {t('common.results')}
      </Typography>
    </div>
  )
}
