import { Alert, Button, Card, Typography } from '@electro/shared-ui-components'
import { useFleetsFetchBusinessEntity } from '@electro/fleets/src/services'
import { WidgetSkeleton } from '@electro/fleets/src/skeletons'
import { useMount } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { InformationItem } from '@electro/fleets/src/components/BusinessOverview/components/InformationItem'
import { useRouter } from 'next/router'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useFleetsOnboarding } from '@electro/fleets/src/hooks'

export const BillingAddress = () => {
  const { t } = useTranslation('common')
  const [fetchBusinessEntity, { data, loading, error }] = useFleetsFetchBusinessEntity()
  const { businessEntityStatus } = useFleetsOnboarding()
  const router = useRouter()

  useMount(() => fetchBusinessEntity())

  if (loading) return <WidgetSkeleton rows={5} />

  const address1AndAddress2 = data?.fleetsBusinessEntity?.billingAccount?.billingDetails
    ?.billingAddressLine1
    ? `${data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressLine1}, ${data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressLine2}`
    : null

  return (
    <Card data-testid="billing-address-widget">
      {!!error && (
        <>
          <Typography variant="h3">{t('admin.billing_address.heading')}</Typography>
          <Alert variant="error" className="mt-4">
            <Typography variant="h3">{t('common.error.something_went_wrong')}</Typography>
            <Typography>{error?.message}</Typography>
          </Alert>
        </>
      )}

      {!!data && !error && (
        <>
          <div className="flex items-center space-x-2">
            <Typography variant="h3">{t('admin.billing_address.heading')}</Typography>

            {businessEntityStatus.hasPaymentMethod && (
              <Button
                data-testid="edit-billing-address-button"
                size="xs"
                variant="naked"
                onClick={() => router.push('/dashboard/admin/billing')}
              >
                <PencilIcon className="w-5 h-5" />
              </Button>
            )}
          </div>
          <div className="flex flex-col mt-4 gap-5">
            <InformationItem
              labelKey="common.name"
              value={data?.fleetsBusinessEntity?.billingAccount?.name}
            />
            <InformationItem
              labelKey="common.email"
              value={data?.fleetsBusinessEntity?.billingAccount?.email}
            />
            <InformationItem labelKey="common.address" value={address1AndAddress2} />
            <InformationItem
              labelKey="common.postcode"
              value={
                data?.fleetsBusinessEntity?.billingAccount?.billingDetails?.billingAddressPostcode
              }
            />
          </div>
        </>
      )}
    </Card>
  )
}
