import { Button, Input, Typography } from '@electro/shared-ui-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { useErrorMessage } from '@electro/fleets/src/hooks'

interface Fields {
  email: string
}

interface Props {
  onSubmit: (fields: Fields) => void
  loading?: boolean
  error?: ApolloError | null
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required')
    .nullable(),
})

export const MagicLinkForm = ({ onSubmit, loading = false, error }: Props) => {
  const errorMessage = useErrorMessage(error)

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ handleBlur, values, errors, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Input
            fullWidth
            placeholder="hello@octopus.energy"
            label="Work email address"
            name="email"
            value={values.email || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={errors.email || errorMessage}
            disabled={loading}
            required
          />

          <Button fullWidth type="submit" loading={loading}>
            Sign up
          </Button>

          <div className="mt-6">
            <Typography variant="small" as="p">
              By clicking sign up, you agree to Electroverse for Business&apos; &nbsp;
              <a
                href="https://electroverse.octopus.energy/legal/business/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://electroverse.octopus.energy/legal/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Typography>
          </div>
        </form>
      )}
    </Formik>
  )
}
