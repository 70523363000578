import { fireGtmEvent } from '@electro/shared/utils/fireGtmEvent'

enum GTM_EVENTS {
  SIGN_UP_COMPLETED = 'FLEETS::SIGN_UP_COMPLETED',
  EMAIL_VERIFIED = 'FLEETS::EMAIL_VERIFIED',
}

/** User has completed the initial sign up flow */
export const signupConversion = ({ companyName }: { companyName?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.SIGN_UP_COMPLETED,
    companyName,
  })

/** User has clicked the link in the verification email and verified their email address */
export const emailVerified = () =>
  fireGtmEvent({
    event: GTM_EVENTS.EMAIL_VERIFIED,
  })
