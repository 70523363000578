import { MutationHookOptions, MutationResult, gql, useMutation } from '@apollo/client'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

export const FLEETS_LOGOUT = gql`
  mutation fleetsLogout {
    fleetsLogout {
      success
    }
  }
`

interface FleetsLogoutMutation {
  success: boolean
}

type UseFleetsLogout = [
  ApolloMutationFunction<FleetsLogoutMutation>,
  MutationResult<FleetsLogoutMutation>,
]

type UseFleetsLogoutArgs = MutationHookOptions<FleetsLogoutMutation>

export function useFleetsLogoutMutation(options?: UseFleetsLogoutArgs): UseFleetsLogout {
  const [mutation, result] = useMutation<FleetsLogoutMutation>(FLEETS_LOGOUT, options)

  return [mutation, result]
}
