import FLEETS_ASSIGN_CARD_TO_DRIVER_MUTATION from '@electro/fleets/graphql/fleetsAssignCardToDriverMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsAssignCardToDriverArgs } from '@electro/fleets/generated/graphql'

type AssignCardToDriverMutation = Pick<Mutation, 'fleetsAssignCardToDriver'>

type UseFleetsAssignCardToDriver = [
  ApolloMutationFunction<AssignCardToDriverMutation>,
  MutationResult<AssignCardToDriverMutation>,
]

type UseFleetsAssignCardToDriverArgs = MutationHookOptions<
  AssignCardToDriverMutation,
  MutationFleetsAssignCardToDriverArgs
>

export function useFleetsAssignCardToDriver(
  options?: UseFleetsAssignCardToDriverArgs,
): UseFleetsAssignCardToDriver {
  const [reassignCardMutation, reassignCardResult] = useMutation<AssignCardToDriverMutation>(
    FLEETS_ASSIGN_CARD_TO_DRIVER_MUTATION,
    options,
  )

  return [reassignCardMutation, reassignCardResult]
}
