import { LazyQueryResult, OperationVariables } from '@apollo/client'
import { FleetsBusinessEntityReceiptsAggregateQuery } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { StatisticWidget } from '@electro/fleets/src/components/StatisticWidget'

interface DriverConsumptionReportSummaryProps {
  fleetsBusinessReceipts: LazyQueryResult<
    FleetsBusinessEntityReceiptsAggregateQuery,
    OperationVariables
  >
}

export const DriverConsumptionReportSummary = ({
  fleetsBusinessReceipts,
}: DriverConsumptionReportSummaryProps) => {
  const { t } = useTranslation('common')

  const businessHasHeadlineData =
    fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges.length > 0

  if (!businessHasHeadlineData) return null

  return (
    <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
      <StatisticWidget
        statHeading={t('dashboard.widgets.title.total_cost')}
        statData={`£${fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node?.totalCost}`}
        loading={fleetsBusinessReceipts.loading}
        error={fleetsBusinessReceipts.error}
        borderColour="tertiary"
      />
      <StatisticWidget
        statHeading={t('reporting.drivers_report.total_consumption')}
        statData={`${fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node?.totalConsumption} kWh`}
        loading={fleetsBusinessReceipts.loading}
        error={fleetsBusinessReceipts.error}
        borderColour="lightGrey"
      />
      <StatisticWidget
        statHeading={t('reporting.drivers_report.average_price_per_kwh')}
        statData={`${Math.ceil(
          (parseFloat(
            fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node
              ?.totalCost,
          ) *
            100) /
            parseFloat(
              fleetsBusinessReceipts.data?.fleetsBusinessEntityReceiptsAggregate.edges[0]?.node
                ?.totalConsumption,
            ),
        )} p/kWh`}
        loading={fleetsBusinessReceipts.loading}
        error={fleetsBusinessReceipts.error}
        borderColour="green"
      />
    </div>
  )
}
