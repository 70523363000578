import Link from 'next/link'
import { useMemo, useState } from 'react'
import { Button, Modal } from '@electro/shared-ui-components'
import { OrderElectrocardsForm } from '@electro/fleets/src/components/ElectrocardsList/components'
import { OnboardingStatus } from '@electro/fleets/src/components'
import { useFleetsCardOrdersQuery } from '@electro/fleets/generated/graphql'
import ReactJoyride, { ACTIONS, CallBackProps, Step } from 'react-joyride'
import { FLEETS_USER_TOUR } from '@electro/fleets/src/constants/localStorage'
import { UserTour } from '@electro/fleets/src/types/localStorage'
import { useLocalStorage, useMount } from 'react-use'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

interface OrderElectrocardsModalProps {
  show: boolean
}

export const OrderElectrocardsModal = ({ show }: OrderElectrocardsModalProps) => {
  const { t } = useTranslation('common')
  const tourSteps: Step[] = [
    {
      target: '#electrocardList',
      placement: 'top',
      content: t('onboarding_steps.electrocards.content'),
      disableBeacon: true,
      disableScrolling: true,
    },
  ]
  const { data: cardOrdersData } = useFleetsCardOrdersQuery()

  const [open, setOpen] = useState(!!show)
  const [userTour, setUserTour] = useLocalStorage<UserTour | undefined>(FLEETS_USER_TOUR, {})
  const router = useRouter()

  const runJoyride = useMemo(
    (): boolean =>
      !userTour.electrocardList && cardOrdersData?.fleetsCardOrders?.edges?.length === 0,
    [userTour, cardOrdersData],
  )

  const handleJoyRideCallback = ({ action }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      const existingUserTour: UserTour = { ...userTour, electrocardList: true }
      setUserTour(existingUserTour)
    }
  }

  useMount(() => {
    if (show) {
      router.replace('electrocards', undefined, { shallow: true })
    }
  })

  return (
    <div className="mt-4">
      <ReactJoyride
        callback={handleJoyRideCallback}
        run={runJoyride}
        styles={{
          buttonNext: {
            display: 'none',
          },
        }}
        steps={tourSteps}
      />

      <Button id="electrocardList" className="whitespace-nowrap" onClick={() => setOpen(true)}>
        {t('electrocards.order_card_modal.button.order_cards')}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.CloseButton />

        <Modal.Header>{t('electrocards.order_card_modal.order_electrocards')}</Modal.Header>

        <Modal.Body>
          <OnboardingStatus>
            <OnboardingStatus.Complete>
              <OrderElectrocardsForm>
                <OrderElectrocardsForm.Fields />
                <OrderElectrocardsForm.Review />
                <OrderElectrocardsForm.SubmitButton />
                <OrderElectrocardsForm.Success>
                  <div className="text-center">
                    <div className="font-medium mb-4">{t('common.success')}</div>

                    <div className="text-center mb-4">
                      {t('electrocards.order_card_modal.cards_ordered')}
                    </div>

                    <div className="text-center">
                      <div>{t('electrocards.order_card_modal.what_next')}</div>

                      <ol className="list-decimal space-y-2">
                        <li>{t('electrocards.order_card_modal.new_cards_unassigned')}</li>
                        <li>{t('electrocards.order_card_modal.arrive_in_3_to_5_days')}</li>
                        <li>{t('electrocards.order_card_modal.how_to_assign')}</li>
                      </ol>
                    </div>

                    <Button className="mt-8" fullWidth onClick={() => setOpen(false)}>
                      {t('common.button.done')}
                    </Button>
                  </div>
                </OrderElectrocardsForm.Success>
              </OrderElectrocardsForm>
            </OnboardingStatus.Complete>
            <OnboardingStatus.AddressRequired>
              <p>{t('electrocards.order_card_modal.need_delivery_address')}</p>
              <Link
                href="/dashboard/admin/group"
                className="no-underline inline-block text-base-dark hover:text-base-dark"
              >
                <Button>{t('electrocards.order_card_modal.button.add_delivery_address')}</Button>
              </Link>
            </OnboardingStatus.AddressRequired>
            <OnboardingStatus.PaymentRequired>
              <p>{t('electrocards.order_card_modal.need_delivery_address')}</p>
              <Link
                href="/dashboard/admin/billing"
                className="no-underline inline-block text-base-dark hover:text-base-dark"
              >
                <Button>{t('electrocards.order_card_modal.button.update_billing_details')}</Button>
              </Link>
            </OnboardingStatus.PaymentRequired>
          </OnboardingStatus>
        </Modal.Body>
      </Modal>
    </div>
  )
}
