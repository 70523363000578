import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'
import { Mutation, MutationFleetsUntagDriversArgs } from '@electro/fleets/generated/graphql'
import FLEETS_UNTAG_DRIVERS from '@electro/fleets/graphql/fleetsUntagDriversMutation.graphql'
import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

type FleetsUntagDriversMutation = Pick<Mutation, 'fleetsUntagDrivers'>

type FleetsUntagDriversReturnType = [
  ApolloMutationFunction<FleetsUntagDriversMutation>,
  MutationResult<FleetsUntagDriversMutation>,
]

type Options = MutationHookOptions<FleetsUntagDriversMutation, MutationFleetsUntagDriversArgs>

export const useFleetsUntagDrivers = (options?: Options): FleetsUntagDriversReturnType => {
  const [fleetsUntagDriversMutation, fleetsUntagDriversResult] = useMutation(
    FLEETS_UNTAG_DRIVERS,
    options,
  )

  return [fleetsUntagDriversMutation, fleetsUntagDriversResult]
}
