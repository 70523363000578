import { useEffect, useMemo, useState } from 'react'
import {
  FleetsAdminUsersQuery,
  FleetsBusinessEntityQuery,
  FleetsDriversQuery,
  FleetsDriversTokensQuery,
  useFleetsAdminUsersQuery,
  useFleetsBusinessEntityQuery,
  useFleetsDriversQuery,
  useFleetsCardOrdersQuery,
  FleetsCardOrdersQuery,
} from '@electro/fleets/generated/graphql'
import { isDemoMode } from '@electro/fleets/src/utils/envFlagCheck'

type CheckArgument =
  | FleetsBusinessEntityQuery
  | FleetsCardOrdersQuery
  | FleetsDriversTokensQuery
  | FleetsAdminUsersQuery

interface OnboardingStatusOperation {
  name: string
  check: (argument: CheckArgument) => boolean
}

interface BusinessEntityStatus {
  hasDeliveryAddress: boolean
  hasPaymentMethod: boolean
  success: boolean
}

interface DriverStatus {
  hasUploadDrivers: boolean
  success: boolean
}

interface ElectrocardsStatus {
  hasOrderElectrocards: boolean
  success: boolean
}

interface AdminUsersStatus {
  hasAddedAdminUsers: boolean
  success: boolean
}

export interface OnboardingReturnType {
  businessEntityStatus: BusinessEntityStatus
  driversStatus: DriverStatus
  electrocardsStatus: ElectrocardsStatus
  adminUsersStatus: AdminUsersStatus
  loading: boolean
  success: boolean
}

const businessEntityStatusOperations: OnboardingStatusOperation[] = [
  {
    name: 'hasDeliveryAddress',
    check: (businessEntity: FleetsBusinessEntityQuery) =>
      !!businessEntity?.fleetsBusinessEntity?.deliveryAddressLine1 &&
      !!businessEntity?.fleetsBusinessEntity?.deliveryPostcode,
  },
  {
    name: 'hasPaymentMethod',
    check: (businessEntity: FleetsBusinessEntityQuery) =>
      !!businessEntity?.fleetsBusinessEntity?.billingAccount?.paymentMethod,
  },
]

const driversStatusOperations: OnboardingStatusOperation[] = [
  {
    name: 'hasUploadDrivers',
    check: (drivers: FleetsDriversQuery) => !!drivers?.fleetsDrivers?.totalCount,
  },
]

const electrocardsStatusOperations: OnboardingStatusOperation[] = [
  {
    name: 'hasOrderElectrocards',
    check: (electrocardOrdersData: FleetsCardOrdersQuery) =>
      electrocardOrdersData?.fleetsCardOrders?.edges.length > 0,
  },
]

const adminUsersStatusOperations: OnboardingStatusOperation[] = [
  {
    name: 'hasAddedAdminUsers',
    check: (adminUsers: FleetsAdminUsersQuery) => !!adminUsers?.fleetsAdmins?.totalCount,
  },
]

export const useFleetsOnboarding = (): OnboardingReturnType => {
  const [businessEntityStatus, setBusinessEntityStatus] = useState<BusinessEntityStatus>({
    hasDeliveryAddress: false,
    hasPaymentMethod: false,
    success: false,
  })
  const [driversStatus, setDriverStatus] = useState<DriverStatus>({
    hasUploadDrivers: false,
    success: false,
  })
  const [electrocardsStatus, setElectrocardsStatus] = useState<ElectrocardsStatus>({
    hasOrderElectrocards: false,
    success: false,
  })
  const [adminUsersStatus, setAdminUsersStatus] = useState<AdminUsersStatus>({
    hasAddedAdminUsers: false,
    success: false,
  })

  const {
    data: businessEntityData,
    loading: businessEntityLoading,
    error: businessEntityError,
  } = useFleetsBusinessEntityQuery()
  const {
    data: driversData,
    loading: driversLoading,
    error: driversError,
  } = useFleetsDriversQuery({
    variables: { first: 20, offset: 0 },
    fetchPolicy: 'network-only',
  })
  const {
    data: electrocardOrdersData,
    loading: electrocardOrdersLoading,
    error: electrocardOrdersError,
  } = useFleetsCardOrdersQuery()
  const {
    data: adminUsersData,
    loading: adminUsersLoading,
    error: adminUsersError,
  } = useFleetsAdminUsersQuery({
    variables: { first: 20, offset: 0 },
  })

  /**
   * Keep track of business entity and if something updates
   * the onboarding business entity status will update
   */
  useEffect(() => {
    if (!businessEntityData || businessEntityError) return

    const newBusinessEntityStatus: BusinessEntityStatus = {
      hasDeliveryAddress: false,
      hasPaymentMethod: false,
      success: true,
    }

    businessEntityStatusOperations.forEach(({ name, check }) => {
      newBusinessEntityStatus[name] = check(businessEntityData)
    })

    setBusinessEntityStatus(newBusinessEntityStatus)
  }, [businessEntityData, businessEntityError])

  /**
   * Keep track of drivers and if a driver is updated or
   * added the onboarding drivers status also will update
   */
  useEffect(() => {
    if (!driversData || driversError) return

    const newDriverStatus: DriverStatus = { hasUploadDrivers: false, success: true }

    driversStatusOperations.forEach(({ name, check }) => {
      newDriverStatus[name] = check(driversData)
    })

    setDriverStatus(newDriverStatus)
  }, [driversData, driversError])

  /**
   * Keep track of electrocards and if a electrocard is updated or
   * added the onboarding electrocards status also will update
   */
  useEffect(() => {
    /**
     * HACK:
     * This is a temporary workaround to set the electrocard status to true
     * when in demo mode. This will need to be removed when BE returns
     * the stub data for the electrocard orders in demo.
     */
    if (isDemoMode) {
      setElectrocardsStatus({ hasOrderElectrocards: true, success: true })
      return
    }
    if (!electrocardOrdersData || electrocardOrdersError) return

    const newElectrocardsStatus: ElectrocardsStatus = {
      hasOrderElectrocards: false,
      success: true,
    }

    electrocardsStatusOperations.forEach(({ name, check }) => {
      newElectrocardsStatus[name] = check(electrocardOrdersData)
    })

    setElectrocardsStatus(newElectrocardsStatus)
  }, [electrocardOrdersData, electrocardOrdersError])

  /**
   * Keep track of admin users and if a user is updated or
   * added the onboarding users status also will update
   */
  useEffect(() => {
    if (!adminUsersData || adminUsersError) return

    const newAdminUsersStatus: AdminUsersStatus = { hasAddedAdminUsers: false, success: true }

    adminUsersStatusOperations.forEach(({ name, check }) => {
      newAdminUsersStatus[name] = check(adminUsersData)
    })

    setAdminUsersStatus(newAdminUsersStatus)
  }, [adminUsersData, adminUsersError])

  const loading = useMemo(
    () => businessEntityLoading || driversLoading || adminUsersLoading || electrocardOrdersLoading,
    [businessEntityLoading, driversLoading, adminUsersLoading, electrocardOrdersLoading],
  )

  const success = useMemo(
    () =>
      businessEntityStatus.success &&
      driversStatus.success &&
      electrocardsStatus.success &&
      adminUsersStatus.success,
    [businessEntityStatus, driversStatus, electrocardsStatus, adminUsersStatus],
  )

  return {
    businessEntityStatus,
    driversStatus,
    electrocardsStatus,
    adminUsersStatus,
    loading,
    success,
  }
}
