import { useDriverMenuActions } from '@electro/fleets/src/components/DriverActionsDropDownMenu/hooks'
import { Button, Modal } from '@electro/shared-ui-components'
import { ExclamationTriangleIcon, PauseIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

interface DeactivateDriverModalScreenProps {
  onClose: () => void
}

export const DeactivateDriverModalScreen = ({ onClose }: DeactivateDriverModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage }, { deactivateDriver, resetErrorMessage }] =
    useDriverMenuActions()
  const handleConfirmation = useCallback(() => {
    deactivateDriver({ onSuccess: onClose })
  }, [deactivateDriver, onClose])

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return (
    <>
      <Modal.Header>
        <div className="flex text-action-danger">
          <ExclamationTriangleIcon className="h-6 w-6 mr-2 -ml-2 text-action-danger " />
          {t('drivers.deactivate_driver.heading.confirm_deactivation')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg font-medium">{t('drivers.deactivate_driver.body.this_will')}</p>
        <ol>
          <li>{t('drivers.deactivate_driver.body.log_them_out')}</li>
          <li>{t('drivers.deactivate_driver.body.deactivate_electrocard')}</li>
        </ol>
        <p>{t('drivers.deactivate_driver.body.are_you_sure')}</p>
        {errorMessage ? <p className="text-action-danger mt-2">{errorMessage}</p> : null}
      </Modal.Body>
      <Modal.Actions center stacked>
        <Button
          fullWidth
          className="border-action-danger text-action-danger hover:bg-action-danger hover:text-white"
          variant="outline"
          onClick={handleConfirmation}
          disabled={loading}
        >
          <PauseIcon className="h-6 w-6 mr-2 -ml-2" />
          {loading
            ? t('admin.deactivate_admin_user_modal.button.loading.deactivating')
            : t('admin.deactivate_admin_user_modal.button.deactivate')}
        </Button>
        <Button disabled={loading} fullWidth variant="naked" onClick={onClose}>
          {t('admin.admin_user_modal.button.no_take_me_back')}
        </Button>
      </Modal.Actions>
    </>
  )
}
