import { ElementType, ReactNode, isValidElement, useMemo } from 'react'

import { Menu } from '@electro/shared-ui-components'

import {
  NoSymbolIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  LockClosedIcon,
  EnvelopeIcon,
  PauseIcon,
  PencilIcon,
  PlusIcon,
  ArrowsRightLeftIcon,
  PlayIcon,
} from '@heroicons/react/24/outline'
import { SpinnerIcon } from '@electro/fleets/src/icons'

import {
  DriverActionsProvider,
  useDriverMenuActions,
} from '@electro/fleets/src/components/DriverActionsDropDownMenu/hooks'

import {
  ModalScreensEnum,
  DriverActionsModal,
} from '@electro/fleets/src/components/DriverActionsDropDownMenu/components'
import { DriverType } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

interface DriverActionsDropDownMenuProps {
  driver: DriverType
  children: ReactNode
}

const DriverActionsDropDownMenuContainer = ({
  driver,
  children,
}: DriverActionsDropDownMenuProps) => (
  <DriverActionsProvider driver={driver}>
    <DriverActionsDropDownMenu>{children}</DriverActionsDropDownMenu>
  </DriverActionsProvider>
)

const DriverActionsDropDownMenu = ({ children }) => {
  const [{ driver, activeDriverMenu, activeModalScreen }, { closeDriverActionModal }] =
    useDriverMenuActions()

  const driverActionModalOpen = useMemo(
    () => activeDriverMenu === driver.pk,
    [activeDriverMenu, driver.pk],
  )

  return (
    <Menu>
      {children}
      <DriverActionsModal
        onClose={closeDriverActionModal}
        open={driverActionModalOpen}
        activeModalScreen={activeModalScreen}
      />
    </Menu>
  )
}

interface ButtonProps {
  children?: ReactNode
  as?: ElementType
  noPadding?: boolean
}

const Button = ({ as, noPadding, children }: ButtonProps) => {
  const [{ driver, loading }] = useDriverMenuActions()

  if (loading) return <SpinnerIcon aria-label="loading spinner" className="animate-spin w-6, h-6" />

  return (
    <Menu.Button
      data-testid={`driver-actions-dropdown-menu-${driver.pk}`}
      aria-label={`Driver options menu for ${driver.user.firstName} ${driver.user.lastName}`}
      disabled={false}
      as={as}
      noPadding={noPadding}
      chevron={false}
    >
      {isValidElement(children) ? children : <EllipsisHorizontalIcon className="w-6 h-6" />}
    </Menu.Button>
  )
}

const MenuItemList = ({ children }) => <Menu.MenuItemList>{children}</Menu.MenuItemList>

const EditDetails = () => {
  const { t } = useTranslation('common')
  const [{ driver }, { editDriverDetails }] = useDriverMenuActions()

  return (
    <Menu.MenuItem onSelect={editDriverDetails(driver.pk)}>
      <PencilIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.edit_details')}
    </Menu.MenuItem>
  )
}

const ResetPassword = () => {
  const { t } = useTranslation('common')
  const [, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      onSelect={showActionConfirmationModal(ModalScreensEnum.RESET_PASSWORD_MODAL_SCREEN)}
    >
      <LockClosedIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.reset_password')}
    </Menu.MenuItem>
  )
}

const ResendSignupEmail = () => {
  const { t } = useTranslation('common')
  const [{ driver }, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      disabled={driver.hasSignedUp}
      onSelect={showActionConfirmationModal(ModalScreensEnum.RESEND_SIGNUP_EMAIL_MODAL_SCREEN)}
    >
      <EnvelopeIcon className="w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.resend_signup_email')}
    </Menu.MenuItem>
  )
}

const ViewReceipts = () => {
  const { t } = useTranslation('common')
  const [, { viewDriverReceipts }] = useDriverMenuActions()

  return (
    <Menu.MenuItem onSelect={viewDriverReceipts}>
      <DocumentTextIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.view_receipts')}
    </Menu.MenuItem>
  )
}

const AssignCard = () => {
  const { t } = useTranslation('common')
  const [{ hasElectroCardAssigned }, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      disabled={hasElectroCardAssigned}
      onSelect={showActionConfirmationModal(ModalScreensEnum.ASSIGN_DRIVER_MODAL_SCREEN)}
    >
      <PlusIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.assign_card')}
    </Menu.MenuItem>
  )
}

const ReAssignCard = () => {
  const { t } = useTranslation('common')
  const [{ hasElectroCardAssigned }, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      disabled={!hasElectroCardAssigned}
      onSelect={showActionConfirmationModal(ModalScreensEnum.REASSIGN_DRIVER_MODAL_SCREEN)}
    >
      <ArrowsRightLeftIcon className="-mt-0.5 w-4 h-4 mr-1.5" />{' '}
      {t('drivers.widgets.driver_details.driver_actions.reassign_card')}
    </Menu.MenuItem>
  )
}

const DeactivateCard = () => {
  const { t } = useTranslation('common')
  const [{ hasElectroCardAssigned }, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      disabled={!hasElectroCardAssigned}
      onSelect={showActionConfirmationModal(ModalScreensEnum.DEACTIVATE_CARD_MODAL_SCREEN)}
    >
      <NoSymbolIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      {t('drivers.widgets.driver_details.driver_actions.deactivate_card')}
    </Menu.MenuItem>
  )
}

const DeactivateDriver = () => {
  const { t } = useTranslation('common')
  const [, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      onSelect={showActionConfirmationModal(ModalScreensEnum.DEACTIVATE_DRIVER_MODAL_SCREEN)}
    >
      <PauseIcon className="-mt-0.5 w-4 h-4 mr-1.5 text-action-danger" />
      <span className="text-action-danger">
        {t('drivers.widgets.driver_details.driver_actions.deactivate_driver')}
      </span>
    </Menu.MenuItem>
  )
}

const ReactivateDriver = () => {
  const { t } = useTranslation('common')
  const [, { showActionConfirmationModal }] = useDriverMenuActions()

  return (
    <Menu.MenuItem
      onSelect={showActionConfirmationModal(ModalScreensEnum.REACTIVATE_DRIVER_MODAL_SCREEN)}
    >
      <PlayIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
      <span>{t('drivers.widgets.driver_details.driver_actions.reactivate_driver')}</span>
    </Menu.MenuItem>
  )
}

DriverActionsDropDownMenuContainer.Button = Button
DriverActionsDropDownMenuContainer.MenuItemList = MenuItemList
DriverActionsDropDownMenuContainer.EditDetails = EditDetails
DriverActionsDropDownMenuContainer.ResetPassword = ResetPassword
DriverActionsDropDownMenuContainer.ResendSignupEmail = ResendSignupEmail
DriverActionsDropDownMenuContainer.ViewReceipts = ViewReceipts
DriverActionsDropDownMenuContainer.AssignCard = AssignCard
DriverActionsDropDownMenuContainer.DeactivateCard = DeactivateCard
DriverActionsDropDownMenuContainer.ReAssignCard = ReAssignCard
DriverActionsDropDownMenuContainer.DeactivateDriver = DeactivateDriver
DriverActionsDropDownMenuContainer.ReactivateDriver = ReactivateDriver

export { DriverActionsDropDownMenuContainer as DriverActionsDropDownMenu }
