import { Typography } from 'libs/shared-ui-components/src/lib/Typography'
import Link from 'next/link'

export const HavingProblems = () => (
  <div className="flex flex-col lg:flex-row justify-center items-center mt-5">
    <Typography variant="small" className="font-bold">
      Having problems?
    </Typography>
    <span className="hidden lg:block">&nbsp;</span>
    <Typography variant="small">
      Contact our team{' '}
      <Link data-testid="help-link" target="_blank" href="https://octopus.typeform.com/to/qgiqkFsr">
        here
      </Link>
    </Typography>
  </div>
)
