import { create } from 'zustand'
import { CountryCodeType } from '@electro/fleets/src/constants/countryCodes'

export interface BusinessEntity {
  companyName: string
  referralCode: string
  hasCharged: boolean
  registeredAt: string
  countryCode: CountryCodeType
  logo: string
  companyHouseNumber: string
  charityNumber: string
  companyType: string
  vatNumber: string
  onboardingFlags: {
    hasFetched: boolean
    hasPaymentMethod: boolean
    hasDeliveryAddress: boolean
    hasVerifiedEmail: boolean
  }
}

interface State {
  businessEntity: Partial<BusinessEntity>
  updateBusinessEntity: (user: BusinessEntity) => void
  resetBusinessEntity: () => void
}

const INITIAL_STATE: BusinessEntity = {
  companyName: null,
  referralCode: null,
  hasCharged: null,
  registeredAt: null,
  countryCode: null,
  logo: null,
  companyHouseNumber: null,
  charityNumber: null,
  companyType: null,
  vatNumber: null,
  onboardingFlags: {
    hasFetched: false,
    hasPaymentMethod: null,
    hasDeliveryAddress: null,
    hasVerifiedEmail: null,
  },
}

export const useBusinessEntityStore = create<State>((set) => ({
  businessEntity: INITIAL_STATE,
  updateBusinessEntity: (values: Partial<BusinessEntity>) =>
    set((state) => ({ businessEntity: { ...state.businessEntity, ...values } })),
  resetBusinessEntity: () => set(() => ({ businessEntity: INITIAL_STATE })),
}))
