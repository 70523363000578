import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client'
import FLEETS_DRIVERS_QUERY from '@electro/fleets/graphql/fleetsDriversQuery.graphql'

import { Query, QueryFleetsDriversArgs } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsDrivers = Pick<Query, 'fleetsDrivers'>

type UseFleetsFetchDrivers = [
  ApolloLazyQueryFunction<FetchFleetsDrivers>,
  LazyQueryResult<FetchFleetsDrivers, OperationVariables>,
]

type FetchFleetsDriversArgs = LazyQueryHookOptions<FetchFleetsDrivers, QueryFleetsDriversArgs>

export function useFleetsFetchDrivers(options?: FetchFleetsDriversArgs): UseFleetsFetchDrivers {
  const [lazyFetchFleetsDrivers, fleetsDriversResult] = useLazyQuery<FetchFleetsDrivers>(
    FLEETS_DRIVERS_QUERY,
    options,
  )

  return [lazyFetchFleetsDrivers, fleetsDriversResult]
}
