import FLEETS_EDIT_DRIVER_MUTATION from '@electro/fleets/graphql/fleetsEditDriverMutation.graphql'

import { Mutation, MutationFleetsEditDriverArgs } from '@electro/fleets/generated/graphql'
import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

export type FleetsEditDriver = Pick<Mutation, 'fleetsEditDriver'>

type UseFleetsEditDriver = [
  ApolloMutationFunction<FleetsEditDriver>,
  MutationResult<FleetsEditDriver>,
]

export function useFleetsEditDriver(): UseFleetsEditDriver {
  const [fleetsEditDriverMutation, fleetsEditDriverResult] = useMutation<
    FleetsEditDriver,
    MutationFleetsEditDriverArgs
  >(FLEETS_EDIT_DRIVER_MUTATION)

  return [fleetsEditDriverMutation, fleetsEditDriverResult]
}
