import FLEETS_SEND_RESET_PASSWORD_EMAIL_MUTATION from '@electro/fleets/graphql/fleetsSendResetPasswordEmailMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import {
  Mutation,
  MutationFleetsSendResetPasswordEmailArgs,
} from '@electro/fleets/generated/graphql'

type fleetsSendResetPasswordEmailsMutation = Pick<Mutation, 'fleetsSendResetPasswordEmail'>

type UseFleetsSendResetPasswordEmail = [
  ApolloMutationFunction<fleetsSendResetPasswordEmailsMutation>,
  MutationResult<fleetsSendResetPasswordEmailsMutation>,
]

type UseFleetsSendResetPasswordEmailArgs = MutationHookOptions<
  fleetsSendResetPasswordEmailsMutation,
  MutationFleetsSendResetPasswordEmailArgs
>

export function useFleetsSendResetPasswordEmail(
  options?: UseFleetsSendResetPasswordEmailArgs,
): UseFleetsSendResetPasswordEmail {
  const [resetPasswordMutation, resetPasswordResult] =
    useMutation<fleetsSendResetPasswordEmailsMutation>(
      FLEETS_SEND_RESET_PASSWORD_EMAIL_MUTATION,
      options,
    )

  return [resetPasswordMutation, resetPasswordResult]
}
