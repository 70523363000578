import { Button, Modal, Typography } from '@electro/shared-ui-components'
import Image from 'next/image'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useState } from 'react'
import { useDriverMenuActions } from '../../hooks'

interface ResetDriverPasswordModalScreenProps {
  onClose: () => void
}

export const ResetDriverPasswordModalScreen = ({
  onClose,
}: ResetDriverPasswordModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ driver, loading, errorMessage }, { sendResetDriverPasswordEmail, resetErrorMessage }] =
    useDriverMenuActions()

  const [emailSent, setEmailSent] = useState<boolean>(false)

  const handleReset = useCallback(
    () => sendResetDriverPasswordEmail({ onSuccess: () => setEmailSent(true) }),
    [sendResetDriverPasswordEmail],
  )

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return !emailSent ? (
    <>
      <Modal.Header>
        <Typography variant="h2">
          <Trans
            i18nKey="common:drivers.reset_driver_password.header"
            components={{
              driverFirstLastName: (
                <strong>{`${driver.user.firstName} ${driver.user.lastName}`}</strong>
              ),
            }}
          />
        </Typography>
      </Modal.Header>
      <Modal.Body data-testid="reset-driver-password-modal">
        <Typography variant="p">
          <Trans
            i18nKey="common:drivers.reset_driver_password.body"
            components={{
              driverEmail: <strong>{driver.user.email}</strong>,
            }}
          />
        </Typography>
        {errorMessage ? <p className="text-action-danger mt-2">{errorMessage}</p> : null}
      </Modal.Body>
      <Modal.Actions stacked>
        <Button fullWidth disabled={loading} onClick={handleReset}>
          {loading
            ? t('common.button.loading.sending')
            : t('drivers.reset_driver_password.button.send_reset_link')}
        </Button>
        <Button fullWidth disabled={loading} variant="outline" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  ) : (
    <>
      <Modal.Header>{t('drivers.reset_driver_password.success.heading')}</Modal.Header>
      <Modal.Body data-testid="reset-driver-password-modaal">
        <Image
          src="/images/mr-zap-checking-his-email.png"
          alt="illustration of mr zap and constantine checking their emails "
          width={218}
          height={278}
          className="m-auto mt-4 mb-8"
        />
        <Typography variant="p">
          <Trans
            i18nKey="common:drivers.reset_driver_password.success.body"
            components={{ driverEmail: <strong>{driver.user.email}</strong> }}
          />
        </Typography>
      </Modal.Body>
      <Modal.Actions stacked>
        <Button onClick={onClose} fullWidth>
          {t('common.button.close')}
        </Button>
      </Modal.Actions>
    </>
  )
}
