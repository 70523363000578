import { useEffect, useState } from 'react'

import { useFleetsFetchAdminUsers } from '@electro/fleets/src/services'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tag,
} from '@electro/shared-ui-components'
import { AdminUserActionsDropDownMenu } from '@electro/fleets/src/components'
import useTranslation from 'next-translate/useTranslation'

export const ManageUsers = () => {
  const { t } = useTranslation('common')
  const [fetchAdmins, { loading, data }] = useFleetsFetchAdminUsers({
    variables: { first: 500 },
  })
  const [showDeactivatedUsers, setShowDeactivatedUsers] = useState<boolean>(true)

  useEffect(() => {
    fetchAdmins()
  }, [fetchAdmins])

  const activeUsersSortedByEmail = data?.fleetsAdmins
    ? [...data.fleetsAdmins.edges]
        .filter((admin) => (showDeactivatedUsers ? true : admin.node.hasActiveAccount))
        .sort((a, b) => a.node.user.email.localeCompare(b.node.user.email))
        .sort((a, b) => Number(b.node.hasActiveAccount) - Number(a.node.hasActiveAccount))
    : []

  const hasSomeDeactivatedUsers = data?.fleetsAdmins?.edges?.some(
    (admin) => !admin.node.hasActiveAccount,
  )

  return (
    <div className="overflow-x-auto">
      {hasSomeDeactivatedUsers ? (
        <Button
          onClick={() => setShowDeactivatedUsers(!showDeactivatedUsers)}
          variant="naked"
          size="xs"
          className="mb-2"
        >
          {hasSomeDeactivatedUsers && showDeactivatedUsers
            ? t('admin.manage_users.hide_deactivated_users')
            : t('admin.manage_users.show_deactivated_users')}
        </Button>
      ) : null}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.email')}</TableCell>
            <TableCell>{t('common.status')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={6} className="text-center">
                {t('common.loading.loading_ellipsis')}
              </TableCell>
            </TableRow>
          )}

          {!loading && data && (
            <TableRow>
              <TableCell>{data?.fleetsCurrentAdmin?.user.email}</TableCell>
              <TableCell>
                <Tag colour="green" label={t('common.active')} className="min-w-24 w-fit" />
              </TableCell>
              <TableCell />
            </TableRow>
          )}

          {!loading &&
            data &&
            activeUsersSortedByEmail.map((edge) => (
              <TableRow key={edge.node.id}>
                <TableCell>{edge.node.user.email}</TableCell>
                <TableCell>
                  {edge.node.hasActiveAccount && edge.node.hasSignedUp && (
                    <Tag colour="green" label={t('common.active')} className="min-w-24 w-fit" />
                  )}
                  {!edge.node.hasActiveAccount && (
                    <Tag colour="red" label={t('common.deactivated')} className="min-w-24 w-fit" />
                  )}
                  {!edge.node.hasSignedUp && edge.node.hasActiveAccount && (
                    <Tag colour="orange" label={t('common.pending')} className="min-w-24 w-fit" />
                  )}
                </TableCell>
                <TableCell>
                  <AdminUserActionsDropDownMenu adminUser={edge.node} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}
