import { Card, Typography } from '@electro/shared-ui-components'
import { useDashboardSummary } from '@electro/fleets/src/components/DashboardSummary/hooks'
import { useFleetsSummaryStatsQuery } from '@electro/fleets/generated/graphql'
import { format } from 'date-fns'
import { Loader } from '@electro/fleets/src/components/DashboardSummary/components'
import { QUERY_DATE_FORMAT } from '@electro/shared/constants'
import Image from 'next/image'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

interface ChargerInfo {
  orderKey: number
  image: string
  alt: string
  title: string
  body: string
}

enum ChargerSpeedMapping {
  FAST = 'Slow',
  RAPID = 'Fast',
  SLOW = 'Rapid',
  ULTRA = 'Ultra',
}

const chargerMapper: Record<string, ChargerInfo> = {
  Slow: {
    orderKey: 1,
    image: '/images/key-speed-fast.png',
    alt: 'Slow charger icon',
    title: 'dashboard.widgets.chargers_used_by_speed.slow.title',
    body: '< 8kW',
  },
  Fast: {
    orderKey: 2,
    image: '/images/key-speed-rapid.png',
    alt: 'Fast charger icon',
    title: 'dashboard.widgets.chargers_used_by_speed.fast.title',
    body: '8kW - 50kW',
  },
  Rapid: {
    orderKey: 3,
    image: '/images/key-speed-ultra-rapid.png',
    alt: 'Rapid charger icon',
    title: 'dashboard.widgets.chargers_used_by_speed.rapid.title',
    body: '50kW - 150kW',
  },
  Ultra: {
    orderKey: 4,
    image: '/images/key-speed-ultra-rapid.png',
    alt: 'Ultra Rapid charger icon',
    title: 'dashboard.widgets.chargers_used_by_speed.ultra.title',
    body: '> 150kW',
  },
}

export const ChargersUsedBySpeed = () => {
  const { t } = useTranslation('common')
  const { startDate, endDate } = useDashboardSummary()
  const { data, loading } = useFleetsSummaryStatsQuery({
    variables: {
      startDate: format(startDate, QUERY_DATE_FORMAT),
      endDate: format(endDate, QUERY_DATE_FORMAT),
    },
  })

  const orderedChargerTypes = useMemo(() => {
    const chargerSpeedDataFromBackend = data?.fleetsSummaryStats?.chargerSpeedSplit

    let chargerSpeeds = Object.keys(chargerMapper).map((chargeMapperKey) => ({
      key: chargeMapperKey,
      ...chargerMapper[chargeMapperKey],
      percentage: 0,
    }))

    // Match the data from backend with local chargeMapper and add percentage if it exists
    if (chargerSpeedDataFromBackend) {
      chargerSpeeds = chargerSpeeds.map((charger) => {
        const backendChargers = chargerSpeedDataFromBackend.find(
          (backendCharger) => ChargerSpeedMapping[backendCharger.chargerSpeed] === charger.key,
        )
        if (backendChargers) {
          return { ...charger, percentage: backendChargers.percentage }
        }
        return charger
      })
    }
    const sortChargerTypesWithData = chargerSpeeds.sort((a, b) => a.orderKey - b.orderKey)
    return sortChargerTypesWithData
  }, [data])

  return (
    <Card density="high" frosted className="h-56 flex-1 flex flex-col p-6 justify-between">
      <div className="flex justify-between">
        <Typography variant="h6">{t('dashboard.widgets.chargers_used_by_speed.title')}</Typography>
        <Image
          src="/images/dashboardIcons/chargers_used_by_speed.svg"
          alt="Chargers used by speed iconIcon"
          width="25"
          height="25"
        />
      </div>

      <div className="mt-4 flex-1 flex overflow-auto">
        {loading && (
          <div className="flex-1 flex  justify-center items-center">
            <Loader />
          </div>
        )}

        {!loading && !!data && (
          <div className="flex flex-col gap-1">
            {orderedChargerTypes.map((chargerType) => {
              const chargerData = data?.fleetsSummaryStats?.chargerSpeedSplit?.find(
                (charger) => charger.chargerSpeed === chargerType.key.toUpperCase(),
              )
              const chargerPercentage = chargerData
                ? parseFloat((chargerData.percentage * 100).toFixed(2))
                : 0

              return (
                <div className="flex items-center" key={chargerType.orderKey}>
                  <Image alt={chargerType.alt} width="29" height="29" src={chargerType.image} />
                  <Typography variant="p" className="ml-2">
                    {`${chargerPercentage}% ${t(chargerType.title)} (${chargerType.body})`}
                  </Typography>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Card>
  )
}
