import { Alert, Card, Typography } from '@electro/shared-ui-components'
import { WidgetSkeleton } from '@electro/fleets/src/skeletons'
import { useFleetsFetchAdminUser } from '@electro/fleets/src/services'
import { useMedia, useMount } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { InformationItem } from '@electro/fleets/src/components/BusinessOverview/components/InformationItem'
import { useFleetsBusinessEntityQuery } from '@electro/fleets/generated/graphql'
import { BusinessType } from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'
import * as Sentry from '@sentry/nextjs'

const styles = {
  grid: {
    default: {
      root: 'grid grid-cols-1 mt-4 lg:gap-4 md:grid-flow-col grid-flow-row auto-rows-min relative',
      desktop: 'md:grid-cols-3 md:gap-1 md:grid-rows-3 justify-center items-center',
    },
    withCharity: 'md:grid-rows-4',
  },
  logo: {
    wrapper: {
      default:
        'md:grid-rows-subgrid md:row-span-3 md:relative absolute md:-top-6 lg:-top-9 md:right-0 -top-22 right-1 ',
      withCharity: 'md:row-span-4',
    },
    companyLogo: 'rounded-full m-auto md:m-0',
    fallbackLogo:
      'md:h-32 md:w-32 h-[90px] w-[90px] rounded-full flex items-center justify-center background-color: bg-secondary m-auto md:m-0',
  },
}

export const YourDetails = () => {
  const { t } = useTranslation('common')
  const { data: businessEntityData } = useFleetsBusinessEntityQuery()
  const [fetchAdminUser, { data, loading, error }] = useFleetsFetchAdminUser()
  const xsScreenMatch = useMedia('(max-width: 640px)')

  useMount(() => fetchAdminUser())

  if (loading) return <WidgetSkeleton rows={3} />

  const adminInitials = `${data?.fleetsCurrentAdmin?.user?.firstName?.charAt(
    0,
  )}${data?.fleetsCurrentAdmin?.user?.lastName?.charAt(0)}`

  const adminName = `${data?.fleetsCurrentAdmin?.user?.firstName} ${data?.fleetsCurrentAdmin?.user?.lastName}`
  const hasCompanyHouseAndCharityNumber =
    !!businessEntityData?.fleetsBusinessEntity?.companyHouseNumber &&
    !!businessEntityData?.fleetsBusinessEntity?.charityNumber

  function getFormattedBusinessType(businessType: string) {
    switch (businessType) {
      case BusinessType.LIMITED_COMPANY:
        return 'Limited Company'
      case BusinessType.SOLE_TRADER:
        return 'Sole Trader'
      case BusinessType.PARTNERSHIP:
        return 'Partnership'
      case BusinessType.CHARITY:
        return 'Charity'
      default:
        Sentry.captureException(`Unknown business type: ${businessType}`)
        return null
    }
  }

  return (
    <Card data-testid="your-details-widget">
      {!!error && (
        <>
          <Typography variant="h3">{t('admin.your_details.heading')}</Typography>

          <Alert variant="error" className="mt-4">
            <div className="mb-2 text-lg ">{t('common.error.something_went_wrong')}</div>
            {error?.message}
          </Alert>
        </>
      )}

      {!!data && !error && (
        <>
          <Typography variant="h3" className="my-8 md:my-0">
            {t('admin.your_details.heading')}
          </Typography>

          <section
            className={tw({
              [styles.grid.default.root]: true,
              [styles.grid.default.desktop]: true,
              [styles.grid.withCharity]: hasCompanyHouseAndCharityNumber,
            })}
          >
            <div
              className={tw({
                [styles.logo.wrapper.default]: true,
                [styles.logo.wrapper.withCharity]: hasCompanyHouseAndCharityNumber,
              })}
            >
              {businessEntityData?.fleetsBusinessEntity?.logo ? (
                <Image
                  src={businessEntityData?.fleetsBusinessEntity?.logo}
                  alt="company logo"
                  width={xsScreenMatch ? 90 : 125}
                  height={xsScreenMatch ? 90 : 125}
                  className={styles.logo.companyLogo}
                />
              ) : (
                <div className={styles.logo.fallbackLogo}>
                  <Typography variant="h2">{adminInitials}</Typography>
                </div>
              )}
            </div>

            <InformationItem labelKey="common.name" value={adminName} show columnNumber={2} />
            <InformationItem
              labelKey="common.email"
              value={data?.fleetsCurrentAdmin?.user.email}
              show
              columnNumber={2}
            />
            <InformationItem
              labelKey="admin.your_details.companies_house_number"
              value={businessEntityData?.fleetsBusinessEntity?.companyHouseNumber}
              show={!!businessEntityData?.fleetsBusinessEntity?.companyHouseNumber}
              columnNumber={2}
            />
            <InformationItem
              labelKey="admin.your_details.vat_number"
              value={businessEntityData?.fleetsBusinessEntity?.vatNumber}
              show={
                businessEntityData?.fleetsBusinessEntity?.vatNumber &&
                !businessEntityData?.fleetsBusinessEntity?.companyHouseNumber
              }
              columnNumber={2}
            />
            <InformationItem
              labelKey="admin.your_details.charities_commission_number"
              value={businessEntityData?.fleetsBusinessEntity?.charityNumber}
              show={!!businessEntityData?.fleetsBusinessEntity?.charityNumber}
              columnNumber={2}
            />
            <InformationItem
              labelKey="admin.your_details.business_type"
              value={getFormattedBusinessType(
                businessEntityData?.fleetsBusinessEntity?.companyType,
              )}
              show
              columnNumber={3}
            />
            <InformationItem
              labelKey="admin.your_details.company_name"
              value={businessEntityData?.fleetsBusinessEntity?.name}
              show
              columnNumber={3}
            />
            <InformationItem
              labelKey="admin.your_details.vat_number"
              value={businessEntityData?.fleetsBusinessEntity?.vatNumber}
              show={
                !!businessEntityData?.fleetsBusinessEntity?.vatNumber &&
                !!businessEntityData?.fleetsBusinessEntity?.companyHouseNumber
              }
              columnNumber={3}
            />
          </section>
        </>
      )}
    </Card>
  )
}
