import { FLEETS_ACCOUNT_ID } from '@electro/fleets/src/constants/localStorage'

export async function fetchReceiptsCsv({
  startDate,
  endDate,
  driverId,
}: {
  startDate: string
  endDate: string
  driverId: string
}): Promise<Blob> {
  const searchParams = new URLSearchParams({ start_date: startDate, end_date: endDate })
  const path = driverId ? `receipt_csv/${driverId}/` : `receipt_csv/`
  const accountId = window?.localStorage?.getItem(FLEETS_ACCOUNT_ID)
  const response = await fetch(`/api/rest/${path}?${searchParams}`, {
    method: 'GET',
    headers: {
      'X-Account-Id': accountId,
    },
  })

  if (response.status !== 200) throw new Error('Error fetching CSV')

  return response.blob()
}
