import { DataGrid } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

export const DataGridWithPagination = <T extends string>({ ...props }) => {
  const { t } = useTranslation('common')

  const paginationConfig = {
    previousButtonText: t('common.button.previous.label'),
    previousAriaLabel: t('common.button.previous.aria_label'),
    nextButtonText: t('common.button.next.label'),
    nextAriaLabel: t('common.button.next.aria_label'),
    ofText: t('common.of'),
    toText: t('common.to'),
  }

  return <DataGrid<T> paginationConfig={paginationConfig} {...props} />
}
