import { Modal, ModalProps } from '@electro/shared-ui-components'
import {
  DeactivateCardModalScreen,
  AssignElectroCardModalScreen,
  ReassignCardModalScreen,
  AssignVehicleModalScreen,
} from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components'
import { ModalScreensEnum } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/DriverWidgets.types'
import { useMemo } from 'react'
import { AssignVehicleToDriverSuccessModal } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/components/AssignVehicleSuccessModalScreen'

interface Props extends Partial<ModalProps> {
  onClose: () => void
  activeModalScreen: ModalScreensEnum
  modalProps?: Partial<ModalProps>
}

interface ModalScreenProps {
  content: JSX.Element
  variant?: ModalProps['variant']
}

const getModalOptions = (variant: ModalScreensEnum): ModalScreenProps => {
  const map = {
    [ModalScreensEnum.ASSIGN_ELECTROCARD_MODAL_SCREEN]: {
      content: <AssignElectroCardModalScreen />,
    },
    [ModalScreensEnum.DEACTIVATE_ELECTROCARD_MODAL_SCREEN]: {
      content: <DeactivateCardModalScreen />,
      variant: 'warning',
    },
    [ModalScreensEnum.REASSIGN_ELECTROCARD_MODAL_SCREEN]: {
      content: <ReassignCardModalScreen />,
    },
    [ModalScreensEnum.ASSIGN_VEHICLE_MODAL_SCREEN]: {
      content: <AssignVehicleModalScreen />,
    },
    [ModalScreensEnum.ASSIGN_VEHICLE_SUCCESS_MODAL_SCREEN]: {
      content: <AssignVehicleToDriverSuccessModal />,
    },
  }

  return map[variant] || null
}

export const DriverWidgetsModal = ({ activeModalScreen, onClose, ...props }: Props) => {
  const open = useMemo(() => !!activeModalScreen, [activeModalScreen])
  const elem = getModalOptions(activeModalScreen)
  return (
    <Modal
      size="md"
      open={open}
      onClose={onClose}
      variant={elem?.variant}
      {...props}
      dialogClassName="!overflow-visible"
    >
      <Modal.CloseButton />
      {elem?.content}
    </Modal>
  )
}
