import { SearchToolbar } from '@electro/shared-ui-components'
import { FilterToolbar } from '@electro/fleets/src/components/DriversListV2/components/FilterToolbar'

export const QuickSearchToolbar = ({ getDrivers }) => (
  <div className="flex gap-5 items-center">
    <div className="flex-1">
      <SearchToolbar />
    </div>
    <div className="flex gap-3 mb-3 items-center">
      <FilterToolbar getDrivers={getDrivers} />
    </div>
  </div>
)
