import FLEETS_SEND_RESET_PASSWORD_EMAIL_MUTATION from '@electro/fleets/graphql/quickAuthTokenMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationQuickAuthArgs } from '@electro/fleets/generated/graphql'

type SsoLoginsMutation = Pick<Mutation, 'quickAuth'>

type UseSsoLogin = [ApolloMutationFunction<SsoLoginsMutation>, MutationResult<SsoLoginsMutation>]

type UseSsoLoginArgs = MutationHookOptions<SsoLoginsMutation, MutationQuickAuthArgs>

export function useSsoLogin(options?: UseSsoLoginArgs): UseSsoLogin {
  const [ssoLoginMutation, ssoLoginResult] = useMutation<SsoLoginsMutation>(
    FLEETS_SEND_RESET_PASSWORD_EMAIL_MUTATION,
    options,
  )

  return [ssoLoginMutation, ssoLoginResult]
}
