import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { Modal, Typography, Button } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'

interface OrderMoreElectroCardsModalProps {
  show: boolean
}

export const OrderMoreElectroCardsModal = ({ show }: OrderMoreElectroCardsModalProps) => {
  const router = useRouter()
  return show ? (
    <Modal.Body>
      <div className="flex flex-col items-center">
        <AnimatedComponent
          name="multiple-cards-animation"
          getAnimationData={ANIMATIONS.multipleCards}
        />
        <Typography className="text-center mt-5">
          You need to order some Electrocards before you can assign them!
        </Typography>
        <Modal.Actions stacked>
          <Button
            fullWidth
            variant="outline"
            className="mt-5"
            onClick={() => {
              router.push({
                pathname: '/dashboard/electrocards',
                query: { openOrderModal: true },
              })
            }}
          >
            Order Electrocards
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Body>
  ) : null
}
