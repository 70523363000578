import { useEffect } from 'react'
import { useFleetsOnboarding, OnboardingReturnType } from '@electro/fleets/src/hooks'
import { useRouter } from 'next/router'

const Loader = () => (
  <div
    className="h-full flex items-center justify-center relative px-2"
    data-testid="spinner-loading"
  >
    <div className="w-12 h-12 rounded-full absolute border-4 border-solid border-primary border-opacity-30" />
    <div className="w-12 h-12 rounded-full absolute border-4 border-solid border-primary animate-spin border-t-transparent" />
  </div>
)

const onboardingPermissions = [
  {
    pathname: 'drivers',
    check: ({ businessEntityStatus }: OnboardingReturnType) =>
      businessEntityStatus.hasDeliveryAddress && businessEntityStatus.hasPaymentMethod,
  },
  {
    pathname: 'vehicles',
    check: ({ businessEntityStatus }: OnboardingReturnType) =>
      businessEntityStatus.hasDeliveryAddress && businessEntityStatus.hasPaymentMethod,
  },
  {
    pathname: 'electrocards',
    check: ({ businessEntityStatus, driversStatus }: OnboardingReturnType) =>
      businessEntityStatus.hasDeliveryAddress &&
      businessEntityStatus.hasPaymentMethod &&
      driversStatus.hasUploadDrivers,
  },
  {
    pathname: 'payment-and-invoices',
    check: ({ businessEntityStatus }: OnboardingReturnType) =>
      businessEntityStatus.hasDeliveryAddress,
  },
]

export const OnboardingPermission = ({ children }) => {
  const router = useRouter()
  const onboarding = useFleetsOnboarding()

  /**
   * We track the current page user is navigating and
   * we check to see if has completed the onboarding
   * for that specific page if not we redirect them
   */
  useEffect(() => {
    if (onboarding.success) {
      onboardingPermissions.forEach(({ pathname, check }) => {
        if (router.pathname.includes(pathname)) {
          const hasPermission = check(onboarding)

          if (!hasPermission) router.push('/dashboard')
        }
      })
    }
  }, [router, onboarding])

  if (onboarding.loading) return <Loader />

  return children
}
