import {
  DeliveryAddress,
  YourDetails,
  BillingAddress,
} from '@electro/fleets/src/components/BusinessOverview/components'

export const BusinessOverview = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 gap-4">
    <section className="col-span-1 md:col-span-2">
      <YourDetails />
    </section>
    <DeliveryAddress />
    <BillingAddress />
  </div>
)
