import { useEffect, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import UAParser from 'ua-parser-js'

import { LoudHailerIcon } from '@electro/fleets/src/icons'

const FEEDBACK_URL = 'https://octopus.typeform.com/to/ipt8n4Uo'

export const FeedbackButton = () => {
  const [feedbackUrl, setFeedbackUrl] = useState<string>('')
  useEffect(() => {
    const ua = new UAParser(window.navigator.userAgent)

    const device = ua.getDevice().type
      ? `${ua.getDevice().model} ${ua.getDevice().type} ${ua.getDevice().vendor}`
      : 'Probably a desktop/laptop computer'

    const typeFormParams = {
      browser: `${ua.getBrowser().name} ${ua.getBrowser().version} ${ua.getEngine().name}`,
      os: `${ua.getOS().name} ${ua.getOS().version}`,
      device,
    }
    const searchParams = new URLSearchParams(typeFormParams).toString()
    setFeedbackUrl(`${FEEDBACK_URL}?${searchParams}`)
  }, [])

  return (
    <a
      href={feedbackUrl}
      target="_blank"
      rel="noreferrer"
      className={tw(
        'bg-info-light shadow-md rounded-full flex items-center font-medium no-underline',
        'lg:relative lg:left-2 lg:p-3 lg:translate-x-0 lg:bottom-3 lg:w-fit lg:m-auto',
        'fixed bottom-2 right-0 -translate-x-1/2 z-10 p-4',
        'text-info-dark hover:text-octo-primary-pink active:text-octo-primary-pink focus:text-octo-primary-pink',
      )}
    >
      <LoudHailerIcon className="w-6 h-6 sm:mr-2" />

      <span className="sr-only sm:not-sr-only">
        What are your thoughts? &nbsp; Give us feedback!
      </span>
    </a>
  )
}
