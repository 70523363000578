import { createContext, useContext, useState } from 'react'

interface DashboardSummaryReturnType {
  startDate: Date
  endDate: Date
  handleSelectedDate: (date: Date) => void
}

export const DashboardSummaryContext = createContext<DashboardSummaryReturnType>(null)

const useDashboardSummaryProvider = (): DashboardSummaryReturnType => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const [startDate, setStartDate] = useState(new Date(currentYear, currentMonth, 1))
  const [endDate, setEndDate] = useState(new Date(currentYear, currentMonth + 1, 0))

  const handleSelectedDate = (date: Date) => {
    const startDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const endDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    setStartDate(startDay)
    setEndDate(endDay)
  }

  return { startDate, endDate, handleSelectedDate }
}

export const DashboardSummaryProvider = ({ children }) => {
  const value = useDashboardSummaryProvider()

  return (
    <DashboardSummaryContext.Provider value={value}>{children}</DashboardSummaryContext.Provider>
  )
}

export const useDashboardSummary = () => {
  const context = useContext(DashboardSummaryContext)

  if (!context)
    throw new Error('useDashboardSummary() cannot be used outside of <DashboardSummaryProvider/>')
  return context
}
