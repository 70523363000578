import { DriverTypeOrderingFields, OrderingDirectionEnum } from '@electro/fleets/generated/graphql'
import { QueryKeyEnum } from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { useQueryParams } from '@electro/fleets/src/hooks/useQueryParams'

export const useDriversQueryParams = () =>
  useQueryParams<DriverTypeOrderingFields>(QueryKeyEnum.DriverPage, {
    initialState: {
      first: 50,
      ordering: [
        {
          orderBy: DriverTypeOrderingFields.Name,
          direction: OrderingDirectionEnum.Asc,
        },
      ],
      additionalParams: { isActive: 'true' },
    },
  })
