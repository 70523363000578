import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as Sentry from '@sentry/nextjs'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Alert, Button, Input, Typography } from '@electro/shared-ui-components'
import { companiesHouseNumberValidator } from '@electro/shared/utils/validators'

import { useRouter } from 'next/router'
import { formatCompaniesHouseNumber } from '@electro/shared/utils/formatters'
import {
  useFleetsCompleteFleetSignupMutation,
  useFleetsRetrieveCompanyHouseInfoMutation,
} from '@electro/fleets/generated/graphql'
import { useDebounce, useMedia } from 'react-use'
import electroTheme from '@electro/shared/theme/electro'
import { useState } from 'react'
import { FleetsForBusinessInfo } from '@electro/fleets/src/components'
import { SIGN_UP_TOKEN } from '@electro/fleets/src/constants/urlParams'
import { useErrorMessage } from '@electro/fleets/src/hooks'
import {
  SignUpFormExistingUserProps,
  SignUpFormExistingUserFieldValues,
  SignUpFormExistingUserFieldNamesEnum,
} from './SignUpFormExistingUser.types'

const { FIRST_NAME, LAST_NAME, COMPANY_NAME, COMPANIES_HOUSE_NUMBER, COMPANIES_HOUSE_IS_VALID } =
  SignUpFormExistingUserFieldNamesEnum

const validationSchema = Yup.object().shape({
  [FIRST_NAME]: Yup.string().required('First name is required'),
  [LAST_NAME]: Yup.string().required('Last name is required'),
  [COMPANY_NAME]: Yup.string().required('You need to tell us your company name'),
  [COMPANIES_HOUSE_NUMBER]: Yup.string()
    .required('We need to check that you are a business!')
    .test(
      COMPANIES_HOUSE_NUMBER,
      'Please enter a valid company house number',
      companiesHouseNumberValidator,
    ),
  [COMPANIES_HOUSE_IS_VALID]: Yup.boolean().oneOf([true], 'Company house number need validation'),
})

const INITIAL_FORM_VALUES = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [COMPANY_NAME]: '',
  [COMPANIES_HOUSE_NUMBER]: '',
  [COMPANIES_HOUSE_IS_VALID]: false,
}

const styles = {
  alert: {
    root: 'flex-1 py-1 lg:p-1 px-2 text-sm border rounded-none rounded-b-md z-10 -mt-4',
  },
  formDesktop: 'flex-1',
  parentCard: {
    desktop: 'lg:flex lg:flex-row lg:items-center lg:space-x-14 max-w-6xl',
    mobile: 'flex items-center flex-col-reverse max-w-6xl ',
  },
  button: {
    visible: 'mt-10 text-center',
    hidden: 'hidden',
  },
}

const Loader = () => (
  <div className="absolute top-7 right-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="animate-spin text-white/20 fill-primary m-1"
    >
      <g>
        <path
          d="M24 12.1416C24 18.769 18.6274 24.1416 12 24.1416C5.37259 24.1416 0 18.769 0 12.1416C0 5.51418 5.37259 0.141602 12 0.141602C18.6274 0.141602 24 5.51418 24 12.1416ZM2.17955 12.1416C2.17955 17.5653 6.57631 21.962 12 21.962C17.4237 21.962 21.8205 17.5653 21.8205 12.1416C21.8205 6.7179 17.4237 2.32114 12 2.32114C6.57631 2.32114 2.17955 6.7179 2.17955 12.1416Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M22.5523 9.36962C23.1344 9.21672 23.4871 8.61859 23.282 8.05274C22.8705 6.91725 22.2892 5.84841 21.5561 4.88332C20.603 3.62841 19.4119 2.57352 18.0511 1.7789C16.6902 0.984271 15.1862 0.465465 13.6249 0.252102C12.4241 0.0880147 11.2075 0.107047 10.0164 0.3067C9.42283 0.406192 9.07523 1.00727 9.22814 1.58939C9.38107 2.1715 9.97677 2.51301 10.5722 2.42551C11.4844 2.29146 12.4127 2.28626 13.3298 2.41159C14.6075 2.58619 15.8384 3.01077 16.9521 3.66105C18.0658 4.31136 19.0404 5.17466 19.8205 6.20164C20.3803 6.93871 20.832 7.74972 21.1636 8.61C21.38 9.1716 21.9702 9.52255 22.5523 9.36962Z"
          fill="#F050F8"
        />
      </g>
    </svg>
  </div>
)

export const SignUpFormExistingUser = ({ onSuccess }: SignUpFormExistingUserProps) => {
  const { query } = useRouter()
  const token = query?.[SIGN_UP_TOKEN] as string
  const [submitCount, setSubmitCount] = useState(0)
  const mobileScreenMatch = useMedia(`(min-width: ${electroTheme.screens.md})`)
  const [
    completeFleetsSignup,
    { error: completeFleetsSignupError, loading: completeFleetsSignupLoading },
  ] = useFleetsCompleteFleetSignupMutation()
  const [
    getCompaniesHouseInfo,
    { data: companyInfoData, loading: companyInfoLoading, error: companyInfoError },
  ] = useFleetsRetrieveCompanyHouseInfoMutation()
  const completeFleetsSignupErrorMessage = useErrorMessage(completeFleetsSignupError)
  const companyInfoErrorMessage = useErrorMessage(companyInfoError)

  const onSubmit = async (fieldValues: SignUpFormExistingUserFieldValues) => {
    try {
      await completeFleetsSignup({
        variables: {
          firstName: fieldValues[FIRST_NAME],
          lastName: fieldValues[LAST_NAME],
          companyName: fieldValues[COMPANY_NAME],
          companyHouseNumber: fieldValues[COMPANIES_HOUSE_NUMBER],
          signupToken: token,
        },
      })

      onSuccess?.()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const { handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, setFieldValue } =
    useFormik({
      initialValues: INITIAL_FORM_VALUES,
      validationSchema,
      onSubmit,
      validateOnChange: submitCount > 0,
      validateOnBlur: submitCount > 0,
    })

  const handleCompaniesHouseNumberChange = (event) => {
    const formattedCompaniesHouseNumber = formatCompaniesHouseNumber(event.target.value)

    setFieldValue(COMPANIES_HOUSE_NUMBER, formattedCompaniesHouseNumber)
  }

  const handleSubmitClick = () => setSubmitCount((oldCount) => oldCount + 1)

  useDebounce(
    async () => {
      const companiesHouseNumber = values[COMPANIES_HOUSE_NUMBER]
      const companiesHouseNumberIsValid = companiesHouseNumberValidator(companiesHouseNumber)

      if (companiesHouseNumberIsValid) {
        try {
          await getCompaniesHouseInfo({
            variables: {
              companyHouseNumber: companiesHouseNumber,
              signupToken: token,
            },
          })

          setFieldValue(COMPANIES_HOUSE_IS_VALID, true)
        } catch (err) {
          setFieldValue(COMPANIES_HOUSE_IS_VALID, false)
          Sentry.captureException(err)
        }
      }
    },
    400,
    [values[COMPANIES_HOUSE_NUMBER], token],
  )

  function handleScrollToForm() {
    const form = document.getElementById('signup-form')
    form.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div
      className={tw({
        [styles.parentCard.desktop]: !mobileScreenMatch,
        [styles.parentCard.mobile]: mobileScreenMatch,
      })}
    >
      <form className="flex-5" onSubmit={handleSubmit}>
        {completeFleetsSignupErrorMessage && (
          <Alert variant="error" className="mb-4">
            {completeFleetsSignupErrorMessage}
          </Alert>
        )}

        <div className="sm:grid sm:grid-cols-2 sm:gap-8">
          <Input
            placeholder="E.g. John"
            disabled={isSubmitting}
            fullWidth
            label="First name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[FIRST_NAME]}
            errorMessage={errors[FIRST_NAME]}
            name={FIRST_NAME}
          />
          <Input
            placeholder="E.g. Smith"
            disabled={isSubmitting}
            fullWidth
            label="Last name"
            type="text"
            onChange={handleChange}
            value={values[LAST_NAME]}
            errorMessage={errors[LAST_NAME]}
            name={LAST_NAME}
          />
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex-1 mb-1">
            <div id="signup-form" className="relative">
              <Input
                className="flex-1"
                placeholder="E.g. 09263424"
                disabled={isSubmitting}
                fullWidth
                label="UK Companies House Number"
                type="text"
                onChange={handleCompaniesHouseNumberChange}
                maxLength={8}
                value={values[COMPANIES_HOUSE_NUMBER]}
                errorMessage={errors[COMPANIES_HOUSE_NUMBER]}
                name={COMPANIES_HOUSE_NUMBER}
              />

              {companyInfoLoading && <Loader />}
            </div>

            {values[COMPANIES_HOUSE_NUMBER].length >= 7 && !errors[COMPANIES_HOUSE_NUMBER] ? (
              <div className="px-2">
                {companyInfoErrorMessage && (
                  <Alert variant="error" className={styles.alert.root}>
                    {companyInfoErrorMessage}
                  </Alert>
                )}

                {companyInfoData?.fleetsRetrieveCompanyHouseInfo && (
                  <Alert variant="success" className={styles.alert.root}>
                    Number is valid, company found with name: &nbsp;
                    <span className="font-medium">
                      {
                        companyInfoData?.fleetsRetrieveCompanyHouseInfo?.companyHouseInfo
                          ?.companyName
                      }
                    </span>
                  </Alert>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <Input
          className="flex-1"
          placeholder="E.g. Octopus Energy"
          disabled={isSubmitting}
          fullWidth
          label="Company name"
          type="text"
          onChange={handleChange}
          value={values[COMPANY_NAME]}
          errorMessage={errors[COMPANY_NAME]}
          name={COMPANY_NAME}
        />

        <div className="mb-12">
          <Typography variant="small" as="p">
            By clicking sign up, you agree to Electroverse for Business&apos; &nbsp;
            <a
              href="https://electroverse.octopus.energy/legal/business/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms &amp; Conditions
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://electroverse.octopus.energy/legal/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Typography>
        </div>

        <Button
          fullWidth
          type="submit"
          onClick={handleSubmitClick}
          loading={completeFleetsSignupLoading}
          disabled={completeFleetsSignupLoading}
        >
          Sign up
        </Button>
        <Typography
          className={tw({
            [styles.button.visible]: mobileScreenMatch,
            [styles.button.hidden]: !mobileScreenMatch,
          })}
        >
          Struggling to sign-up? Click
          <a href="https://octopus.typeform.com/to/bb9myDlj">here</a>
        </Typography>
      </form>

      <hr className="border-secondary/50 border-t-2 my-12" />

      <div className="flex-3 min-w-[360px]">
        <FleetsForBusinessInfo />
        <Button
          fullWidth
          onClick={handleScrollToForm}
          type="button"
          className={tw({
            [styles.button.visible]: mobileScreenMatch,
            [styles.button.hidden]: !mobileScreenMatch,
          })}
        >
          Let&apos;s Go
        </Button>
        <Typography
          className={tw({
            [styles.button.visible]: !mobileScreenMatch,
            [styles.button.hidden]: mobileScreenMatch,
          })}
        >
          Struggling to sign-up? Click&nbsp;
          <a href="https://octopus.typeform.com/to/bb9myDlj">here</a>
        </Typography>
      </div>
    </div>
  )
}
