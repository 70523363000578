import { useState } from 'react'

import {
  Button,
  Card,
  Input,
  TagColours,
  Typography,
  tagStyles,
} from '@electro/shared-ui-components'
import { EllipsisHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
  tagColors,
  EditTagProvider,
  useEditTag,
  tagColourNames,
} from '@electro/fleets/src/components/TagsManager/components/EditTagForm/hooks'
import { tw } from '@electro/shared/utils/tailwind-merge'
import capitalize from '@electro/shared/utils/capitalize'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import useTranslation from 'next-translate/useTranslation'

enum EditTagFormNames {
  NAME = 'name',
  COLOUR = 'colour',
}

const { NAME, COLOUR } = EditTagFormNames

export interface EditTagFormFields {
  [NAME]: string
  [COLOUR]: string
}

export const styles = {
  root: 'p-2 w-60',
  colors: {
    container: 'mt-2',
    title: 'text-sm text-tertiary-shade mb-2',
    button: {
      root: tw(
        'px-2 py-1 flex items-center rounded w-full transition-all text-sm',
        'border-base hover:bg-secondary',
      ),
      active: 'bg-secondary ring-secondary hover:ring-0 hover-border-secondary',
      inactive: 'border-white hover:border-tertiary',
    },
    previewWrapper: 'bg-white rounded mr-3',
    preview: 'h-5 w-5 rounded border-white',
  },
  submit: 'mt-4 flex space-x-2',
}

const EditTagForm = () => {
  const { t } = useTranslation('common')
  const [editTagRef, setEditTagRef] = useState<Element>(null)
  const [editTagPopperRef, setEditTagPopperRef] = useState<HTMLElement>(null)
  const { styles: editTagPopperStyles, attributes: editTagPopperAttr } = usePopper(
    editTagRef,
    editTagPopperRef,
    { placement: 'right-start' },
  )

  const { oldValues, formik, tagPk, deleteTagMutation, deleteTagLoading, editTagLoading } =
    useEditTag()

  const deleteTagHandler = () => {
    deleteTagMutation(tagPk)
  }

  const changeTagColorHandler = (color: TagColours) => formik.setFieldValue('colour', color)

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div" data-testid="popover-button-dots-horizontal" ref={setEditTagRef}>
            <Button
              size="xs"
              variant="naked"
              className="hover:bg-secondary"
              style={{ borderRadius: '4px', padding: '2px 4px' }}
            >
              <EllipsisHorizontalIcon className="h-5 w-5 " />
            </Button>
          </Popover.Button>

          <Popover.Panel
            className="absolute z-20 translate-x-7 -translate-y-7"
            ref={setEditTagPopperRef}
            style={editTagPopperStyles.popper}
            {...editTagPopperAttr.popper}
          >
            <Card className={styles.root} data-testid="edit-tag-container">
              <form onSubmit={formik.handleSubmit}>
                <Input
                  fullWidth
                  name="name"
                  value={formik.values[NAME]}
                  onChange={formik.handleChange}
                  errorMessage={t(formik.errors[NAME])}
                />

                <Button
                  fullWidth
                  variant="naked"
                  size="xs"
                  disabled={deleteTagLoading}
                  style={{ justifyContent: 'flex-start' }}
                  onClick={deleteTagHandler}
                >
                  <TrashIcon className="h-5 w-5 mr-2" />
                  {deleteTagLoading ? t('common.button.loading.delete') : t('common.button.delete')}
                </Button>

                <div className={styles.colors.container}>
                  <div className={styles.colors.title}>{t('tags.heading.colours')}</div>

                  <div>
                    {tagColors.map((color, index) => (
                      <button
                        type="button"
                        key={color}
                        className={tw({
                          [styles.colors.button.root]: true,
                          [styles.colors.button.active]: color === formik.values[COLOUR],
                          [styles.colors.button.inactive]: color !== formik.values[COLOUR],
                        })}
                        onClick={() => changeTagColorHandler(color)}
                      >
                        <div className={styles.colors.previewWrapper}>
                          <div className={tw(styles.colors.preview, tagStyles.colour[color])} />
                        </div>
                        <Typography variant="small">
                          {capitalize(t(tagColourNames[index]))}
                        </Typography>
                      </button>
                    ))}
                  </div>
                </div>

                <div className={styles.submit}>
                  <Button fullWidth size="xs" variant="naked" onClick={close}>
                    {t('common.button.cancel')}
                  </Button>

                  <Button
                    fullWidth
                    size="xs"
                    type="submit"
                    disabled={oldValues === JSON.stringify(formik.values) || editTagLoading}
                  >
                    {editTagLoading ? t('common.loading.button.save') : t('common.button.save')}
                  </Button>
                </div>
              </form>
            </Card>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

interface ProviderProps {
  tagPk: number
  formFields?: EditTagFormFields
}

const EditTagFormWithProvider = ({ tagPk, formFields }: ProviderProps) => (
  <EditTagProvider formFields={formFields} tagPk={tagPk}>
    <EditTagForm />
  </EditTagProvider>
)

export { EditTagFormWithProvider as EditTagForm }
