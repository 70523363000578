import * as Yup from 'yup'

import {
  charityNumberValidator,
  companiesHouseNumberValidator,
  ukVATNumberValidator,
  websiteUrlRegex,
} from '@electro/shared/utils/validators'
import {
  SignUpFormFieldsEnum,
  BusinessType,
} from '@electro/fleets/src/components/BusinessOnboardingForm/hooks'

const { COMPANY_NAME, COMPANIES_HOUSE_NUMBER, VAT_NUMBER, WEBSITE, CHARITIES_COMMISSION_NUMBER } =
  SignUpFormFieldsEnum

export const COMPANIES_HOUSE_LOOKUP_COMPLETED = 'companies-house-number-valid'
export const CHARITY_LOOKUP_COMPLETED = 'charity-number-valid'
export const VAT_LOOKUP_COMPLETED = 'vat-number-valid'

const optionalCompanyHouseValidationSchema = Yup.string().when((value) => {
  if (value) {
    return (
      Yup.string()
        .test(
          COMPANIES_HOUSE_NUMBER,
          'Please enter a valid company house number',
          companiesHouseNumberValidator,
        )
        // ensures the companies house lookup has run successfully
        .test(
          COMPANIES_HOUSE_LOOKUP_COMPLETED,
          'Could not find company with given number,',
          (_, context) => context.parent[COMPANIES_HOUSE_LOOKUP_COMPLETED],
        )
    )
  }
  return Yup.string().notRequired()
})

const optionalVatValidationSchema = Yup.string().when((value) => {
  if (value) {
    return (
      Yup.string()
        .test(VAT_NUMBER, 'Please enter a valid VAT number', ukVATNumberValidator)
        // ensures the VAT registration lookup has run successfully
        .test(
          VAT_LOOKUP_COMPLETED,
          'Could not find company with given VAT number',
          (_, context) => context.parent[VAT_LOOKUP_COMPLETED],
        )
    )
  }
  return Yup.string().notRequired()
})

const optionalWebsiteValidationSchema = Yup.string().when((value) => {
  if (value) {
    return Yup.string().matches(websiteUrlRegex, 'please enter a valid url!')
  }
  return Yup.string().notRequired()
})

export const formFieldsConfigByBusinessType = {
  [BusinessType.LIMITED_COMPANY]: {
    fields: {
      [COMPANY_NAME]: true,
      [COMPANIES_HOUSE_NUMBER]: true,
      [VAT_NUMBER]: true,
      [WEBSITE]: true,
    },
    validationSchema: Yup.object().shape({
      [COMPANY_NAME]: Yup.string().required('Please tell us the name of your business'),
      [COMPANIES_HOUSE_NUMBER]: Yup.string()
        .required('We need to check that you are a business!')
        .test(
          COMPANIES_HOUSE_NUMBER,
          'Please enter a valid company house number',
          companiesHouseNumberValidator,
        ),
      [VAT_NUMBER]: optionalVatValidationSchema,
      [WEBSITE]: optionalWebsiteValidationSchema,
      [COMPANIES_HOUSE_LOOKUP_COMPLETED]: Yup.boolean().oneOf(
        [true],
        'Could not find company with given number',
      ),
    }),
  },
  [BusinessType.SOLE_TRADER]: {
    fields: {
      [COMPANY_NAME]: true,
      [VAT_NUMBER]: true,
      [WEBSITE]: true,
    },
    validationSchema: Yup.object().shape({
      [COMPANY_NAME]: Yup.string().required('Please tell us the name of your business'),
      [VAT_NUMBER]: Yup.string()
        .required('Please enter a valid VAT number')
        .test(VAT_NUMBER, 'Please enter a valid VAT number', ukVATNumberValidator)
        .test(
          VAT_LOOKUP_COMPLETED,
          'Could not find company with given VAT number',
          (_, context) => context.parent[VAT_LOOKUP_COMPLETED],
        ),
      [WEBSITE]: optionalWebsiteValidationSchema,
    }),
  },
  [BusinessType.PARTNERSHIP]: {
    fields: {
      [COMPANY_NAME]: true,
      [COMPANIES_HOUSE_NUMBER]: true,
      [VAT_NUMBER]: true,
      [WEBSITE]: true,
    },
    validationSchema: Yup.object().shape({
      [COMPANY_NAME]: Yup.string().required('Please tell us the name of your business'),
      [COMPANIES_HOUSE_NUMBER]: Yup.string()
        .required('We need to check that you are a business!')
        .test(
          COMPANIES_HOUSE_NUMBER,
          'Please enter a valid company house number',
          companiesHouseNumberValidator,
        ),
      [VAT_NUMBER]: optionalVatValidationSchema,
      [COMPANIES_HOUSE_LOOKUP_COMPLETED]: Yup.boolean().oneOf(
        [true],
        'Could not find company with given number',
      ),
      [WEBSITE]: optionalWebsiteValidationSchema,
    }),
  },
  [BusinessType.CHARITY]: {
    fields: {
      [COMPANY_NAME]: true,
      [COMPANIES_HOUSE_NUMBER]: true,
      [CHARITIES_COMMISSION_NUMBER]: true,
      [VAT_NUMBER]: true,
      [WEBSITE]: true,
    },
    validationSchema: Yup.object().shape({
      [COMPANY_NAME]: Yup.string().required('Please tell us the name of your business'),
      [COMPANIES_HOUSE_NUMBER]: optionalCompanyHouseValidationSchema,
      [CHARITIES_COMMISSION_NUMBER]: Yup.string()
        .required('Please tell us your charities commission number')
        .test(
          CHARITIES_COMMISSION_NUMBER,
          'Please enter a valid charities commission number',
          charityNumberValidator,
        ),
      [CHARITY_LOOKUP_COMPLETED]: Yup.boolean().oneOf(
        [true],
        'Could not find charity with given number.',
      ),
      [VAT_NUMBER]: optionalVatValidationSchema,
      [WEBSITE]: optionalWebsiteValidationSchema,
    }),
  },
}
