import USE_FLEETS_SEND_DRIVER_SIGNUP_EMAIL from '@electro/fleets/graphql/fleetsSendDriverSignupEmailMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsSendSignupEmailArgs } from '@electro/fleets/generated/graphql'

type fleetsSendDriverSignupEmailMutation = Pick<Mutation, 'fleetsSendSignupEmail'>

type UseFleetsSendDriverSignupEmail = [
  ApolloMutationFunction<fleetsSendDriverSignupEmailMutation>,
  MutationResult<fleetsSendDriverSignupEmailMutation>,
]

type UseFleetsSendDriverSignupEmailArgs = MutationHookOptions<
  fleetsSendDriverSignupEmailMutation,
  MutationFleetsSendSignupEmailArgs
>

export function useFleetsSendDriverSignupEmail(
  options?: UseFleetsSendDriverSignupEmailArgs,
): UseFleetsSendDriverSignupEmail {
  const [mutation, result] = useMutation<fleetsSendDriverSignupEmailMutation>(
    USE_FLEETS_SEND_DRIVER_SIGNUP_EMAIL,
    options,
  )

  return [mutation, result]
}
