import { Alert, Button, Modal } from '@electro/shared-ui-components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect } from 'react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useAdminMenuActions } from '../../hooks/useAdminMenuActions'

interface DeactivateAdminUserModalScreenProps {
  onClose: () => void
}

export const DeactivateAdminUserModalScreen = ({
  onClose,
}: DeactivateAdminUserModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage, adminUser }, { deactivateAdminUser, resetErrorMessage }] =
    useAdminMenuActions()

  const handleConfirmation = useCallback(() => {
    deactivateAdminUser({ onSuccess: onClose })
  }, [deactivateAdminUser, onClose])

  useEffect(() => () => resetErrorMessage(), [errorMessage, resetErrorMessage])

  return (
    <>
      <Modal.Header>{t('admin.deactivate_admin_user_modal.heading')}</Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="common:admin.deactivate_admin_user_modal.body"
          components={{ adminUserEmail: <span>{adminUser?.user?.email}</span> }}
        />
        {/* <p>
          This will deactivate the account for the email {adminUser.user.email}. They will no longer
          be able to log in.
        </p> */}
        {errorMessage ? <Alert variant="error">{errorMessage}</Alert> : null}
      </Modal.Body>
      <Modal.Actions center stacked>
        <Button disabled={loading} onClick={handleConfirmation} fullWidth>
          {!loading ? <TrashIcon className="h-6 w-6 mr-2 -ml-2" /> : null}
          {loading
            ? t('admin.deactivate_admin_user_modal.button.loading.deactivating')
            : t('admin.deactivate_admin_user_modal.button.deactivate')}
        </Button>
        <Button disabled={loading} variant="naked" onClick={onClose} fullWidth>
          {t('admin.admin_user_modal.button.no_take_me_back')}
        </Button>
      </Modal.Actions>
    </>
  )
}
