import { create } from 'zustand'

interface State {
  navigationExpanded: boolean
  openMobileNavigation: boolean
  openSubNavigationItems: Record<string, boolean>
  setNavigationExpanded: (payload: boolean) => void
  toggleOpenMobileNavigation: () => void
  syncOpenSubNavigationItems: (openSubNavigationItem: Record<string, boolean>) => void
  clearOpenSubNavigationItemsL: () => void
}

export const useUiStore = create<State>((set) => ({
  navigationExpanded: true,
  openMobileNavigation: false,
  openSubNavigationItems: {},
  setNavigationExpanded: (payload: boolean) => set({ navigationExpanded: payload }),
  toggleOpenMobileNavigation: () =>
    set((state) => ({ openMobileNavigation: !state.openMobileNavigation })),
  syncOpenSubNavigationItems: (openSubNavigationItem: Record<string, boolean>) =>
    set((state) => ({
      openSubNavigationItems: { ...state.openSubNavigationItems, ...openSubNavigationItem },
    })),
  clearOpenSubNavigationItemsL: () => set(() => ({ openSubNavigationItems: {} })),
}))
