import { useEffect, useState } from 'react'

import {
  useTagsManager,
  TagsManagerProvider,
} from '@electro/fleets/src/components/TagsManager/hooks'
import { EditTagForm } from '@electro/fleets/src/components/TagsManager/components'
import {
  Button,
  Card,
  Checkbox,
  Input,
  Skeleton,
  Tag,
  TagColours,
} from '@electro/shared-ui-components'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Popover } from '@headlessui/react'
import { FleetsTagType, FleetsTagTypeEdge } from '@electro/fleets/generated/graphql'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { isFunction } from '@electro/shared/utils/typed'
import { usePopper } from 'react-popper'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'flex flex-col',
  popper: 'p-2 w-80',
  tags: {
    root: tw(
      'group px-2 py-1 rounded flex items-center justify-between ',
      'hover:bg-secondary hover:bg-opacity-30',
    ),
    button: 'flex-1 flex items-center',
  },
  buttonPlus: tw(
    'h-5 w-5 flex items-center justify-center rounded m-1 hover:cursor-pointer',
    'bg-secondary text-white',
  ),
  loading: {
    wrapper: 'flex flex-col',
    skeleton: 'flex px-2 py-2',
  },
  error: 'ml-2 text-sm text-action-danger border-action-danger',
}

interface BaseProps {
  tags: FleetsTagType[]
}

interface FormVariantProps extends BaseProps {
  variant: 'form'
  onTagsChange?: (tags: FleetsTagType[]) => void
  onAssignTag?: never
  onUnassignTag?: never
}

interface ComponentVariantProps extends BaseProps {
  variant: 'component'
  onTagsChange?: never
  onAssignTag?: (tag: FleetsTagType) => void
  onUnassignTag?: (tag: FleetsTagType) => void
}

/**
 * We separate the props types because depending on the variant
 * we will use some callbacks and other callbacks not. We want
 * to raise error and force us to use correct callbacks.
 *
 * -> When variant is <form> we will use onTagsChange handler.
 *
 * -> When variant is <component> we will use onAssignTag and
 *    onUnassignTag handlers.
 */
type Props = FormVariantProps | ComponentVariantProps

const TagsManager = ({
  tags,
  variant = 'component',
  onAssignTag,
  onUnassignTag,
  onTagsChange,
}: Props) => {
  const { t } = useTranslation('common')
  const [tagManagerRef, setTagManagerRef] = useState<Element>(null)
  const [tagManagerPopperRef, setTagManagerPopperRef] = useState<HTMLElement>(null)
  const { styles: tagManagerPopperStyles, attributes: tagManagerPopperAttr } = usePopper(
    tagManagerRef,
    tagManagerPopperRef,
    { placement: 'bottom-start' },
  )

  const {
    initialTagEdges,
    tagEdges,
    searchTextValue,
    setSearchTextValue,
    loading,
    success,
    error,
    createTag,
    createTagLoading,
  } = useTagsManager()

  const tagChangeHandler = (tag: FleetsTagType) => {
    /**
     * Tags change handler has two cases:
     *
     * -> First one is when we use the tags manager inside
     *    a form and we need back from change handler an
     *    array of tags.
     *
     * -> Second one is when we use tags manager on normal
     *    component and we want to assign or unassign a single
     *    tag from a driver every time.
     */
    const tagExists = tags.some((element) => element.pk === tag.pk)

    switch (variant) {
      case 'component': {
        if (tagExists) {
          if (isFunction(onUnassignTag)) onUnassignTag(tag)
        } else if (isFunction(onAssignTag)) onAssignTag(tag)
        break
      }
      case 'form': {
        const newTags = [...tags]

        if (tagExists) {
          const tagToRemoveIndex = newTags.indexOf(tag)
          newTags.splice(tagToRemoveIndex, 1)
        } else {
          newTags.push(tag)
        }

        if (isFunction(onTagsChange)) onTagsChange(newTags)
        break
      }
      default:
        break
    }
  }

  useEffect(() => {
    /**
     * When initial tags change we need to trigger onTagsChange callback as
     * well. Initial tags can be changed when user delete or edit a tag
     * and so we need to do a comparison on given tags with initials tags
     */

    if (variant === 'component' || !success) return

    const newTags = []

    tags.forEach((tag) => {
      const tagEdgeFound = initialTagEdges.find((element) => element.node.pk === tag.pk)

      if (tagEdgeFound) {
        // check if tag has updated if yes push the new tag
        const hasTagUpdated = JSON.stringify(tag) !== JSON.stringify(tagEdgeFound.node)

        if (hasTagUpdated) {
          newTags.push(tagEdgeFound.node)
        } else {
          newTags.push(tag)
        }
      }
    })

    if (isFunction(onTagsChange)) onTagsChange(newTags)
    // eslint-disable-next-line
  }, [initialTagEdges])

  const handleCreateTag = (name: string) => {
    const newTag = { name, colour: 'blue' }
    createTag(newTag)
  }

  return (
    <div data-testid="tag-manager-container" className={styles.root} ref={setTagManagerRef}>
      <Popover className="relative">
        <Popover.Button ref={setTagManagerRef}>
          <div className="flex items-center">
            {tags.map((tag) => (
              <Tag key={tag.pk} label={tag.name} colour={tag.colour as TagColours} />
            ))}

            <div className={styles.buttonPlus}>
              <PlusIcon className="h-4 w-4" />
            </div>
          </div>
        </Popover.Button>

        <Popover.Panel
          className="absolute z-10"
          ref={setTagManagerPopperRef}
          style={tagManagerPopperStyles.popper}
          {...tagManagerPopperAttr.popper}
        >
          <Card className={styles.popper}>
            <Input
              fullWidth
              name="search"
              placeholder={t('tags.search_input.placeholder')}
              value={searchTextValue}
              onChange={(event) => setSearchTextValue(event.target.value)}
            />

            <div>
              {loading && (
                <div className={styles.loading.wrapper}>
                  <div className={styles.loading.skeleton}>
                    <Skeleton width={16} height={16} className="mr-4" />
                    <Skeleton width={200} height={18} />
                  </div>
                  <div className={styles.loading.skeleton}>
                    <Skeleton width={16} height={16} className="mr-4" />
                    <Skeleton width={200} height={18} />
                  </div>
                  <div className={styles.loading.skeleton}>
                    <Skeleton width={16} height={16} className="mr-4" />
                    <Skeleton width={200} height={18} />
                  </div>
                  <div className={styles.loading.skeleton}>
                    <Skeleton width={16} height={16} className="mr-4" />
                    <Skeleton width={200} height={18} />
                  </div>
                  <div className={styles.loading.skeleton}>
                    <Skeleton width={16} height={16} className="mr-4" />
                    <Skeleton width={200} height={18} />
                  </div>
                </div>
              )}

              {error && <div className={styles.error}>{error.message}</div>}

              {success && !initialTagEdges.length && (
                <div className="text-tertiary-shade text-sm ml-2">
                  {t('common.tags.no_tags_warning')}
                </div>
              )}

              {!loading && success && (
                <div data-testid="tags-wrapper">
                  {tagEdges.map((fleetTagEdge: FleetsTagTypeEdge) => {
                    const checked = tags.some((tag) => tag.pk === fleetTagEdge.node.pk)

                    return (
                      <div key={fleetTagEdge.node.name} className={styles.tags.root}>
                        <button
                          data-testid="tag-button"
                          className={styles.tags.button}
                          onClick={() => tagChangeHandler(fleetTagEdge.node)}
                        >
                          <div className="-mt-2">
                            <Checkbox checked={checked} onCheckboxChange={() => {}} />
                          </div>

                          <Tag
                            label={fleetTagEdge.node.name}
                            colour={fleetTagEdge.node.colour as TagColours}
                          />
                        </button>

                        <EditTagForm
                          tagPk={fleetTagEdge.node.pk}
                          formFields={{
                            name: fleetTagEdge.node.name,
                            colour: fleetTagEdge.node.colour,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>

            {searchTextValue && !tagEdges.length && (
              <Button
                fullWidth
                disabled={createTagLoading}
                style={{ padding: '0 16px' }}
                onClick={() => handleCreateTag(searchTextValue)}
              >
                {createTagLoading ? t('common.loading.creating') : t('common.button.create')}
                <Tag label={searchTextValue} colour={'blue' as TagColours} />
              </Button>
            )}
          </Card>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

const TagsManagerWithProvider = (props: Props) => (
  <TagsManagerProvider>
    <TagsManager {...props} />
  </TagsManagerProvider>
)

export { TagsManagerWithProvider as TagsManager }
