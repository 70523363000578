/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Input, Modal } from '@electro/shared-ui-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useDriverMenuActions } from '@electro/fleets/src/components/DriverActionsDropDownMenu/hooks'
import Image from 'next/image'
import { ChangeEvent, useEffect } from 'react'
import { formatAlphaNumericAllCaps } from '@electro/shared/utils/formatters'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  onClose: () => void
}

interface FormFields {
  hexCode: string
}

const validationSchema = Yup.object().shape({
  hexCode: Yup.string().required('A valid card number is required.').nullable(),
})

/**
 * @deprecated Use `AssignElectroCardModalScreen` in DriversListV2/components/DriverWidgets/components instead
 */
export const AssignElectroCardModalScreen = ({ onClose }: Props) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage }, { assignCardToDriver, resetErrorMessage }] =
    useDriverMenuActions()
  const formik = useFormik({
    initialValues: {
      hexCode: '',
    },
    validationSchema,
    onSubmit: ({ hexCode }: FormFields) => assignCardToDriver({ hexCode, onSuccess: onClose }),
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    resetErrorMessage()
    const formattedValue = formatAlphaNumericAllCaps(event.target.value)
    formik.setFieldValue('hexCode', formattedValue)
  }

  useEffect(() => () => resetErrorMessage(), [resetErrorMessage])

  return (
    <>
      <Modal.Header>
        {t('drivers.widgets.assign_electrocard.heading.assign_an_electrocard')}
      </Modal.Header>
      <Modal.Body data-testid="assign-card-modal">
        <label htmlFor="hexCode">
          {t('drivers.widgets.assign_electrocard.body.instructions')}
          <Image
            className="m-auto mt-4"
            src="/images/card-number-location.svg"
            alt="Illustration of an Electrocards number location"
            width="235"
            height="357"
          />
        </label>
        <form id="assign-card-form" onSubmit={formik.handleSubmit}>
          <Input
            disabled={loading}
            fullWidth
            name="hexCode"
            placeholder="0CT0PUS1234567"
            value={formik.values.hexCode}
            onChange={handleChange}
            errorMessage={formik.errors.hexCode || errorMessage}
          />
        </form>
      </Modal.Body>
      <Modal.Actions stacked>
        <Button disabled={loading} form="assign-card-form" type="submit">
          {loading
            ? t('driver.widgets.button.loading.assigning')
            : t('drivers.widgets.driver_details.driver_actions.assign_card')}
        </Button>
        <Button disabled={loading} variant="outline" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
      </Modal.Actions>
    </>
  )
}
