import FLEETS_BULK_CREATE_DRIVERS from '@electro/fleets/graphql/fleetsBulkCreateDriversMutation.graphql'

import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsBulkCreateDriversArgs } from '@electro/fleets/generated/graphql'

type BulkCreateDriversMutation = Pick<Mutation, 'fleetsBulkCreateDrivers'>

type UseFleetsBulkCreateDrivers = [
  ApolloMutationFunction<BulkCreateDriversMutation>,
  MutationResult<BulkCreateDriversMutation>,
]

type UseFleetsBulkCreateDriversArgs = MutationHookOptions<
  BulkCreateDriversMutation,
  MutationFleetsBulkCreateDriversArgs
>

export function useFleetsBulkCreateDrivers(
  options?: UseFleetsBulkCreateDriversArgs,
): UseFleetsBulkCreateDrivers {
  const [mutation, result] = useMutation<BulkCreateDriversMutation>(
    FLEETS_BULK_CREATE_DRIVERS,
    options,
  )

  return [mutation, result]
}
