import { LazyQueryHookOptions, OperationVariables, QueryResult, useLazyQuery } from '@apollo/client'

import ADMIN_USER_QUERY from '@electro/fleets/graphql/fleetsAdminUserQuery.graphql'

import { Query } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

type LazyFleetsFetchAdminUser = Pick<Query, 'fleetsCurrentAdmin'>

type UseFleetsFetchAdminUser = [
  ApolloLazyQueryFunction<LazyFleetsFetchAdminUser>,
  QueryResult<LazyFleetsFetchAdminUser, OperationVariables>,
]

interface UseFleetsFetchAdminUserArgs {
  options?: LazyQueryHookOptions<LazyFleetsFetchAdminUser, OperationVariables>
}

export function useFleetsFetchAdminUser({
  options,
}: UseFleetsFetchAdminUserArgs = {}): UseFleetsFetchAdminUser {
  const [FleetsFetchAdminUser, userQuery] = useLazyQuery<LazyFleetsFetchAdminUser>(
    ADMIN_USER_QUERY,
    options,
  )

  return [FleetsFetchAdminUser, userQuery]
}
