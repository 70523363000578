import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Input, Button } from '@electro/shared-ui-components'

import {
  UserAdminDetailsFormFields,
  UserAdminDetailsFormFieldNames,
} from '@electro/fleets/src/types/AdminForm'

export interface AdminUserDetailsFormProps {
  disabled?: boolean
  loading?: boolean
  error?: Partial<ApolloError>
  formFields?: Partial<UserAdminDetailsFormFields>
  onSubmit?: (values: UserAdminDetailsFormFields) => void
  submitText?: string
  submittingText?: string
}

const adminUserDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  email: Yup.string()
    .email('A valid email address is required.')
    .required('Email address is required.'),
})

const styles = {
  errorMessage: 'text-action-danger pt-2',
  counter: 'text-right text-sm -mt-4',
}

export const AdminUserDetailsForm = ({
  disabled,
  loading,
  error,
  onSubmit,
  submitText = 'Save details',
  submittingText = 'Saving...',
  formFields: { firstName = '', lastName = '', email = '' },
}: AdminUserDetailsFormProps) => {
  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
    },
    validationSchema: adminUserDetailsValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null
  return (
    <div aria-label="admin-user-details-form">
      <h3>Your details</h3>
      <form onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <Input
          fullWidth
          name={UserAdminDetailsFormFieldNames.FIRST_NAME}
          label="First name"
          placeholder="E.g. Jane"
          value={formik.values.firstName}
          errorMessage={formik.errors.firstName}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={UserAdminDetailsFormFieldNames.LAST_NAME}
          label="Last name"
          placeholder="E.g. Smith"
          value={formik.values.lastName}
          errorMessage={formik.errors.lastName}
          onChange={formik.handleChange}
        />
        <Input
          fullWidth
          name={UserAdminDetailsFormFieldNames.EMAIL}
          label="Email address"
          placeholder="E.g. jane@smith.com"
          value={formik.values.email}
          errorMessage={formik.errors.email}
          onChange={formik.handleChange}
        />
        <div className="pt-8">
          <Button disabled={disabled} data-testid="Submit" fullWidth type="submit">
            {loading ? submittingText : submitText}
          </Button>
        </div>
        {!!error && (
          <div className={styles.errorMessage}>
            {error.message ? error.message : 'Something went wrong!'}
          </div>
        )}
      </form>
    </div>
  )
}
