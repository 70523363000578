import { SingletonRouter } from 'next/router'
import {
  Query,
  QueryParamsState,
  Options,
  QueryKeyEnum,
} from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { ORDER_BY_PARAM, ORDER_DIRECTION_PARAM } from '@electro/fleets/src/constants/urlParams'
import { createQueryParam } from '@electro/fleets/src/hooks/useQueryParams/createQueryParam'

interface QueryParamsInit {
  router: SingletonRouter
}

export class QueryParams<T extends string> {
  private queryParams: Query<T>[] = []

  private router: SingletonRouter = null

  constructor(init: QueryParamsInit) {
    this.router = init.router
  }

  reset = () => {
    this.queryParams = []
  }

  getQueryParam = (queryKey: QueryKeyEnum, options: Options<T>) => {
    const queryHash = JSON.stringify(queryKey) + JSON.stringify(options.initialState)

    let query = this.queryParams.find((q) => q.queryHash === queryHash)

    if (!query) {
      query = createQueryParam<T>(queryKey, options)
      this.queryParams.push(query)
    }

    return query
  }

  syncQueryParams = (queryKey: string, newQueryParams: Partial<QueryParamsState<T>>) => {
    if (typeof window !== 'undefined') {
      const query: Query<T> = this.queryParams.find((q) => q.queryKey === queryKey)

      if (query) {
        query.setState((prevState) => ({
          ...prevState,
          queryParams: {
            ...prevState.queryParams,
            ...newQueryParams,
          },
        }))

        const queryParams = { ...this.router.query, ...newQueryParams }

        // Handle ordering and split it into 2 params, order by param and order direction.
        // We cant parse ordering into url as an object so we split it into 2 params
        const [order] = queryParams.ordering || []

        if (order) {
          queryParams[ORDER_BY_PARAM] = order.orderBy
          queryParams[ORDER_DIRECTION_PARAM] = order.direction
        }

        delete queryParams.ordering

        if (!queryParams.search) delete queryParams.search

        // Handle additional params, create new object and remove it from primary queryParams
        const additionalParams = { ...queryParams.additionalParams }

        delete queryParams.additionalParams

        const searchParams = new URLSearchParams({
          ...(queryParams as Record<string, string>),
          ...(additionalParams as Record<string, string>),
        })

        const newSearchParams = searchParams.toString() ? `?${searchParams.toString()}` : ''

        this.router.push(`${this.router.pathname}${newSearchParams}`)
      }
    }
  }
}
