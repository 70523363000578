import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client'
import FLEETS_BUSINESS_ENTITY_RECEIPTS_AGGREGATE_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityReceiptsAggregateQuery.graphql'

import {
  Query,
  QueryFleetsBusinessEntityReceiptsAggregateArgs,
} from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsBusinessEntityReceiptsAggregate = Pick<
  Query,
  'fleetsBusinessEntityReceiptsAggregate'
>

type UseFetchFleetsBusinessEntityReceiptsAggregate = [
  ApolloLazyQueryFunction<FetchFleetsBusinessEntityReceiptsAggregate>,
  LazyQueryResult<FetchFleetsBusinessEntityReceiptsAggregate, OperationVariables>,
]

type FetchFleetsBusinessEntityReceiptsAggregateArgs = LazyQueryHookOptions<
  FetchFleetsBusinessEntityReceiptsAggregate,
  QueryFleetsBusinessEntityReceiptsAggregateArgs
>

export function useFetchFleetsBusinessEntityReceiptsAggregate(
  options?: FetchFleetsBusinessEntityReceiptsAggregateArgs,
): UseFetchFleetsBusinessEntityReceiptsAggregate {
  const [
    lazyFetchFleetsBusinessEntityReceiptsAggregate,
    FleetsBusinessEntityReceiptsAggregateResult,
  ] = useLazyQuery<FetchFleetsBusinessEntityReceiptsAggregate>(
    FLEETS_BUSINESS_ENTITY_RECEIPTS_AGGREGATE_QUERY,
    options,
  )

  return [
    lazyFetchFleetsBusinessEntityReceiptsAggregate,
    FleetsBusinessEntityReceiptsAggregateResult,
  ]
}
