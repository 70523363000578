import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'

import {
  Input,
  Button,
  LoadingDoubleRowPlaceholder,
  Typography,
} from '@electro/shared-ui-components'
import { ukMobileNumberValidator } from '@electro/shared/utils/validators'
import { formatMobileNumber } from '@electro/shared/utils/formatters'

import {
  DriverFormFields,
  keysOfEditDriverFormFields,
  DriverFormFieldNames,
  EditDriverFormFields,
} from '@electro/fleets/src/types/DriverForm'
import { TagsManager } from '@electro/fleets/src/components'
import type { FleetsTagType } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface EditDriverFormProps {
  loading?: boolean
  sending?: boolean
  error?: Partial<ApolloError>
  formFields?: Partial<DriverFormFields>
  onSubmit?: (values: EditDriverFormFields) => void
  disabledFields?: keysOfEditDriverFormFields[]
}

const { GIVEN_NAME, FAMILY_NAME, MOBILE, EMAIL, TAGS } = DriverFormFieldNames

// TODO: FORM VALIDATION REQUIRED FOR FRANCE ON MOBILE NUMBER
const editDriverValidationSchema = () =>
  Yup.object().shape({
    [GIVEN_NAME]: Yup.string().required('drivers.create_driver.validation_warning.first_name'),
    [FAMILY_NAME]: Yup.string().required('drivers.create_driver.validation_warning.last_name'),
    [EMAIL]: Yup.string()
      .email('drivers.create_driver.validation_warning.email')
      .required('drivers.create_driver.validation_warning.email_required'),
    [MOBILE]: Yup.string()
      .test(MOBILE, 'Enter a valid UK mobile phone number starting 07.', (mobileNumber) => {
        if (mobileNumber?.length > 1) {
          return ukMobileNumberValidator(mobileNumber)
        }
        return true
      })
      .length(11, 'Enter a valid UK mobile phone number starting 07.')
      .nullable(),
    [TAGS]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          colour: Yup.string(),
        }),
      )
      .nullable(),
  })

const styles = {
  errorMessage: 'text-action-danger pt-2',
  counter: 'text-right text-sm -mt-4',
  postcodeCity: 'flex space-x-4',
  backButton: 'absolute top-4 left-4',
}

export const EditDriverForm = ({
  sending,
  loading,
  error,
  onSubmit,
  disabledFields,
  formFields,
}: EditDriverFormProps) => {
  const { t } = useTranslation('common')
  const formik = useFormik({
    initialValues: {
      [GIVEN_NAME]: formFields?.[GIVEN_NAME] || '',
      [FAMILY_NAME]: formFields?.[FAMILY_NAME] || '',
      [EMAIL]: formFields?.[EMAIL] || '',
      [MOBILE]: formFields?.[MOBILE] || '',
      [TAGS]: formFields?.[TAGS] || [],
    },
    validationSchema: editDriverValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const handlePhoneNumberChange = (e) => {
    const formattedMobileNumber = formatMobileNumber(e.target.value)
    formik.setFieldValue(MOBILE, formattedMobileNumber)
  }

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  function isFieldDisabled(fieldName: keysOfEditDriverFormFields) {
    if (!disabledFields) return false
    return disabledFields.includes(fieldName) || loading
  }

  const handleTagChange = (tags: FleetsTagType[]) => {
    formik.setFieldValue('tags', tags)
  }

  if (!formFields && loading) return <LoadingDoubleRowPlaceholder rows={5} />

  return (
    <div aria-label="edit-driver-form">
      <form id="driver-form" onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <section>
          <Input
            fullWidth
            disabled={isFieldDisabled(GIVEN_NAME)}
            name={GIVEN_NAME}
            label={t('drivers.create_driver.form_label.first_name')}
            placeholder={t('form_placeholder.first_name')}
            value={formik.values[GIVEN_NAME]}
            errorMessage={formik.errors[GIVEN_NAME]}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(FAMILY_NAME)}
            name={FAMILY_NAME}
            label={t('drivers.create_driver.form_label.last_name')}
            placeholder={t('form_placeholder.family_name')}
            value={formik.values[FAMILY_NAME]}
            errorMessage={formik.errors[FAMILY_NAME]}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(EMAIL)}
            name={EMAIL}
            label={t('drivers.create_driver.form_label.email')}
            placeholder={t('form_placeholder.email')}
            value={formik.values[EMAIL]}
            errorMessage={formik.errors[EMAIL]}
            onChange={formik.handleChange}
          />
          <Input
            fullWidth
            disabled={isFieldDisabled(MOBILE)}
            name={MOBILE}
            label={t('drivers.create_driver.form_label.mobile_number')}
            type="tel"
            placeholder={t('form_placeholder.mobile')}
            value={formik.values[MOBILE]}
            errorMessage={formik.errors[MOBILE]}
            onChange={handlePhoneNumberChange}
            maxLength={11}
          />
        </section>
      </form>

      <div className="mb-10">
        <Typography variant="small" className="pl-1 pb-1">
          {t('common.tags.tags')}
        </Typography>

        <TagsManager variant="form" tags={formik.values[TAGS]} onTagsChange={handleTagChange} />
      </div>

      <Button form="driver-form" disabled={sending} data-testid="Submit" fullWidth type="submit">
        {sending ? t('common.loading.button.save') : t('common.button.save_changes')}
      </Button>

      {!!error && (
        <div className={styles.errorMessage}>
          {error.message ? error.message : t('common.error.message')}
        </div>
      )}
    </div>
  )
}
