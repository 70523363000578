import { Button } from '@electro/shared-ui-components'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useBulkDriverUpload } from '@electro/fleets/src/components/BulkDriverUploadButton/hooks/'
import useTranslation from 'next-translate/useTranslation'

export const DownloadCsvTemplateButton = () => {
  const { t } = useTranslation('common')
  const [{ loading }, { handleDownloadCsvTemplate }] = useBulkDriverUpload()

  return (
    <Button disabled={loading} onClick={handleDownloadCsvTemplate}>
      <ArrowDownTrayIcon className="w-6 h-6 mr-1" />
      {loading
        ? t('common.button.loading.downloading')
        : t('drivers.bulk_upload_modal.button.download_template')}
    </Button>
  )
}
