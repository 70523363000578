import { useEffect, useState } from 'react'
import { Button, Modal } from '@electro/shared-ui-components'
import Image from 'next/legacy/image'
import { useLocalStorage } from 'react-use'
import { FLEETS_FIRST_TIME_USER_WELCOME } from '@electro/shared/constants'
import useTranslation from 'next-translate/useTranslation'

export const FirstTimeUserWelcome = () => {
  const { t } = useTranslation('common')
  const [open, setOpen] = useState(false)
  const [isFirstTimeUser, setIsFirstTimeUser] = useLocalStorage(
    FLEETS_FIRST_TIME_USER_WELCOME,
    false,
  )

  const handleClose = () => {
    setIsFirstTimeUser(true)
    setOpen(false)
  }

  useEffect(() => {
    if (!isFirstTimeUser) setOpen(true)
  }, [isFirstTimeUser])

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Body>
        <h1 className="text-center">{t('welcome_modal.heading.welcome')}</h1>
        <Image
          layout="responsive"
          src="/images/mr-zap-welcome.png"
          alt={t('welcome_modal.heading.welcome')}
          width="50"
          height="50"
        />
      </Modal.Body>

      <Modal.Actions stacked>
        <Button fullWidth onClick={handleClose}>
          {t('common.button.lets_go')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
