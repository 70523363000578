import { Modal, Typography, Button, Checkbox } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { useDriverWidgets } from '@electro/fleets/src/components/DriversListV2/components/DriverWidgets/hooks'

export const AssignVehicleToDriverSuccessModal = () => {
  const { handleStopShowingVehicleAssignedSuccessModal, closeModalScreen } = useDriverWidgets()
  return (
    <>
      <Modal.Header>
        <Typography variant="h2" className="text-center">
          Vehicle assigned
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <AnimatedComponent
          name="zap-celebration-animation"
          lottieProps={{ className: 'h-[250px]' }}
          getAnimationData={ANIMATIONS.zapCelebrationDark}
        />
        <Typography>
          This driver will now be able to do route planning from their mobile app with this
          vehicle&#39;s specifications!
        </Typography>
        <div className="flex justify-center mt-5">
          <Checkbox
            name="hideModal"
            type="checkbox"
            label="Don&#39;t show me again"
            onCheckboxChange={handleStopShowingVehicleAssignedSuccessModal}
          />
        </div>
      </Modal.Body>
      <Modal.Actions stacked>
        <Button
          onClick={closeModalScreen}
          fullWidth
          data-testid="assign-vehicle-success-modal-button"
        >
          Close
        </Button>
      </Modal.Actions>
    </>
  )
}
