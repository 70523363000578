import Image from 'next/image'
import { Button, Modal } from '@electro/shared-ui-components'
import { useBulkDriverUpload } from '../../hooks'

export const FileUploadCompleteModalScreen = () => {
  const [, { toggleModal }] = useBulkDriverUpload()

  const handleClose = () => {
    toggleModal()
  }

  return (
    <>
      <Modal.Body>
        <div className="text-center">
          <h3>Your drivers have been uploaded!</h3>
          <Image
            className="mx-auto pt-6"
            src="/images/zap-celebrate.png"
            width={300}
            height={300}
            alt="illustration of zap celebrating"
          />
          <p>You can view them in the drivers list.</p>
        </div>
      </Modal.Body>
      <Modal.Actions center>
        <Button onClick={handleClose}>View drivers</Button>
      </Modal.Actions>
    </>
  )
}
