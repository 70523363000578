import { useMemo } from 'react'
import {
  Card,
  Tag,
  Typography,
  TagColours,
  VibrantTagColours,
  Tooltip as SharedTooltip,
} from '@electro/shared-ui-components'
import { useFleetsSummaryStatsQuery } from '@electro/fleets/generated/graphql'
import { useDashboardSummary } from '@electro/fleets/src/components/DashboardSummary/hooks'
import { Loader } from '@electro/fleets/src/components/DashboardSummary/components'
import { format } from 'date-fns'
import { QUERY_DATE_FORMAT } from '@electro/shared/constants'
import { scaleOrdinal } from '@electro/shared/utils/color'
import Image from 'next/image'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import { MinusIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'

export const ChargingByDriver = () => {
  const { t } = useTranslation('common')
  const { startDate, endDate } = useDashboardSummary()
  const { data, loading } = useFleetsSummaryStatsQuery({
    variables: {
      startDate: format(startDate, QUERY_DATE_FORMAT),
      endDate: format(endDate, QUERY_DATE_FORMAT),
    },
  })
  const summaryStats = data?.fleetsSummaryStats
  const businessHasCharged = useBusinessEntityStore((state) => state.businessEntity?.hasCharged)
  const driverCpoSplitHighestPercentage = useMemo(() => {
    if (!data?.fleetsSummaryStats) return []

    return data?.fleetsSummaryStats?.cpoSplitPerDriver?.map((cpoSplitDriver) => {
      const cpoSplitLargerPercentage = cpoSplitDriver.cpoSplit.reduce((prev, next) => {
        if (prev.percentage < next.percentage) {
          return next
        }
        return prev
      })

      return cpoSplitLargerPercentage
    })
  }, [data?.fleetsSummaryStats])

  const color = scaleOrdinal(
    driverCpoSplitHighestPercentage.map((cpoSplit) => cpoSplit.cpo.name),
    VibrantTagColours,
  )

  const showLoading = useMemo(
    () =>
      loading && (
        <div className="flex-1 flex justify-center items-center">
          <Loader />
        </div>
      ),
    [loading],
  )

  const showNoData = useMemo(() => {
    if (!loading && !summaryStats && !businessHasCharged) {
      return (
        <div className="flex items-end">
          <Typography variant="p" className="text-tertiary">
            {t('dashboard.widgets.charging_by_driver.no_data')}
          </Typography>
        </div>
      )
    }
    if (!loading && !summaryStats && businessHasCharged) {
      return (
        <Typography className="text-tertiary">
          <MinusIcon className="w-6 h-6" data-testid="minus-icon-chargeByDriver" />
        </Typography>
      )
    }
    return null
  }, [summaryStats, loading, businessHasCharged, t])

  const showChargingByDriverList = useMemo(
    () =>
      summaryStats && (
        <ul className="flex-1 divide-y overflow-auto divide-secondary">
          {summaryStats?.cpoSplitPerDriver?.map((cpoSplitDriver) => {
            const cpoSplitLargerPercentage = cpoSplitDriver.cpoSplit.reduce((prev, next) => {
              if (prev.percentage < next.percentage) {
                return next
              }
              return prev
            })
            const percentage = cpoSplitLargerPercentage.percentage * 100
            const percentageCleaned = parseFloat(Number(percentage).toFixed(2))
            const tagColor = color(cpoSplitLargerPercentage.cpo.name)

            return (
              <li key={cpoSplitDriver.driverId} className="flex justify-between py-2">
                <Typography variant="small">{`${cpoSplitDriver.firstName} ${cpoSplitDriver.lastName}`}</Typography>
                <Tag
                  colour={tagColor as TagColours}
                  size="xs"
                  label={`${cpoSplitLargerPercentage.cpo.name} - ${percentageCleaned}%`}
                  className="w-1/2"
                />
              </li>
            )
          })}
        </ul>
      ),
    [summaryStats, color],
  )

  return (
    <Card density="high" frosted className="h-56 flex-1 flex flex-col p-6 justify-between">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Typography variant="h6">{t('dashboard.widgets.charging_by_driver.title')}</Typography>
          <SharedTooltip>
            <SharedTooltip.Trigger
              ariaLabel={t('dashboard.widgets.charging_by_driver.aria_label')}
              className="flex align-middle"
            >
              <Image
                src="/images/information-circle-icon.svg"
                alt={t('dashboard.widgets.information_circle_icon')}
                width="25"
                height="25"
              />
            </SharedTooltip.Trigger>
            <SharedTooltip.Body>
              {t('dashboard.widgets.charging_by_driver.tooltip.body')}
            </SharedTooltip.Body>
          </SharedTooltip>
        </div>
        <Image
          src="/images/dashboardIcons/cpo_per_driver.svg"
          alt={t('dashboard.widgets.charging_by_driver.icon_alt_text')}
          width="25"
          height="25"
        />
      </div>
      {showLoading}
      {showNoData}
      {showChargingByDriverList}
    </Card>
  )
}
