import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client'
import FLEETS_DRIVERS_TOKENS_QUERY from '@electro/fleets/graphql/fleetsDriversTokensQuery.graphql'

import { Query, QueryFleetsDriversTokensArgs } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

interface FleetsDriversTokensStats {
  activeTokens: {
    totalCount: number
  }
  pendingTokens: {
    totalCount: number
  }
  unassignedTokens: {
    totalCount: number
  }
}

export type FetchFleetsDriversTokens = Pick<Query, 'fleetsDriversTokens'> & FleetsDriversTokensStats

type UseFleetsFetchDriversTokens = [
  ApolloLazyQueryFunction<FetchFleetsDriversTokens>,
  LazyQueryResult<FetchFleetsDriversTokens, OperationVariables>,
]

type FetchFleetsDriversTokensArgs = LazyQueryHookOptions<
  FetchFleetsDriversTokens,
  QueryFleetsDriversTokensArgs
>

export function useFleetsFetchDriversTokens(
  options?: FetchFleetsDriversTokensArgs,
): UseFleetsFetchDriversTokens {
  const [lazyFetchFleetsDriversTokens, fleetsDriversTokensResult] =
    useLazyQuery<FetchFleetsDriversTokens>(FLEETS_DRIVERS_TOKENS_QUERY, options)

  return [lazyFetchFleetsDriversTokens, fleetsDriversTokensResult]
}
