import { useCallback, useMemo, useState } from 'react'

import { Menu } from '@electro/shared-ui-components'

import { EllipsisHorizontalIcon, ArrowPathIcon, TrashIcon } from '@heroicons/react/24/outline'
import { SpinnerIcon } from '@electro/fleets/src/icons'

import { AdminType } from '@electro/fleets/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { AdminUserActionsProvider, useAdminMenuActions } from './hooks/useAdminMenuActions'
import { AdminUserActionsModal, ModalScreenNames, ModalScreensEnum } from './components'

interface AdminUserActionsDropDownMenuProps {
  adminUser: AdminType
}

const AdminUserActionsDropDownMenu = ({ adminUser }: AdminUserActionsDropDownMenuProps) => {
  const { t } = useTranslation('common')
  const [activeUserMenu, setActiveUserMenu] = useState<number>(null)
  const [activeModalScreen, setActiveModalScreen] = useState<ModalScreenNames>(null)

  const showActionConfirmationModal = useCallback(
    (modalScreenName) => () => {
      setActiveUserMenu(adminUser.pk)
      setActiveModalScreen(modalScreenName)
    },
    [adminUser.pk],
  )

  const driverActionModalOpen = useMemo(
    () => activeUserMenu === adminUser.pk,
    [activeUserMenu, adminUser.pk],
  )

  const closeDriverActionModal = useCallback(() => setActiveUserMenu(null), [])

  const [{ loading }] = useAdminMenuActions()

  return (
    <>
      <Menu>
        <Menu.Button
          data-testid={`admin-actions-dropdown-menu-${adminUser.id}`}
          aria-label={`Admin user options menu for ${adminUser.user.email}`}
          disabled={false}
          className="hover:bg-tertiary-shade bg-transparent"
          chevron={false}
        >
          {loading ? (
            <SpinnerIcon aria-label="loading spinner" className="animate-spin w-6, h-6" />
          ) : null}

          {!loading ? <EllipsisHorizontalIcon className="w-6 h-6" /> : null}
        </Menu.Button>
        <Menu.MenuItemList variant="dark">
          {adminUser.hasActiveAccount ? (
            <Menu.MenuItem
              onSelect={showActionConfirmationModal(
                ModalScreensEnum.DEACTIVATE_ADMIN_USER_MODAL_SCREEN,
              )}
            >
              <TrashIcon className="-mt-0.5 w-4 h-4 mr-1.5 text-action-danger" />
              <span className="text-action-danger">
                {t('admin.manage_users.button.deactivate_user')}
              </span>
            </Menu.MenuItem>
          ) : (
            <Menu.MenuItem
              onSelect={showActionConfirmationModal(
                ModalScreensEnum.REACTIVATE_ADMIN_USER_MODAL_SCREEN,
              )}
            >
              <ArrowPathIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
              <span>{t('admin.manage_users.button.reactivate_user')}</span>
            </Menu.MenuItem>
          )}
        </Menu.MenuItemList>
      </Menu>
      <AdminUserActionsModal
        onClose={closeDriverActionModal}
        open={driverActionModalOpen}
        activeModalScreen={activeModalScreen}
      />
    </>
  )
}

const AdminUserActionsDropDownMenuContainer = ({
  adminUser,
}: AdminUserActionsDropDownMenuProps) => (
  <AdminUserActionsProvider adminUser={adminUser}>
    <AdminUserActionsDropDownMenu adminUser={adminUser} />
  </AdminUserActionsProvider>
)

export { AdminUserActionsDropDownMenuContainer as AdminUserActionsDropDownMenu }
