import { Modal } from '@electro/shared-ui-components'
import {
  DeactivateAdminUserModalScreen,
  ReactivateAdminUserModalScreen,
} from '@electro/fleets/src/components/AdminUserActionsDropDownMenu/components'

export enum ModalScreensEnum {
  DEACTIVATE_ADMIN_USER_MODAL_SCREEN = 'DEACTIVATE_ADMIN_USER_MODAL_SCREEN',
  REACTIVATE_ADMIN_USER_MODAL_SCREEN = 'REACTIVATE_ADMIN_USER_MODAL_SCREEN',
}

export type ModalScreenNames = `${ModalScreensEnum}`

interface AdminUserActionsModalProps {
  open: boolean
  onClose: () => void
  activeModalScreen: ModalScreenNames
}

export const AdminUserActionsModal = ({
  open,
  onClose,
  activeModalScreen,
}: AdminUserActionsModalProps) => (
  <Modal size="md" open={open} onClose={onClose}>
    <Modal.CloseButton />
    {activeModalScreen === ModalScreensEnum.DEACTIVATE_ADMIN_USER_MODAL_SCREEN ? (
      <DeactivateAdminUserModalScreen onClose={onClose} />
    ) : null}
    {activeModalScreen === ModalScreensEnum.REACTIVATE_ADMIN_USER_MODAL_SCREEN ? (
      <ReactivateAdminUserModalScreen onClose={onClose} />
    ) : null}
  </Modal>
)
