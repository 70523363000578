import {
  FIRST_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  ORDER_DIRECTION_PARAM,
  SEARCH,
} from '@electro/fleets/src/constants/urlParams'
import {
  Observer,
  Ordering,
  OrderingDirectionEnum,
  Query,
  QueryParamsState,
  Options,
  QueryKeyEnum,
} from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'

export const createQueryParam = <T extends string>(queryKey: QueryKeyEnum, options: Options<T>) => {
  const query: Query<T> = {
    queryKey,
    queryHash: JSON.stringify(queryKey) + JSON.stringify(options.initialState),
    subscribers: [],
    state: {
      isInitialized: false,
      queryParams: {},
    },

    subscribe: (subscriber: Observer<T>) => {
      query.subscribers.push(subscriber)

      return () => {
        query.subscribers = query.subscribers.filter((s) => s !== subscriber)
      }
    },

    setState: (updater) => {
      query.state = updater(query.state)
      query.subscribers.forEach((s) => s.notify())
    },

    initialize: () => {
      if (typeof window === 'undefined') return
      const { initialState = {} } = options

      const urlParams = new URLSearchParams(window.location.search)

      const defaultFirst = (() => {
        if (urlParams.get(FIRST_PARAM)) {
          return Number(urlParams.get(FIRST_PARAM))
        }

        if (initialState.first) {
          return initialState.first
        }

        return 20
      })()

      const defaultOffset = (() => {
        if (urlParams.get(OFFSET_PARAM)) {
          return Number(urlParams.get(OFFSET_PARAM))
        }

        if (initialState.offset) {
          return initialState.offset
        }

        return 0
      })()

      const defaultOrdering: Ordering<T>[] = (() => {
        if (urlParams.get(ORDER_BY_PARAM) && urlParams.get(ORDER_DIRECTION_PARAM)) {
          return [
            {
              orderBy: urlParams.get(ORDER_BY_PARAM) as T,
              direction: urlParams.get(ORDER_DIRECTION_PARAM) as OrderingDirectionEnum,
            },
          ]
        }

        if (initialState.ordering) {
          return initialState.ordering
        }

        return []
      })()

      const defaultSearch = (() => {
        if (urlParams.get(SEARCH)) {
          return String(urlParams.get(SEARCH))
        }

        return initialState.search ?? ''
      })()

      const defaultAdditionalParams = (() => {
        if (Object.keys(initialState.additionalParams || {}).length) {
          const urlAdditionalParams: any = {}

          Object.keys(initialState.additionalParams).forEach((key) => {
            const value = urlParams.get(key)

            if (value) {
              urlAdditionalParams[key] = value
            }
          })

          return { ...initialState.additionalParams, ...urlAdditionalParams }
        }

        return {}
      })()

      const defaultQueryParams: QueryParamsState<T> = {
        first: defaultFirst,
        offset: defaultOffset,
        ordering: defaultOrdering,
        search: defaultSearch,
        additionalParams: defaultAdditionalParams,
      }

      query.setState((prevState) => ({
        ...prevState,
        isInitialized: true,
        queryParams: defaultQueryParams,
      }))
    },
  }

  return query
}
