import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { AdminType } from '@electro/fleets/generated/graphql'
import { useFleetsEditAdminStatus } from '@electro/fleets/src/services'

import FLEETS_ADMINS_QUERY from '@electro/fleets/graphql/fleetsAdminUsersQuery.graphql'
import { useToastNotification } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'

interface AdminUserActionsProps {
  children: any
  adminUser?: AdminType
}

interface ActionArgs {
  onSuccess?: () => void
  onError?: () => void
}

type DeactivateAdminUserArgs = ActionArgs
type ReactivateAdminUserArgs = ActionArgs

interface State {
  loading: boolean
  errorMessage: string
  adminUser: AdminType
}

interface Handlers {
  deactivateAdminUser: (args: DeactivateAdminUserArgs) => void
  reactivateAdminUser: (args: ReactivateAdminUserArgs) => void
  resetErrorMessage: () => void
}

type UseAdminMenuActionsContext = [State, Handlers]

const useAdminUserActionsProvider = ({
  adminUser,
}: {
  adminUser: AdminType
}): UseAdminMenuActionsContext => {
  const { t } = useTranslation('common')
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const { showToastNotification } = useToastNotification()

  const [setAdminUserStatus, editAdminStatusMutation] = useFleetsEditAdminStatus({
    refetchQueries: [{ query: FLEETS_ADMINS_QUERY, variables: { first: 500 } }],
    awaitRefetchQueries: true,
  })

  const resetErrorMessage = useCallback(() => setErrorMessage(null), [])

  const deactivateAdminUser = useCallback(
    async ({ onSuccess, onError }: { onSuccess; onError }) => {
      try {
        await setAdminUserStatus({
          variables: {
            pk: adminUser.pk,
            isActive: false,
          },
        })
        onSuccess?.()
        showToastNotification({
          variant: 'success',
          heading: t('admin.toast.heading'),
          body: (
            <Trans
              i18nKey="common:admin.toast.body.deactivated"
              components={{ adminUserEmail: <span>{adminUser?.user?.email}</span> }}
            />
          ),
          timeout: 5000,
        })
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [adminUser, setAdminUserStatus, showToastNotification, t],
  )

  const reactivateAdminUser = useCallback(
    async ({ onSuccess, onError }: { onSuccess; onError }) => {
      try {
        await setAdminUserStatus({
          variables: {
            pk: adminUser.pk,
            isActive: true,
          },
        })
        onSuccess?.()
        showToastNotification({
          variant: 'success',
          heading: t('admin.toast.heading'),
          body: (
            <Trans
              i18nKey="common:admin.toast.body.reactivated"
              components={{ adminUserEmail: <span>{adminUser?.user?.email}</span> }}
            />
          ),
          timeout: 5000,
        })
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [adminUser, setAdminUserStatus, showToastNotification, t],
  )

  const loading = useMemo(() => editAdminStatusMutation.loading, [editAdminStatusMutation.loading])

  const state = {
    loading,
    errorMessage,
    adminUser,
  }

  const handlers = {
    deactivateAdminUser,
    reactivateAdminUser,
    resetErrorMessage,
  }

  return [state, handlers]
}

const AdminUserActionsContext = createContext<UseAdminMenuActionsContext>(null)

const useAdminMenuActions = () => {
  const context = useContext(AdminUserActionsContext)
  if (!context) {
    throw new Error(
      `useAdminMenuActions() cannot be used outside the context of <AdminUserActions/> `,
    )
  }
  return context
}

const AdminUserActionsProvider = ({ children, adminUser }: AdminUserActionsProps) => {
  const context = useAdminUserActionsProvider({ adminUser })
  return (
    <AdminUserActionsContext.Provider value={context}>{children}</AdminUserActionsContext.Provider>
  )
}

export { AdminUserActionsProvider, useAdminMenuActions }
