import { useState } from 'react'
import { Button, Modal, Typography } from '@electro/shared-ui-components'
import { useFleetsRedeemCreditCodeMutation } from '@electro/fleets/generated/graphql'
import { RedeemCreditCodeForm } from '@electro/fleets/src/components/CreditAndReferrals/components'
import * as Sentry from '@sentry/nextjs'
import FLEETS_CURRENT_BALANCE from '@electro/fleets/graphql/fleetsCurrentBalanceQuery.graphql'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import useTranslation from 'next-translate/useTranslation'

export const RedeemCodeModal = () => {
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [redeemCreditCode, { loading, error }] = useFleetsRedeemCreditCodeMutation({
    onError: (err) => Sentry.captureException(err),
    onCompleted: () => setSuccess(true),
  })
  const { t } = useTranslation('common')
  const handleRedeemCreditCodeSubmit = (values) => {
    redeemCreditCode({
      refetchQueries: [{ query: FLEETS_CURRENT_BALANCE }],
      variables: {
        code: values.code,
      },
    })
  }

  return (
    <div className="mb-8">
      <Button onClick={() => setOpen(true)}>
        {t('credit_and_referrals.redeem_code_modal.button.redeem_a_code')}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        {success ? (
          <>
            <AnimatedComponent
              name="zap-celebration-animation"
              lottieProps={{ className: 'h-[250px]' }}
              getAnimationData={ANIMATIONS.zapCelebrationDark}
            />
            <div className="text-center">
              <Typography variant="h3" className="mb-5">
                {t('credit_and_referrals.redeem_code_modal.heading.credit_being_added')}
              </Typography>
              <Typography variant="p">
                {t('credit_and_referrals.redeem_code_modal.body.could_take_24_hours')}
              </Typography>
            </div>

            <Modal.Actions center>
              <Button onClick={() => setOpen(false)}>{t('common.button.okay')}</Button>
            </Modal.Actions>
          </>
        ) : (
          <>
            <Modal.CloseButton />

            <Modal.Header>
              {t('credit_and_referrals.redeem_code_modal.button.redeem_a_code')}
            </Modal.Header>

            <Modal.Body>
              <Typography className="mb-8">
                {t('credit_and_referrals.redeem_code_modal.body.add_your_code_to_redeem')}
              </Typography>

              <RedeemCreditCodeForm
                loading={loading}
                error={error}
                onSubmit={handleRedeemCreditCodeSubmit}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  )
}
