import { isDemoModeExec } from '@electro/fleets/src/utils/envFlagCheck'
import { Tooltip } from '@electro/shared-ui-components'
import { ReactNode } from 'react'

interface DemoModeWrapperProps {
  /**
   * The children to render if the feature is enabled.
   * Children can be a function that receives a boolean indicating if the feature is disabled,
   * useful for conditional rendering like  disabling a button.
   * @param disabled - Whether the feature is disabled.
   */
  children:
    | JSX.Element
    | JSX.Element[]
    | ReactNode
    | string
    | ((props: { isDemoMode: boolean }) => JSX.Element | JSX.Element[] | ReactNode | string)
  /**
   * When set to true will show the children in demo mode.
   * this is useful if you want to tease a feature that is
   * disabled in demo mode. You can use the disabled prop to
   * conditionally render the children.
   * @default false
   */
  show?: boolean
  /**
   * The tooltip to display when hovering over the children.
   * @default undefined
   */
  tooltip?: string | ReactNode | JSX.Element | JSX.Element[]
}

/**
 * Component wrapper around features that we want to disable/hide in demo mode.
 *
 * Default behaviour is to hide children when the demo mode env flag is set to 'true'.
 */
export const DemoModeWrapper = ({ children, show, tooltip }: DemoModeWrapperProps) => {
  const isDemoMode = isDemoModeExec()

  if (!show && isDemoMode) return null

  if (tooltip && isDemoMode) {
    return (
      <Tooltip placement="top">
        <Tooltip.Trigger ariaLabel="tooltip">
          {typeof children === 'function' ? children({ isDemoMode }) : children}
        </Tooltip.Trigger>
        <Tooltip.Body>{tooltip}</Tooltip.Body>
      </Tooltip>
    )
  }

  if (typeof children === 'function') {
    return children({ isDemoMode })
  }

  return children
}
