export type CountryCodeType = (typeof COUNTRY_CODES)[keyof typeof COUNTRY_CODES]

/**
 * Fleets Country codes:
 * Country codes are different from locales, as they are used to restrict access to certain features based on the user's region
 * Locales are only used to determine the language of the user
 */
export const COUNTRY_CODES = {
  GBR: 'GBR',
  FRA: 'FRA',
} as const
