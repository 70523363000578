import { FormikProps, isFunction, useFormik } from 'formik'
import { ReactNode, createContext, useContext, useState } from 'react'
import * as Yup from 'yup'
import { postcodeValidator } from '@electro/shared/utils/validators'
import {
  AdminBusinessBillingFormFieldsEnum,
  AdminBusinessBillingFormFields,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/AdminBusinessBillingForm.types'
import { ApolloError } from '@apollo/client'
import { useFleetsEditStripeDirectDebitBillingDetailsMutation } from '@electro/fleets/generated/graphql'
import FLEETS_BUSINESS_ENTITY_QUERY from '@electro/fleets/graphql/fleetsBusinessEntityQuery.graphql'

interface AdminBusinessBillingReturnType {
  formik: FormikProps<AdminBusinessBillingFormFields>
  addDirectDebitLoading: boolean
  error: ApolloError
}

const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY, EMAIL, NAME, POSTCODE } =
  AdminBusinessBillingFormFieldsEnum

const adminBusinessBillingSchema = Yup.object().shape({
  [EMAIL]: Yup.string()
    .email('A valid email address is required.')
    .required('Email address is required.'),
  [NAME]: Yup.string().required('First name is required.'),
  [ADDRESS_1]: Yup.string().required('Billing address line 1 is required.'),
  [ADDRESS_2]: Yup.string(),
  [POSTCODE]: Yup.string()
    .required('Postcode is required.')
    .test('postcode', 'This postcode is not valid.', postcodeValidator),
})

const AdminBusinessBillingContext = createContext<AdminBusinessBillingReturnType>(null)

interface Props {
  onSuccess?: () => void
  formFields: Partial<AdminBusinessBillingFormFields>
}

const useAdminBusinessBillingProvider = ({
  onSuccess,
  formFields = {},
}: Props): AdminBusinessBillingReturnType => {
  const [fleetsEditStripeDirectDebitBillingDetails, { loading: addDirectDebitLoading }] =
    useFleetsEditStripeDirectDebitBillingDetailsMutation()
  const [error, setError] = useState<ApolloError>()

  const submitHandler = async (values: AdminBusinessBillingFormFields) => {
    const { address1, address2, city, email, name, postcode } = values

    try {
      await fleetsEditStripeDirectDebitBillingDetails({
        variables: { address1, address2, city, email, name, postcode },
        refetchQueries: [FLEETS_BUSINESS_ENTITY_QUERY],
      })

      if (isFunction(onSuccess)) onSuccess()
    } catch (err) {
      setError(err)
    }
  }

  const defaultValues: AdminBusinessBillingFormFields = {
    [ADDRESS_1]: '',
    [ADDRESS_2]: '',
    [CITY]: '',
    [COUNTRY]: '',
    [EMAIL]: '',
    [NAME]: '',
    [POSTCODE]: '',
  }

  const formik: any = useFormik({
    initialValues: { ...defaultValues, ...formFields },
    validationSchema: adminBusinessBillingSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: submitHandler,
  })

  return { formik, addDirectDebitLoading, error }
}

interface ProviderProps extends Props {
  children: ReactNode | ReactNode[]
}

export const AdminBusinessBillingProvider = ({
  onSuccess,
  formFields,
  children,
}: ProviderProps) => {
  const value = useAdminBusinessBillingProvider({ onSuccess, formFields })

  return (
    <AdminBusinessBillingContext.Provider value={value}>
      {children}
    </AdminBusinessBillingContext.Provider>
  )
}

export const useAdminBusinessBilling = () => {
  const context = useContext(AdminBusinessBillingContext)

  if (!context)
    throw new Error(
      'useAdminBusinessBilling() cannot be used outside of <AdminBusinessBillingProvider/>',
    )

  return context
}
