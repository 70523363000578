import { Alert, Button, Input, Typography } from '@electro/shared-ui-components'
import {
  AdminBusinessBillingProvider,
  useAdminBusinessBilling,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/hooks'
import {
  AdminBusinessBillingFormFields,
  AdminBusinessBillingFormFieldsEnum,
} from '@electro/fleets/src/components/AdminBusinessBillingForm/AdminBusinessBillingForm.types'
import { ReactNode } from 'react'
import { CountrySelect } from '@electro/fleets/src/components'

const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY, EMAIL, NAME, POSTCODE } =
  AdminBusinessBillingFormFieldsEnum

interface Props {
  onSuccess?: () => void
  formFields?: Partial<AdminBusinessBillingFormFields>
  children: ReactNode | ReactNode[]
}

const AdminBusinessBillingForm = ({ onSuccess, formFields, children }: Props) => (
  <AdminBusinessBillingProvider onSuccess={onSuccess} formFields={formFields}>
    {children}
  </AdminBusinessBillingProvider>
)

const Fields = () => {
  const { formik, error } = useAdminBusinessBilling()

  return (
    <form
      data-testid="business-billing-form"
      id="business-billing-form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-8">
        <Typography variant="h3" className="mb-4">
          Personal Details
        </Typography>

        {!!error && (
          <Alert className="mb-4" variant="error">
            {error.message}
          </Alert>
        )}

        <Input
          name={EMAIL}
          fullWidth
          label="Email address"
          placeholder="E.g. jane@smith.com"
          value={formik.values[EMAIL]}
          errorMessage={formik.errors[EMAIL]}
          onChange={formik.handleChange}
        />

        <Input
          name={NAME}
          fullWidth
          label="Account name"
          placeholder="E.g. Jane Smith"
          value={formik.values[NAME]}
          errorMessage={formik.errors[NAME]}
          onChange={formik.handleChange}
        />
      </div>

      <Typography variant="h3" className="mb-4">
        Billing Address
      </Typography>

      <Input
        name={ADDRESS_1}
        fullWidth
        label="Billing address line 1"
        placeholder="E.g. 11 High Street"
        value={formik.values[ADDRESS_1]}
        errorMessage={formik.errors[ADDRESS_1]}
        onChange={formik.handleChange}
      />

      <Input
        fullWidth
        name={ADDRESS_2}
        label="Billing address line 2"
        placeholder="E.g. Camden"
        value={formik.values[ADDRESS_2]}
        errorMessage={formik.errors[ADDRESS_2]}
        onChange={formik.handleChange}
      />
      <Input
        fullWidth
        name={CITY}
        label="Billing city"
        placeholder="E.g. London"
        value={formik.values[CITY]}
        errorMessage={formik.errors[CITY]}
        onChange={formik.handleChange}
      />

      <Input
        fullWidth
        name={POSTCODE}
        label="Billing postcode"
        placeholder="E.g. PO57 6DE"
        value={formik.values[POSTCODE]}
        errorMessage={formik.errors[POSTCODE]}
        onChange={formik.handleChange}
      />

      <CountrySelect
        disabled
        className="mt-4"
        defaultCountryAlpha2={formik.values[COUNTRY]}
        onSelect={(country) => formik.setFieldValue(COUNTRY, country.alpha2)}
        filter={(countryItem) =>
          countryItem.filter((country) => country.alpha2 === 'GB' || country.alpha2 === 'FR')
        }
        name={COUNTRY}
        label="Billing country"
        errorMessage={formik.errors[COUNTRY]}
      />
    </form>
  )
}

const SubmitButton = () => {
  const { addDirectDebitLoading } = useAdminBusinessBilling()

  return (
    <Button fullWidth form="business-billing-form" type="submit" disabled={addDirectDebitLoading}>
      {addDirectDebitLoading ? 'Saving...' : 'Save details'}
    </Button>
  )
}

AdminBusinessBillingForm.Fields = Fields
AdminBusinessBillingForm.SubmitButton = SubmitButton

export { AdminBusinessBillingForm }
