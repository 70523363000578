import { VehicleType } from '@electro/fleets/generated/graphql'
import { fireGtmEvent } from '@electro/shared/utils/fireGtmEvent'

enum GTM_EVENTS {
  LOG_IN = 'FLEETS::LOG_IN',
  LOG_OUT = 'FLEETS::LOG_OUT',
  ONBOARDING_STEP_CLICKED = 'FLEETS::ONBOARDING_STEP_CLICKED',
  VEHICLE_SUBMITTED = 'FLEETS::VEHICLE_SUBMITTED',
}

/** User has logged in */
export const loggedIn = ({ companyName }: { companyName?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.LOG_IN,
    companyName,
  })

/** User has logged out */
export const loggedOut = ({ companyName }: { companyName?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.LOG_OUT,
    companyName,
  })

// prettier-ignore
interface OnboardingStepType  { onboardingStep: 'complete-business-info' | 'add-payment-details' | 'upload-drivers' | 'order-electrocards' | 'add-admin' }
/** User has clicked a dashboard onboarding step button */
export const onboardingStepClicked = ({ onboardingStep }: OnboardingStepType) =>
  fireGtmEvent({
    event: GTM_EVENTS.ONBOARDING_STEP_CLICKED,
    onboardingStep,
  })

// prettier-ignore
type SubmitVehicleType = { companyName?: string; vehicle?: VehicleType; vehicleLookup : 'manual' | 'registration' }
/** User adds a new vehicle to their fleet */
export const submitVehicle = ({ companyName, vehicle, vehicleLookup }: SubmitVehicleType) =>
  fireGtmEvent({
    event: GTM_EVENTS.VEHICLE_SUBMITTED,
    companyName,
    vehicle,
    vehicleLookup,
  })
