import { useMemo, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Card, Typography, Tooltip as SharedTooltip } from '@electro/shared-ui-components'
import { useDashboardSummary } from '@electro/fleets/src/components/DashboardSummary/hooks'
import { useFleetsSummaryStatsQuery } from '@electro/fleets/generated/graphql'
import { format } from 'date-fns'
import { Chart as ChartJS, Tooltip, ChartOptions, ArcElement, Plugin } from 'chart.js'
import { Loader } from '@electro/fleets/src/components/DashboardSummary/components'
import { QUERY_DATE_FORMAT } from '@electro/shared/constants'
import electroTheme from '@electro/shared/theme/electro'
import { scaleOrdinal } from '@electro/shared/utils/color'
import Image from 'next/image'
import { useBusinessEntityStore } from '@electro/fleets/src/hooks/stores'
import { MinusIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'

ChartJS.register(Tooltip, ArcElement)

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (d) => {
          const percentage = (+d.raw * 100).toFixed(2)
          return ` ${d.label} (${percentage}%)`
        },
      },
    },
    legend: {
      display: false,
    },
  },
}

export const ChargingByOperatorWidget = () => {
  const { t } = useTranslation('common')
  const chartRef = useRef<any>(null)
  const { startDate, endDate } = useDashboardSummary()
  const { data, loading } = useFleetsSummaryStatsQuery({
    variables: {
      startDate: format(startDate, QUERY_DATE_FORMAT),
      endDate: format(endDate, QUERY_DATE_FORMAT),
    },
  })
  const businessHasCharged = useBusinessEntityStore((state) => state.businessEntity?.hasCharged)
  const datasets = useMemo(() => {
    if (data?.fleetsSummaryStats?.combinedCpoSplit) {
      const cpoSplitNames = data?.fleetsSummaryStats?.combinedCpoSplit?.map(
        (cpoSplit) => cpoSplit.cpo.name,
      )
      const colors = scaleOrdinal(cpoSplitNames).all()

      return [
        {
          data: data?.fleetsSummaryStats?.combinedCpoSplit?.map((cpoSplit) => cpoSplit.percentage),
          backgroundColor: colors,
          borderWidth: 0,
        },
      ]
    }

    return [
      {
        data: [1],
        backgroundColor: electroTheme.extend.colors.tertiary.shade,
        borderWidth: 0,
      },
    ]
  }, [data?.fleetsSummaryStats?.combinedCpoSplit])

  const labels = useMemo(() => {
    if (data) {
      return data?.fleetsSummaryStats?.combinedCpoSplit?.map((cpoSplit) => cpoSplit.cpo.name)
    }

    return ['No data']
  }, [data])

  const noDataPlugin: Plugin<'doughnut'> = useMemo(
    () => ({
      id: 'textCenter',
      beforeDatasetDraw(chart) {
        const text = 'No data'
        const { ctx } = chart

        if (!data?.fleetsSummaryStats && !loading) {
          ctx.save()
          ctx.font = 'normal 16px sans-serif'
          ctx.fillStyle = 'white'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.fillText(text, chart.getDatasetMeta(0).data[0]?.x, chart.getDatasetMeta(0).data[0]?.y)
        }
      },
    }),
    [data, loading],
  )

  const chartData = useMemo(
    () => ({
      labels,
      datasets,
    }),
    [labels, datasets],
  )

  const widgetOutput = useMemo(() => {
    const summaryStats = data?.fleetsSummaryStats
    if (loading) {
      return [
        <div key="loader" className="flex-1 flex  justify-center items-center">
          <Loader />,
        </div>,
      ]
    }
    if (businessHasCharged && !summaryStats && !loading) {
      return [
        <MinusIcon
          key="minus-icon"
          className="w-6 h-6 absolute bottom-6 left-6 text-tertiary"
          data-testid="minus-icon-chargingByOperator"
        />,
      ]
    }
    if (!businessHasCharged && !summaryStats && !loading) {
      return [
        <div key="no-data-message" className="flex items-end">
          <Typography variant="p" className="text-tertiary">
            Start charging to see the most popular charging operators among your drivers.
          </Typography>
        </div>,
      ]
    }
    return [
      <Doughnut
        key="doughnut-chart"
        ref={chartRef}
        data={chartData}
        options={options}
        plugins={[noDataPlugin]}
        data-testid="cpo-split-chart"
      />,
    ]
  }, [loading, data?.fleetsSummaryStats, chartData, noDataPlugin, businessHasCharged])

  return (
    <Card frosted density="high" className="h-56 flex-1 p-6 flex flex-col justify-between">
      <div className="flex justify-between">
        <div className="flex gap-2 ">
          <Typography variant="h6" className="flex-wrap whitespace-normal">
            {t('dashboard.widgets.charging_by_operator.title')}
          </Typography>
          <SharedTooltip>
            <SharedTooltip.Trigger
              ariaLabel={t('dashboard.widgets.charging_by_operator.title')}
              className="flex align-middle"
            >
              <Image
                src="/images/information-circle-icon.svg"
                alt={t('dashboard.widgets.information_circle_icon')}
                width="25"
                height="25"
              />
            </SharedTooltip.Trigger>
            <SharedTooltip.Body>
              {t('dashboard.widgets.charging_by_operator.body')}
            </SharedTooltip.Body>
          </SharedTooltip>
        </div>
        <Image
          src="/images/dashboardIcons/cpo_split.svg"
          alt={t('dashboard.widgets.charging_by_operator.cpo_split_icon')}
          width="25"
          height="25"
        />
      </div>

      <div className="flex-1 flex mt-2 overflow-auto">{widgetOutput}</div>
    </Card>
  )
}
