import { tw } from '@electro/shared/utils/tailwind-merge'
import {
  SideBarNavigationHeader,
  SideBarNavigationSection,
} from '@electro/fleets/src/components/SideBarNavigation/components'
import { NavSection } from '@electro/fleets/src/components/SideBarNavigation/SideBarNavigation.types'
import { Button } from '@electro/shared-ui-components'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChartBarSquareIcon,
  MapIcon,
  CreditCardIcon,
  CogIcon,
  CurrencyDollarIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  RectangleGroupIcon,
  TruckIcon,
  GiftIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline'
import { useUiStore } from '@electro/fleets/src/hooks/stores'
import Link from 'next/link'
import Image from 'next/image'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { useFleetsOnboarding } from '@electro/fleets/src/hooks'
import { Transition } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { isDemoMode } from '@electro/fleets/src/utils/envFlagCheck'
import { COUNTRY_CODES } from '@electro/fleets/src/constants/countryCodes'
import { devOrPreviewEnv } from '@electro/shared/utils/isEnv'

const styles = {
  root: {
    default:
      '!block bg-base lg:flex fixed z-10 lg:duration-300 h-screen group/menuContainer w-screen lg:w-96',
    expanded: 'lg:w-96 ',
    contracted: 'lg:w-24',
    mobileOpen: '!block overflow-x-hidden !w-screen lg:!w-fit',
    mobileClosed: '!hidden lg:!block',
  },
  container: {
    root: 'relative lg:flex flex-col flex-1 overflow-auto lg:overflow-hidden h-full px-1',
    mobileContainer: 'z-40 bg-base w-full h-14 fixed lg:hidden',
  },
  eclipse: {
    first:
      'lg:absolute bg-[#6e1ee2] rounded-full w-[600px] h-[800px] blur-[180px] top-[85%] left-[-80%] pointer-events-none fixed ',
    second:
      'lg:absolute bg-[#2000FF] rounded-full w-[480px] h-[600px] blur-[250px] top-[10%] left-[60%] pointer-events-none fixed ',
    third:
      'lg:absolute bg-[#6018c8] rounded-full w-[480px] h-[600px] blur-[100px] top-[-5%] left-[100%] pointer-events-none fixed ',
  },
  nav: {
    root: 'flex-1 overflow-x-hidden overflow-y-auto z-10 relative',
  },
  menuButton: 'fixed lg:hidden top-2.5 left-5 z-50',
  logo: 'flex py-3 z-10 justify-center relative ',
  expand:
    'bg-secondary absolute lg:inline-block hidden lg:right-0 lg:top-[120px] lg:translate-x-1/2 rounded-full transition-opacity duration-300 opacity-0 lg:group-hover/menuContainer:opacity-100  overflow-y-auto overflow-x-hidden z-10',
}

export const SideBarNavigation = () => {
  const { t } = useTranslation('common')
  const { pathname } = useRouter()
  const expanded = useUiStore((state) => state.navigationExpanded)
  const setExpanded = useUiStore((state) => state.setNavigationExpanded)
  const openMobileNavigation = useUiStore((state) => state.openMobileNavigation)
  const toggleOpenMobileNavigation = useUiStore((state) => state.toggleOpenMobileNavigation)
  const { businessEntityStatus, driversStatus } = useFleetsOnboarding()

  const toggleSideNavigation = () => setExpanded(!expanded)

  const toggleMobileNavigation = () => {
    toggleOpenMobileNavigation()
    setExpanded(true)
  }

  const NAV_SECTIONS: NavSection[] = useMemo(() => {
    const disabledPaymentAndInvoice = !businessEntityStatus.hasDeliveryAddress
    const disabledDrivers = disabledPaymentAndInvoice || !businessEntityStatus.hasPaymentMethod
    const disabledElectrocards = disabledDrivers || !driversStatus.hasUploadDrivers

    const sections: NavSection[] = [
      {
        title: t('menu.heading.management'),
        navItems: [
          {
            label: t('dashboard.page.heading'),
            url: '/dashboard',
            icon: <RectangleGroupIcon className="h-7 w-7" />,
            active: pathname === '/dashboard',
          },
          {
            label: t('drivers.page.heading.drivers'),
            url: '/dashboard/drivers',
            icon: <UserIcon className="h-7 w-7" />,
            active: pathname.includes('drivers'),
            disabled: disabledDrivers,
            toolTipSection: {
              ariaLabel: 'drivers-menu-button',
              text: 'Create, update and find drivers here',
            },
          },
          ...(devOrPreviewEnv
            ? [
                {
                  label: t('reporting.page.header.reporting'),
                  url: '',
                  icon: <ChartBarSquareIcon className="h-7 w-7" />,
                  active: pathname.includes('reporting'),
                  children: [
                    {
                      label: t('reporting.page.header.new'),
                      url: '/dashboard/reporting',
                      icon: <ChartBarSquareIcon className="h-7 w-7" />,
                      active: pathname === '/dashboard/reporting',
                      toolTipSection: {
                        ariaLabel: 'reporting-menu-button',
                        text: t('reporting.page.navigation.tooltip'),
                      },
                    },
                    {
                      label: t('reporting.expenses.navigation.label'),
                      url: '/dashboard/reporting-expenses',
                      icon: <ChartBarSquareIcon className="h-7 w-7" />,
                      active: pathname.includes('reporting-expenses'),
                      toolTipSection: {
                        ariaLabel: 'reporting-expenses-menu-button',
                        text: t('reporting.expenses.navigation.tooltip'),
                      },
                    },
                  ],
                },
              ]
            : [
                {
                  label: t('reporting.page.header.reporting'),
                  url: '/dashboard/reporting',
                  icon: <ChartBarSquareIcon className="h-7 w-7" />,
                  active: pathname.includes('reporting'),
                  toolTipSection: {
                    ariaLabel: 'reporting-menu-button',
                    text: t('reporting.page.navigation.tooltip'),
                  },
                },
              ]),
          {
            label: t('map.page.heading.map'),
            url: '/dashboard/map',
            icon: <MapIcon className="h-7 w-7" />,
            active: pathname.includes('map'),
            toolTipSection: {
              ariaLabel: 'map-menu-button',
              text: 'Explore and locate chargers',
            },
          },
          {
            label: t('electrocards.title.header'),
            url: '/dashboard/electrocards',
            icon: <CreditCardIcon className="h-7 w-7" />,
            active: pathname.includes('electrocards'),
            disabled: disabledElectrocards,
            toolTipSection: {
              ariaLabel: 'electrocards-menu-button',
              text: 'Order Electrocards',
            },
          },
          {
            label: t('common.vehicles'),
            url: '/dashboard/vehicles',
            icon: <TruckIcon className="h-7 w-7" />,
            active: pathname.includes('vehicles'),
            disabled: disabledDrivers,
            toolTipSection: {
              ariaLabel: 'vehicles-menu-button',
              text: 'Add your EVs',
            },
          },
        ],
      },
      {
        title: t('menu.heading.settings'),
        navItems: [
          {
            label: t('admin.heading'),
            url: '/dashboard/admin',
            icon: <CogIcon className="h-7 w-7" />,
            active: pathname.includes('admin'),
          },
          {
            label: t('payment_and_invoices.page.heading'),
            url: '/dashboard/payment-and-invoices',
            icon: <CurrencyDollarIcon className="h-7 w-7" />,
            active: pathname.includes('payment-and-invoices'),
            disabled: disabledPaymentAndInvoice,
            toolTipSection: {
              ariaLabel: 'payment-and-invoices-menu-button',
              text: 'Add payment and download invoices',
            },
          },
          {
            label: t('credit_and_referrals.page.heading'),
            url: '/dashboard/credit-and-referrals',
            icon: <GiftIcon className="h-7 w-7" />,
            active: pathname.includes('credit-and-referrals'),
            showInCountries: [COUNTRY_CODES.GBR],
          },
          {
            label: t('workplace_charging.page.heading'),
            url: '/dashboard/workspace-charging',
            icon: <BuildingOfficeIcon className="h-7 w-7" />,
            active: pathname.includes('workspace-charging'),
            showInCountries: [COUNTRY_CODES.GBR],
          },
          {
            label: t('using_the_platform.page.heading'),
            url: '/dashboard/onboarding',
            icon: <BookOpenIcon className="h-7 w-7" />,
            active: pathname.includes('onboarding'),
          },
          {
            label: t('faq.page.heading'),
            url: '/dashboard/faqs',
            icon: <QuestionMarkCircleIcon className="h-7 w-7" />,
            active: pathname.includes('faqs'),
            toolTipSection: {
              ariaLabel: 'faqs-menu-button',
              text: t('menu.faq.tooltip'),
            },
          },
        ],
      },
    ]
    return sections.map((section) => ({
      ...section,
      navItems: section.navItems.filter((item) => {
        if (isDemoMode && item?.url.includes('workspace-charging')) {
          return null
        }
        return item !== null
      }),
    }))
  }, [
    businessEntityStatus.hasDeliveryAddress,
    businessEntityStatus.hasPaymentMethod,
    driversStatus.hasUploadDrivers,
    t,
    pathname,
  ])

  return (
    <>
      <div className={styles.container.mobileContainer}>
        <Button
          className={styles.menuButton}
          variant={openMobileNavigation ? 'default' : 'outline'}
          size="xs"
          onClick={toggleMobileNavigation}
        >
          <span className="px-4">{openMobileNavigation ? 'close' : 'menu'}</span>
        </Button>
      </div>
      <div
        className={tw({
          [styles.root.mobileOpen]: openMobileNavigation,
          [styles.root.mobileClosed]: !openMobileNavigation,
        })}
      >
        <Transition
          aria-label="sidebar-navigation"
          data-testid="side-navigation"
          unmount={false}
          show={expanded}
          as="aside"
          className={tw({
            [styles.root.default]: true,
            [styles.root.expanded]: expanded,
            [styles.root.contracted]: !expanded,
          })}
          enter="lg:transition-all lg:transform lg:ease-in-out lg:duration-300"
          enterFrom="lg:!w-24" // collapsed
          enterTo="lg:w-96"
          leave="lg:transition-all lg:transform lg:ease-in-out lg:duration-300"
          leaveFrom="lg:w-96"
          leaveTo="lg:!w-24" // collapsed
        >
          <div className={styles.container.root}>
            <div className={styles.eclipse.first} />
            <div className={styles.eclipse.second} />
            <div className={styles.eclipse.third} />

            <SideBarNavigationHeader />

            <nav id="navigation" className={styles.nav.root}>
              <ul>
                {NAV_SECTIONS.map((navSection) => (
                  <SideBarNavigationSection key={navSection.title} navSection={navSection} />
                ))}
              </ul>
            </nav>

            <div className={styles.logo}>
              <Link
                className="relative block w-full h-8"
                target="_blank"
                aria-label="Go to Octopus Energy"
                rel="noreferrer"
                href="https://octopus.energy"
              >
                <Transition
                  as="div"
                  className="lg:absolute lg:left-0 lg:w-96 flex justify-center mx-auto"
                  enter="transition-all duration-500"
                  enterFrom="opacity-0 translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition-all duration-500"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 translate-x-full"
                  show={expanded}
                >
                  <Image
                    src="/images/constantine-with-logo.svg"
                    width={150}
                    height={27}
                    alt="Logo with constantine"
                  />
                </Transition>
                <Transition
                  as="div"
                  className="absolute w-24 left-0 flex justify-center"
                  enter="transition-all duration-500"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition-all duration-500"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                  show={!expanded}
                >
                  <Image
                    src="/images/constantine.svg"
                    width={27}
                    height={27}
                    alt="Logo with constantine"
                  />
                </Transition>
              </Link>
            </div>
          </div>

          <div className={styles.expand}>
            <Button style={{ padding: 4 }} onClick={toggleSideNavigation}>
              {expanded ? (
                <ChevronLeftIcon className="w-6 h-6 " />
              ) : (
                <ChevronRightIcon className="w-6 h-6" />
              )}
            </Button>
          </div>
        </Transition>
      </div>
    </>
  )
}
