/* eslint-disable react-hooks/rules-of-hooks */
import FLEETS_DRIVER_SET_PASSWORD_MUTATION from '@electro/fleets/graphql/fleetsDriverSetPasswordMutation.graphql'

import { MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationFleetsDriverSetPasswordArgs } from '@electro/fleets/generated/graphql'

export type FleetsDriverSetPassword = Pick<Mutation, 'fleetsDriverSetPassword'>

type UsefleetsDriverSetPassword = [
  ApolloMutationFunction<FleetsDriverSetPassword>,
  MutationResult<FleetsDriverSetPassword>,
]

export function usefleetsDriverSetPassword(): UsefleetsDriverSetPassword {
  const [fleetsDriverSetPasswordMutation, fleetsDriverSetPasswordResult] = useMutation<
    FleetsDriverSetPassword,
    MutationFleetsDriverSetPasswordArgs
  >(FLEETS_DRIVER_SET_PASSWORD_MUTATION)

  return [fleetsDriverSetPasswordMutation, fleetsDriverSetPasswordResult]
}
