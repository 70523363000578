import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client'
import FLEETS_DRIVERS_RECEIPTS_AGGREGATE_QUERY from '@electro/fleets/graphql/fleetsDriversReceiptsAggregateQuery.graphql'

import { Query, QueryFleetsDriversReceiptsAggregateArgs } from '@electro/fleets/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchFleetsDriversReceiptsAggregate = Pick<Query, 'fleetsDriversReceiptsAggregate'>

type UseFetchFleetsDriversReceiptsAggregate = [
  ApolloLazyQueryFunction<FetchFleetsDriversReceiptsAggregate>,
  LazyQueryResult<FetchFleetsDriversReceiptsAggregate, OperationVariables>,
]

type FetchFleetsDriversReceiptsAggregateArgs = LazyQueryHookOptions<
  FetchFleetsDriversReceiptsAggregate,
  QueryFleetsDriversReceiptsAggregateArgs
>

export function useFetchFleetsDriversReceiptsAggregate(
  options?: FetchFleetsDriversReceiptsAggregateArgs,
): UseFetchFleetsDriversReceiptsAggregate {
  const [lazyFetchFleetsDriversReceiptsAggregate, FleetsDriversReceiptsAggregateResult] =
    useLazyQuery<FetchFleetsDriversReceiptsAggregate>(
      FLEETS_DRIVERS_RECEIPTS_AGGREGATE_QUERY,
      options,
    )

  return [lazyFetchFleetsDriversReceiptsAggregate, FleetsDriversReceiptsAggregateResult]
}
