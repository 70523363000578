import { Card, Typography } from '@electro/shared-ui-components'
import clsx from 'clsx'
import Image from 'next/image'

const styles = {
  root: '!pt-0',
  header: 'flex justify-center my-4',
  wrap: 'flex-wrap',
  list: 'space-y-4',
  nestedList: 'basis-full !ml-8 mt-4 space-y-2',
  spacing: 'flex space-x-2',
  text: 'flex-1',
}

export const FleetsForBusinessInfo = () => (
  <Card className={styles.root}>
    <div className={styles.header}>
      <Image
        src="/images/electroverse-logo-dark-business.svg"
        width={120}
        height={150}
        alt="Electroverse for business logo"
      />
    </div>

    <ul className={styles.list}>
      <li className={clsx(styles.spacing, styles.wrap)}>
        <div className={styles.spacing}>
          <Image src="/images/star.svg" width={24} height={24} alt="Star" />

          <Typography>2 free drivers</Typography>
        </div>

        <ul className={styles.nestedList}>
          <li className={styles.spacing}>
            <Image src="/images/star.svg" width={14} height={14} alt="Star" />

            <Typography className={styles.text}>
              £4.50 per month for each additional driver
            </Typography>
          </li>
        </ul>
      </li>

      <li className={clsx(styles.spacing, styles.wrap)}>
        <div className={styles.spacing}>
          <Image src="/images/star.svg" width={24} height={24} alt="Star" />

          <Typography>No additional transaction fees</Typography>
        </div>
      </li>

      <li className={styles.spacing}>
        <Image src="/images/star.svg" width={24} height={24} alt="Star" />

        <Typography className={styles.text}>
          1 free Electroverse RFID card for every driver
        </Typography>
      </li>

      <li className={styles.spacing}>
        <Image src="/images/star.svg" width={24} height={24} alt="Star" />

        <Typography className={styles.text}>Full driver and management functions</Typography>
      </li>

      <li className={styles.spacing}>
        <Image src="/images/star.svg" width={24} height={24} alt="Star" />

        <Typography className={styles.text}>No fixed contract - pay for what you use!</Typography>
      </li>
    </ul>
  </Card>
)
