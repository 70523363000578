import { Alert, Button, Modal } from '@electro/shared-ui-components'
import { useCallback, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { useAdminMenuActions } from '../../hooks/useAdminMenuActions'

interface ReactivateAdminUserModalScreenProps {
  onClose: () => void
}

export const ReactivateAdminUserModalScreen = ({
  onClose,
}: ReactivateAdminUserModalScreenProps) => {
  const { t } = useTranslation('common')
  const [{ loading, errorMessage, adminUser }, { reactivateAdminUser, resetErrorMessage }] =
    useAdminMenuActions()

  const handleConfirmation = useCallback(() => {
    reactivateAdminUser({ onSuccess: onClose })
  }, [reactivateAdminUser, onClose])

  useEffect(() => () => resetErrorMessage(), [errorMessage, resetErrorMessage])

  return (
    <>
      <Modal.Header>{t('admin.reactivate_admin_user_modal.heading')}</Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="common:admin.reactivate_admin_user_modal.body"
          components={{ adminUserEmail: <span>{adminUser?.user?.email}</span> }}
        />
        {errorMessage ? <Alert variant="error">{errorMessage}</Alert> : null}
      </Modal.Body>
      <Modal.Actions center stacked>
        <Button disabled={loading} onClick={handleConfirmation} fullWidth>
          {loading
            ? t('admin.reactivate_admin_user_modal.button.loading.reactivating')
            : t('admin.reactivate_admin_user_modal.button.reactivate')}
        </Button>
        <Button disabled={loading} variant="naked" onClick={onClose} fullWidth>
          {t('admin.admin_user_modal.button.no_take_me_back')}
        </Button>
      </Modal.Actions>
    </>
  )
}
