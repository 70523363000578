import { Button, Card, Tooltip, Typography } from '@electro/shared-ui-components'
import { DriverType } from '@electro/fleets/generated/graphql'
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'
import { DriverActionsDropDownMenu } from '@electro/fleets/src/components/DriverActionsDropDownMenu'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  driver: DriverType
}

export const DriverDetailsWidget = ({ driver }: Props) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const handleViewDriverReceipts = () =>
    router.push({
      pathname: `/dashboard/reporting/driver/${driver.pk}`,
      query: {
        showAllReceipts: true,
      },
    })
  const isDriverActive = driver?.isActive

  return (
    <Card className="col-span-4 p-4 lg:pt-8 w-full">
      <div className="flex flex-col justify-between lg:flex-row">
        <Typography variant="h3" className="flex-1">
          {t('drivers.widgets.driver_details.heading')}
        </Typography>

        <div className="flex mt-2 space-x-2 flex-2 justify-end">
          <Tooltip>
            <Tooltip.Trigger ariaLabel="view selected drivers receipts" delay={500}>
              <Button size="xs" onClick={handleViewDriverReceipts} className="lg:px-10">
                <DocumentTextIcon className="-mt-0.5 w-4 h-4 mr-1.5" />
                {t('drivers.widgets.driver_details.button.view_receipts')}
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Body>
              {t('drivers.widgets.driver_details.view_receipts.tooltip_body')}
            </Tooltip.Body>
          </Tooltip>

          <DriverActionsDropDownMenu driver={driver}>
            <DriverActionsDropDownMenu.Button as="div" noPadding>
              <Button size="xs" className="lg:px-10">
                {t('drivers.widgets.driver_details.button.driver_actions')}
                <ChevronDownIcon className="h-4 w-5" />
              </Button>
            </DriverActionsDropDownMenu.Button>

            <DriverActionsDropDownMenu.MenuItemList>
              <DriverActionsDropDownMenu.EditDetails />
              <DriverActionsDropDownMenu.ResetPassword />
              <DriverActionsDropDownMenu.ResendSignupEmail />
              {isDriverActive ? (
                <>
                  <hr className="my-1" />
                  <DriverActionsDropDownMenu.DeactivateDriver />
                </>
              ) : (
                <>
                  <hr className="my-1" />
                  <DriverActionsDropDownMenu.ReactivateDriver />
                </>
              )}
            </DriverActionsDropDownMenu.MenuItemList>
          </DriverActionsDropDownMenu>
        </div>
      </div>

      <div className="lg:grid grid-cols-1 gap-2 mt-4 lg:gap-4 lg:grid-cols-2 flex flex-col">
        <Typography variant="h5">Email</Typography>
        <Typography>{driver?.user?.email}</Typography>

        <Typography variant="h5">Mobile</Typography>
        <Typography>{driver?.mobileNumber || '-'}</Typography>
      </div>
    </Card>
  )
}
