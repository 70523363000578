import { DriverType } from '@electro/fleets/generated/graphql'

export interface DriverWidgetsProps {
  driver: DriverType
}

export enum ModalScreensEnum {
  ASSIGN_ELECTROCARD_MODAL_SCREEN = 'ASSIGN_ELECTROCARD_MODAL_SCREEN',
  REASSIGN_ELECTROCARD_MODAL_SCREEN = 'REASSIGN_ELECTROCARD_MODAL_SCREEN',
  DEACTIVATE_ELECTROCARD_MODAL_SCREEN = 'DEACTIVATE_ELECTROCARD_MODAL_SCREEN',
  ASSIGN_VEHICLE_MODAL_SCREEN = 'ASSIGN_VEHICLE_MODAL_SCREEN',
  ASSIGN_VEHICLE_SUCCESS_MODAL_SCREEN = 'ASSIGN_VEHICLE_SUCCESS_MODAL_SCREEN',
}
