import { Button, DateRangeType, Tooltip, useToastNotification } from '@electro/shared-ui-components'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { formatDateForQuery } from '@electro/fleets/src/utils/formatters'
import { DownloadFile } from 'libs/shared-ui-components/src/lib/DownloadFile'
import { fetchReceiptsCsv } from '@electro/fleets/src/services/rest'
import { BusinessEntityType, DriverType } from '@electro/fleets/generated/graphql'
import { format } from 'date-fns'
import capitalize from '@electro/shared/utils/capitalize'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { DemoModeWrapper } from '../DemoModeWrapper'

interface ReportDriverType {
  reportType: 'driver'
}

interface ReportBusinessType {
  reportType: 'business'
}

interface DriverTypeEntity {
  driver: DriverType
  business: null
}

interface BusinessTypeEntity {
  business: BusinessEntityType
  driver: null
}

type Props =
  | (ReportDriverType & DriverTypeEntity & DateRangeType)
  | (ReportBusinessType & BusinessTypeEntity & DateRangeType)

export const DownloadReportButton = ({
  reportType = 'driver',
  startDate,
  endDate,
  driver,
  business,
}: Props) => {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const startDateFileName = format(startDate, 'dd-MM-yyyy')
  const endDateFileName = format(endDate, 'dd-MM-yyyy')

  const nameOfEntity = useMemo(() => {
    if (reportType === 'driver') {
      return `${driver?.user.firstName}_${driver?.user.lastName}`
    }
    if (reportType === 'business') {
      return business?.name?.replace(/ /g, '_')
    }
    return 'Entity_not_found'
  }, [driver, business, reportType])

  return (
    <DemoModeWrapper show tooltip={t('demo.tooltip.no_csv_download')}>
      {({ isDemoMode }) => (
        <Tooltip>
          <Tooltip.Trigger ariaLabel={reportType.toLowerCase()} delay={500}>
            <DownloadFile
              onSuccess={() => {
                showToastNotification({
                  heading: t('common.success'),
                  body: (
                    <Trans
                      i18nKey="common:download_report_button.body.download_success"
                      components={{ reportType: <span>{capitalize(reportType)}</span> }}
                    />
                  ),
                  variant: 'success',
                  timeout: 5000,
                })
              }}
              onError={(error) => {
                showToastNotification({
                  heading: t('errors.common.error'),
                  body: (
                    <Trans
                      i18nKey="common:download_report_button.toast.error.body"
                      components={{ reportType: <span>{reportType.toLowerCase()}</span> }}
                    />
                  ),
                  variant: 'error',
                  timeout: 8000,
                })
              }}
              filename={`${nameOfEntity}_${startDateFileName}_to_${endDateFileName}_Report.csv`}
              fetcher={() =>
                fetchReceiptsCsv({
                  startDate: formatDateForQuery(startDate),
                  endDate: formatDateForQuery(endDate),
                  driverId: driver?.pk.toString(),
                })
              }
            >
              {({ fetchFileThenDownload, downloading }) => (
                <Button
                  disabled={isDemoMode || downloading}
                  onClick={fetchFileThenDownload}
                  variant="outline"
                >
                  <DocumentArrowDownIcon className="w-5 h-5 mr-1 -mt-0.5" />
                  {downloading
                    ? t('common.button.loading.downloading')
                    : t('download_report_button.button.download_csv')}
                </Button>
              )}
            </DownloadFile>
          </Tooltip.Trigger>
          <Tooltip.Body>
            {t('download_report_button.tooltip.body.download_charging_history')}
          </Tooltip.Body>
        </Tooltip>
      )}
    </DemoModeWrapper>
  )
}
