import { ApolloError } from '@apollo/client'
import { ErrorCode } from '@electro/fleets/generated/graphql'
import { getErrorMessages } from '@electro/fleets/src/utils/getErrorMessages'

export default async function getMessageFromError(error: ApolloError, locale) {
  const [firstError] = error?.graphQLErrors || []

  // If error has graphql error we get the first graphql error
  if (typeof firstError === 'object' && 'errorCode' in firstError) {
    const errorMessages = await getErrorMessages(locale)
    const errorCode = firstError?.errorCode as ErrorCode
    const errorMessage = errorMessages[errorCode] || errorMessages.DEFAULT_ERROR_MESSAGE

    return errorMessage
  }

  /**
   * Sometimes we get back normal error and not graphql errors. If error
   * does not include graphql error we will return default error message back
   */
  if (error?.message) {
    const errorMessages = await getErrorMessages(locale)

    return errorMessages.DEFAULT_ERROR_MESSAGE
  }

  return null
}
