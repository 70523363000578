import { ErrorCode } from '@electro/fleets/generated/graphql'

import getT from 'next-translate/getT'

const {
  CompanyLiquidated,
  CompanyHouseNumberNotFound,
  CharityNumberNotFound,
  VatRegistrationNotFound,
  SignupIntentAlreadyExists,
  FleetsBusinessEntityAlreadyExists,
  SignupTokenDoesNotExist,
  CreditCodeDoesNotExist,
  CreditCodeExpired,
  CreditCodeAlreadyRedeemed,
  InactiveUser,
} = ErrorCode

const DefaultErrorMessage = 'DEFAULT_ERROR_MESSAGE'

export const getErrorMessages = async (locale) => {
  const t = await getT(locale || 'en-GB', 'common')

  const errorMessages = {
    [InactiveUser]: t('errors.inactive_user'),
    [CompanyHouseNumberNotFound]: t('errors.company_house_number_not_found'),
    [CompanyLiquidated]: t('errors.company_liquidated'),
    [CharityNumberNotFound]: t('errors.charity_number_not_found'),
    [VatRegistrationNotFound]: t('errors.vat_registration_not_found'),
    [SignupIntentAlreadyExists]: t('errors.signup_intent_already_exists'),
    [FleetsBusinessEntityAlreadyExists]: t('errors.fleets_business_entity_already_exists'),
    [SignupTokenDoesNotExist]: t('errors.signup_token_does_not_exist'),
    [CreditCodeDoesNotExist]: t('errors.credit_code_does_not_exist'),
    [CreditCodeExpired]: t('errors.credit_code_expired'),
    [CreditCodeAlreadyRedeemed]: t('errors.credit_code_already_redeemed'),
    [DefaultErrorMessage]: t('errors.default_error_message'),
  }

  return errorMessages
}
